import AxiosConfig from '../serverAxiosConfig'
import firebase from '../../config/FirebaseConfig'

const create = async (lensId, reportPayload) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.post(
    `lenses/${lensId}/reports`,
    { ...reportPayload },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const getById = async (reportId) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.get(
    `/lenses/reports/${reportId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const getAllByLensId = async (lensId) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.get(
    `/lenses/${lensId}/reports`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}
export default {
  create,
  getById,
  getAllByLensId
}
