const performanceColumns = [
  {
    title: 'Results',
    key: 'results',
    description: 'The number of times your ads achieved an outcome, based on the objective you selected.',
    type: 'number'
  },
  {
    title: 'Result Rate',
    key: 'resultRate',
    description: 'The number of times your ads achieved an outcome, based on the objective you selected, divided by the number of impressions.',
    type: 'percent'
  },
  {
    title: 'Frequency',
    key: 'frequency',
    description: 'The average number of times each person saw your ad.',
    type: 'number'
  },
  {
    title: 'Impressions',
    key: 'impressions',
    description: 'The number of times your ads were on screen.',
    type: 'number'
  },
  {
    title: 'Views',
    key: 'views',
    description: 'The number of times your video was viewed for 3 seconds or more.',
    type: 'number'
  },
  {
    title: 'Delivery',
    key: 'delivery',
    description: 'The number of times your ads were on screen.',
    type: 'number'
  },
  {
    title: 'Ad Set Delivery',
    key: 'adSetDelivery',
    description: 'The number of times your ads were on screen.',
    type: 'number'
  },
  {
    title: 'Clicks (All)',
    key: 'clicksAll',
    description: 'The number of clicks on your ads.',
    type: 'number'
  },
  {
    title: 'CPC',
    key: 'cpcAll',
    description: 'The average cost for each click on your ads.',
    type: 'decimal'
  },
  {
    title: 'CTR (All)',
    key: 'ctr',
    description: 'The percentage of times people saw your ad and performed a click (all).',
    type: 'percent'
  },
  {
    title: 'Gross Impressions (includes invalid impressions from non-human traffic)',
    key: 'grossImpressions',
    description: 'The number of times your ads were on screen.',
    type: 'number'
  },
  {
    title: 'Auto-refresh Impressions',
    key: 'autoRefreshImpressions',
    description: 'The number of times your ads were on screen.',
    type: 'number'
  },
  {
    title: 'Attribution Setting',
    key: 'attributionSetting',
    description: 'Lorem Ipsum Dolor Sit Amet',
    type: 'number'
  },
  {
    title: 'Average Purchases Conversion Value',
    key: 'avgPurchasesConversionValue',
    description: 'The average value of purchases made through your ads.',
    type: 'decimal'
  }
]

const adRelevanceMetrics = [
  {
    title: 'Quality Ranking',
    key: 'qualityRanking',
    type: 'number'
  },
  {
    title: 'Engagement Rate Ranking',
    key: 'engagementRateRanking',
    type: 'number'
  },
  {
    title: 'Conversion Rate Ranking',
    key: 'conversionRateRanking',
    type: 'number'
  }
]

const adCostMetrics = [
  {
    title: 'Amount Spent',
    key: 'amountSpent',
    type: 'decimal'
  },
  {
    title: 'Cost per Result',
    key: 'costPerResult',
    type: 'decimal'
  },
  {
    title: 'Cost per 1000 People Reached',
    key: 'costPer1000PeopleReached',
    type: 'decimal'
  },
  {
    title: 'Cost per 1000 Impressions',
    key: 'costPer1000Impressions',
    type: 'decimal'
  },
  {
    title: 'Cost per Click (CPC)',
    key: 'costPerClick',
    type: 'decimal'
  },
  {
    title: 'Cost per 1000 Impressions (CPM)',
    key: 'costPer1000ImpressionsCPM',
    type: 'decimal'
  },
  {
    title: 'Cost per 1000 People Reached (CPP)',
    key: 'costPer1000PeopleReachedCPP',
    type: 'decimal'
  }
]

const performanceMetrics = {
  sectionId: 'performance',
  metrics: performanceColumns,
  subSections: [
    {
      sectionId: 'Ad Relevance Diagnostics',
      domId: 'ad-relevance-diagnostics',
      metrics: adRelevanceMetrics
    },
    {
      sectionId: 'Cost',
      domId: 'cost',
      metrics: adCostMetrics
    }
  ]
}
Object.freeze(performanceMetrics)

const performanceSearchList = [
  ...performanceColumns,
  ...adRelevanceMetrics,
  ...adCostMetrics
]

export {
  performanceMetrics,
  performanceSearchList
}
