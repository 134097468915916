<template>
    <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.91733 3.14282H7.16409V6.88308H3.42383V5.63633C3.42383 4.25867 4.53967 3.14282 5.91733 3.14282Z"
            :fill="color" />
        <path opacity="0.85" d="M13.3977 3.14282H8.41064V6.88308H13.3977V3.14282Z" :fill="color" />
        <path opacity="0.7"
            d="M12.7743 8.13013H8.41064V11.8704H11.5275C12.2161 11.8704 12.7743 11.3122 12.7743 10.6236V8.13013Z"
            :fill="color" />
        <path opacity="0.2"
            d="M17.1374 8.13013H14.0205V10.6236C14.0205 11.3122 14.5787 11.8704 15.2673 11.8704H17.1374V8.13013Z"
            :fill="color" />
        <path opacity="0.1"
            d="M13.3975 13.1169H17.1377V14.3637C17.1377 15.7414 16.0219 16.8572 14.6442 16.8572H13.3975V13.1169Z"
            :fill="color" />
        <path opacity="0.2"
            d="M10.9042 13.1169H8.41064V16.8572H12.1509V14.3637C12.1509 13.6751 11.5927 13.1169 10.9042 13.1169Z"
            :fill="color" />
        <path opacity="0.6" d="M14.6445 3.14282C16.0222 3.14282 17.138 4.25867 17.138 5.63633V6.88308H14.6445V3.14282Z"
            :fill="color" />
        <path opacity="0.85" d="M7.16409 8.13013H3.42383V11.8704H7.16409V8.13013Z" :fill="color" />
        <path opacity="0.6"
            d="M3.42383 13.1169H7.16409V16.8572H5.91733C4.53967 16.8572 3.42383 15.7414 3.42383 14.3637V13.1169Z"
            :fill="color" />
    </svg>

</template>

<script>
export default {
    name: 'ForeplayIcon',
    props: {
        width: {
            type: Number,
            default: 20
        },
        height: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: "#fff"
        }
    }
}
</script>

<style scoped></style>