<template>
  <BaseCard
    v-if="storyBoardInformation.length > 0"
    class="w-96 h-max mt-5 rounded-xl bg-white shadow-sm custom-width"
    border
  >
    <div
      class="flex justify-between"
      :class="videoScriptExpanded ? 'border-b border-neutral-50 border-opacity-80' : '' "
    >
      <div
        class="py-4 px-5 rounded-full flex"
      >
        <img
          src="../../../assets/icons/video-script-icon.svg"
          alt=""
          class=" w-10 h-10 rounded-full p-2.5 overflow-visible"
          style="box-shadow: 0px 1px 2px rgba(9, 25, 72, 0.13), 0px 0px 0px 1px rgba(18, 55, 105, 0.08);"
        >
        <div
          class="w-full ml-3 flex items-center"
        >
          <BaseText
            type="label"
            size="md"
            class="text-text-muted"
          >
            Video Script
          </BaseText>
        </div>
      </div>
      <img
        src="../../../assets/icons/chevron-down-small.svg"
        alt=""
        class="w-5 mr-5 transition-transform duration-300 cursor-pointer"
        :class="videoScriptExpanded ? 'rotate-180 transform' : ''"
        @click="toggleExpanded()"
      >
    </div>
    <div
      v-if="videoScriptExpanded "
    >
      <!-- Step 1 -->
      <div
        v-for="(script, index) in storyBoardInformation"
        :key="index"
        class="h-full px-5 flex gap-x-2 h-max w-full"
        :class="index === 0 ? 'pt-5' : ''"
      >
        <div
          class="flex flex-col items-center"
        >
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted w-6 h-8 border border-neutral-50 border-opacity-80 rounded-md flex items-center justify-center"
          >
            {{ index + 1 }}
          </BaseText>
          <div
            v-if="index !== storyBoardInformation.length - 1"
            class="flex-1 bg-border-normal self-center"
            style="width: 1px;"
          />
        </div>
        <div
          class="h-max flex flex-col pb-5 w-full"
        >
          <div
            class="h-8 flex items-center green-background bg-primary-green-10 rounded-md pl-2.5 w-full"
            :style="index === storyBoardInformation.length - 1 ? '' : ''"
          >
            <BaseText
              tyle="display"
              size="xs"
              class="text-primary-green-300 px-1.5 uppercase "
            >
              {{ script.scene[0].title }}
            </BaseText>
          </div>
          <BaseCard
            class="rounded-lg mt-3"
            border
          >
            <div
              class="flex flex-col justify-center gap-x-1.5 py-3 px-4 border-b border-neutral-50 border-opacity-80 w-full"
            >
              <div
                class="flex items-center"
              >
                <div
                  class="h-1 w-1 rounded-full bg-primary-green-50 mr-2"
                />
                <BaseText
                  tyle="display"
                  size="xs"
                  class="text-roboto text-neutral-400"
                >
                  SCRIPT COPY
                </BaseText>
              </div>
              <BaseText
                tyle="body"
                size="sm"
                class="text-text-muted"
              >
                <BaseContentEditable
                  disabled
                  no-padding
                  :read-only="true"
                  :current-value="script.copy"
                />
              </BaseText>
            </div>
            <div
              class="flex flex-col justify-center gap-x-1.5 py-3 px-4 w-full"
              :class="script.overlay ? 'border-b border-neutral-50 border-opacity-80' : 'border-b-0'"
            >
              <div
                class="flex items-center"
              >
                <div
                  class="h-1 w-1 rounded-full bg-primary-blue-50 mr-2"
                />
                <BaseText
                  tyle="display"
                  size="xs"
                  class="text-roboto text-neutral-400"
                >
                  ACTION & DESCRIPTION
                </BaseText>
              </div>
              <BaseText
                tyle="body"
                size="sm"
                class="text-text-muted"
              >
                <BaseContentEditable
                  disabled
                  no-padding
                  :read-only="true"
                  :current-value="script.visuals"
                />
              </BaseText>
            </div>
            <div
              v-if="script.overlay"
              class="flex flex-col justify-center gap-x-1.5 py-3 px-4 w-full"
            >
              <div
                class="flex items-center"
              >
                <div
                  class="h-1 w-1 rounded-full bg-primary-purple-50 mr-2"
                />
                <BaseText
                  tyle="display"
                  size="xs"
                  class="text-roboto text-neutral-400"
                >
                  {{ script.overlay && 'TEXT OVERLAY' }}
                </BaseText>
              </div>
              <BaseText
                tyle="body"
                size="sm"
                class="text-text-muted"
              >
                <BaseContentEditable
                  disabled
                  no-padding
                  :read-only="true"
                  :current-value="script.overlay"
                />
              </BaseText>
            </div>
          </BaseCard>
        </div>
      </div>
    </div>
  </basecard>
</template>

<script>
export default {
  name: 'VideoScript',
  props: {
    videoScriptExpanded: Boolean,
    storyBoardInformation: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  methods: {
    toggleExpanded () {
      if (this.videoScriptExpanded) this.$emit('update:videoScriptExpanded', false)
      else this.$emit('update:videoScriptExpanded', true)
    }
  }
}
</script>

<style scoped>
.green-background{
  background-image: url('../../../assets/images/green-striped-background.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.custom-width {
  width: 95%;
}

@media (min-width: 640px) {
  .custom-width {
    width: 584px;
  }
}
</style>
