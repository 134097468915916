<template>
  <div class="relative min-w-0" :class="expanded ? 'flex-grow' : 'flex-shrink'" style="z-index: 40001; max-width: 225px">
    <button class="w-full flex items-center pl-2 pr-1 py-1.5 rounded-md min-w-0" @click="$emit('expand')"
    :class="{'hover-shadow': !expanded}">
      <component :is="currentRouteItem.icon" class="text-icon-normal flex-shrink-0 mr-2" />
      <BaseText type="label" size="sm" class="text-text-muted flex-shrink truncate">
        {{ currentRouteItem.name || '' }}
      </BaseText>
      <ChevronGrabberIcon class="text-icon-disabled flex-shrink-0 ml-1" />
    </button>
    <!-- DROPDOWN -->
    <div v-if="expanded" class="route-switcher-dropdown flex flex-col gap-1 px-1 pt-1 pb-0.5 bg-neutral-25 shadow-md"
    v-on-clickaway="() => { $emit('collapse') }">
      <!-- top fade overlay -->
      <transition name="overlay">
        <div v-if="!isMinScroll" class="top-fade-overlay absolute top-10 left-0 right-0 h-3" />
      </transition>
      <!-- Current route item appears first -->
      <button class="w-full flex items-center pl-2 pr-1 py-1.5 rounded-md min-w-0 bg-white option-shadow" 
      @click="$emit('collapse')">
        <component :is="currentRouteItem.icon" class="text-icon-normal flex-shrink-0 mr-2" />
        <BaseText type="label" size="sm" class="text-text-muted flex-shrink truncate mr-1">
          {{ currentRouteItem.name || '' }}
        </BaseText>
        <ChevronGrabberIcon class="text-icon-disabled flex-shrink-0 ml-auto" />
      </button>
      <!-- Other folders -->
      <div ref="route-list" class="w-full flex flex-col gap-1 overflow-y-scroll scrollbar-hide pb-0.5" style="max-height: 200px"
      @scroll="handleDropdownScroll">
        <button v-for="(routeOption, index) in routeOptions" :key="`folder-option-${index}`" @click="routeOption.action"
        class="w-full flex items-center pl-2 pr-1 py-1.5 rounded-md min-w-0 transition-colors hover:bg-neutral-50">
          <component :is="routeOption.icon" class="text-icon-normal flex-shrink-0 mr-2" />
          <BaseText type="label" size="sm" class="text-text-muted flex-shrink truncate">
            {{ routeOption.name || '' }}
          </BaseText>
        </button>
      </div>
      <!-- bottom fade overlay -->
      <transition name="overlay">
        <div v-if="listOverflows && !isMaxScroll" class="bottom-fade-overlay absolute bottom-0.5 left-0 right-0 h-4" />
      </transition>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'
import ChevronGrabberIcon from '../globals/Icons/ChevronGrabberIcon.vue'

export default {
  name: 'RouteSwitcherDropdown',
  mixins: [clickaway],
  components: {
    ChevronGrabberIcon
  },
  props: {
    expanded: {
      type: Boolean,
      default: false
    },
    currentRouteItem: {
      type: Object,
      default: () => {}
    },
    routeOptions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      listOverflows: false,
      isMinScroll: true,
      isMaxScroll: false,
    }
  },
  watch: {
    expanded (val) {
      if (!val) return
      this.$nextTick(() => {
        this.computeDropdownOverflows()
      })
    }
  },
  methods: {
    handleDropdownScroll (event) {
      const scroller = event.target
      if (!scroller) return
      this.isMinScroll = Math.floor(scroller.scrollTop) === 0
      this.isMaxScroll = Math.abs(scroller.clientHeight - (scroller.scrollHeight - Math.ceil(scroller.scrollTop))) <= 2
    },
    computeDropdownOverflows () {
      const elem = this.$refs['route-list']
      if (elem) {
        this.listOverflows = elem.scrollHeight > elem.clientHeight
      }
    },
  }
}
</script>

<style scoped>
.hover-shadow {
  transition: box-shadow 150ms ease-in-out;
  box-shadow: none;
}
.hover-shadow:hover, .option-shadow {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}
.route-switcher-dropdown {
  position: absolute;
  left: -4px;
  top: -4px;
  right: -4px;
  z-index: 10;
  border-radius: 10px;
}
.bottom-fade-overlay, .top-fade-overlay {
  z-index: 10;
  pointer-events: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  opacity: 1 !important;
}
.bottom-fade-overlay {
  background: linear-gradient(to bottom, transparent, #F6F8FA);
  transform-origin: bottom;
}
.top-fade-overlay {
  background: linear-gradient(to top, transparent, #F6F8FA);
  transform-origin: top;
}
.overlay-enter-active, .overlay-leave-active {
  transition: transform 150ms ease-in-out;
}
.overlay-enter-from, .overlay-enter, .overlay-leave-to {
  transform: scaleY(0.2);
}
.overlay-enter-to, .overlay-leave-from {
  transform: scaleY(1);
}
</style>