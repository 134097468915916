<template>
  <div class="card-container relative flex flex-col gap-1 rounded-xl bg-white py-1.5 px-0.5">
    <div class="w-full px-1">
      <div class="w-full flex items-center gap-0.5 rounded-md p-1.5 transition-colors hover:bg-neutral-25 cursor-default"
      @mouseenter="tooltipVisible = true" @mouseleave="tooltipVisible = false">
        <DCOIcon class="ml-auto text-text-subdued" />
        <BaseText type="label" size="sm" class="text-text-muted mr-auto">
          DCO
        </BaseText>
      </div>
    </div>
    <div class="preview-list px-1" :style="{gridTemplateRows: `repeat(${gridRows}, auto)`}">
      <DCOPreviewItem 
        v-for="(card, index) in cards" :key="`dco-card-${index}`"
        :asset="card.image || card.thumbnail" 
        :isSelected="carouselIndex === index"
        :doAnimate="doAnimate"
        @selected="() => {handlePreviewSelected(index)}" 
      />
    </div>
    <!-- DCO info tooltip -->
    <transition>
      <div v-if="tooltipVisible" class="info-tooltip p-1 rounded-xl pointer-events-none">
        <BaseText type="body" size="sm" class="text-white p-4">
          This ad uses a dynamic Meta product where advertisers provide a combination of images, text and platform-specific preferences so Meta can automatically create the right combination for the audience.
        </BaseText>
      </div>
    </transition>
  </div>
</template>

<script>
import DCOPreviewItem from './DCOPreviewItem.vue'
import DCOIcon from '../../globals/Icons/DCOIcon.vue'

export default {
  name: 'DCOPreviewSelector',
  components: {
    DCOPreviewItem,
    DCOIcon
  },
  props: {
    cards: {
      type: Array,
      required: true
    },
    carouselIndex: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      tooltipVisible: false,
      doAnimate: false,
    }
  },
  computed: {
    gridRows () {
      if (this.cards.length <= 6) return this.cards.length
      return Math.ceil(this.cards.length / 2)
    }
  },
  watch: {
    carouselIndex () {
      this.doAnimate = true
    },
    cards () {
      this.doAnimate = false
    }
  },
  methods: {
    handlePreviewSelected (index) {
      this.$emit('update:carouselIndex', index)
    }
  }
}
</script>

<style scoped>
.card-container {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}
.preview-list {
  display: grid;
  grid-auto-flow: column;
  gap: 4px;
  max-height: 405px;
  overflow-y: auto;
}
.preview-list::-webkit-scrollbar {
  width: 3px;
}
.preview-list::-webkit-scrollbar-thumb {
  background-color: #DFE1E7;
  border-radius: 18px;
}
.info-tooltip {
  position: absolute;
  top: 0px;
  left: calc(100% + 8px);
  width: 340px;
  background-color: rgba(6, 7, 16, 0.92);
  backdrop-filter: blur(4px);
}

.v-enter-active, .v-leave-active {
  transition: opacity 150ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
</style>