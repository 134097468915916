<template>
  <div class="flex gap-3">
    <div class="relative flex items-center gap-1 z-50">
      <div
        v-if="!getAppliedPreset?.name"
        class="h-8 skeleton w-36 rounded-xl"
      />
      <BaseSingleDropdown
        v-else
        :trigger-class="'h-8'"
        :selected-obj="getAppliedPreset?.name"
        :options="getPresets"
        min-width="130px"
        placeholder="Select a preset"
        :option-label="(opt) => opt.name"
        :selected-label="(opt) => `Preset ${opt}`"
        @change="updateCurrentPreset"
      >
        <template #icon>
          <PresetIcon class="text-icon-normal" />
        </template>
      </BaseSingleDropdown>
    </div>
    <div
      v-on-clickaway="() => { showDropdown.tableSettings = false }"
      class="relative"
    >
      <button
        class="flex items-center gap-1 base-btn"
        @click="showDropdown.tableSettings = !showDropdown.tableSettings"
      >
        <SettingsIcon class="text-icon-normal" />
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted"
        >
          Table Settings
        </BaseText>
        <div
          class="transform transition-transform duration-200"
          :style="showDropdown.tableSettings ? 'transform: scale(1, -1)' : ''"
        >
          <ChevronIcon class-name="text-icon-disabled" />
        </div>
      </button>

      <transition name="fade">
        <div
          v-if="showDropdown.tableSettings"
          class="p-1 absolute w-80 left-0 mt-1 border border-border-normal bg-white overflow-y-hidden dropdown-scrollable shadow-md rounded-lg"
        >
          <!-- Color formatting -->
          <div class="flex items-center justify-between gap-x-2 setting-option">
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              Color formatting
            </BaseText>
            <BaseSingleDropdown
              :selected-obj="getTableConfig.colorFormat"
              :options="colorFormatOptions"
              placeholder="Select a format"
              :trigger-class="'h-7'"
              @change="updateTableSettings('colorFormat', $event)"
            />
          </div>
          <!-- Results per page row -->
          <div class="flex items-center justify-between setting-option">
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted col-span-8"
            >
              Results per page
            </BaseText>
            <div
              v-on-clickaway="() => { showDropdown.resultsPer = false }"
              class="relative w-max"
              @click="showDropdown.resultsPer = !showDropdown.resultsPer"
            >
              <div
                class="flex gap-2 h-7 items-center p-2 py-1.5 rounded-md bg-white hover:bg-background-normal border border-border-normal cursor-pointer transition-colors"
                :class="`${showDropdown.resultsPer && 'bg-background-normal'}`"
              >
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-muted"
                >
                  {{ getCurrentPagination.limit }}
                </BaseText>
                <div
                  class="transform transition-transform duration-200"
                  :style="showDropdown.resultsPer ? 'transform: scale(1, -1)' : ''"
                >
                  <ChevronIcon class-name="text-icon-disabled" />
                </div>
              </div>
              <transition name="fade">
                <div
                  v-if="showDropdown.resultsPer"
                  style="z-index: 99999"
                  class="absolute w-full min-w-max bg-white right-0 p-1 overflow-y-auto max-h-72 dropdown-scrollable  shadow-md rounded-lg flex flex-col gap-2"
                >
                  <button
                    v-for="option in paginationOptions"
                    :key="option.name"
                    class="w-full bg-white hover:bg-background-normal rounded-md py-1 px-2 text-left flex items-center gap-1"
                    :class="{ 'bg-background-normal': option === getCurrentPagination.limit }"
                    @click="updateTableSettings('pagination', option)"
                  >
                    <div v-show="option === getCurrentPagination.limit">
                      <CheckmarkIcon />
                    </div>
                    <BaseText size="sm">
                      {{ option.name }}
                    </BaseText>
                  </button>
                </div>
              </transition>
            </div>
          </div>
          <!-- Status row -->
          <div class="flex justify-between items-center setting-option">
            <BaseText
              size="sm"
              type="label"
              class="text-text-muted"
            >
              Show status
            </BaseText>
            <BaseToggle
              :value="getTableConfig.showStatus"
              @toggle="updateTableSettings('showStatus', !getTableConfig.showStatus)"
            />
          </div>
          <!-- Tags row -->
          <div class="flex justify-between items-center setting-option">
            <BaseText
              size="sm"
              type="label"
              class="text-text-muted"
            >
              Show tags
            </BaseText>
            <BaseToggle
              :value="getTableConfig.showTags"
              @toggle="updateTableSettings('showTags', !getTableConfig.showTags)"
            />
          </div>
          <!-- Divider -->
          <div class="w-full h-0.5 border-t border-border-normal my-3" />
          <!-- included kpis -->
          <div class=" max-h-44 dropdown-scrollable overflow-y-auto">
            <SelectedKPISidebar
              hide-title
              no-padding
              no-border
              :kpi-order="getLocalPreset?.tableColumns"
              @change="handleKpiChange"
            />
          </div>
          <!-- Divider -->
          <div class="w-full h-0.5 border-t border-border-normal my-3" />
          <button
            class="w-full flex items-center gap-1.5 hover:bg-background-hover p-1.5 rounded-md cursor-pointer transition-colors relative"
            @click="showColumnDrawer"
          >
            <PlusIcon class="text-icon-normal" />
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              Add Metric
            </BaseText>
          </button>
        </div>
      </transition>
    </div>
    <div>
      <button
        class="w-full flex items-center gap-1.5 hover:bg-background-hover p-1.5 rounded-md cursor-pointer transition-colors relative"
        @click="showColumnDrawer"
      >
        <PlusIcon class="text-icon-normal" />
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted"
        >
          Add Metric
        </BaseText>
      </button>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'
import { mapGetters, mapMutations } from 'vuex'

// Icons
import SettingsIcon from '../../globals/Icons/SettingsIcons/SettingsIcon.vue'
import ChevronIcon from '../../globals/Icons/ChevronIcon.vue'
import CheckmarkIcon from '../../globals/Icons/CheckmarkIcon.vue'
import PlusIcon from '../../globals/Icons/PlusIcon.vue'
// Components
import SelectedKPISidebar from '../settings/SelectedKPISidebar.vue'
import { mapActions } from 'vuex/dist/vuex.common.js'
import BaseSingleDropdown from '../../globals/BaseSingleDropdown.vue'
import PresetIcon from '../../globals/Icons/LensIcons/PresetIcon.vue'

export default {
  components: {
    // Components
    SelectedKPISidebar,
    BaseSingleDropdown,

    // Icons
    SettingsIcon,
    ChevronIcon,
    CheckmarkIcon,
    PlusIcon,
    PresetIcon
  },
  mixins: [clickaway],
  data () {
    // TODO @Sam: Update
    const colorFormatOptions = [
      'Positive Outlier',
      'Negative Outlier',
      'Something Outlier'
    ]
    return {
      colorFormatOptions,
      paginationOptions: [],
      showDropdown: {
        tableSettings: false,
        resultsPer: false
      }
    }
  },
  computed: {
    ...mapGetters('LensModule', ['getTableConfig', 'getCurrentPagination', 'getLocalPreset', 'getPresets', 'getAppliedPreset'])
  },
  watch: {
    getCurrentPagination (newVal) {
      const totalPages = newVal?.totalPages
      const totalItems = newVal?.totalItems

      this.paginationOptions = Array.from({ length: totalPages }, (_, i) => ({
        name: `${(i + 1) * Math.ceil(totalItems / totalPages)} results`,
        value: (i + 1) * Math.ceil(totalItems / totalPages)
      }))
    }
  },
  methods: {
    ...mapActions('LensModule', ['applyPreset', 'setPresetById', 'updateTableConfigField']),
    ...mapMutations('LensModule', ['SET_COLUMN_DRAWER_VIEW']),

    showColumnDrawer () {
      this.SET_COLUMN_DRAWER_VIEW(true)
    },
    handleKpiChange () {
      this.applyPreset(this.getLocalPreset)
    },
    updateCurrentPreset (preset) {
      this.setPresetById({ id: preset.id, shouldSelect: true })
    },
    updateTableSettings (key, value) {
      this.updateTableConfigField({ key, value })
    }
  }
}
</script>

<style scoped>
.dropdown-scrollable {
  transition: colors 250ms ease-in-out;
  top: 100%;
  z-index: 999;
}

.dropdown-scrollable::-webkit-scrollbar {
  width: 3px;
}

.dropdown-scrollable::-webkit-scrollbar-thumb {
  background-color: #DFE1E7;
  border-radius: 18px;
}

.base-btn {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 100ms ease-in-out, background-color 100ms ease-in-out;
  border-radius: 6px;
  padding: 6px 12px 6px 6px;
}

.base-btn:hover {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.17), 0px 0px 0px 1px rgba(0, 56, 108, 0.12);
}

.setting-option {
  padding: 0.25rem 0.5rem 0.25rem 0.75rem;
}
</style>
