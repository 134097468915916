<template>
    <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_13014_2189)">
            <path
                d="M3.3418 15.2913H6.86689L7.53099 10.9747L7.66998 10.0789L8.46921 4.86655H13.2491C14.0484 4.86655 14.7858 5.06346 15.3881 5.38779C15.3959 4.90516 15.3186 4.43798 15.1603 4.00941C14.6043 2.48817 13.0908 1.39551 11.1719 1.39551H5.46535L3.34566 15.2913H3.3418Z"
                fill="#002991" style="fill:#002991;fill:color(display-p3 0.0000 0.1608 0.5686);fill-opacity:1;" />
            <path
                d="M7.66587 10.0828L7.52687 10.9785L6.86278 15.2951L6.29907 18.77H9.801L10.6234 13.5577H12.5925C14.9941 13.5577 17.0249 11.8009 17.4149 9.38779C17.6736 7.69667 16.8319 6.15226 15.384 5.39551C15.384 7.31057 13.8396 9.5345 11.4265 9.99782C11.1292 10.0557 10.8203 10.0866 10.4998 10.0866H7.66587V10.0828Z"
                fill="#60CDFF" style="fill:#60CDFF;fill:color(display-p3 0.3765 0.8039 1.0000);fill-opacity:1;" />
            <path
                d="M8.46524 4.87061L7.66602 10.083H10.5C10.8205 10.083 11.1332 10.0521 11.4266 9.99416C13.8398 9.53084 15.3842 7.3069 15.3842 5.39184C14.778 5.06752 14.0444 4.87061 13.2452 4.87061H8.46524Z"
                fill="#008CFF" style="fill:#008CFF;fill:color(display-p3 0.0000 0.5490 1.0000);fill-opacity:1;" />
        </g>
    </svg>


</template>

<script>
export default {
    name: 'PayPalIcon',
    props: {
        width: {
            type: Number,
            default: 20
        },
        height: {
            type: Number,
            default: 20
        }
    }
}
</script>

<style scoped></style>