<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M5.05 10H5.2H5.05ZM9.925 10H10.075H9.925ZM5.5 10C5.5 10.2071 5.33211 10.375 5.125 10.375C4.91789 10.375 4.75 10.2071 4.75 10C4.75 9.79292 4.91789 9.625 5.125 9.625C5.33211 9.625 5.5 9.79292 5.5 10ZM10.375 10C10.375 10.2071 10.2071 10.375 10 10.375C9.79292 10.375 9.625 10.2071 9.625 10C9.625 9.79292 9.79292 9.625 10 9.625C10.2071 9.625 10.375 9.79292 10.375 10ZM11.125 5.125C11.125 5.7463 10.6213 6.25 10 6.25C9.3787 6.25 8.875 5.7463 8.875 5.125C8.875 4.5037 9.3787 4 10 4C10.6213 4 11.125 4.5037 11.125 5.125Z" 
    fill="currentColor"/>
    <path d="M5.05 10H5.2M9.925 10H10.075M14.8 10H14.95M5.05 14.865H5.2M14.8 14.865H14.95M5.5 10C5.5 10.2071 5.33211 10.375 5.125 10.375C4.91789 10.375 4.75 10.2071 4.75 10C4.75 9.79293 4.91789 9.625 5.125 9.625C5.33211 9.625 5.5 9.79293 5.5 10ZM10.375 10C10.375 10.2071 10.2071 10.375 10 10.375C9.79293 10.375 9.625 10.2071 9.625 10C9.625 9.79293 9.79293 9.625 10 9.625C10.2071 9.625 10.375 9.79293 10.375 10ZM11.125 5.125C11.125 5.7463 10.6213 6.25 10 6.25C9.3787 6.25 8.875 5.7463 8.875 5.125C8.875 4.5037 9.3787 4 10 4C10.6213 4 11.125 4.5037 11.125 5.125ZM15.25 10C15.25 10.2071 15.0821 10.375 14.875 10.375C14.6679 10.375 14.5 10.2071 14.5 10C14.5 9.79293 14.6679 9.625 14.875 9.625C15.0821 9.625 15.25 9.79293 15.25 10ZM5.5 14.865C5.5 15.0121 5.33211 15.24 5.125 15.24C4.91789 15.24 4.75 15.0121 4.75 14.865C4.75 14.5979 4.91789 14.43 5.125 14.49C5.33211 14.43 5.5 14.5979 5.5 14.865ZM15.25 14.865C15.25 15.0721 15.0821 15.24 14.875 15.24C14.6679 15.24 14.5 15.0721 14.5 14.865C14.5 14.6579 14.6679 14.49 14.875 14.49C15.0821 14.49 15.25 14.6579 15.25 14.865Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="square"/>
  </svg>
</template>

<script>
export default {
  name: 'AdPersonaIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>