<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M11.8748 13C14.5672 13 16.7498 10.8174 16.7498 8.125C16.7498 7.56278 16.6546 7.02281 16.4795 6.52028L14.4998 8.5C13.6713 9.32845 12.3282 9.32845 11.4998 8.5C10.6713 7.67157 10.6713 6.32843 11.4998 5.5L13.4795 3.52028C12.977 3.34517 12.437 3.25 11.8748 3.25C9.18235 3.25 6.99978 5.43261 6.99978 8.125C6.99978 8.7898 7.13285 9.42355 7.3738 10.001L3.74793 13.6269C3.16215 14.2126 3.16215 15.1624 3.74793 15.7481L4.25162 16.2519C4.83741 16.8376 5.78715 16.8376 6.37293 16.2519L9.9988 12.626C10.5762 12.8669 11.21 13 11.8748 13Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IntegrationsSettingsIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>