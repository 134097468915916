<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="0.75"
      width="18.5"
      height="18.5"
      rx="5.25"
      stroke="#DF1C41"
      style="stroke:#DF1C41;stroke:color(display-p3 0.8745 0.1098 0.2549);stroke-opacity:1;"
      stroke-width="1.5"
    />
    <path
      d="M10 6.25V9.25"
      stroke="#DF1C41"
      style="stroke:#DF1C41;stroke:color(display-p3 0.8745 0.1098 0.2549);stroke-opacity:1;"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M10 13.25H10.01M10.25 13.25C10.25 13.3881 10.1381 13.5 10 13.5C9.8619 13.5 9.75 13.3881 9.75 13.25C9.75 13.1119 9.8619 13 10 13C10.1381 13 10.25 13.1119 10.25 13.25Z"
      stroke="#DF1C41"
      style="stroke:#DF1C41;stroke:color(display-p3 0.8745 0.1098 0.2549);stroke-opacity:1;"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ErrorIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

  <style scoped></style>
