<template>
  <div class="bg-white w-full flex-wrap relative"
  :class="{'h-max': expandCopy }">
    <BaseText ref="text" type="body" size="sm"
    class="text-text-muted flex items-center gap-2 flex-wrap break-words text-sm font-normal px-4 pt-1 bg-white w-full pb-1"
    :class="{'max-h-36 overflow-hidden': !expandCopy }"
    v-html="getCopy"/>
    <div v-if="textOverflows && !expandCopy"
    class="flex justify-center items-end gap-2 w-full px-3 absolute -bottom-px width-full bg-gradient-to-t h-4/5 from-white to-transparent">
      <button class="view-more-button relative bottom-px group px-2.5 py-0.5 rounded-full shadow-sm transition-colors bg-white hover:bg-neutral-25
      border border-border-normal hover:border-transparent"
      @click="handleReadMore">
        <BaseText type="label" size="xs" class="relative text-text-muted transition-colors group-hover:text-text-loud z-50" >
          Read More
        </BaseText>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdvertisementInformation',
  props: {
    advertisement: {
      type: Object,
      default: () => {}
    },
    openDetailsDrawer: {
      type: Function,
      default: () => {}
    },
    allAdSettings: {
      type: Boolean,
      default: () => false
    },
    startExpanded: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      textOverflows: false,
      expandCopy: false
    }
  },
  created () {
    this.expandCopy = this.startExpanded
  },
  mounted () {
    if (!this.expandCopy) this.determineTextOverflows()
  },
  computed: {
    getCopy () {
      if (['dpa', 'dco'].includes(this.advertisement.type)) {
        return this.advertisement.cards[0]?.description?.replace(/\n/g, '<br>')
      }

      return this.advertisement.description?.replace(/\n/g, '<br>')
    }
  },
  watch: {
    advertisement () {
      this.expandCopy = false
      this.determineTextOverflows()
    }
  },
  methods: {
    determineTextOverflows () {
      // Combining nextTick and requestAnimationFrame ensures the DOM is fully rendered first
      this.$nextTick(() => {
        requestAnimationFrame(() => {
          this.textOverflows = this.$refs.text.$el.scrollHeight > 144
        })
      })
    },
    handleReadMore () {
      if (this.allAdSettings) {
        this.expandCopy = true
        this.$emit('expandCopy')
      } else {
        this.openDetailsDrawer(true)
      }
    }
  }
}
</script>

<style scoped>
</style>
