<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M10.75 4.75H4.75C3.92157 4.75 3.25 5.42157 3.25 6.25V13.75C3.25 14.5784 3.92157 15.25 4.75 15.25H15.25C16.0784 15.25 16.75 14.5784 16.75 13.75V10.75M3.24854 7.75C5.12896 9.16278 7.46653 10 9.99962 10C11.0387 10 12.0448 9.85915 13 9.59545M18.25 5.5C18.25 6.74264 17.2427 7.75 16 7.75C14.7573 7.75 13.75 6.74264 13.75 5.5C13.75 4.25736 14.7573 3.25 16 3.25C17.2427 3.25 18.25 4.25736 18.25 5.5Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'EmailSentIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>