<template>
  <BaseModal @close="$emit('close')" cardWidth="520px" noPadding noToolbar noExit>
    <template #default>
      <!-- CONNECTION STAGE -->
      <div v-if="stage === 'connect'" class="w-full">
        <!-- Modal Header -->
        <div class="relative flex flex-col gap-1 p-5 border-b border-border-normal">
          <div class="flex items-center gap-2">
            <InstagramPlatformIcon :width="18" :height="18" />
            <BaseText type="label" size="md" class="text-text-muted">
              {{ modalTitle }}
            </BaseText>
          </div>
          <BaseText type="body" size="sm" class="text-text-normal">
            Send ads to @foreplay_co using your account.
          </BaseText>
          <button class="absolute top-3 right-3 p-1" @click="$emit('close')">
            <TagRemoveIcon stroke="#5E6678" :width="16" :height="16" />
          </button>
        </div>
        <!-- Modal Content -->
        <div class="w-full flex flex-col p-5">
          <BaseText type="label" size="sm" class="text-text-muted">
            Your IG Handle
          </BaseText>
          <input v-model="username" class="form-input w-full mt-1 px-2 py-1.5 rounded-md" placeholder="foreplay_co" />
          <div class="flex items-center gap-1 mt-3">
            <BaseText type="label" size="sm" class="text-text-muted">
              Location
            </BaseText>
            <div class="relative">
              <InfoResponseIcon :width="18" :height="18" class="text-icon-muted opacity-80" 
              @mouseenter.native="tooltipHovered = true" @mouseleave.native="tooltipHovered = false"/>
              <transition>
                <div v-if="tooltipHovered" class="label-tooltip">
                  <BaseText type="body" size="sm" class="text-white">
                    The board(s) where your Instagram ads will be saved.
                  </BaseText>
                </div>
              </transition>
            </div>
          </div>
          <BaseMultiSelect 
            class="mt-1"
            :items="boardItems"
            :selections.sync="selectedBoards"
            :icon="dropdownIcon"
            emptyLabel="Select boards"
          />
        </div>
        <!-- Modal Actions Footer -->
        <div class="w-full flex items-center justify-between p-5 border-t border-border-normal">
          <button class="px-2 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-25"
          @click="$emit('close')">
            <BaseText type="label" size="sm" class="text-text-muted">
              Cancel
            </BaseText>
          </button>
          <button class="submit-btn px-3 py-1.5 rounded-md" :class="submitEnabled ? 'enabled' : 'disabled'"
          :disabled="loadingSubmit || !submitEnabled" @click="connectInstagram">
            <BaseLoadingSpinner v-if="loadingSubmit" small style="width: 57px" />
            <BaseText v-else type="label" size="sm" class="transition-colors duration-100 whitespace-nowrap"
            :class="submitEnabled ? 'text-white' : 'text-text-disabled'">
              {{ submitText }}
            </BaseText>
          </button>
        </div>
      </div>
      <!-- VERIFICATION STAGE -->
      <div v-else class="w-full">
        <div class="w-full flex flex-col">
          <div class="w-full flex flex-col p-5 border-b border-border-normal">
            <div class="w-full flex items-center justify-center gap-5 mt-2">
              <img src="../../../assets/icons/ig-verify-1.svg">
              <img src="../../../assets/icons/ig-verify-2.svg">
            </div>
            <BaseText type="label" size="md" class="mt-4 mx-auto text-text-muted">
              Verify your account
            </BaseText>
            <BaseText type="body" size="sm" class="mt-2 mx-auto text-text-normal text-center">
              Message the code below to <span class="text-text-muted">@foreplay_co</span> on Instagram to<br/>
              finish linking your Instagram account with Foreplay.
            </BaseText>
            <div class="w-full flex items-center justify-center gap-1 pt-7">
              <div v-for="(num, index) in verificationCode.split('')" :key="`verif-num-${index}`"
              class="p-2.5 rounded-md bg-neutral-25" style="width: 60px; height: 60px">
                <BaseText type="display" size="xs" class="text-text-muted text-center" style="width: 40px; height: 40px">
                  {{ num }}
                </BaseText>
              </div>
            </div>
          </div>
          <div class="w-full flex flex-col gap-2 items-center px-5 pt-5 pb-3">
            <button class="submit-btn enabled flex items-center gap-1.5 pl-3 pr-2 py-1.5 rounded-md"
            @click="copyAndRedirect">
              <BaseText type="label" size="sm" class="text-white">
                Copy and open Instagram
              </BaseText>
              <OpenExternalIcon class="text-white" />
            </button>
            <button class="px-2 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-25"
            :disabled="loadingCancelVerification" @click="cancelVerification">
              <BaseLoadingSpinnerCircle v-if="loadingCancelVerification" class="text-icon-normal" />
              <BaseText v-else type="label" size="sm" class="text-text-muted">
                Change Details
              </BaseText>
            </button>
          </div>
          <button class="absolute top-3 right-3 p-1" @click="$emit('close')">
            <TagRemoveIcon stroke="#5E6678" :width="16" :height="16" />
          </button>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import FirebaseAPI from '@/api/firebase'
import firebase from '../../../api/config/FirebaseConfig'

// Icons
import TagRemoveIcon from '../../globals/Icons/TagRemoveIcon.vue'
import InstagramPlatformIcon from '../../globals/Icons/FilterIcons/Platforms/InstagramPlatformIcon.vue'
import BoardIcon from '../../globals/Icons/BoardIcon.vue'
import InfoResponseIcon from '../../globals/Icons/ResponseAlertIcons/InfoResponseIcon.vue'
import OpenExternalIcon from '../../globals/Icons/OpenExternalIcon.vue'

export default {
  name: 'ConnectInstagramModal',
  components: {
    TagRemoveIcon,
    InstagramPlatformIcon,
    InfoResponseIcon,
    OpenExternalIcon
  },
  data () {
    return {
      stage: 'connect',
      username: '',
      selectedBoards: [],
      verificationCode: '',

      // UI States
      dropdownIcon: BoardIcon,
      tooltipHovered: false,
      loadingSubmit: false,
      loadingCancelVerification: false
    }
  },
  mounted () {
    this.username = this.getUser.igUsername || ''
    if (this.getUser.igUsername && this.getUser.igWaitingVerification) {
      this.stage = 'verify'
    }
    if (this.getUser.igBoardIds?.length) {
      this.selectedBoards = this.getBoards
        .filter(board => this.getUser.igBoardIds.includes(board.id))
        .map(board => board.id)
    }
    this.verificationCode = this.getUser.igVerificationCode || (Math.floor(100000 + Math.random() * 900000)).toString()
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser']),
    ...mapGetters('BoardsModule', ['getBoards']),
    boardItems () {
      return this.getBoards.map(board => ({
        id: board.id,
        name: board.name.slice(1),
        icon: BoardIcon
      }))
    },
    modalTitle () {
      if (this.getUser.igUsername) return 'Manage Instagram Connection'
      return 'Connect Instagram'
    },
    submitText () {
      if (this.getUser.igUsername) {
        if (!this.username.length)
          return 'Remove Connection'
        if (this.getUser.igUsername !== this.username)
          return 'Update Connection'
        if (
          this.selectedBoards.length !== this.getUser.igBoardIds.length
          || this.selectedBoards.some(boardId => !this.getUser.igBoardIds.includes(boardId))
        )
          return 'Update Boards'
        return 'Update'
      }
      return 'Connect'
    },
    submitEnabled () {
      return this.selectedBoards.length && (
        (
          this.getUser.igUsername
          && (
            (this.getUser.igUsername !== this.username)
            || this.selectedBoards.length !== this.getUser.igBoardIds.length
            || this.selectedBoards.some(boardId => !this.getUser.igBoardIds.includes(boardId))
          )
        ) || (!this.getUser.igUsername && this.username.length)
      )
    }
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_USER']),
    async copyAndRedirect () {
      await this.$copyText(this.verificationCode)
      window.open('https://www.instagram.com/foreplay_co', '_blank')
    },
    async connectInstagram () {
      if (!this.submitEnabled) return
      this.loadingSubmit = true

      // Handle disconnection
      if (this.getUser.igUsername && !this.username.length) {
        try {
          await this.disconnectInstagram()
          this.$showAlert({
            type: 'success',
            message: 'Instagram successfully disconnected.'
          })
        } catch (error) {
          console.error(error)
          this.$showAlert({
            type: 'error',
            message: 'Failed to disconnect Instagram. See console for details.'
          })
        }
        this.$emit('close')
        return
      }

      const updatedUser = { ...this.getUser }
      let doVerify = false

      // In case of new connection or updated IG handle
      if (!this.getUser.igUsername || (this.getUser.igUsername !== this.username)) {
        const formattedUsername = this.username.startsWith('@') ? this.username.slice(1) : this.username
        updatedUser.igUsername = formattedUsername
        updatedUser.igVerificationCode = this.verificationCode
        updatedUser.igWaitingVerification = true
        doVerify = true
      }
      // In case of updated boards
      if (
        this.selectedBoards.length !== this.getUser.igBoardIds?.length
        || this.selectedBoards.some(boardId => !this.getUser.igBoardIds.includes(boardId))
      ) {
        updatedUser.igBoardIds = this.selectedBoards
      }

      // Submit the updated user data
      try {
        await FirebaseAPI.Users.update(this.getUser.user_id, updatedUser)
        this.SET_USER(updatedUser)
        this.$emit('refresh')
        if (doVerify)  {
          this.stage = 'verify' // Move to the verification stage of the modal
        } else {
          // We don't require verification if the user is only updating boards
          this.$showAlert({
            type: 'success',
            message: 'Linked boards updated successfully.'
          })
          this.$emit('close')
        }
      } catch (error) {
        console.error(error)
        this.$showAlert({
          type: 'error',
          message: 'Failed to submit data. See console for details.'
        })
      } finally {
        this.loadingSubmit = false
      }
    },
    async cancelVerification () {
      this.loadingCancelVerification = true
      try {
        await this.disconnectInstagram()
        this.username = ''
        this.selectedBoards = []
        this.stage = 'connect'
      } catch (error) {
        console.error(error)
        this.$showAlert({
          type: 'error',
          message: 'Failed to cancel verification. See console for details.'
        })
      } finally {
        this.loadingCancelVerification = false
      }
    },
    async disconnectInstagram () {
      const uid = this.getUser.user_id
      await FirebaseAPI.Users.update(uid, {
        igUsername: firebase.firestore.FieldValue.delete(),
        igVerificationCode: firebase.firestore.FieldValue.delete(),
        igWaitingVerification: firebase.firestore.FieldValue.delete(),
        igBoardIds: firebase.firestore.FieldValue.delete(),
        igConversationId: firebase.firestore.FieldValue.delete()
      })
      const updatedUser = { ...this.getUser }
      delete updatedUser.igUsername
      delete updatedUser.igVerificationCode
      delete updatedUser.igWaitingVerification
      delete updatedUser.igBoardIds
      delete updatedUser.igConversationId
      this.SET_USER(updatedUser)
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>
.form-input {
  color: #06070F;
  border-radius: 6px;
  border: 1px solid;
  border-color: transparent;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;

  /* Body/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.form-input:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.1), 0px 0px 0px 1px rgba(0, 56, 108, 0.1);
}
.form-input::placeholder {
  color: #5E6678;
  transition: color 150ms ease-in-out;
  opacity: 0.9;
}
.form-input.invalid {
  box-shadow: none;
  border-color: #FF002F;
}
.form-input:hover::placeholder {
  color: #303546;
}
.form-input:focus::placeholder {
  color: #C1C7D0;
}
.label-tooltip {
  position: absolute;
  left: 50%;
  bottom: calc(100% + 6px);
  transform: translateX(-50%);
  padding: 8px 12px;
  border-radius: 6px;
  color: white;
  background-color: #131520;
  width: 212px;
}

@property --submit-btn-opacity-1 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
@property --submit-btn-opacity-2 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
@property --submit-color {
  syntax: '<color>';
  initial-value: #0063F4;
  inherits: false;
}
.submit-btn {
  background: linear-gradient(180deg, rgba(255, 255, 255, var(--submit-btn-opacity-1)) 0%, rgba(255, 255, 255, var(--submit-btn-opacity-2)) 100%), var(--submit-color);
  color: white;
  box-shadow: 0px -1px 12px 0px rgba(255, 255, 255, 0.12) inset, 0px 0px 0px 1px #0063F4;
  transition: box-shadow 100ms ease-in-out,
    color 100ms ease-in-out, 
    background-color 100ms ease-in-out,
    --submit-btn-opacity-1 100ms ease-in-out, 
    --submit-btn-opacity-2 100ms ease-in-out,
    --submit-color 100ms ease-in-out;
}
.submit-btn.enabled:hover {
  --submit-btn-opacity-1: 0.24;
  --submit-btn-opacity-2: 0;
}
.submit-btn.disabled {
  color: #A4ABB8;
  box-shadow: none;
  --submit-btn-opacity-1: 0;
  --submit-btn-opacity-2: 0;
  --submit-color: #F6F8FA;
}

.v-enter-active, .v-leave-active {
  transition: opacity 150ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
</style>