<template>
  <MainLayout v-scroll="onScroll" scrollToolbar>
    <!-- Toolbar -->
    <template #toolbar>
      <div ref="toolbar" class="relative w-full flex items-center justify-between p-3">
        <!-- Left-side actions/info -->
        <div class="flex items-center gap-2 min-w-0" :style="{maxWidth: labelMaxWidth}">
          <button class="p-1.5 text-icon-normal transition-colors hover:text-icon-muted"
          @click="navigateBack">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M11.3215 13.3333L8.57742 10.5892C8.25198 10.2638 8.25198 9.73614 8.57742 9.4107L11.3215 6.66663" 
              stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
          <!-- Category type icon -->
          <div v-if="$route.name === 'DiscoveryProductCategoryView'" class="rounded-md overflow-hidden" style="background-color: #1A1A23;">
            <div class="bg-product-category w-full h-full p-1">
              <ProductCategoryIcon :width="24" :height="24" style="color: #D198F5" />
            </div>
          </div>
          <div v-else-if="$route.name === 'DiscoveryCreativeTargetingView'" class="rounded-md overflow-hidden" style="background-color: #1A1A23;">
            <div class="bg-creative-target w-full h-full p-1">
              <CreativeTargetIcon :width="24" :height="24" class="text-primary-purple-50" />
            </div>
          </div>
          <!-- Category Labels -->
          <div class="relative top-px flex flex-col ml-0.5 flex-shrink min-w-0" style="font-size: 10px; line-height: 12px;">
            <div class="text-text-normal">
              {{ $route.name === 'DiscoveryProductCategoryView' ? 'Product Category' : 'Creative Targeting' }}
            </div>
            <BaseText ref="categoryLabel" type="label" size="sm" class="text-text-muted capitalize truncate cursor-default"
            @mouseenter.native="showCategoryTooltip = true" @mouseleave.native="showCategoryTooltip = false">
              {{ categoryName }}
            </BaseText>
          </div>
        </div>

        <!-- Discovery Search Bar -->
        <div class="absolute flex top-1/2 transform -translate-y-1/2 pointer-events-none" style="left: 308px; right: 308px; z-index: 40001">
          <div ref="searchBar" class="relative flex-grow mx-auto min-w-0 h-8 pointer-events-auto" style="max-width: 600px">
            <SearchBar 
              :text-search.sync="textSearch"
              :qualifier="searchQualifierText"
              keywords-only
              @submit="handleSearchSubmit"
            />
          </div>
        </div>

        <!-- Category label hover tooltip (shows full text if it's truncated) -->
        <transition>
          <div v-if="showCategoryTooltip && isLabelTruncated" style="top: 46px; left: 82px; z-index: 40001"
          class="absolute px-2 py-1.5 rounded-md bg-neutral-800 shadow-md cursor-default">
            <BaseText type="body" size="sm" class="text-white capitalize">
              {{ categoryName }}
            </BaseText>
          </div>
        </transition>
      </div>
    </template>

    <!-- Filter Top Bar -->
    <template #filter>
      <div ref="filterBar" class="border border-border-normal bg-white bg-opacity-90" style="backdrop-filter: blur(6px)">
        <FilterTopBar
          no-search
          :text-search="textSearch"
          :selected-formats.sync="selectedFormats"
          :selected-platforms.sync="selectedPlatforms"
          :selected-niches.sync="selectedNiches"
          :selected-languages.sync="selectedLanguages"
          :selected-content-filters.sync="selectedContentFilters"
          :selected-market-targets.sync="selectedMarketTargets"
          :selected-date-range.sync="selectedDateRange"
          :sort-order.sync="sortOrder"
          :live-status.sync="liveStatus"
          @change="fetchAdvertisements"
        />
      </div>
    </template>

    <template #content>
      <!-- Loading State -->
      <BaseLoadingLogo v-if="loadingAdvertisements" class="mt-24" />

      <!-- Empty State -->
      <div v-else-if="!advertisements.length" class="flex flex-col items-center mt-20">
        <img src="../../assets/images/empty-single-library.gif" class="border border-gray-400 rounded mx-auto object-cover"
        style="width: 400px; height: 300px">
        <div class="text-lg font-bold my-4">
          This category is empty...
        </div>
      </div>

      <!-- Ads List -->
      <AdvertisementList
        v-else
        :discovery="true"
        :advertisements="advertisements"
        :loaded="!loadingAdvertisements"
        @updateAds="updateAdsLocal"
        @getMoreAds="() => getMoreAds({})"
        @reload="fetchAdvertisements(false, true)"
      />

      <infinite-loading
        v-if="advertisements.length"
        :identifier="infiniteId"
        @infinite="getMoreAds"
      >
        <div slot="spinner">
          <BaseLoadingLogo :margin="2" />
        </div>
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading>
    </template>
  </MainLayout>
</template>

<script>
import ForeplayAPI from '@/api/foreplayServer'
import { mapGetters } from 'vuex'

// Components
import InfiniteLoading from 'vue-infinite-loading'
import MainLayout from '../../layouts/MainLayout'
import AdvertisementList from '../../components/advertisements/AdvertisementList.vue'
import SearchBar from '../../components/search/SearchBar.vue'
import FilterTopBar from '../../components/search/FilterTopBar.vue'

// Icons
import ProductCategoryIcon from '../../components/globals/Icons/SearchIcons/ProductCategoryIcon.vue'
import CreativeTargetIcon from '../../components/globals/Icons/CreativeTargetIcon.vue'

export default {
  name: 'DiscoveryAICategoryView',
  components: {
    MainLayout,
    AdvertisementList,
    SearchBar,
    FilterTopBar,
    InfiniteLoading,
    ProductCategoryIcon,
    CreativeTargetIcon
  },
  data () {
    return {
      // Data States
      advertisements: [],
      textSearch: '',
      selectedFormats: [],
      selectedPlatforms: [],
      selectedNiches: [],
      selectedContentFilters: [],
      selectedLanguages: [],
      selectedMarketTargets: [],
      selectedDateRange: {
        start: null,
        end: null
      },
      liveStatus: [],
      sortOrder: [],
      lastDocId: null,
      infiniteId: +new Date(),

      // UI States
      loadingAdvertisements: true,
      labelMaxWidth: '308px',
      showCategoryTooltip: false,
      isLabelTruncated: false
    }
  },
  created () {
    if (this.$route.query.search) {
      this.textSearch = this.$route.query.search
    }
  },
  async mounted () {
    this.$refs.filterBar.style.position = 'sticky'
    this.$refs.filterBar.style.top = '16px'
    this.$refs.filterBar.style.zIndex = '40000'

    this.sortOrder = this.textSearch.length
        ? [{ name: 'Most Relevant', value: 'relevance' }]
        : [{ name: 'Newest', value: 'desc' }]
    await this.fetchAdvertisements()

    this.$nextTick(() => {
      this.computeLabelMaxWidth()
      window.addEventListener('resize', this.computeLabelMaxWidth)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.computeLabelMaxWidth)
  },
  computed: {
    ...mapGetters('RouterModule', ['getLastRoute']),
    categoryName () {
      // Convert from kebab case to normal case
      return this.$route.params?.category?.split('-')?.join(' ') || ''
    },
    searchQualifierText () {
      const text = this.categoryName?.split(' ')?.map(word => word.charAt(0).toUpperCase() + word.slice(1))?.join(' ')
      return text ? `"${text}"` : ''
    }
  },
  methods: {
    // ================================================================================
    // ================================= DATA METHODS =================================
    // ================================================================================

    handleSearchSubmit () {
      this.updateRouteQuery()
      this.sortOrder = this.textSearch.length
        ? [{ name: 'Most Relevant', value: 'relevance' }]
        : [{ name: 'Newest', value: 'desc' }]
      this.fetchAdvertisements()
    },
    updateRouteQuery () {
      if (!this.textSearch.length) {
        window.history.replaceState({}, '', this.$route.path)
        return
      }
      // Update the route's search query parameter with the current textSearch value
      const params = new URLSearchParams(window.location.search)
      params.set('search', this.textSearch)
      const newUrl = `${this.$route.path}?${params.toString()}`
      window.history.replaceState({}, '', newUrl)
    },
    navigateBack () {
      // If there is a previous history entry, go back
      if (this.getLastRoute) {
        this.$router.go(-1)
      } else {
        // Otherwise, go to the home page
        this.$router.push({ name: 'DiscoveryView' })
      }
    },
    async fetchAdvertisements (load = true, refresh = false) {
      this.loadingAdvertisements = load
      this.advertisements = []

      if (refresh) {
        this.lastDocId = null
      }

      const savedBetweenStart = +new Date(this.selectedDateRange.start) || null
      const savedBetweenEnd = +new Date(this.selectedDateRange.end) || null
      const filters = {
        savedBetweenStart,
        savedBetweenEnd,
        textSearch: this.textSearch,
        sort: this.sortOrder[0].value,
        orFilters: {
          formats: this.selectedFormats,
          niches: this.selectedNiches,
          contentFilters: this.selectedContentFilters,
          publisher_platform: this.selectedPlatforms,
          liveStatus: this.liveStatus,
          languages: this.selectedLanguages,
          marketTarget: this.selectedMarketTargets
        }
      }
      if (this.$route.name === 'DiscoveryProductCategoryView') filters.productCategoryQuery = this.categoryName
      if (this.$route.name === 'DiscoveryCreativeTargetingView') filters.creativeTargetingQuery = this.categoryName

      try {
        const { results, nextPage } = await ForeplayAPI.Ads.getDiscoveryAds(null, filters, true) // Include spyder ads
        this.lastDocId = nextPage
        this.advertisements = results
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingAdvertisements = false
        this.infiniteId += 1

        setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
        }, 100)
      }
    },
    async getMoreAds ($state) {
      if (this.trialExpired) {
        return
      }
      if (!this.lastDocId) {
        if (Object.keys($state).length) {
          $state.complete()
        }
        return
      }

      const savedBetweenStart = +new Date(this.selectedDateRange.start) || null
      const savedBetweenEnd = +new Date(this.selectedDateRange.end) || null
      const filters = {
        savedBetweenStart,
        savedBetweenEnd,
        textSearch: this.textSearch,
        sort: this.sortOrder[0].value,
        orFilters: {
          formats: this.selectedFormats,
          niches: this.selectedNiches,
          contentFilters: this.selectedContentFilters,
          publisher_platform: this.selectedPlatforms,
          liveStatus: this.liveStatus,
          languages: this.selectedLanguages,
          marketTarget: this.selectedMarketTargets
        }
      }
      if (this.$route.name === 'DiscoveryProductCategoryView') filters.productCategoryQuery = this.categoryName
      if (this.$route.name === 'DiscoveryCreativeTargetingView') filters.creativeTargetingQuery = this.categoryName

      const { results, nextPage } = await ForeplayAPI.Ads.getDiscoveryAds(this.lastDocId, filters, true) // Include spyder ads

      this.lastDocId = nextPage

      if (!this.lastDocId) {
        if (Object.keys($state).length) {
          $state.complete()
        }
        return
      }

      this.advertisements = this.advertisements.concat(results)
      if (Object.keys($state).length) {
        $state.loaded()
      }
    },
    updateAdsLocal (adsToUpdate) {
      const duplicateAds = [...this.advertisements]

      adsToUpdate.forEach((ad) => {
        const index = duplicateAds.findIndex((a) => a.id === ad.id)

        if (index !== -1) {
          duplicateAds[index] = ad
        }
      })

      this.advertisements = duplicateAds
    },

    // ================================================================================
    // ================================== UI METHODS ==================================
    // ================================================================================

    onScroll (e, position) {
      const marginValue =
        position.scrollTop / 6 < 16 ? position.scrollTop / 6 : 16
      const rounding =
        position.scrollTop / 10 < 5 ? position.scrollTop / 10 : 5
      if (this.$refs.filterBar) {
        this.$refs.filterBar.style.marginLeft = `${marginValue}px`
        this.$refs.filterBar.style.marginRight = `${marginValue}px`
        this.$refs.filterBar.style.marginTop = `${marginValue}px`
        this.$refs.filterBar.style.borderRadius = `${rounding}px`
      }
      const shadowSection = (maxValue) => {
        return position.scrollTop / 15 < maxValue
          ? position.scrollTop / 15
          : maxValue
      }

      if (position.scrollTop && this.$refs.filterBar) {
        this.$refs.filterBar.style.boxShadow = `rgba(0, 0, 0, 0.08) 0px ${shadowSection(
        15
      )}px ${shadowSection(55)}px, rgba(0, 0, 0, 0.08) 0px -${shadowSection(
        7
      )}px ${shadowSection(
        10
      )}px, rgba(0, 0, 0, 0.08) 0px 4px 6px, rgba(0, 0, 0, 0.08) 0px ${shadowSection(
        7
      )}px ${shadowSection(13)}px, rgba(0, 0, 0, 0.08) 0px -${shadowSection(
        3
      )}px ${shadowSection(5)}px`
      // this.$refs.filterBar.style.boxShadow = `rgba(58, 111, 251, 0.4) -${shadowSection(5)}px ${shadowSection(5)}px, rgba(58, 111, 251, 0.3) -${shadowSection(10)}px ${shadowSection(10)}px, rgba(58, 111, 251, 0.2) -${shadowSection(15)}px ${shadowSection(15)}px, rgba(58, 111, 251, 0.1) -${shadowSection(20)}px ${shadowSection(20)}px, rgba(58, 111, 251, 0.05) -${shadowSection(25)}px ${shadowSection(25)}px`
      } else {
        if (this.$refs.filterBar) {
          this.$refs.filterBar.style.boxShadow = null
        }
      }
    },
    computeLabelMaxWidth () {
      const toolbarLeft = this.$refs.toolbar.getBoundingClientRect().left
      const searchLeft = this.$refs.searchBar.getBoundingClientRect().left
      const maxWidth = searchLeft - toolbarLeft - 16
      this.labelMaxWidth = `${maxWidth}px`

      // Determine if the label is truncated
      const categoryLabel = this.$refs.categoryLabel.$el
      this.isLabelTruncated = categoryLabel.scrollWidth > categoryLabel.clientWidth
    }
  }
}
</script>

<style scoped>
/* Category type icon backgrounds */
.bg-product-category {
  background: linear-gradient(180deg, transparent 0%, rgba(202, 33, 231, 0.12) 100%), rgba(202, 33, 231, 0.16);
}
.bg-creative-target {
  background: linear-gradient(180deg, transparent 0%, rgba(109, 49, 232, 0.12) 100%), rgba(109, 49, 232, 0.16);
}

/* ========= Vue <transition> classes ========= */
.v-enter-active, .v-leave-active {
  transition: opacity 150ms ease-in-out
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0
}
.v-enter-to, .v-leave-from {
  opacity: 1
}
</style>