<template>
  <svg
    :width="width"
    :Height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6734 7.75043H11.1242C10.9171 7.75043 10.7492 7.58254 10.7492 7.37543V2.80147C10.7492 2.43044 10.268 2.28475 10.0622 2.59346L4.01283 11.6674C3.84669 11.9166 4.02534 12.2504 4.32485 12.2504H8.87416C9.08123 12.2504 9.24916 12.4184 9.24916 12.6254V17.1994C9.24916 17.5704 9.73036 17.7161 9.93616 17.4074L15.9855 8.33344C16.1516 8.08423 15.973 7.75043 15.6734 7.75043Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'PerformanceIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

  <style scoped></style>
