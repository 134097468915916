var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border-border-normal"},[_c('div',{class:{ 'h-5 mb-1': _vm.showLabelSpace }},[_c('BaseText',{attrs:{"type":"label","size":"sm"}},[_vm._v(" "+_vm._s(_vm.label)+" ")])],1),_c('div',{staticClass:"relative flex-grow"},[_c('button',{staticClass:"flex items-center gap-1 w-full pl-1.5 pr-3 py-1.5 border border-border-normal flex-nowrap",class:{
        'rounded-t-lg': _vm.expanded,
        'rounded-lg': !_vm.expanded,
        [_vm.triggerClass]: _vm.triggerClass
      },style:(_vm.minWidth),on:{"click":_vm.toggleDropdown}},[_vm._t("icon"),(_vm.selectedItem)?_c('BaseText',{staticClass:"flex-grow text-text-muted truncate text-left mr-2",attrs:{"type":"body","size":"sm"}},[_vm._v(" "+_vm._s(_vm.selectedLabel(_vm.selectedItem))+" ")]):_c('BaseText',{staticClass:"flex-grow placeholder truncate text-left mr-2 transition-colors",class:_vm.expanded ? 'text-neutral-200' : 'text-neutral-500',attrs:{"type":"body","size":"sm"}},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]),_c('div',{staticClass:"transform transition-transform flex-shrink-0",style:({ transform: _vm.expanded ? 'scaleY(-1)' : '' })},[_c('ChevronIcon',{staticClass:"text-icon-normal"})],1)],2),_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(() => { _vm.expanded = false }),expression:"() => { expanded = false }"}],staticClass:"dropdown-container rounded-b-lg"},[_c('div',{staticClass:"dropdown flex flex-col gap-1 px-1 rounded-b-lg bg-white cursor-pointer overflow-y-scroll scrollbar-hide",class:{ expanded: _vm.expanded },style:({ height: _vm.expanded ? `${Math.min(_vm.dropdownOptions.length * 36 + 4, 203)}px` : '0px' })},_vm._l((_vm.dropdownOptions),function(option,index){return _c('button',{key:`dropdown-option-${index}`,staticClass:"group flex items-center justify-between rounded-lg px-2 py-1.5 transition duration-100 hover:bg-neutral-10 whitespace-nowrap",class:{
            'mt-1': index === 0,
            'mb-1': index === _vm.options.length - 1,
            'opacity-100': _vm.expanded,
            'opacity-0': !_vm.expanded,
            'bg-neutral-10': _vm.selectedItem && _vm.selectedItem[_vm.optionKey] === option[_vm.optionKey]
          },on:{"click":function($event){return _vm.updateSelectedItem(option)}}},[_vm._t("option-icon",null,{"option":option}),_c('BaseText',{staticClass:"text-text-muted flex-grow truncate text-left",attrs:{"type":"body","size":"sm"}},[_vm._v(" "+_vm._s(_vm.optionLabel(option))+" ")]),(_vm.selectedItem && _vm.selectedItem[_vm.optionKey] === option[_vm.optionKey])?_c('div',{staticClass:"text-icon-normal"},[_c('CheckmarkIcon')],1):_vm._e()],2)}),0),(_vm.options.length > 5)?_c('div',{staticClass:"dropdown-fade-overlay"}):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }