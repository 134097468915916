<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
        <path d="M9.9375 16H6C5.17157 16 4.5 15.3284 4.5 14.5V5.5C4.5 4.67157 5.17157 4 6 4H9.9375M16.5 10H8.0625M16.5 10L13.125 13.375M16.5 10L13.125 6.625" 
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: 'LogoutIcon',
    props: {
        width: {
            type: Number,
            default: 20
        },
        height: {
            type: Number,
            default: 20
        }
    }
}
</script>

<style scoped></style>