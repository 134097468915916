<template>
  <BaseCard>
    <div
      class="flex items-center justify-between p-3.5 px-5 border-b border-border-normal"
      style="height: 72px"
    >
      <!-- left container -->
      <div class="flex">
        <!-- Icon -->
        <div
          style="width: 45px;"
          class="icon-shadow mr-3 p-2.5 rounded-full flex items-center justify-center"
        >
          <img src="../../assets/icons/ai-book.svg">
        </div>
        <!-- Text -->
        <div>
          <!-- Title Container -->
          <div class="flex gap-1">
            <!-- Title -->
            <BaseText
              type="label"
              size="md"
            >
              <span class="text-text-muted">
                AI Storyboard Generator
              </span>
            </BaseText>
          </div>
          <!-- SubTitle -->
          <BaseText
            type="body"
            size="sm"
          >
            <span class="text-text-normal">
              Transform your ad inspiration into a contextual direct response ad brief
            </span>
          </BaseText>
          <div />
        </div>
      </div>

      <div class="flex items-start py-4 px-4">
        <div class="flex items-center">
          <div class="text-gray-600 text-sm font-medium mr-2 whitespace-nowrap">
            Show AI Tools
          </div>
          <BaseToggle
            v-model="showGenerator"
            @toggle="showGenerator = !showGenerator"
          />
        </div>
      </div>
    </div>

    <!-- Body -->
    <div
      v-if="showGenerator"
      class="mb-9"
    >
      <!-- Ai Section -->
      <div class="grid grid-cols-2">
        <!-- Inspiration -->
        <div class="pt-6 px-8 border-r">
          <!-- Text -->
          <div class="text-text-muted">
            <BaseText
              type="label"
              size="md"
            >
              1. Select from Inspiration
            </BaseText>
            <div class="text-text-normal">
              <BaseText
                type="body"
                size="sm"
              >
                Use AI to create a Brief based on one of your Inspirations
              </BaseText>
            </div>
          </div>
          <!-- Carousel -->
          <div
            v-if="advertisements.length"
            class="h-full w-full flex items-start justify-center pt-10"
          >
            <!-- Left Arrow -->
            <div
              class="p-2 mt-52 select-none rounded-full cursor-pointer"
              style="background: linear-gradient(180deg, rgba(223, 225, 231, 0) 0%, rgba(223, 225, 231, 0.08) 100%), #FFFFFF; box-shadow: 0px 1px 2px rgba(9, 25, 72, 0.13), 0px 0px 0px 1px rgba(18, 55, 105, 0.08);"
              @click="nextAd"
            >
              <!--  -->
              <img
                class="transform -rotate-90"
                src="../../assets/icons/chevron-top-small.svg"
              >
            </div>

            <!-- Media -->
            <div
              class="overflow-hidden flex justify-center w-full rounded-lg"
            >
              <div class="rounded-lg overflow-hidden">
                <video
                  :key="advertisements[currentIndex].id"
                  ref="videoPlayer"
                  :poster="advertisements[currentIndex].thumbnail && advertisements[currentIndex].thumbnail"
                  style="height: 450px;"
                  controls
                  preload="metadata"
                >
                  <source
                    :src="advertisements[currentIndex].video"
                    type="video/mp4"
                  >
                </video>
              </div>
            </div>

            <!-- Right Arrow -->
            <div
              class="p-2 mt-52 select-none rounded-full cursor-pointer"
              style="background: linear-gradient(180deg, rgba(223, 225, 231, 0) 0%, rgba(223, 225, 231, 0.08) 100%), #FFFFFF; box-shadow: 0px 1px 2px rgba(9, 25, 72, 0.13), 0px 0px 0px 1px rgba(18, 55, 105, 0.08);"
              @click="previousAd"
            >
              <!--  -->
              <img
                class="transform rotate-90"
                src="../../assets/icons/chevron-top-small.svg"
              >
            </div>
          </div>
          <div
            v-else
            class="flex items-center h-full justify-center"
          >
            <BaseCard
              border
              class="p-4 rounded-xl flex items-center justify-center"
              style="height: 350px;"
              clickable
              @click="$emit('scrollToElement')"
            >
              <div class="text-center gap-2">
                <!-- Title -->
                <BaseText
                  type="label"
                  size="md"
                >
                  <span class="text-text-muted">
                    AI Storyboard Generator
                  </span>
                </BaseText>
                <!-- SubTitle -->
                <BaseText
                  type="body"
                  size="sm"
                >
                  <span class="text-text-normal">
                    Add Video Inspiration to get started
                  </span>
                </BaseText>
                <div />
              </div>
            </BaseCard>
          </div>
        </div>

        <!-- Input Details -->
        <div class="pt-6 px-8 mb-9">
          <!-- Text -->
          <div class="text-text-muted">
            <BaseText
              type="label"
              size="md"
            >
              2. Input Your Ad Details
            </BaseText>
            <div class="text-text-normal">
              <BaseText
                type="body"
                size="sm"
              >
                Ad details about your product and company.
              </BaseText>
            </div>
          </div>
          <!-- Inputs -->
          <div class="mt-6">
            <!-- Company Name -->
            <BaseInputV2
              v-model="companyName"
              label="Company Name"
              placeholder="Dr. Soap"
            />
            <!-- Product Name -->
            <BaseInputV2
              v-model="productName"
              class="mt-4"
              label="Product Name"
              placeholder="Organic Soap"
            />
            <!-- Target Audience -->
            <BaseInputV2
              v-model="targetAudience"
              class="mt-4"
              label="Target Audience"
              placeholder="Women who are frustrated with their husband's grooming routine."
            />

            <!-- Product Description -->
            <div class="text-text-muted mb-1 mt-4">
              <BaseText
                type="label"
                size="sm"
              >
                Product Description
              </BaseText>
            </div>
            <div
              style="box-shadow: 0px 1px 2px rgba(18, 55, 105, 0.08), 0px 0px 0px 1px rgba(18, 55, 105, 0.08);"
              class="py-2 px-3 overflow-scroll rounded-md"
            >
              <BaseContentEditable
                :current-value="productDescription"
                no-hover
                placeholder="We give a damn about Natural. Too many products use synthetic detergents and harsh chemicals, wreaking havoc on your skin and body..."
                height="80"
                @update="(newValue) => updatePanelSection(index, 'description', newValue)"
              />
            </div>
          </div>

          <!-- Generation Toggles -->
          <div class="rounded-md border border-border-normal text-text-muted mt-4">
            <!-- Action/Description -->
            <div
              class="flex items-center justify-between py-2.5 px-4 border-b border-border-normal transition duration-200"
              :class="generateActionDescription ? 'text-text-muted' : 'text-text-disabled'"
            >
              <div class="flex items-center gap-1.5">
                <BaseText
                  type="label"
                  size="sm"
                >
                  Generate Action/Description
                </BaseText>

                <BaseTooltip>
                  <img
                    slot="reference"
                    class="cursor-pointer"
                    src="../../assets/icons/questionmark.svg"
                  >
                  <div
                    slot="content"
                    class="px-2 w-40"
                  >
                    Create a description of what actions should be in the scene.
                  </div>
                </BaseTooltip>
              </div>

              <BaseToggle
                v-model="generateActionDescription"
                @toggle="generateActionDescription = !generateActionDescription"
              />
            </div>
            <!-- Generate Images -->
            <div
              class="flex items-center justify-between py-2.5 border-b border-border-normal px-4 transition duration-200"
              :class="generateInspirationImages ? 'text-text-muted' : 'text-text-disabled'"
            >
              <div class="flex items-center gap-1.5">
                <BaseText
                  type="label"
                  size="sm"
                >
                  Import Inspiration Images
                </BaseText>
                <BaseTooltip>
                  <img
                    slot="reference"
                    class="cursor-pointer"
                    src="../../assets/icons/questionmark.svg"
                  >
                  <div
                    slot="content"
                    class="px-2 w-40"
                  >
                    Take frames from your inspiration video and put them into your storyboard.
                  </div>
                </BaseTooltip>
              </div>

              <BaseToggle
                v-model="generateInspirationImages"
                @toggle="generateInspirationImages = !generateInspirationImages"
              />
            </div>
            <!-- Text Overlay -->
            <div
              class="flex items-center justify-between py-2.5 px-4 transition duration-200"
              :class="generateTextOverlay ? 'text-text-muted' : 'text-text-disabled'"
            >
              <div class="flex items-center gap-1.5">
                <BaseText
                  type="label"
                  size="sm"
                >
                  Generate Text Overlay
                </BaseText>
                <BaseTooltip>
                  <img
                    slot="reference"
                    class="cursor-pointer"
                    src="../../assets/icons/questionmark.svg"
                  >
                  <div
                    slot="content"
                    class="px-2 w-40"
                  >
                    Create a description of what actions should be in the scene.
                  </div>
                </BaseTooltip>
              </div>

              <BaseToggle
                v-model="generateTextOverlay"
                @toggle="generateTextOverlay = !generateTextOverlay"
              />
            </div>
          </div>

          <!-- Language Select -->
          <div class="text-text-muted mb-1 mt-4">
            <BaseText
              type="label"
              size="sm"
            >
              Output Language
            </BaseText>
          </div>
          <BaseSelect
            :selected.sync="selectedLanguage"
            item-value="name"
            :items="[
              { name: 'English' },
              { name: 'Arabic' },
              { name: 'Dutch' },
              { name: 'French' },
              { name: 'German' },
              { name: 'Greek' },
              { name: 'Italian' },
              { name: 'Japanese' },
              { name: 'Mandarin' },
              { name: 'Polish' },
              { name: 'Russian' },
              { name: 'Spanish' },
              { name: 'Turkish' },
              { name: 'Ukranian' },
            ]"
            @select="() => {}"
            @input="() => {}"
          >
            <div
              slot="reference"
              class="flex w-full items-center justify-between"
            >
              <BaseText
                type="body"
                size="sm"
              >
                {{ selectedLanguage[0].name }}
              </BaseText>
              <img
                class="ml-auto"
                src="../../assets/icons/chevron-down-small.svg"
              >
            </div>

            <template #item="{ item }">
              <div class="flex">
                <BaseText
                  type="body"
                  size="sm"
                >
                  {{ item.name }}
                </BaseText>
              </div>
            </template>
          </BaseSelect>
        </div>
      </div>

      <!-- Generate Script Button -->
      <div class="flex justify-center items-center">
        <!-- Left Line -->
        <div class="w-1/2 border-t">
          <!--  -->
        </div>
        <!-- Write Script Button -->
        <BaseButton
          class="p-2 m-2"
          padding-x="28"
          primary
          :disabled="loadingScript"
          @click="generateBrief"
        >
          <div class="flex items-center">
            <img
              class="mr-1.5"
              src="../../assets/images/wand.svg"
            >

            <BaseText
              type="label"
              size="sm"
            >
              Write Script
            </BaseText>
          </div>
        </BaseButton>

        <!-- Right Line -->
        <div class="w-1/2 border-t">
          <!--  -->
        </div>
      </div>

      <!-- Script Output -->
      <div class="py-6">
        <!-- Top Section -->
        <div class="flex justify-between items-center border-b border-border-normal px-8 pb-6">
          <!-- Text -->
          <div
            ref="afterGenScroll"
            class="text-text-muted"
          >
            <BaseText
              type="label"
              size="md"
            >
              3. Review & Edit Your Script
            </BaseText>
            <div class="text-text-normal">
              <BaseText
                type="body"
                size="sm"
              >
                Review and edit your new AI script
              </BaseText>
            </div>
          </div>
        </div>

        <!-- Script -->
        <div
          v-if="generatedScript.length && !loadingScript"
          class="h-full px-6 pt-4 flex gap-x-2 h-max text-text-muted"
        >
          <!-- Original Scripy -->
          <div class="flex w-1/2 pl-8 py-1.5 gap-1.5">
            <img src="../../assets/icons/source-script.svg">
            <BaseText
              type="label"
              size="sm"
            >
              Source Script
            </BaseText>
          </div>
          <!-- AI Scripy -->
          <div class="flex pl-6 py-1.5 gap-1.5">
            <img src="../../assets/icons/ai-script.svg">
            <BaseText
              type="label"
              size="sm"
              class="whitespace-nowrap"
            >
              AI Script
            </BaseText>
          </div>
        </div>

        <div v-if="generatedScript.length && !loadingScript">
          <div
            v-for="(scene, index) of generatedScript"
            :key="index"
          >
            <ScriptSection
              :show-action="generateActionDescription"
              :show-overlay="generateTextOverlay"
              :scene="originalScript[index]"
              :second-scene="scene"
            />
          </div>
        </div>
        <!-- Loader -->
        <div
          v-else-if="loadingScript"
          class="flex flex-col items-center py-16"
        >
          <div
            class="border border-border-normal rounded-full p-1"
          >
            <!-- Loading Video -->
            <div
              class="rounded-full overflow-hidden"
              style="filter: drop-shadow(0px 26px 10px rgba(8, 17, 17, 0.04)) drop-shadow(0px 14px 9px rgba(8, 17, 17, 0.12)) drop-shadow(0px 6px 6px rgba(8, 17, 17, 0.2)) drop-shadow(0px 2px 4px rgba(8, 17, 17, 0.24)) drop-shadow(0px 0px 0px rgba(8, 17, 17, 0.24));"
            >
              <video
                style="height: 96px;"
                preload="metadata"
                playsinline
                autoplay
                muted
                loop
              >
                <source
                  src="../../assets/videos/foreplay-loading_anubis.webm"
                  type="video/mp4"
                >
              </video>
            </div>
          </div>

          <!-- Text -->
          <div class="mt-3 text-text-muted">
            <BaseText
              type="label"
              size="md"
            >
              Generating your new AI Script
            </BaseText>
          </div>
          <div class="mt-1 text-text-normal">
            <BaseText
              type="body"
              size="sm"
            >
              This can take up to a minute. Script writing speed will be 5x faster this month
            </BaseText>
          </div>
        </div>
        <div
          v-else
          class="flex items-center h-full justify-center my-8"
        >
          <div class="text-center gap-2">
            <!-- Title -->
            <BaseText
              type="label"
              size="md"
            >
              <span class="text-text-muted">
                Your AI Script
              </span>
            </BaseText>
            <!-- SubTitle -->
            <BaseText
              type="body"
              size="sm"
            >
              <span class="text-text-normal">
                Your AI script will appear here
              </span>
            </BaseText>
            <div />
          </div>
        </div>
      </div>

      <!-- Bottom Section -->
      <div class="flex justify-between px-5">
        <!-- Left Side -->
        <div class="flex text-text-muted gap-3">
          <!-- Word Count -->
          <div class="px-2 py-1.5 border border-border-normal rounded-md flex gap-2">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.77222 16.8757V9.1665M18.5416 3.95898H8.12492M10.2083 9.16732H1.45825M13.3333 4.16732V16.8757"
                stroke="#5E6678"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <BaseText
              type="body"
              size="sm"
            >
              {{ wordCount || 0 }} Words
            </BaseText>
          </div>
          <!-- Estimated Duration -->
          <div class="px-2 py-1.5 border border-border-normal rounded-md flex gap-2">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0001 6.45768V9.99935L12.2917 12.291M17.7084 9.99935C17.7084 14.2565 14.2573 17.7077 10.0001 17.7077C5.74289 17.7077 2.29175 14.2565 2.29175 9.99935C2.29175 5.74215 5.74289 2.29102 10.0001 2.29102C14.2573 2.29102 17.7084 5.74215 17.7084 9.99935Z"
                stroke="#5E6678"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <BaseText
              type="body"
              size="sm"
            >
              {{ estimatedSpeechTime || '00:00 Duration' }}
            </BaseText>
          </div>
        </div>

        <BaseButton
          primary
          :disabled="!generatedScript.length || !currentInspo"
          @click="generateStoryboard"
        >
          <div class="flex items-center gap-1.5">
            <img src="../../assets/icons/ai-book-white.svg">
            <BaseText
              type="label"
              size="sm"
            >
              Make Storyboard
            </BaseText>
          </div>
        </BaseButton>
      </div>
    </div>
  </BaseCard>
</template>
<script>
import API from '@/api'
import firebase from '../../api/config/FirebaseConfig'
import ScriptSection from '../briefs/editForm/story/ScriptSection.vue'

export default {
  name: 'BriefGenerator',
  components: {
    ScriptSection
  },
  props: {
    brief: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      companyName: null,
      productName: null,
      targetAudience: null,
      productDescription: null,
      showGenerator: true,
      selectedLanguage: [{ name: 'English' }],
      advertisements: [],
      currentInspo: {},
      currentIndex: 0,
      originalScript: [],
      generatedScript: [],
      loadingScript: false,
      loadingStoryboard: false,
      generateActionDescription: true,
      generateTextOverlay: true,
      generateInspirationImages: true,
      estimatedSpeechTime: null,
      wordCount: null,
      carouselSettings: {
        infinite: true,
        autoplay: false,
        focusOnSelect: true,
        centerPadding: '20px',
        slidesToShow: 3,
        slidesToScroll: 1
      }
    }
  },
  watch: {
    'brief.adIds' (newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        this.fetchAdvertisements()
      }
    }
  },
  async mounted () {
    this.fetchAdvertisements()

    if (this.brief.generatedScript) {
      this.generatedScript = this.brief.generatedScript.generated.scenes
      this.originalScript = this.brief.generatedScript.original.scenes

      this.estimatedSpeechTime = this.calculateSpeechTimeRangeFromScenes(this.generatedScript)
      this.wordCount = this.generatedScript.map(scene => scene.script_copy).join(' ').split(' ').length || 0
    }
  },
  methods: {
    async fetchAdvertisements () {
      this.loadingAdvertisements = true

      try {
        const db = firebase.firestore()

        // Get ads - only videos for now
        const ads = await Promise.all(
          this.brief.adIds ? this.brief.adIds.map(async (adId) => {
            const adDoc = await db.collection('ads').doc(adId).get()

            return {
              ...adDoc.data(),
              id: adId
            }
          }) : []
        )

        this.advertisements = ads.filter(ad => ad.video)

        this.currentInspo = this.advertisements[0]
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e,
          type: 'error'
        })
      } finally {
        this.loadingAdvertisements = false

        setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
        }, 100)
      }
    },
    nextAd () {
      if (this.currentIndex === this.advertisements.length - 1) {
        this.currentIndex = 0
      } else {
        this.currentIndex = this.currentIndex + 1
      }

      this.currentInspo = this.advertisements[this.currentIndex]
    },
    previousAd () {
      if (this.currentIndex === 0) {
        this.currentIndex = this.advertisements.length - 1
      } else {
        this.currentIndex = this.currentIndex - 1
      }

      this.currentInspo = this.advertisements[this.currentIndex]
    },
    async generateBrief () {
      try {
        if (!this.currentInspo) {
          this.$showAlert({
            message: 'Please add and select an single inspiration video - other media types are not supported',
            type: 'error'
          })
          return
        }
        this.loadingScript = true

        this.generatedScript = []
        this.estimatedSpeechTime = null
        this.wordCount = null

        const language = this.selectedLanguage[0].name

        const brandDetails = {
          companyName: this.companyName,
          productName: this.productName,
          targetAudience: this.targetAudience,
          productDescription: this.productDescription
        }

        const generatedBrief = await API.Briefs.generateBrief(this.currentInspo?.id, language, brandDetails)
        // console.log('generatedBrief -->', generatedBrief)
        this.estimatedSpeechTime = this.calculateSpeechTimeRangeFromScenes(generatedBrief.generated.scenes)
        this.wordCount = generatedBrief.generated.scenes.map(scene => scene.script_copy).join(' ').split(' ').length || 0

        this.originalScript = generatedBrief.original.scenes
        this.generatedScript = generatedBrief.generated.scenes

        this.loadingScript = false
        this.$nextTick(() => {
          this.$refs.afterGenScroll.scrollIntoView({ behavior: 'smooth' })
        })

        // scroll into view @ ref
        this.selectedScript = 'ai'

        this.$emit('saveSection', {
          field: 'generatedScript',
          value: generatedBrief
        })
      } catch (error) {
        if (error.response.data === 'No transcription found') {
          this.$showAlert({
            message: 'No transcription found for this video. Please try another video.',
            type: 'error'
          })
        } else {
          console.log('error in transcription', error)
          this.$showAlert({
            message: 'Something went wrong - please try again. If the issue persists, there may be an issue with the selected video',
            type: 'error'
          })
        }
        this.loadingScript = false
      }
    },
    calculateSpeechTimeRangeFromScenes (scenes) {
      // Average speaking rate is 125 words per minute
      const averageWordsPerMinute = 125

      // Extract the script copy from each scene and join them into a single string
      const scriptCopy = scenes.map(scene => scene.script_copy).join(' ')

      // Estimate the number of words in the script copy by splitting it into an array
      const words = scriptCopy.split(' ')
      const wordCount = words.length

      // Calculate the estimated speaking time range in seconds
      const minimumTime = Math.round(wordCount / averageWordsPerMinute * 60)
      const maximumTime = Math.round((wordCount / averageWordsPerMinute + 0.5) * 60)

      // Format the response in the desired format
      const minimumTimeString = new Date(minimumTime * 1000).toISOString().substr(14, 5)
      const maximumTimeString = new Date(maximumTime * 1000).toISOString().substr(14, 5)
      const durationString = `${minimumTimeString} - ${maximumTimeString} Duration`

      return durationString
    },
    async generateStoryboard () {
      // console.log('running')
      this.loadingStoryboard = true

      // Generate Screenshots - don't await this
      if (this.generateInspirationImages) {
        this.generateFrameScreenshots()
      }

      // Generate Frames
      const story = this.generatedScript.map((scene, index) => {
        return {
          scene: [{ visual: '', title: scene.scene_type }],
          copy: scene.script_copy,
          visuals: scene.action_description,
          overlay: scene.text_overlay
        }
      })

      // Emit an update for the parent briefs storyboard
      this.$emit('saveSection', {
        field: 'story',
        value: story
      })

      this.loadingStoryboard = false
    },
    updatePanelSection (index, section, value) {
      if (section === 'description') {
        this.productDescription = value
      } else {
        this.productDescription[index][section] = value
      }

      this.emitUpdate()
    },
    async generateFrameScreenshots () {
      this.$emit('loadingReferences')

      const screenshots = await API.Briefs.generateFrameScreenshots(this.currentInspo.video, this.currentInspo.id, this.generatedScript.length)

      const story = this.generatedScript.map((scene, index) => {
        const screenshot = screenshots.find(s => s.order === index)

        return {
          scene: [{ visual: '', title: scene.scene_type }],
          reference: [{
            format: 'img/png',
            path: screenshot.url
          }],
          copy: scene.script_copy,
          visuals: scene.action_description,
          overlay: scene.text_overlay
        }
      })

      // Emit an update for the parent briefs storyboard
      this.$emit('saveSection', {
        field: 'story',
        value: story
      })

      this.$emit('loadedFrames')
    }
  }
}
</script>
<style scoped>
video {
  max-width: unset;
}
</style>
