import { render, staticRenderFns } from "./WhiteLabelSettings.vue?vue&type=template&id=7220484a&scoped=true"
import script from "./WhiteLabelSettings.vue?vue&type=script&lang=js"
export * from "./WhiteLabelSettings.vue?vue&type=script&lang=js"
import style0 from "./WhiteLabelSettings.vue?vue&type=style&index=0&id=7220484a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7220484a",
  null
  
)

export default component.exports