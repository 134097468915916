<template>
  <!-- Boards Details View -->
  <MainLayout scroll-toolbar>
    <!-- Toolbar -->
    <template #toolbar>
      <div ref="toolbar" class="relative w-full flex items-center justify-between p-3 border-b border-border-normal">
        <!-- Left-side actions/info -->
        <div class="flex items-center gap-2 min-w-0">
          <button class="p-1.5 text-icon-normal transition-colors hover:text-icon-muted flex-shrink-0"
          @click="navigateBack">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M11.3215 13.3333L8.57742 10.5892C8.25198 10.2638 8.25198 9.73614 8.57742 9.4107L11.3215 6.66663" 
              stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
          <div v-if="brandImageLoading" class="p-1">
            <BaseLoadingSpinnerCircle class="text-icon-normal opacity-50" :duration="1" />
          </div>
          <img v-show="!brandImageLoading" :src="brand.avatar" class="w-7 h-7 rounded-md flex-shrink-0" 
          @load="brandImageLoading = false" />
          <BaseText ref="brandLabel" type="label" size="sm" class="text-black flex-shrink truncate cursor-default">
            {{ brand.name }}
          </BaseText>
          <!-- <div v-if="brand?.niches?.length || brand.category" class="px-2 py-1.5 rounded-md bg-neutral-25">
            <BaseText type="label" size="sm" class="text-text-muted whitespace-nowrap">
              {{ brand?.niches?.[0] || brand.category }}
            </BaseText>
          </div> -->
        </div>

        <!-- Right-side actions (for now, just ad library) -->
        <button v-if="adLibraryId" class="group ad-library-btn flex items-center gap-1.5 pl-2 py-1.5 pr-3 rounded-md"
        @click="goToAdLibrary">
          <AdLibraryIcon class="text-icon-normal transition-colors group-hover:text-icon-muted" />
          <BaseText type="label" size="sm" class="text-text-muted">
            Ad Library
          </BaseText>
        </button>
      </div>
    </template>

    <template #content>
      <!-- Loading State -->
      <BaseLoadingSpinner v-if="loadingAdvertisements" class="mt-24"/>

      <!-- Empty State -->
      <div v-else-if="!advertisements.length" class="flex flex-col items-center mt-20">
        <img src="../../assets/images/empty-single-library.gif"
        class="border border-gray-400 rounded mx-auto object-cover" style="width: 400px; height: 300px">
        <div class="text-lg font-bold my-4">
          This brand is empty...
        </div>
        <div class="text-center text-gray-700">
          You created this board but haven't saved
          <br>
          any ads yet.
        </div>
        <div class="flex mt-5">
          <BaseButton primary @click="showAdvertisementModal = true">
            <i class="fas fa-plus mr-1 text-xs" />
            Save ads
          </BaseButton>
        </div>
      </div>

      <!-- Ads List -->
      <AdvertisementList
        v-else
        :advertisements="advertisements"
        @reload="fetchAdvertisements(false, true)"
        @getMoreAds="() => getMoreAds({})"
      />

      <!-- Create Advertisement Modal -->
      <CreateAdvertisementModal
        v-if="showAdvertisementModal"
        :current-board="board.id"
        @close="showAdvertisementModal = false"
        @save="fetchAdvertisements(false)"
      />

      <!-- Manage Board Modal -->
      <infinite-loading
        v-if="advertisements.length"
        :identifier="infiniteId"
        @infinite="getMoreAds"
      >
        <div slot="spinner">
          <BaseLoadingLogo :margin="2" />
        </div>
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading>
    </template>
  </MainLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import firebase from '@/api/config/FirebaseConfig'
import AdvertisementList from '../../components/advertisements/AdvertisementList'
import CreateAdvertisementModal from '../../components/advertisements/AdvertisementCreateModal'
import MainLayout from '../../layouts/MainLayout'
import InfiniteLoading from 'vue-infinite-loading'
import AdLibraryIcon from '../../components/globals/Icons/AdLibraryIcon.vue'

export default {
  name: 'BrandView',
  components: {
    AdvertisementList,
    CreateAdvertisementModal,
    MainLayout,
    AdLibraryIcon,
    InfiniteLoading
  },
  data () {
    return {
      brand: {},
      advertisements: [],
      adLibraryId: null,
      loadingAdvertisements: false,
      searchQuery: '',
      showManageBoardModal: false,
      showAdvertisementModal: false,
      selectedBoard: {},
      infiniteId: +new Date(),
      lastDocId: null,
      docOrder: 'desc',
      brandImageLoading: true,
    }
  },
  computed: {
    ...mapGetters('AdvertisementsModule', ['getAdsBeingSaved']),
    ...mapGetters('AuthModule', ['getUserEmail', 'getUserName', 'getTeam']),
    ...mapGetters('BoardsModule', ['getBoards']),
    ...mapGetters('RouterModule', ['getLastRoute']),
    // Get Advertisements
    getAdvertisements () {
      return this.advertisements
    }
  },
  async mounted () {
    await this.fetchAdvertisements()
    this.getUniqueBrand()
  },
  methods: {
    toggleOrder () {
      if (this.docOrder === 'asc') {
        this.docOrder = 'desc'
      } else {
        this.docOrder = 'asc'
      }

      this.fetchAdvertisements()
    },
    async getUniqueBrand () {
      const db = firebase.firestore()
      const brandInstance = await db.collection('brands').doc(this.brand.brandId).get()
      this.adLibraryId = brandInstance.data().adLibraryId
    },
    // Fetch Advertisements
    async fetchAdvertisements (load = true, refresh = false) {
      this.loadingAdvertisements = load

      if (refresh) {
        this.lastDocId = null
      }

      try {
        const db = firebase.firestore()
        const user = firebase.auth().currentUser

        const createdByCondition = this.getTeam ? ['teamId', '==', this.getTeam.id] : ['created_by', '==', user.uid]

        // Get the user brand
        const brandSnapshot = await db.collection('user-brands').doc(this.$route.params.id).get()
        this.brand = { ...brandSnapshot.data(), id: brandSnapshot.id }

        // get all adds using the brandId on the user brand and the createdBy field
        // MAKE SURE THE USER HAS ACCESS TO THIS MF AD (teamId or uid)
        const query = await db.collection('ads').where(...createdByCondition).where('brandId', '==', this.brand.brandId).orderBy('createdAt', this.docOrder)
        const snapshot = await query.limit(20).get()

        const size = snapshot.size
        this.lastDocId = snapshot.size ? snapshot.docs[size - 1].id : null

        this.advertisements = snapshot.docs?.map(doc => {
          return {
            id: doc.id,
            ...doc.data() || []
          }
        })
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e,
          type: 'error'
        })
      } finally {
        this.loadingAdvertisements = false
        this.infiniteId += 1

        setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
        }, 100)
      }
    },
    async getMoreAds ($state) {
      // Setup

      const user = firebase.auth().currentUser
      const db = firebase.firestore()

      const createdByCondition = this.getTeam ? ['teamId', '==', this.getTeam.id] : ['created_by', '==', user.uid]

      const query = await db.collection('ads').where(...createdByCondition).where('brandId', '==', this.brand.brandId).orderBy('createdAt', this.docOrder)
      const doc = await db.collection('ads').doc(this.lastDocId).get()

      const offsetQuery = query.startAfter(doc)
      const snapshot = await offsetQuery.limit(20).get()

      const size = snapshot.size

      if (!size) {
        if (Object.keys($state).length) {
          $state.complete()
        }
        return
      }

      this.lastDocId = snapshot.docs[size - 1].id

      const newDocs = snapshot.docs?.map((doc) => {
        return {
          id: doc.id,
          ...(doc.data() || [])
        }
      })

      this.advertisements = this.advertisements.concat(newDocs)
      if (Object.keys($state).length) {
        $state.loaded()
      }
    },
    navigateBack () {
      // If there is a previous history entry, go back
      if (this.getLastRoute) {
        this.$router.go(-1)
      } else {
        // Otherwise, go to the home page
        this.$router.push({ name: 'LibraryBrandsView' })
      }
    },
    goToAdLibrary () {
      if (!this.adLibraryId) return
      const url = `https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&view_all_page_id=${this.adLibraryId}`
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped>
.ad-library-btn {
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.12), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: background-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
}
.ad-library-btn:hover {
  background-color: #F6F8FA; /* neutral-25 */
  box-shadow: none;
}
</style>
