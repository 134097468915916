var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseModal',{attrs:{"card-width":'650px'},on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('div',{staticClass:"flex text-xl text-black font-medium"},[_c('div',{staticClass:"bg-gray-300 text-gray-600 w-12 h-12 rounded-full flex justify-center items-center"},[_c('svg',{staticClass:"w-6 h-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z","clip-rule":"evenodd"}})])]),_c('div',{staticClass:"ml-3"},[_vm._v(" Save a New Ad "),_c('div',{staticClass:"text-gray-400 text-base font-normal"},[_vm._v(" Ads saved to foreplay will be in your library forever! ")])])])]},proxy:true},{key:"default",fn:function(){return [(_vm.adBlockedDetected)?_c('div',{staticClass:"flex items-center border-b border-gray-200 mb-4 pb-4 px-4"},[_c('img',{attrs:{"src":require("../../assets/images/adblock.jpg"),"width":"50"}}),_c('div',{staticClass:"ml-3"},[_c('div',{staticClass:"font-bold"},[_vm._v(" Disable AdBlocker ")]),_c('div',{staticClass:"text-gray-700"},[_vm._v(" Please disable AdBlocker for foreplay to work ")])])]):_vm._e(),_c('div',{staticClass:"border-t border-gray-300 w-full"}),_c('div',{staticClass:"hidden sm:flex w-full items-center justify-center my-2"},[_c('nav',{staticClass:"flex px-4 items-center justify-center"},[_c('div',{staticClass:"flex bg-neutral-25 rounded-full py-0.5"},[_c('a',{staticClass:"flex select-none items-center justify-center py-1 gap-1 rounded-full cursor-pointer transition-all duration-300",class:{
              'active-shadow bg-white text-neutral-900': _vm.selectedTab === 'extension',
              'text-neutral-500': _vm.selectedTab !== 'extension'
            },staticStyle:{"width":"150px"},attrs:{"value":"inspiration"},on:{"click":function($event){_vm.selectedTab = 'extension'}}},[_vm._v(" Chrome Extension ")]),_c('a',{staticClass:"flex select-none items-center justify-center py-1 gap-1 rounded-full cursor-pointer transition-all duration-300",class:{
              'active-shadow bg-white text-neutral-900': _vm.selectedTab === 'manual',
              'text-neutral-500': _vm.selectedTab !== 'manual'
            },staticStyle:{"width":"150px"},attrs:{"value":"specs"},on:{"click":function($event){_vm.selectedTab = 'manual'}}},[_vm._v(" Bulk Upload ")])])])]),_c('div',{staticClass:"border-t border-gray-300 w-full"}),(_vm.selectedTab === 'extension')?_c('Extension'):_vm._e(),(_vm.selectedTab === 'manual')?_c('ManualUpload',{on:{"close":function($event){return _vm.$emit('close')},"refresh":function($event){return _vm.$emit('refresh')},"updateFileBoards":_vm.updateFileBoards}}):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }