<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 10V13.75M11.3257 6.79909C12.0581 7.53156 12.0581 8.71915 11.3257 9.45093C10.5933 10.1827 9.40577 10.1834 8.67407 9.45093C7.94234 8.7184 7.94162 7.53085 8.67407 6.79909C9.40645 6.06733 10.5933 6.06661 11.3257 6.79909ZM10 16.75C6.27208 16.75 3.25 13.728 3.25 10C3.25 6.27208 6.27208 3.25 10 3.25C13.728 3.25 16.75 6.27208 16.75 10C16.75 13.728 13.728 16.75 10 16.75Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'OptimizationIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

    <style scoped></style>
