<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M3.45341 8.65398C3.41505 8.79494 3.39369 8.95163 3.40752 9.12488C3.44834 9.63632 3.69673 11.095 5.12232 13.2643C6.13316 14.809 7.9704 16.4672 8.89809 17.2607C9.53573 17.8062 10.4665 17.8004 11.0984 17.2523C12.0254 16.4481 13.8653 14.7689 14.8756 13.2398C16.2971 11.1005 16.5495 9.64756 16.5918 9.13083C16.6065 8.95103 16.5834 8.78873 16.5424 8.64332C16.4187 8.20455 15.9283 6.60636 14.9339 5.10206C13.9473 3.60949 12.3519 2.04999 9.99798 2.04999C7.61659 2.04999 6.01822 3.61201 5.03536 5.11163C4.04641 6.62051 3.57112 8.22145 3.45341 8.65398Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M8.22908 11.8663L8.23883 11.8767C8.5748 12.2417 8.92943 12.5237 9.25499 12.7102C9.59054 12.9024 9.84697 12.9662 9.99782 12.9662L8.22908 11.8663ZM8.22908 11.8663C7.49231 11.0958 6.99916 9.98164 6.84434 8.8579C6.8417 8.83873 6.84449 8.81713 6.8625 8.78965C6.8824 8.75928 6.91946 8.72603 6.97291 8.70625L9.91171 7.61866C9.96762 7.59797 10.0291 7.59794 10.085 7.61857L13.0457 8.71072C13.0992 8.73047 13.1364 8.76375 13.1564 8.79421C13.1745 8.82179 13.1773 8.84353 13.1746 8.86288C13.0186 9.99312 12.5218 11.1124 11.7786 11.8892L11.7786 11.8892M8.22908 11.8663L11.7786 11.8892M11.7786 11.8892L11.7744 11.8936M11.7786 11.8892L11.7744 11.8936M11.7744 11.8936C11.0732 12.6385 10.3864 12.9662 9.99788 12.9662L11.7744 11.8936Z" 
    fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'AnalyzeSpyderIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>