<template>
  <div class="w-full h-full overflow-y-auto">
    <div class="w-full h-full flex flex-col gap-5 rounded-t-3xl px-32 pt-12">
      <div class="flex justify-between items-end border-b border-neutral-50 pb-5">
        <div class="flex flex-col gap-1">
          <BaseText
            type="label"
            class="text-neutral-600"
          >
            White Label Share Links
          </BaseText>
          <BaseText
            size="sm"
            class="text-neutral-500"
            style="max-width: 512px"
          >
            This feature lets you share your boards with your own branding, giving you full control over how they're presented while maintaining a professional look.
          </BaseText>
        </div>
        <BaseButton
          primary
          :disabled="!hasDataChanged"
          :loading="loading"
          @click="saveLabelConfig"
          forcedTheme="blue"
        >
          Save Changes
        </BaseButton>
      </div>
      <div class="settings-preview-container rounded-2xl p-1 w-full grid grid-cols-12 grid-rows-auto gap-2 xl:gap-0">
        <!-- Customize Config -->
        <div class="col-span-12 xl:col-span-6 row-span-1  w-full bg-white rounded-xl flex flex-col">
          <!-- Logo -->
          <div class="flex p-4 gap-5">
            <img
              v-if="companyLogo"
              :src="companyLogo"
              class="rounded-md"
              style="width: 44px; height: 44px; padding: 2px"
            >
            <img
              v-else
              src="../../assets/images/defaultProfileImage.webp"
              class="rounded-md"
              style="width: 44px; height: 44px; padding: 2px"
            >
            <div class="flex flex-col">
              <BaseText
                type="label"
                size="sm"
                class="text-text-loud mt-0.5"
              >
                Logo
              </BaseText>
              <BaseText
                type="body"
                size="sm"
                class="text-text-normal mt-px"
              >
                We support PNGs and JPEGs.
              </BaseText>
              <div class="flex items-center gap-2 mt-4">
                <!-- Upload Logo Button -->
                <button
                  class="upload-btn flex items-center gap-1.5 pl-1.5 py-1.5 pr-2.5 rounded-md"
                  @click="handleFileInput"
                >
                  <input
                    ref="fileInput"
                    class="hidden"
                    type="file"
                    @change="handleFileUpload"
                  >
                  <UploadIcon class="text-icon-normal" />
                  <BaseText
                    type="label"
                    size="sm"
                    class="text-text-muted"
                  >
                    Upload Logo
                  </BaseText>
                </button>
                <!-- Remove Button -->
                <button
                  class="px-2.5 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-25"
                  :class="companyLogo === '' ? 'cursor-default bg-neutral-10 text-text-disabled' : 'text-text-muted'"
                  :disabled="companyLogo === ''"
                  @click="removeLogo"
                >
                  <BaseText
                    type="label"
                    size="sm"
                  >
                    Remove
                  </BaseText>
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="showLogoCallout"
            class="w-full px-4"
          >
            <div class="w-full flex items-center gap-2 p-2 mt-1 rounded-md bg-primary-red-10">
              <WarningIcon class="text-primary-red-300" />
              <BaseText
                type="body"
                size="sm"
                class="text-secondary-red-300"
              >
                Use a square image for best results.
              </BaseText>
            </div>
          </div>
          <div class="w-full flex flex-col p-4 mt-1">
            <BaseText
              type="label"
              size="sm"
              class="text-text-loud"
            >
              Company Information
            </BaseText>
            <BaseText
              type="body"
              size="sm"
              class="text-text-normal mt-1"
            >
              Details to show related to your company.
            </BaseText>
            <!-- Company Info Inputs -->
            <div class="w-full info-grid mt-3">
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted self-center"
              >
                Company Name
              </BaseText>
              <input
                v-model="companyName"
                placeholder="Foreplay"
                class="w-full form-input px-2 py-1.5 max-w-none min-w-0 caret-neutral-400"
              >
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted self-center"
              >
                Company URL
              </BaseText>
              <input
                v-model="companyURL"
                placeholder="https://foreplay.co"
                class="w-full form-input px-2 py-1.5 max-w-none min-w-0 caret-neutral-400"
              >
            </div>
          </div>
          <div class="w-full flex flex-col p-4 mt-1">
            <BaseText
              type="label"
              size="sm"
              class="text-text-loud"
            >
              Styles
            </BaseText>
            <BaseText
              type="body"
              size="sm"
              class="text-text-normal mt-1"
            >
              Customize your brand's share page.
            </BaseText>
            <div class="w-full info-grid mt-3">
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted self-center"
              >
                Primary Color
              </BaseText>
              <BaseColorPicker
                :value="companyColor"
                @change="(e) => companyColor = e"
              />
            </div>
          </div>
        </div>
        <!-- Preview -->
        <div class="col-span-12 xl:col-span-6 w-full row-span-1 pl-5 pt-5 overflow-hidden rounded-xl bg-neutral-25">
          <div class="flex gap-2">
            <img src="../../assets/icons/preview-icon.svg">
            <span>Preview</span>
          </div>
          <!-- Mock preview -->
          <div class="bg-none h-full mt-5 rounded-l-xl relative preview-container">
            <!-- Gradient preview -->
            <div class="absolute top-0 right-0 w-full h-full gradient-container">
              <WhiteLabelGradient :fill="companyColor" />
            </div>
            <div class="flex whitespace-nowrap justify-between text-white py-4 px-7 relative z-50">
              <div class="flex items-center gap-2">
                <img
                  v-if="companyLogo"
                  :src="companyLogo"
                  class="rounded-md w-5 h-5"
                >
                <img
                  v-else
                  src="../../assets/images/defaultProfileImage.webp"
                  class="rounded-md w-5 h-5"
                >
                <div class="flex gap-1">
                  <BaseText
                    type="label"
                    size="lg"
                  >
                    {{ companyName }}
                  </BaseText>
                </div>
                <!-- Divider -->
                <div class="text-neutral-alpha-300 w-min text-center mx-2">
                  |
                </div>
                <img
                  v-if="currentUser?.avatar"
                  :src="currentUser?.avatar"
                  class="rounded-full w-5 h-5"
                >
                <img
                  v-else
                  src="../../assets/images/defaultProfileImage.webp"
                  class="rounded-full w-5 h-5"
                >

                <BaseText
                  type="label"
                  size="sm"
                >
                  {{ currentUser?.name }}
                </BaseText>
                <BaseText
                  size="sm"
                  class="text-neutral-alpha-700"
                >
                  shared this board with you.
                </BaseText>
              </div>
            </div>
            <!-- Stack -->
            <div class="w-full h-full flex gap-5 px-7 z-50 relative">
              <div
                v-for="row in [1, 2, 3, 4]"
                :key="row"
                class="flex flex-col gap-5 w-56 h-full flex-shrink-0 "
              >
                <div
                  v-for="col in [1, 2, 3, 4]"
                  :key="`${row}-${col}`"
                  :class="[row % 2 !== 0 ? 'max-h-72' : 'max-h-92']"
                  class="bg-neutral-alpha-200 rounded-xl h-full flex-shrink-0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from '@/api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'
import { mapMutations } from 'vuex'
import { nanoid } from 'nanoid'
import WhiteLabelGradient from '../account/whiteLabel/WhiteLabelGradient.vue'
import UploadIcon from '../globals/Icons/UploadIcon.vue'
import WarningIcon from '../globals/Icons/WarningIcon.vue'
import InfoIcon from '../globals/Icons/InfoBorderIcon.vue'

export default {
  name: 'WhiteLabelSettings',
  components: {
    WhiteLabelGradient,
    UploadIcon,
    WarningIcon,
    InfoIcon
  },
  props: {
    currentUser: {
      type: Object
    },
    currentTeam: {
      type: Object
    }
  },
  data () {
    return {
      companyName: '',
      companyURL: '',
      companyLogo: '',
      companyColor: '',

      originalData: {
        companyName: '',
        companyURL: '',
        companyLogo: '',
        companyColor: ''
      },

      loading: false,
      showLogoCallout: false
    }
  },
  computed: {
    hasDataChanged () {
      const ogData = this.originalData
      return this.companyName !== ogData.companyName || this.companyURL !== ogData.companyURL || this.companyLogo !== ogData.companyLogo || this.companyColor !== ogData.companyColor
    }
  },
  async mounted () {
    const currentTeam = this.$props.currentTeam
    this.companyName = this.originalData.companyName = currentTeam?.whiteLabel?.companyName || ''
    this.companyURL = this.originalData.companyURL = currentTeam?.whiteLabel?.companyURL || ''
    this.companyLogo = this.originalData.companyLogo = currentTeam?.avatar || ''
    this.companyColor = this.originalData.companyColor = currentTeam?.whiteLabel?.companyColor || '#4c99e1'
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_USER_TEAM']),
    async uploadNewAvatar () {
      if (this.companyLogo !== this.originalData.companyLogo) {
        const team = this.$props.currentTeam
        const storage = firebase
          .app()
          .storage('gs://foreplay-team-avatars')

        // Delete old avatar if exists
        if (team && team?.avatar) {
          const oldAvatarPath = team.avatar
          const oldAvatarRef = storage.ref().child(
            oldAvatarPath.split(
              'https://storage.googleapis.com/foreplay-team-avatars/'
            )[1]
          )
          try {
            await oldAvatarRef.delete()
          } catch (e) {
            console.log('Error deleting avatar: ', e.message)
          }
        }

        // Handle if logo was removed
        if (this.companyLogo === '') {
          return ''
        }

        // Upload logo to gstorage
        const folderName = nanoid()
        const fileName = nanoid()
        const filePath = `${folderName}/${fileName}`
        const base64Logo = this.companyLogo
        await storage.ref(filePath).putString(base64Logo, 'data_url')

        return `https://storage.googleapis.com/foreplay-team-avatars/${filePath}`
      }
      return undefined
    },
    async saveLabelConfig () {
      if (!this.hasDataChanged) {
        this.$showAlert({
          message: 'No changes were made',
          type: 'error'
        })
        return
      }

      const currentTeam = this.$props.currentTeam
      this.loading = true
      try {
        // Create update object
        let toUpdateBuilder = {}
        // Handle new avatar upload
        const fileLocation = await this.uploadNewAvatar()
        // Conditionally add changed properties
        toUpdateBuilder = {
          ...(fileLocation !== undefined) && { avatar: fileLocation },
          ...(this.companyName !== this.originalData.companyName) && { 'whiteLabel.companyName': this.companyName },
          ...(this.companyURL !== this.originalData.companyURL) && { 'whiteLabel.companyURL': this.companyURL },
          ...(this.companyColor !== this.originalData.companyColor) && { 'whiteLabel.companyColor': this.companyColor }
        }

        await FirebaseAPI.Teams.update(currentTeam.id, toUpdateBuilder)

        const newLabelConfig = {
          companyName: this.companyName,
          companyURL: this.companyURL,
          companyColor: this.companyColor
        }
        const newAvatar = fileLocation !== undefined ? fileLocation : currentTeam?.avatar || ''
        this.SET_USER_TEAM({
          ...currentTeam,
          ...{
            avatar: newAvatar,
            whiteLabel: {
              ...newLabelConfig
            }
          }
        })

        this.originalData = {
          ...newLabelConfig,
          companyLogo: newAvatar
        }
        this.companyLogo = newAvatar
        this.$showAlert({
          message: 'White label updated',
          type: 'success'
        })

        this.loading = false
      } catch (e) {
        this.$showAlert({
          message: 'Error updating white label',
          type: 'error'
        })
        console.log('Error', e.message)
        this.loading = false
      }
    },
    handleFileUpload (event) {
      this.showLogoCallout = false
      const mediaFile = event.target.files[0]
      if (!mediaFile) return
      const fileType = mediaFile.type
      if (!(fileType === 'image/png' || fileType === 'image/jpeg')) {
        this.$showAlert({
          message: 'Only JPEG and PNG accepted',
          type: 'error'
        })
        return
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        this.companyLogo = e.target.result

        // We preload the image to check if it is close to square
        const img = new Image()
        img.onload = () => {
          console.log('Image loaded')
          const isSquare = Math.abs(img.width - img.height) < 10
          this.showLogoCallout = !isSquare
        }
        img.src = e.target.result
      }
      reader.readAsDataURL(mediaFile)
    },
    removeLogo () {
      this.companyLogo = ''
      this.$refs.fileInput.value = ''
      this.showLogoCallout = false
    },
    handleFileInput () {
      this.$refs.fileInput.click()
    }
  }

}
</script>

<style scoped>
.upload-btn {
  background: linear-gradient(180deg, rgba(223, 225, 231, 0.08) 0%, rgba(223, 225, 231, 0.08) 100%), #FFFFFF;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 100ms ease-in-out;
}
.upload-btn:hover {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.17), 0px 0px 0px 1px rgba(0, 56, 108, 0.12);
}

.info-grid {
  display: grid;
  grid-template-columns: 160px 1fr;
  column-gap: 4px;
  row-gap: 8px;
}

.form-input {
  color: #06070F;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 150ms ease-in-out;
}
.form-input:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.1), 0px 0px 0px 1px rgba(0, 56, 108, 0.1);
}
.form-input::placeholder {
  color: #5E6678;
  transition: color 150ms ease-in-out;
  opacity: 0.9;
}
.form-input:hover::placeholder {
  color: #303546;
}
.form-input:focus::placeholder {
  color: #C1C7D0;
}

.preview-container {
  width: 1440px;
  border-top-left-radius: 12px;
  overflow: hidden;
}
</style>
