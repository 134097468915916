// Lens Module

import Vue from 'vue'
import FirebaseAPI from '@/api/firebase'
import LensAPI from '@/api/lens'
import ForeplayAPI from '@/api/foreplayServer'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    columnDrawerView: false,
    isLoading: {
      report: true
    },
    presets: [],
    /**
     * selectedPreset - source of truth, used to populate local state
     * appliedPreset - used for the current session modifications
     * localPreset - local preset for saving, apply changes to applied & selected
     */
    selectedPreset: {},
    appliedPreset: {},
    localPreset: {},

    fuzzyResults: new Set(),
    metricSearch: '',

    // Report table
    adGroups: [],
    currentFilter: 'ad_name',
    currentSort: null,
    pagination: {
      currentPage: 1,
      limit: 10,
      totalItems: 0,
      totalPages: 0
    },
    tableConfig: {
      colorFormat: 'Positive Outlier',
      showStatus: false,
      showTags: false
    },
    selectedColumns: []
  },
  getters: {
    getPresets: state => state.presets,
    getSelectedPreset: state => state.selectedPreset,
    getAppliedPreset: state => state.appliedPreset,
    getLocalPreset: state => state.localPreset,
    getFuzzyResults: state => state.fuzzyResults,
    getMetricSearch: state => state.metricSearch,
    getCurrentFilter: state => state.currentFilter,
    getAdGroups: state => state.adGroups,
    getCurrentPagination: state => state.pagination,
    getSelectedColumns: state => state.selectedColumns,
    getTableConfig: state => state.tableConfig,
    getColumnDrawerView: state => state.columnDrawerView,
    getLoading: state => state.isLoading
  },
  mutations: {
    SET_PRESETS (state, presets) {
      state.presets = presets
    },
    SET_SELECTED_PRESET (state, preset) {
      state.selectedPreset = { ...preset }
    },
    SET_APPLIED_PRESET (state, preset) {
      state.appliedPreset = { ...preset }
    },
    SET_LOCAL_PRESET (state, preset) {
      state.localPreset = { ...preset }
    },
    SET_PRESET_VALUE (state, { key, value }) {
      Vue.set(state.appliedPreset, key, value)
    },
    SET_LOCAL_PRESET_VALUE (state, { key, value }) {
      Vue.set(state.localPreset, key, value)
    },
    SET_FUZZY_RESULTS (state, results) {
      state.fuzzyResults = results
    },
    SET_METRIC_SEARCH (state, text) {
      state.metricSearch = text
    },
    SET_CURRENT_FILTER (state, filter) {
      state.currentFilter = filter
    },
    SET_AD_GROUPS (state, groups) {
      state.adGroups = [...groups]
    },
    SET_PAGINATION (state, pagination) {
      state.pagination = { ...state.pagination, ...pagination }
    },
    SET_SELECTED_COLUMNS (state, columns) {
      state.selectedColumns = [...columns]
    },
    SET_CURRENT_SORT (state, sort) {
      state.currentSort = { ...sort }
    },
    SET_COLUMN_DRAWER_VIEW (state, canShow) {
      state.columnDrawerView = canShow
    },
    SET_TABLE_CONFIG_VALUE (state, { key, value }) {
      Vue.set(state.tableConfig, key, value)
    },
    SET_LOADING (state, { key, value }) {
      state.isLoading[key] = value
    }
  },
  actions: {
    async fetchPresets ({ state, commit }) {
      const data = await ForeplayAPI.LensPreset.getAll()
      console.log('%c GOT DATA', 'font-size: 18px;')
      console.log(data)
      const presets = data
      commit('SET_PRESETS', presets)
      const defaultPreset = presets.find(preset => preset.isDefault)
      console.log('Fetching Presets', state, defaultPreset)
      // If we dont currently have a selected preset, use default
      if (_.isEmpty(state.selectedPreset)) {
        commit('SET_SELECTED_PRESET', defaultPreset)
        commit('SET_LOCAL_PRESET', defaultPreset)
        commit('SET_APPLIED_PRESET', defaultPreset)
      }
    },
    /**
     * -- shouldSelect = true --
     * Used when a user changes presets for a report
     * OR
     * -- shouldSelect = false --
     * Used when a user is swapping between presets for editing
     */
    async setPresetById ({ state, commit }, { id, shouldSelect = false }) {
      const presets = state.presets

      const selectedPreset = (id ? presets.find((preset) => preset.id === id) : presets?.[0]) ?? {}
      // We should keep local and selected state seperate until save/update
      commit('SET_LOCAL_PRESET', selectedPreset)
      if (shouldSelect) {
        commit('SET_SELECTED_PRESET', selectedPreset)
        commit('SET_APPLIED_PRESET', selectedPreset)
      }
    },
    async updatePreset ({ dispatch, state, commit }, toUpdate) {
      const newPreset = {
        ...toUpdate
      }
      try {
        await ForeplayAPI.LensPreset.update(toUpdate.id, newPreset)
      } catch (e) {
        console.error(e)
      }
      const originalPreset = state.localPreset
      commit('SET_SELECTED_PRESET', { ...originalPreset, ...newPreset })
      commit('SET_APPLIED_PRESET', { ...originalPreset, ...newPreset })
      commit('SET_LOCAL_PRESET', { ...originalPreset, ...newPreset })
      dispatch('fetchPresets')
    },
    applyPreset ({ state, commit }, toUpdate) {
      commit('SET_APPLIED_PRESET', toUpdate)
      commit('SET_LOCAL_PRESET', toUpdate)
    },
    updatePresetField ({ commit }, { key, value }) {
      commit('SET_PRESET_VALUE', { key, value })
    },
    updateLocalPresetField ({ commit }, { key, value }) {
      commit('SET_LOCAL_PRESET_VALUE', { key, value })
    },
    setFuzzyResults ({ commit }, results) {
      commit('SET_FUZZY_RESULTS', results)
    },
    updateTextSearch ({ commit }, text) {
      commit('SET_METRIC_SEARCH', text)
    },

    // Table ad groups
    async fetchAdGroups ({ state, commit }, query) {
      if (state.adGroups.length === 0) {
        commit('SET_LOADING', { key: 'report', value: true })
      }

      const filter = query?.filter
      const page = query?.page
      const sort = query?.sort
      const currentFilter = filter ?? state.currentFilter
      const currentPage = page?.currentPage ?? 1
      const limit = page?.limit ?? 10

      const payload = {
        filter: currentFilter,
        page: currentPage,
        limit,
        sortBy: sort ? `${sort?.key}:${sort.value}` : null
      }
      const adGroups = await LensAPI.AdGroups.getLensGroups(payload)

      const totalItems = adGroups.total
      const totalPages = adGroups.pages

      commit('SET_AD_GROUPS', adGroups.data)
      commit('SET_PAGINATION', { totalItems, totalPages })
      commit('SET_LOADING', { key: 'report', value: false })
    },
    updateTableConfigField ({ commit }, { key, value }) {
      commit('SET_TABLE_CONFIG_VALUE', { key, value })
    },
    updateGroupFilter ({ commit, dispatch }, filter) {
      console.log(`%c UPDATING FILTER ${JSON.stringify(filter)}`, 'font-size: 18px; color: red')
      commit('SET_CURRENT_FILTER', filter)
      dispatch('fetchAdGroups', { filter })
    },
    updateSortFilter ({ commit, dispatch }, sort) {
      commit('SET_CURRENT_SORT', sort)
      dispatch('fetchAdGroups', { sort })
    },
    // Pagination
    updatePagination ({ commit, dispatch }, pagination) {
      commit('SET_PAGINATION', pagination)
      console.log(`%c UPDATING PAGE ${JSON.stringify(pagination)}`, 'font-size: 18px; color: green')
      dispatch('fetchAdGroups', { page: pagination })
    },
    // Report actions
    updateSelectedColumns ({ commit }, columns) {
      commit('SET_SELECTED_COLUMNS', columns)
    }
  }
}
