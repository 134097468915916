<template>
  <BaseCard>
    <div class="relative overflow-hidden">
      <!-- Header -->
      <div id="inspiration" class="flex items-center justify-start p-3.5 px-5 border-b border-border-normal"
        style="height: 72px">
        <!-- Icon -->
        <div style="width: 45px;" class="icon-shadow mr-3 p-2.5 rounded-full flex items-center justify-center">
          <img src="../../../assets/icons/box-click.svg">
        </div>
        <!-- Text -->
        <div>
          <!-- Title -->
          <BaseText type="label" size="md">
            <span class="text-text-muted">
              Submissions & Review
            </span>
          </BaseText>
          <!-- SubTitle -->
          <BaseText type="body" size="sm">
            <span class="text-text-normal">
              Review ad submissions from creators and editors
            </span>
          </BaseText>
          <div />
        </div>
      </div>

      <div class="w-full">
        <!-- Submissions -->
        <div class="p-5 flex flex-wrap gap-5">
          <div v-for="(submission, index) of submissions" :key="index" class="group bg-background-normal"
            style="border-radius: 14px; height: 436px; width: 280px">
            <!-- Top Section -->
            <div class="flex justify-between items-center px-4 py-3">
              <BaseText type="body" size="sm" class="text-text-muted">
                {{ getFileName(submission) }}
                <span class="text-text-subdued">
                  .{{ getFileExtension(submission) }}
                </span>
              </BaseText>

              <!-- Delete -->
              <img class="cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                src="../../../assets/icons/trash.svg" @click="deleteSubmission(index)">
            </div>
            <!-- Media -->
            <div class="p-2 flex items-center" style="height: 280px;">
              <video v-if="submission.format === 'video/mp4'" ref="videoPlayer" controls="true" preload="metadata"
                class="object-contain w-full h-full cursor-pointer rounded-md">
                <source :src="submission.path" type="video/mp4">
              </video>
              <img v-else class="rounded-md"
                style="filter: drop-shadow(0px 2px 4px rgba(54, 57, 74, 0.07)) drop-shadow(0px 7px 7px rgba(54, 57, 74, 0.06)) drop-shadow(0px 16px 16px rgba(54, 57, 74, 0.06)); object-fit: contain; width: 100%; height: 100%;"
                :src="submission.path">
            </div>
            <!-- Submitter -->
            <div class="px-4 pt-3">
              <!-- Top Section -->
              <div class="flex justify-start gap-1 items-center">
                <!-- Name -->
                <BaseText type="label" size="sm" class="text-text-muted">
                  {{ submission.submittedBy.name }}
                </BaseText>
                <!-- Dot -->
                <BaseText type="body" size="sm" class="text-text-subdued">
                  ·
                </BaseText>
                <!-- Date -->
                <BaseText type="body" size="sm" class="text-text-subdued">
                  {{ formatSubmissionDate(submission.submittedAt) }}
                </BaseText>
              </div>
              <!-- Email -->
              <BaseText type="body" size="sm" class="text-text-normal">
                {{ submission.submittedBy.email }}
              </BaseText>
            </div>
            <!-- Download -->
            <div class="px-4 pb-4 mt-3">
              <BaseButton outlined class="w-full" @click="downloadSubmission(submission.path, submission.name)">
                <div class="flex items-center gap-1.5">
                  <img src="../../../assets/icons/download.svg">
                  <BaseText type="label" size="sm" class="text-text-muted">
                    Download
                  </BaseText>
                </div>
              </BaseButton>
            </div>
          </div>
        </div>
        <!-- Empty State -->
        <div>
          <!--  -->
        </div>
      </div>
    </div>
  </BaseCard>
</template>
<script>
import { downloadFile } from 'blob-url-file'
import moment from 'moment'
import firebase from '@/api/config/FirebaseConfig'

export default {
  name: 'Submissions',
  props: {
    submissions: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getFileName(submission) {
      const maxWidth = 20
      const fileName = submission.name.split('.')[0]

      const extension = fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2)
      const nameWithoutExtension = fileName.replace('.' + extension, '')

      if (nameWithoutExtension.length > maxWidth) {
        return `${nameWithoutExtension.slice(0, maxWidth)}...${extension}`
      }

      return fileName
    },
    getFileExtension(submission) {
      return submission.name.split('.')[1]
    },
    async downloadSubmission(path, name) {
      const ref = path.split('foreplay-submissions/')[1]
      const downloadUrl = await firebase.storage().ref(ref).getDownloadURL()

      downloadFile(downloadUrl, name)
    },
    formatSubmissionDate(timeStamp) {
      const duration = moment.duration(moment().diff(moment(timeStamp)))

      let result
      if (duration.asYears() >= 1) {
        result = Math.floor(duration.asYears()) + 'y'
      } else if (duration.asMonths() >= 1) {
        result = Math.floor(duration.asMonths()) + 'mo'
      } else if (duration.asDays() >= 1) {
        result = Math.floor(duration.asDays()) + 'd'
      } else if (duration.asHours() >= 1) {
        result = Math.floor(duration.asHours()) + 'h'
      } else if (duration.asMinutes() >= 1) {
        result = Math.floor(duration.asMinutes()) + 'm'
      } else {
        result = Math.floor(duration.asSeconds()) + 's'
      }

      return result + ' ago'
    },
    async deleteSubmission(index) {
      console.log('delete submission')
      const duplicateArr = [...this.submissions]

      // Filter out the index
      const newSubmissions = duplicateArr.filter((_, i) => i !== index)

      // Submission to remove
      const panel = this.submissions[index]
      console.log('panel', panel)

      // Delete the submission image
      let referenceUrl
      // If its using the old reference type
      if (panel.path.includes('appspot.com')) {
        referenceUrl = panel.path.split('foreplay-submissions.appspot.com/')[1]
      } else {
        referenceUrl = panel.path.split('foreplay-submissions/')[1]
      }

      // Create a reference to the file to delete
      var storage = firebase.storage()

      // Create a storage reference from our storage service
      var storageRef = storage.ref()
      var desertRef = storageRef.child(referenceUrl)
      // Delete the file
      await desertRef.delete()


      // File deleted successfully
      // Update panel and save new reference
      const savePayload = {
        field: 'submissions',
        value: newSubmissions
      }

      this.$emit('saveSection', savePayload)
    }
  }
}
</script>
