<template>
  <svg
    :width="width"
    :Height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M5.33317 4.66602V6.83268C5.33317 7.10882 5.55703 7.33268 5.83317 7.33268H7.83317M14.6744 15.3327V13.166C14.6744 12.8899 14.4506 12.666 14.1744 12.666H12.0077M4.6665 9.99935C4.6665 12.9449 7.05432 15.3327 9.99984 15.3327C11.7573 15.3327 13.3534 14.4826 14.3332 13.1712M15.3332 9.99935C15.3332 7.05383 12.9454 4.66602 9.99984 4.66602C8.24236 4.66602 6.6463 5.5161 5.6665 6.82751"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'RefreshIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
