<template>
  <div class="w-full h-full relative">
    <div class="absolute w-full h-full bg-black opacity-40 z-10 backdrop-blur"></div>

    <svg
      width="1440"
      height="900"
      viewBox="0 0 1440 900"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      class="z-10"
    >
      <g clip-path="url(#clip0_1_5)">
        <rect
          width="1440"
          height="900"
          fill="black"
        />
        <g filter="url(#filter0_d_1_5)">
          <rect
            width="1440"
            height="690"
            transform="matrix(1 0 0 -1 0 690)"
            fill="url(#pattern0_1_5)"
            shape-rendering="crispEdges"
          />
        </g>
        <rect
          width="1440"
          height="900"
          fill="#F82020"
          style="mix-blend-mode:color"
        />
        <rect
          y="231"
          width="1440"
          height="669"
          fill="url(#paint0_linear_1_5)"
        />
        <rect
          width="1440"
          height="900"
          fill="url(#paint1_linear_1_5)"
        />
        <rect
          x="1149"
          width="900"
          height="1149"
          transform="rotate(90 1149 0)"
          fill="url(#paint2_linear_1_5)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1_5"
          x="-4"
          y="0"
          width="1448"
          height="698"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood
            flood-opacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_5"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1_5"
            result="shape"
          />
        </filter>

        <linearGradient
          id="paint0_linear_1_5"
          x1="720"
          y1="900"
          x2="720"
          y2="231"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop
            offset="1"
            stop-opacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_5"
          x1="720"
          y1="900"
          x2="720"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop
            offset="1"
            stop-opacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_5"
          x1="1599"
          y1="1149"
          x2="1599"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop
            offset="1"
            stop-opacity="0"
          />
        </linearGradient>
        <clipPath id="clip0_1_5">
          <rect
            width="1440"
            height="900"
            fill="white"
          />
        </clipPath>

      </defs>
    </svg>
    <div
      class="absolute top-0 -right-0 w-full color-blend z-10 mix-blend-color "
      :style="`background-color: ${fill}`"
    />
    <img
      src="../../../assets/images/white-label-gradient.png"
      class="absolute top-0 -right-0 w-full h-full z-0"
    >
  </div>
</template>

<script>

export default {
  name: 'WhiteLabelGradient',
  props: {
    fill: {
      type: String,
      default: '#4c99e1'
    }
  },
  data () {
    return {

    }
  }
}
</script>

<style scoped>
.color-blend{
  height: 70%;
}
</style>
