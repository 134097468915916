<template>
    <div class="flex flex-col justify-center gap-2 border-border-normal">
        <div>
            <BaseText type="label" size="sm">
                Select Folder
            </BaseText>
        </div>
        <div class="relative flex-grow">
            <button class="flex items-center w-full pl-3 pr-1.5 py-2 border border-border-normal flex-nowrap"
                :class="expanded ? 'rounded-t-lg' : 'rounded-lg'" @click="expanded = !expanded">
                <FolderIcon class="text-icon-normal mr-2 flex-shrink-0" />
                <BaseText type="body" size="sm" class="flex-grow text-text-muted truncate text-left mr-2">
                    {{ selectedFolder.name || '' }}
                </BaseText>
                <div class="transform transition-transform flex-shrink-0"
                    :style="{ transform: expanded ? 'scaleY(-1)' : '' }">
                    <ChevronIcon class="text-icon-normal" />
                </div>
            </button>
            <div class="folder-dropdown-container rounded-b-lg" v-on-clickaway="() => { expanded = false }">
                <div class="folder-dropdown flex flex-col gap-1 px-1 rounded-b-lg bg-white cursor-pointer overflow-y-scroll scrollbar-hide"
                    :class="{ 'expanded': expanded }"
                    :style="{ height: expanded ? `${Math.min(folderOptions.length * 36 + 4, 203)}px` : '0px' }">
                    <button v-for="(folder, index) in folderOptions" :key="`change-folder-${index}`"
                        class="group flex items-center rounded-lg px-2 py-1.5 transition duration-100 hover:bg-neutral-10 whitespace-nowrap"
                        :class="`${index === 0 ? 'mt-1' : index === folderOptions.length - 1 ? 'mb-1' : ''} ${expanded ? 'opacity-100' : 'opacity-0'}`"
                        @click="() => updateSelectedFolder(folder)">
                        <FolderIcon
                            class="transition-colors text-neutral-400 group-hover:text-icon-normal mr-2 flex-shrink-0" />
                        <BaseText type="body" size="sm" class="text-text-muted flex-grow truncate text-left">
                            {{ folder?.name || 'Default Folder' }}
                        </BaseText>
                    </button>
                </div>
                <div v-if="folderOptions.length > 5" class="dropdown-fade-overlay" />
            </div>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway2"
import { mapGetters } from "vuex";
import FolderIcon from '../globals/Icons/SpyderV2Icons/FolderIcon.vue'
import ChevronIcon from '../globals/Icons/ChevronIcon.vue'

export default {
    name: 'FolderDropdown',
    mixins: [clickaway],
    components: {
        FolderIcon,
        ChevronIcon
    },
    data() {
        return {
            selectedFolder: { id: null, name: 'Default Folder' },
            expanded: false,
        }
    },
    computed: {
        ...mapGetters('BoardsModule', ['getFolders']),
        folderOptions() {
            const folders = this.getFolders.filter(folder => folder.id !== this.selectedFolder.id)
            if (this.selectedFolder?.id) {
                folders.push({ id: null, name: 'Default Folder' })
            }

            return folders.sort((a, b) => a.name.localeCompare(b.name)) // sort alphabetically
        },
    },
    methods: {
        updateSelectedFolder(folder){
            this.selectedFolder = folder 
            this.expanded = false
            this.$emit('change', folder)
        }
    }
}
</script>


<style scoped>
.folder-dropdown-container {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    overflow: hidden;
}

.folder-dropdown {
    transition: height 100ms ease-in-out;
}

.folder-dropdown.expanded {
    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: #ECEFF3;
}

.dropdown-fade-overlay {
    position: absolute;
    pointer-events: none;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    z-index: 40;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffffb9 100%);
}
</style>