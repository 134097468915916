import firebase from '../config/FirebaseConfig'

const db = firebase.firestore()

const create = async (report) => {
  const user = firebase.auth().currentUser

  const { id } = await db.collection('reports').add({
    ...report,
    createdBy: user.uid,
    createdAt: +new Date()
  })

  return id
}

const checkUserHasReportedAd = async (adId) => {
  const user = firebase.auth().currentUser

  const snapshot = await db.collection('reports')
    .where('reportedAd', '==', adId)
    .where('createdBy', '==', user.uid)
    .get()

  return snapshot.docs.length > 0
}

export default {
  create,
  checkUserHasReportedAd
}