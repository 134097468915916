<template>
  <svg
    :width="width"
    :Height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.66536 5.33317C8.66536 5.70136 8.36688 5.99984 7.9987 5.99984C7.63051 5.99984 7.33203 5.70136 7.33203 5.33317C7.33203 4.96498 7.63051 4.6665 7.9987 4.6665C8.36688 4.6665 8.66536 4.96498 8.66536 5.33317Z"
      stroke="currentColor"
      stroke-width="1.33333"
    />
    <path
      d="M8.66536 10.0002C8.66536 10.3684 8.36688 10.6668 7.9987 10.6668C7.63051 10.6668 7.33203 10.3684 7.33203 10.0002C7.33203 9.63196 7.63051 9.3335 7.9987 9.3335C8.36688 9.3335 8.66536 9.63196 8.66536 10.0002Z"
      stroke="currentColor"
      stroke-width="1.33333"
    />
    <path
      d="M8.66536 14.6667C8.66536 15.0349 8.36688 15.3333 7.9987 15.3333C7.63051 15.3333 7.33203 15.0349 7.33203 14.6667C7.33203 14.2985 7.63051 14 7.9987 14C8.36688 14 8.66536 14.2985 8.66536 14.6667Z"
      stroke="currentColor"
      stroke-width="1.33333"
    />
    <path
      d="M12.6654 10.0002C12.6654 10.3684 12.3669 10.6668 11.9987 10.6668C11.6305 10.6668 11.332 10.3684 11.332 10.0002C11.332 9.63196 11.6305 9.3335 11.9987 9.3335C12.3669 9.3335 12.6654 9.63196 12.6654 10.0002Z"
      stroke="currentColor"
      stroke-width="1.33333"
    />
    <path
      d="M12.6654 14.6667C12.6654 15.0349 12.3669 15.3333 11.9987 15.3333C11.6305 15.3333 11.332 15.0349 11.332 14.6667C11.332 14.2985 11.6305 14 11.9987 14C12.3669 14 12.6654 14.2985 12.6654 14.6667Z"
      stroke="currentColor"
      stroke-width="1.33333"
    />
    <path
      d="M12.6654 5.33317C12.6654 5.70136 12.3669 5.99984 11.9987 5.99984C11.6305 5.99984 11.332 5.70136 11.332 5.33317C11.332 4.96498 11.6305 4.6665 11.9987 4.6665C12.3669 4.6665 12.6654 4.96498 12.6654 5.33317Z"
      stroke="currentColor"
      stroke-width="1.33333"
    />

  </svg>
</template>

<script>
export default {
  name: 'DragListIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

  <style scoped></style>
