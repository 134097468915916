<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24" fill="none">
    <g filter="url(#filter0_b_7735_2578)">
      <rect width="24" height="24" rx="12" fill="white" fill-opacity="0.24"/>
      <path d="M7.55566 12.8888L10.2223 15.5554L16.4446 8.44434" 
      stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <filter id="filter0_b_7735_2578" x="-16" y="-16" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="8"/>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7735_2578"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_7735_2578" result="shape"/>
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'DCOPreviewCheckmark',
  props: {
    width: {
      type: Number,
      default: 24
    },
    height: {
      type: Number,
      default: 24
    }
  }
}
</script>