<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M13 13.75C13 15.4068 11.6568 16.75 10 16.75C8.34314 16.75 7 15.4068 7 13.75M14.7247 7.74438L14.8593 10.4356C14.8697 10.643 14.9229 10.8458 15.0158 11.0315L15.936 12.8719C15.9781 12.9561 16 13.049 16 13.1432C16 13.4783 15.7283 13.75 15.3932 13.75H4.60677C4.27166 13.75 4 13.4783 4 13.1432C4 13.049 4.02193 12.9561 4.06406 12.8719L4.98423 11.0315C5.07707 10.8458 5.13035 10.643 5.14072 10.4356L5.27528 7.74438C5.40116 5.22671 7.47918 3.25 10 3.25C12.5208 3.25 14.5989 5.22671 14.7247 7.74438Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'NotificationsSettingsIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>