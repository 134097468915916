<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.33341 18C8.80541 18 10.0001 16.8053 10.0001 15.3333V12.6667H7.33341C5.86141 12.6667 4.66675 13.8613 4.66675 15.3333C4.66675 16.8053 5.86141 18 7.33341 18Z" 
    fill="#0ACF83"/>
    <path d="M4.66675 10C4.66675 8.52801 5.86141 7.33334 7.33341 7.33334H10.0001V12.6667H7.33341C5.86141 12.6667 4.66675 11.472 4.66675 10Z" 
    fill="#A259FF"/>
    <path d="M4.66675 4.66667C4.66675 3.19467 5.86141 2 7.33341 2H10.0001V7.33333H7.33341C5.86141 7.33333 4.66675 6.13867 4.66675 4.66667Z" 
    fill="#F24E1E"/>
    <path d="M10 2H12.6667C14.1387 2 15.3333 3.19467 15.3333 4.66667C15.3333 6.13867 14.1387 7.33333 12.6667 7.33333H10V2Z" 
    fill="#FF7262"/>
    <path d="M15.3333 10C15.3333 11.472 14.1387 12.6667 12.6667 12.6667C11.1947 12.6667 10 11.472 10 10C10 8.52801 11.1947 7.33334 12.6667 7.33334C14.1387 7.33334 15.3333 8.52801 15.3333 10Z" 
    fill="#1ABCFE"/>
  </svg>
</template>

<script>
export default {
  name: 'Figma',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>