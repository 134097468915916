<template>
  <!-- Create Advertisement Modal -->
  <BaseModal
    :card-width="'650px'"
    @close="$emit('close')"
  >
    <template #toolbar>
      <div class="flex text-xl text-black font-medium">
        <div
          class="bg-gray-300 text-gray-600 w-12 h-12 rounded-full flex justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-6 h-6"
          >
            <path
              fill-rule="evenodd"
              d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z"
              clip-rule="evenodd"
            />
          </svg>
        </div>

        <div class="ml-3">
          Save a New Ad

          <div class="text-gray-400 text-base font-normal">
            Ads saved to foreplay will be in your library forever!
          </div>
        </div>
      </div>
    </template>

    <template #default>
      <!-- Adblock Detected -->
      <div
        v-if="adBlockedDetected"
        class="flex items-center border-b border-gray-200 mb-4 pb-4 px-4"
      >
        <img
          src="../../assets/images/adblock.jpg"
          width="50"
        >

        <div class="ml-3">
          <div class="font-bold">
            Disable AdBlocker
          </div>

          <div class="text-gray-700">
            Please disable AdBlocker for foreplay to work
          </div>
        </div>
      </div>

      <div class="border-t border-gray-300 w-full" />

      <div class="hidden sm:flex w-full items-center justify-center my-2">
        <nav class="flex px-4 items-center justify-center">
          <div class="flex bg-neutral-25 rounded-full py-0.5">
            <a
              value="inspiration"
              class="flex select-none items-center justify-center py-1 gap-1 rounded-full cursor-pointer transition-all duration-300"
              style="width: 150px;"
              :class="{
                'active-shadow bg-white text-neutral-900': selectedTab === 'extension',
                'text-neutral-500': selectedTab !== 'extension'
              }"
              @click="selectedTab = 'extension'"
            >
              Chrome Extension
            </a>

            <a
              value="specs"
              class="flex select-none items-center justify-center py-1 gap-1 rounded-full cursor-pointer transition-all duration-300"
              style="width: 150px;"
              :class="{
                'active-shadow bg-white text-neutral-900': selectedTab === 'manual',
                'text-neutral-500': selectedTab !== 'manual'
              }"
              @click="selectedTab = 'manual'"
            >
              Bulk Upload
            </a>
            <!-- <a
              value="specs"
              class="flex select-none items-center justify-center py-1 gap-1 rounded-full cursor-pointer transition-all duration-300"
              style="width: 150px;"
              :class="{
                'active-shadow bg-white text-neutral-900': selectedTab === 'link',
                'text-neutral-500': selectedTab !== 'link'
              }"
              @click="selectedTab = 'link'"
            >
              Ad Link
            </a> -->
          </div>
        </nav>
      </div>

      <div class="border-t border-gray-300 w-full" />
      <Extension v-if="selectedTab === 'extension'" />
      <ManualUpload
        v-if="selectedTab === 'manual'"
        @close="$emit('close')"
        @refresh="$emit('refresh')"
        @updateFileBoards="updateFileBoards"
      />
      <!-- <AdLink
        v-if="selectedTab === 'link'"
        @close="$emit('close')"
      /> -->
    </template>
  </BaseModal>
</template>

<script>
import Extension from './AdvertismentCreateModalSections/Extension.vue'
import ManualUpload from './AdvertismentCreateModalSections/ManualUpload.vue'
import AdLink from './AdvertismentCreateModalSections/AdLink.vue'

export default {
  name: 'CreateAdvertisementModal',
  components: {
    AdLink,
    Extension,
    ManualUpload
  },
  props: {
    currentBoard: {
      type: String,
      default: ''
    },
    startingTab: {
      type: String,
      default: 'extension'
    }
  },
  data () {
    return {
      adBlockedDetected: false,
      selectedTab: 'extension'
    }
  },
  computed: {
  },
  async mounted () {
    this.selectedTab = this.startingTab
  },
  methods: {
    updateFileBoards (adId, boardIds) {
      this.$emit('updateFileBoards', adId, boardIds)
    }
  }
}
</script>

<style scoped lang="sass">
.active-shadow
  box-shadow: 0px 3px 8px rgba(9, 25, 72, 0.05), 0px 1px 2px rgba(9, 25, 72, 0.13)
</style>
