<template>
  <svg :width="width" :Height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="Vector"
      d="M7.43756 3.75H6.87506H5.25006C4.42163 3.75 3.75006 4.42157 3.75006 5.24999L3.75001 14.7501C3.75 15.5786 4.42158 16.2501 5.25001 16.2501H14.7499C15.5784 16.2501 16.2499 15.5786 16.2499 14.7502L16.25 5.25002C16.2501 4.42158 15.5785 3.75 14.75 3.75H13.1251H12.5626M7.43756 3.75L7.43838 3.49838C7.43928 3.22287 7.66287 3 7.93838 3H10.0001H12.0617C12.3372 3 12.5608 3.22282 12.5617 3.49829L12.5626 3.75M7.43756 3.75L7.43838 4.00162C7.43928 4.27713 7.66287 4.5 7.93838 4.5H12.0626C12.3387 4.5 12.5626 4.27614 12.5626 4V3.75"
      stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path id="Vector_2" d="M10.0001 13V7.5" stroke="currentColor" stroke-linecap="round" />
    <path id="Vector_3" d="M12.7501 10.25H7.25006" stroke="currentColor" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  name: 'NewReportIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
