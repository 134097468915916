// Imports
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Components from '@/components/globals'
import Filters from '@/filters'
import firebase from '@/api/config/FirebaseConfig'
import { validationMixin } from 'vuelidate'
import BaseNotifications from './plugins/BaseNotifications/BaseNotify'
import VueClipboard from 'vue-clipboard2'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import FirebaseAPI from '@/api/firebase'
import { StripePlugin } from '@vue-stripe/vue-stripe'
import VCalendar from 'v-calendar'
import InstantSearch from 'vue-instantsearch'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import checkView from 'vue-check-view'
import vuescroll from 'vue-scroll'
import VueConfetti from 'vue-confetti'
import contenteditable from 'vue-contenteditable'
import { authSocket } from './api/sockets'
import Rupt from 'rupt'
import ForeplayAPI from './api/foreplayServer'
import _ from "lodash";

// CSS Imports
import './assets/tailwind.css'
import 'inter-ui/inter.css'
import './assets/fonts.css'

// Vue Scroll
Vue.use(vuescroll)

// Check View
Vue.use(checkView)

// Use v-calendar & v-date-picker components
Vue.use(VCalendar)

// Algolia
Vue.use(InstantSearch)

// Confetti for billing page
Vue.use(VueConfetti)

// Content Editable Directive
Vue.use(contenteditable)

// Stripe
Vue.use(StripePlugin, {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  apiVersion: process.env.VUE_APP_API_VERSION
})

Vue.config.productionTip = false
Vue.config.devtools = true

// Add Components
Object.keys(Components).forEach((key) => {
  Vue.component(key, Components[key])
})

// Add Filters
Object.keys(Filters).forEach((key) => {
  Vue.filter(key, Filters[key])
})

// Add Mixins
Vue.mixin(validationMixin)
Vue.use(BaseNotifications)

// Plugins
Vue.use(VueClipboard)

// Init time ago
TimeAgo.addDefaultLocale(en)

// Check State Change
// eslint-disable-next-line
firebase.auth().onAuthStateChanged(async user => {
  if (user) {
    await user.getIdToken(true)
  }

  if (user) {
    // Add the user to gtag
    try {
      window.dataLayer.push({
        user_id: user.uid
      })
    } catch (error) {
      console.log('Error adding user to gtag', error)
    }

    try {
      window.hj('identify', user.uid)
    } catch (error) {
      console.log('Error adding user to Hotjar', error)
    }

    // Check user exists
    await FirebaseAPI.Users.checkUserExists(user)
    await FirebaseAPI.Users.updateLastSeen()

    // Start bugsnag with user data
    Bugsnag.start({
      apiKey: '38c9d9d50f3b5f76613c5ccc3a535019',
      plugins: [new BugsnagPluginVue()],
      onError: function (event) {
        event.addMetadata('user', {
          name: user.displayName, // Full name
          email: user.email // Email address
        })
      }
    })

    // // Add Bugsnag
    const bugsnagVue = Bugsnag.getPlugin('vue')
    bugsnagVue.installVueErrorHandler(Vue)

    window.Intercom('boot', {
      app_id: process.env.VUE_APP_INTERCOM_ID,
      name: user.displayName, // Full name
      email: user.email, // Email address
      created_at: +new Date(user.metadata.creationTime) / 1000 // Signup date as a Unix timestamp
    })

    try {
      // await Rupt.attach({
      //   client_id: '91d42adb-14b3-465b-b1fe-8e0ee3da496f',
      //   account: user.uid,
      //   email: user.email
      // })
    } catch (error) {
      console.log(error)
    }

    // Fetch teams first since we use it to check for a subscription
    await store.dispatch('AuthModule/fetchUserTeam') // Needed first for any function that we need to fetch teams for

    await Promise.all([
      store.dispatch('AuthModule/setUserSubscription', user), // Listener to check for subscription changes (Maybe this can go first)
      store.dispatch('AuthModule/getStripeCustomer', user) // Gets Stripe Customer by UID
    ])

    // Can be done in the background
    store.dispatch('AuthModule/getUserInvoices', user) // Fetches user invoices? Likely can be delayed
    store.dispatch('AuthModule/fetchUserInvitations') // Fetches user invitations - Delay this
    store.dispatch('BoardsModule/fetchBoards') // Fetches user invitations - Delay this

    await store.dispatch('AuthModule/checkTeamExpired') // Checks if the team has expired, should go after fetching user team
    await store.dispatch('AuthModule/setTeamSubscription')

    // ChurnKey Check
    const stripeUser = store.getters['AuthModule/getStripeCustomer']
    const userSub = store.getters['AuthModule/getUserSubscription']
    const teamSub = store.getters['AuthModule/getTeamSubscriptionState']
    try {
      const data = await ForeplayAPI.Users.getChurnHash(stripeUser.stripeId)
      if ((teamSub && teamSub !== 'active') || (!_.isEmpty(userSub) && userSub.status !== 'active')) {
        store.commit('AuthModule/SET_TEAM_SUBSCRIPTION_STATE', 'inactive')
      }
      // Churnkey check if current subscription is paused
      window.churnkey.check('pause', {
        subscriptionId: userSub.id,
        customerId: stripeUser.stripeId,
        authHash: data.hash, // required
        appId: 'yk5n1rq9q', // required
        mode: 'live',
        provider: 'stripe',
        forceCheck: false, // recommended to leave this to false to avoid redundant checks
        handleLogout() {
          store.dispatch('AuthModule/logoutUser')
          window.churnkey.clearState()
        }
      })
    } catch (e) {
      console.log(e)
    }

    try {
      await authSocket() // Auth socket for capturing search actions
    } catch (error) {
      console.log(error)
    }

    // Check if stripe customer exists already
    setTimeout(async () => {
      await store.dispatch('AuthModule/getStripeCustomer', user)
    }, 3000)

    store.commit('AuthModule/SET_INITIAL_LOAD')
  } else {
    window.Intercom('boot', {
      app_id: process.env.VUE_APP_INTERCOM_ID
    })

    store.commit('AuthModule/SET_USER', {})
  }

  window.Intercom('update')
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
