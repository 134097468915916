<template>
  <div>
    <div id="welcome-content" class="flex flex-col items-center gap-16 mx-auto">
      <div class="flex flex-col gap-5 items-center" style="width: 550px">
        <img src="../../assets/icons/spyder.png" class="w-24 h-24">
        <div>
          <BaseText type="heading" size="lg" class="text-text-loud w-full font-semibold">
            Automated Competitor Creative Analysis
          </BaseText>
          <BaseText type="body" size="md" class="text-text-muted text-center mt-1">
            Spyder allows you to track, save and analyze every<br/>
            ad and landing page a brand launches.
          </BaseText>
        </div>
        <button id="welcome-flow-video-button" class="flex items-center gap-1.5 py-1.5 pl-2 pr-2.5 rounded-md button-shadow"
        @click="$emit('openTutorialModal')">
          <PlayIcon class="text-icon-normal" />
          <BaseText type="label" size="sm" class="text-text-muted">
            Watch Video
          </BaseText>
        </button>
      </div>
      <div class="flex gap-4 mx-auto">
        <div class="relative flex flex-col gap-4 items-center py-7 rounded-2xl border border-border-normal" style="width: 356px">
          <div class="absolute left-1/2 top-0 transform -translate-x-1/2 -translate-y-1/2 
          px-3 py-1 rounded-full border border-border-normal bg-white">
            <BaseText type="label" size="sm" class="text-text-muted">
              Option 1
            </BaseText>
          </div>
          <transition>
            <div v-if="!brandsLoading" class="relative w-full overflow-hidden" style="height: 58px">
              <div id="top-scroller" class="scroller-right flex gap-3 items-center">
                <div ref="top-duplicate-content" class="content flex gap-3 items-center"
                :style="{animationDuration: `${topScrollerAnimationDuration}s`}" />
                <div ref="top-content" class="content flex gap-3 items-center"
                :style="{animationDuration: `${topScrollerAnimationDuration}s`}">
                  <div v-for="(brand, index) in topRowBrands" :key="`top-brand-${index}`" :ref="`top-brand-${index}`"
                  class="flex items-center gap-3 py-1.5 pl-1.5 pr-4 rounded-xl border border-border-normal bg-white w-max">
                    <img :src="brand.avatar" class="rounded-md" style="width: 44px; height: 44px">
                    <div class="flex flex-col gap-0.5">
                      <BaseText type="label" size="md" class="text-text-loud">
                        {{ brand.name }}
                      </BaseText>
                      <BaseText type="body" size="xs" class="text-text-normal">
                        {{ brand.adsSaved.toLocaleString() }} ads
                      </BaseText>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <transition>
            <div v-if="!brandsLoading" class="relative w-full overflow-hidden" style="height: 58px">
              <div id="bottom-scroller" class="scroller-right flex gap-3 items-center">
                <div ref="bottom-content" class="content flex gap-3 items-center"
                :style="{animationDuration: `${bottomScrollerAnimationDuration.toFixed(2)}s`}">
                  <div v-for="(brand, index) in bottomRowBrands" :key="`bottom-brand-${index}`" :ref="`bottom-brand-${index}`"
                  class="flex items-center gap-3 py-1.5 pl-1.5 pr-4 rounded-xl border border-border-normal bg-white w-max">
                    <img :src="brand.avatar" class="rounded-md" style="width: 44px; height: 44px">
                    <div class="flex flex-col gap-0.5">
                      <BaseText type="label" size="md" class="text-text-loud">
                        {{ brand.name }}
                      </BaseText>
                      <BaseText type="body" size="xs" class="text-text-normal">
                        {{ brand.adsSaved.toLocaleString() }} ads
                      </BaseText>
                    </div>
                  </div>
                </div>
                <div ref="bottom-duplicate-content" class="content flex gap-3 items-center"
                :style="{animationDuration: `${bottomScrollerAnimationDuration.toFixed(2)}s`}" />
              </div>
            </div>
          </transition>
          <div class="mt-auto">
            <BaseText type="label" size="lg" class="text-text-muted">
              Search an Existing Spyder Brand
            </BaseText>
            <BaseText type="body" size="sm" class="text-text-normal text-center mt-1">
              Unlock the historical data from brands<br/>
              already being tracked on Spyder.
            </BaseText>
          </div>
          <BaseButton primary @click="$emit('openAddSpyderModal', 'search')">
            <div class="flex gap-1.5 items-center">
              <SearchIcon className="text-white" />
              <BaseText type="body" size="sm" class="text-white">
                Search for brand
              </BaseText>
            </div>
          </BaseButton>
        </div>
        <div class="relative flex flex-col gap-4 items-center py-7 rounded-2xl border border-border-normal" style="width: 356px">
          <div class="absolute left-1/2 top-0 transform -translate-x-1/2 -translate-y-1/2 
          px-3 py-1 rounded-full border border-border-normal bg-white">
            <BaseText type="label" size="sm" class="text-text-muted">
              Option 2
            </BaseText>
          </div>
          <img src="../../assets/images/spyder-welcome-option-2.png" class="w-full h-32 pl-6 object-contain" >
          <div>
            <BaseText type="label" size="lg" class="text-text-muted text-center">
              Manually Add a New Brand<br/>
              from Facebook Ad Library
            </BaseText>
            <BaseText type="body" size="sm" class="text-text-normal text-center mt-1">
              Start tracking a brand not yet available<br/>
              in Spyder.
            </BaseText>
          </div>
          <BaseButton primary @click="$emit('openAddSpyderModal', 'manual')">
            <div class="flex gap-1.5 items-center">
              <PlusIcon stroke="white" />
              <BaseText type="body" size="sm" class="text-white">
                Add new brand
              </BaseText>
            </div>
          </BaseButton>
        </div>
      </div>
    </div>
    <!-- Info Popup -->
    <transition>
      <div v-show="showInfoPopup" class="absolute top-28 right-9 flex flex-col gap-1 p-1 rounded-xl bg-neutral-800 shadow-md" 
      style="width: 200px">
        <div class="flex flex-col gap-2 w-full p-3">
          <img src="../../assets/images/meta-logo-white.svg" class="w-6 h-6" >
          <BaseText type="label" size="sm" class="text-white">
            Currently Spyder tracks<br/>
            all Meta ads
          </BaseText>
          <BaseText type="body" size="sm" class="text-neutral-200">
            Coming soon...
          </BaseText>
        </div>
        <div class="flex items-center gap-2 px-3 py-2 rounded-lg bg-neutral-700 w-full">
          <img src="../../assets/images/tiktok-logo-white.svg" class="w-5 h-5" />
          <BaseText type="label" size="sm" class="text-white">
            Tiktok
          </BaseText>
        </div>
        <div class="flex items-center gap-2 px-3 py-2 rounded-lg bg-neutral-700 w-full">
          <img src="../../assets/images/linkedin-logo-white.svg" class="w-5 h-5" />
          <BaseText type="label" size="sm" class="text-white">
            LinkedIn
          </BaseText>
        </div>
        <div class="flex items-center gap-2 px-3 py-2 rounded-lg bg-neutral-700 w-full">
          <img src="../../assets/images/google-logo-white.svg" class="w-5 h-5" />
          <BaseText type="label" size="sm" class="text-white">
            Google
          </BaseText>
        </div>
        <!-- close button -->
        <button class="absolute top-1 right-1 p-0.5" @click="showInfoPopup = false">
          <TagRemoveIcon stroke="#808899" />
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import ForeplayAPI from '@/api/foreplayServer'

// Icons
import PlayIcon from '../globals/Icons/PlayIcon.vue'
import SearchIcon from '../globals/Icons/SearchIcon.vue'
import PlusIcon from '../globals/Icons/PlusIcon.vue'
import TagRemoveIcon from '../globals/Icons/TagRemoveIcon.vue'

// Change this value to adjust the scroller animation speed (lower value = faster)
const TOP_SCROLLER_ANIMATION_DURATION = 100 // seconds

export default {
  name: 'SpyderBrandsWelcomeView',
  components: {
    PlayIcon,
    SearchIcon,
    PlusIcon,
    TagRemoveIcon
  },
  data () {
    return {
      mostPopularBrands: [],
      brandsLoading: true,
      topScrollerAnimationDuration: TOP_SCROLLER_ANIMATION_DURATION,
      bottomScrollerAnimationDuration: 0, // Computed in mounted
      showInfoPopup: false
    }
  },
  computed: {
    topRowBrands () {
      return this.mostPopularBrands.slice(0, 5)
    },
    bottomRowBrands () {
      return this.mostPopularBrands.slice(5, 10)
    },
  },
  async mounted () {
    // Fetching the top 10 most popular Spyder brands
    const filters = { sort: 'subscriberCount' }
    try {
      const data = await ForeplayAPI.Brands.getDiscoveryBrands(null, filters)
      this.mostPopularBrands = data.results.slice(0, 10)
    } catch (error) {
      console.error(error)
      this.$showAlert({
        type: 'error',
        message: 'Failed to fetch most popular brands'
      })
    }
    this.brandsLoading = false
    setTimeout(() => {
      this.showInfoPopup = true
    }, 5000)
    this.$nextTick(() => {
      this.duplicateScrollerRowContent('top')
      this.duplicateScrollerRowContent('bottom')
      this.computeBottomScrollerAnimationDuration()
    })
  },
  methods: {
    duplicateScrollerRowContent (row) {
      const rowElms = []
      for (let i = 0; i < 5; i++) {
        rowElms.push(this.$refs[`${row}-brand-${i}`][0])
      }
      rowElms.forEach(elm => {
        const clonedElm = elm.cloneNode(true)
        this.$refs[`${row}-duplicate-content`].appendChild(clonedElm)
      })
    },
    computeBottomScrollerAnimationDuration () {
      const topScrollerWidth = this.$refs['top-content'].offsetWidth + 12
      const bottomScrollerWidth = this.$refs['bottom-content'].offsetWidth + 12
      const topScrollSpeed = topScrollerWidth / TOP_SCROLLER_ANIMATION_DURATION // px/s
      this.bottomScrollerAnimationDuration = bottomScrollerWidth / topScrollSpeed
    }
  }
}
</script>

<style scoped>
#welcome-content {
  margin-top: 96px;
}
@media screen and (max-height: 1000px) {
  #welcome-content {
    margin-top: 48px;
  }
}

.button-shadow {
  transition: box-shadow 150ms ease-in-out, background-color 150ms ease-in-out;
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}
.button-shadow:hover {
  box-shadow: none;
  background-color: #F6F8FA;
}
.v-enter-active {
  transition: opacity 300ms ease-in-out;
}
.v-leave-active {
  transition: opacity 200ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}

#top-scroller {
  position: absolute;
  right: 0;
}
#top-scroller .content {
  animation-name: scrollToRight;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
#bottom-scroller {
  position: absolute;
  left: 0;
}
#bottom-scroller .content {
  animation-name: scrollToLeft;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes scrollToRight {
  from { transform: translateX(0); }
  to { 
    /* Account for flex gap by adding 0.75 rem */
    transform: translateX(calc(100% + 0.75rem)); 
  }
}
@keyframes scrollToLeft {
  from { transform: translateX(0); }
  to { transform: translateX(calc(-100% - 0.75rem)); }
}
</style>