<template>
  <div class="w-full h-full p-6 overflow-y-auto">
    <div class="mx-auto" style="max-width: 900px">
      <TeamManagement v-if="getTeam" />
      <CreateTeam v-else @createdTeam="showCreateTeamModal = true" />
    </div>
    <TeamCreatedModal
      v-if="showCreateTeamModal"
      @close="showCreateTeamModal = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TeamManagement from '../../account/teams/TeamManagement.vue'
import CreateTeam from '../../account/teams/CreateTeam.vue'
import TeamCreatedModal from '../../account/TeamCreatedModal.vue'

export default {
  name: 'TeamSettings',
  components: {
    TeamManagement,
    CreateTeam,
    TeamCreatedModal
  },
  data () {
    return {
      showCreateTeamModal: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getTeam'])
  }
}
</script>

<style scoped>
</style>