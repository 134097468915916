<template>
  <MainLayout
    bg-color="white"
    no-content-padding
  >
    <template #toolbar>
      <div class="p-3 flex items-center justify-between w-full gap-1">
        <div class="flex items-center gap-1">
          <ChevronIcon class="transform rotate-90" />
          <div class="flex items-center gap-2">
            <div
              v-if="getLoading.report"
              class="h-5 w-11 skeleton rounded-md"
            />
            <BaseText
              v-else
              type="label"
              size="sm"
              class="text-text-muted"
            >
              {{ report?.name }}
            </BaseText>
            <div
              v-if="getLoading.report"
              class="h-5 w-11 skeleton rounded-md"
            />
            <BaseText
              v-else
              class="text-icon-normal"
              size="sm"
            >
              {{ report?.desc }}
            </BaseText>
          </div>
        </div>
        <div class="flex items-center gap-2">
          <div class="py-1.5 px-2">
            <div
              v-if="getLoading.report"
              class="h-5 w-24 skeleton rounded-md"
            />
            <!-- TODO @Sam, update after auth -->
            <div
              v-else
              class="flex items-center gap-2"
            >
              <RefreshIcon class="text-icon-normal" />
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                Data since 14h
              </BaseText>
            </div>
          </div>
          <!-- Edit mode -->
          <button
            class="flex items-center gap-1 base-btn h-8"
          >
            <EditIcon class="text-icon-muted" />
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              Edit Mode
            </BaseText>
          </button>
          <!-- Share -->
          <button
            class="flex text-white items-center gap-1 black-btn-primary p-1.5 pr-3 rounded-md h-8"
          >
            <ShareIcon />
            <BaseText
              type="label"
              size="sm"
            >
              Share
            </BaseText>
          </button>
          <!-- Kebab -->
          <button
            class="base-btn h-8"
            style="padding: 6px;"
          >
            <KebabMenuIcon class="text-icon-normal" />
          </button>
        </div>
      </div>
    </template>
    <template #content>
      <div class="bg-white">
        <div class="p-3">
          <div class="flex items-center gap-2">
            <div
              v-on-clickaway="() => { showDropdown.group = false }"
              class="relative w-max"
              @click="showDropdown.group = !showDropdown.group"
            >
              <div
                class="flex gap-2 items-center p-2 py-1.5 rounded-md bg-white hover:bg-background-normal border border-border-normal cursor-pointer transition-colors"
                :class="`${showDropdown.group && 'bg-background-normal'}`"
              >
                <div
                  class="flex items-center gap-1"
                >
                  <BaseText
                    size="sm"
                    class="text-text-normal"
                  >
                    Group By
                  </BaseText>
                  <BaseText
                    type="label"
                    size="sm"
                    class="text-text-muted"
                  >
                    {{ appliedGroup.name }}
                  </BaseText>
                </div>
                <div
                  class="transform transition-transform duration-200"
                  :style="showDropdown.group ? 'transform: scale(1, -1)' : ''"
                >
                  <ChevronIcon class-name="text-icon-disabled" />
                </div>
              </div>
              <transition name="fade">
                <div
                  v-if="showDropdown.group"
                  class="absolute w-full min-w-max bg-white right-0 p-1 overflow-y-auto max-h-72 dropdown-scrollable  shadow-md rounded-lg flex flex-col gap-2"
                >
                  <button
                    v-for="option in groupOptions"
                    :key="option.name"
                    class="w-full bg-white hover:bg-background-normal rounded-md py-1 px-2 text-left flex items-center gap-1"
                    :class="{'bg-background-normal': option?.value === appliedGroup?.value}"
                    @click="updateFilter(option)"
                  >
                    <div v-show="option?.value === appliedGroup?.value">
                      <CheckmarkIcon />
                    </div>
                    <BaseText
                      size="sm"
                    >
                      {{ option.name }}
                    </BaseText>
                  </button>
                </div>
              </transition>
            </div>
            <button
              class="w-max flex items-center gap-1.5 hover:bg-background-hover p-1.5 rounded-md cursor-pointer transition-colors relative"
            >
              <PlusIcon class="text-icon-normal" />
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                Add Filter
              </BaseText>
            </button>
          </div>
          <div class="h-96 bg-background-normal rounded-md p-2">
            Report Section
          </div>
        </div>
        <div class="pl-3 pt-3">
          <!-- Table settings btn -->
          <div class="flex items-center gap-1">
            <LensTableOptions />
            <div>
              <button
                class="px-2 py-1 bg-gray-700 text-white rounded-md"
                @click="showCreatePreset = true"
              >
                Create a preset
              </button>
            </div>
          </div>
          <Transition
            name="tableFade"
            mode="out-in"
          >
            <div
              v-if="getLoading.report"
              class="grid grid-cols-12 grid-rows-4 gap-0 border relative border-neutral-50 mr-3 mt-3 rounded-lg"
            >
              <!-- Loading text-->
              <div class="absolute w-full h-full flex flex-col items-center justify-center gap-1 text-center">
                <div>
                  <BaseLoadingSpinnerCircle
                    small
                    :width="24"
                    :height="24"
                    class="text-text-muted"
                  />
                </div>
                <BaseText
                  class="text-text-muted"
                  type="label"
                  size="sm"
                >
                  Loading Data...
                </BaseText>
                <BaseText
                  class="text-text-normal w-64"
                  size="sm"
                >
                  One moment while we load the data. This might take up to a minute.
                </BaseText>
              </div>
              <!-- Grid background -->
              <div
                v-for="index in 24"
                :key="index"
                :class="{
                  'border-b': index <= 18,
                  'border-r': index % 6 !== 0,
                }"
                class="border-neutral-50 h-20 col-span-2"
              />
            </div>

            <LensMetricTable
              v-else
              class="mt-4"
              :grouped-by="appliedGroup?.name"
              :total-items="getCurrentPagination.totalItems"
              :total-pages="getCurrentPagination.totalPages"
              :included-kpis="staticKpis"
            />
          </Transition>
        </div>

        <LensSettingsDrawer
          v-if="getColumnDrawerView"
          @close="closeColumnDrawer"
        />
      </div>
      <!-- Modals -->
      <EditPresetModal
        v-if="showCreatePreset"
        key="new-preset"
        is-new
        @close="showCreatePreset = false"
        @save="handleNewPreset"
      />
    </template>
  </MainLayout>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway2'
import ForeplayAPI from '@/api/foreplayServer'
import _ from 'lodash'
// Components
import MainLayout from '../../layouts/MainLayout.vue'
import LensMetricTable from '../../components/lens/table/LensMetricTable.vue'
import LensSettingsDrawer from '../../components/settings/LensSettingsDrawer.vue'
import LensTableOptions from '../../components/lens/table/LensTableOptions.vue'
// Icons
import ChevronIcon from '../../components/globals/Icons/ChevronIcon.vue'
import CheckmarkIcon from '../../components/globals/Icons/CheckmarkIcon.vue'
import EditPresetModal from '../../components/lens/settings/EditPresetModal.vue'
import PlusIcon from '../../components/globals/Icons/PlusIcon.vue'
import RefreshIcon from '../../components/globals/Icons/LensIcons/RefreshIcon.vue'
import EditIcon from '../../components/globals/Icons/EditIcon.vue'
import ShareIcon from '../../components/globals/Icons/ShareIcon.vue'
import KebabMenuIcon from '../../components/globals/Icons/KebabMenuIcon.vue'
export default {
  name: 'LensReportView',
  components: {
    MainLayout,
    LensMetricTable,
    LensSettingsDrawer,
    ChevronIcon,
    CheckmarkIcon,
    PlusIcon,
    RefreshIcon,
    EditIcon,
    ShareIcon,
    KebabMenuIcon,
    LensTableOptions,
    EditPresetModal
  },
  mixins: [clickaway],
  data () {
    const groupOptions = [
      { name: 'Ad Name', value: 'ad_name' },
      { name: 'Ad Copy', value: 'ad_copy' },
      { name: 'Landing Page', value: 'destination_url' },
      { name: 'Creative', value: 'creative' }
    ]
    return {
      groupOptions,

      appliedGroup: groupOptions[0],

      showDropdown: {
        tableSettings: false,
        group: false
      },
      showColumnDrawer: false,
      showCreatePreset: false,
      showInvalidReport: false,

      report: {},

      loading: {
        report: true
      },

      staticKpis: [
        'clicks',
        'conversion_value',
        'ctr',
        'engagements',
        'impressions',
        'inactive_ad_count',
        'paused_ad_count',
        'purchases',
        'reach',
        'roas',
        'three_second_video_view',
        'thumbstop'
      ]
    }
  },
  computed: {
    ...mapGetters('LensModule', ['getAppliedPreset', 'getCurrentPagination', 'getCurrentFilter', 'getAdGroups', 'getPresets', 'getColumnDrawerView', 'getLoading']),
    ...mapGetters('AuthModule', ['getUser', 'getTeam'])

  },
  async mounted () {
    await this.fetchAdGroups()

    const reportId = this.$route.params.reportId
    this.report = await ForeplayAPI.LensReport.getById(reportId)

    if (_.isEmpty(this.report)) {
      this.showInvalidReport = true
      this.$showAlert({ type: 'error', message: 'Invalid report URL' })
      return
    }
    await this.fetchPresets()
  },
  methods: {
    ...mapActions('LensModule', ['fetchPresets', 'fetchAdGroups']),
    ...mapMutations('LensModule', ['SET_COLUMN_DRAWER_VIEW']),
    closeColumnDrawer () {
      this.SET_COLUMN_DRAWER_VIEW(false)
    },
    async handleNewPreset (preset) {
      try {
        const newPreset = {
          ...preset,
          description: 'A new preset!',
          tableColumns: []
        }
        await ForeplayAPI.LensPreset.create(newPreset)
      } catch (e) {
        this.$showAlert({ type: 'error', message: e.message })
      }
      await this.fetchPresets()
    }
  }
}
</script>

<style scoped>
  .dropdown-scrollable {
    transition: colors 250ms ease-in-out;
    top: 100%;
    z-index: 999;
  }
  .dropdown-scrollable::-webkit-scrollbar {
    width: 3px;
  }
  .dropdown-scrollable::-webkit-scrollbar-thumb {
    background-color: #DFE1E7;
    border-radius: 18px;
  }

  .base-btn{
    box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
    transition: box-shadow 100ms ease-in-out, background-color 100ms ease-in-out;
    border-radius: 6px;
    padding: 6px 12px 6px 6px;
  }
  .base-btn:hover {
    box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.17), 0px 0px 0px 1px rgba(0, 56, 108, 0.12);
  }

  .grid-background{
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to right, #ECEFF3 1px, transparent 1px),
    linear-gradient(to bottom, #ECEFF3 1px, transparent 1px);
  background-size: 124px 82px;
  }

  .tableFade-enter-active,
  .tableFade-leave-active {
    transition: all 0.25s ease-out;
  }

  .tableFade-enter-from {
    opacity: 0;
    transform: translateY(1rem);
  }

  .tableFade-leave-to {
    opacity: 0;
    transform: translateY(-1rem);
  }
</style>
