<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M12.4999 3.33325H7.49992C6.57944 3.33325 5.83325 4.07944 5.83325 4.99992V14.9999C5.83325 15.9204 6.57944 16.6666 7.49992 16.6666H12.4999C13.4204 16.6666 14.1666 15.9204 14.1666 14.9999V4.99992C14.1666 4.07944 13.4204 3.33325 12.4999 3.33325Z" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.1667 5H15.8334C16.7539 5 17.5001 5.74619 17.5001 6.66667V13.3333C17.5001 14.2538 16.7539 15 15.8334 15H14.1667" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.83333 15H4.16667C3.24619 15 2.5 14.2538 2.5 13.3333V6.66667C2.5 5.74619 3.24619 5 4.16667 5H5.83333" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'CarouselFormatIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>
