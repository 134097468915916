export default {
  namespaced: true,
  state: {
    currentTextSearch: null,
    currentFilters: {
      contentFilters: [],
      favoriteFilter: false,
      formats: [],
      languages: [],
      liveStatus: [],
      niches: [],
      platforms: [],
      textSearch: []
    }
  },
  getters: {
    getCurrentTextSearch: state => state.currentTextSearch,
    getCurrentFilters: state => state.currentFilters
  },
  mutations: {
    SET_CURRENT_TEXT_SEARCH (state, text) {
      state.currentTextSearch = text
    },
    // This is set in beforeEach router guard
    SET_CURRENT_FILTERS (state, filters) {
      state.currentFilters = filters
    }
  },
  actions: {}
}
