<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 6.25V13.75C4 14.5784 4.67157 15.25 5.5 15.25H10.75"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 13.75V6.25C16 5.42157 15.3284 4.75 14.5 4.75H9.25"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4 6.25C4.82843 6.25 5.5 5.57843 5.5 4.75C5.5 3.92157 4.82843 3.25 4 3.25C3.17157 3.25 2.5 3.92157 2.5 4.75C2.5 5.57843 3.17157 6.25 4 6.25Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 16.75C16.8284 16.75 17.5 16.0784 17.5 15.25C17.5 14.4216 16.8284 13.75 16 13.75C15.1716 13.75 14.5 14.4216 14.5 15.25C14.5 16.0784 15.1716 16.75 16 16.75Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.75 8.5H12.25"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.75 11.5H10.75"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ConversionIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

    <style scoped></style>
