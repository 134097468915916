<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 21 20" fill="none">
    <path d="M9.04167 7.5L7.13092 9.41074C6.80549 9.73618 6.80549 10.2638 7.13092 10.5893L9.04167 12.5M11.9583 7.5L13.8691 9.41074C14.1945 9.73618 14.1945 10.2638 13.8691 10.5893L11.9583 12.5M5.29167 16.875H15.7083C16.6288 16.875 17.375 16.1288 17.375 15.2083V4.79167C17.375 3.87119 16.6288 3.125 15.7083 3.125H5.29167C4.37119 3.125 3.625 3.87119 3.625 4.79167V15.2083C3.625 16.1288 4.37119 16.875 5.29167 16.875Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'EmbedIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>