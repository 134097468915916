import AxiosConfig from './serverAxiosConfig'
import firebase from '../config/FirebaseConfig'

// Fetch autocomplete results
const getAutocompleteResults = async (searchTerm) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const params = { textSearch: searchTerm }

  const { data } = await AxiosConfig.get(
    '/ads/discovery/v2/auto-complete',
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

export default {
  getAutocompleteResults
}