<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M14.2197 15.2807C14.5125 15.5736 14.9874 15.5736 15.2803 15.2807C15.5732 14.9877 15.5732 14.5129 15.2803 14.22L14.2197 15.2807ZM12.8106 12.811C12.2249 13.3968 11.2751 13.3968 10.6893 12.811L9.62868 13.8717C10.8003 15.0432 12.6997 15.0432 13.8713 13.8717L12.8106 12.811ZM10.6893 12.811C10.1035 12.2252 10.1035 11.2755 10.6893 10.6896L9.62868 9.62899C8.45711 10.8006 8.45711 12.7001 9.62868 13.8717L10.6893 12.811ZM10.6893 10.6896C11.2751 10.1039 12.2249 10.1039 12.8106 10.6896L13.8713 9.62899C12.6997 8.45741 10.8003 8.45741 9.62868 9.62899L10.6893 10.6896ZM12.8106 10.6896C13.3965 11.2755 13.3965 12.2252 12.8106 12.811L13.8713 13.8717C15.0429 12.7001 15.0429 10.8006 13.8713 9.62899L12.8106 10.6896ZM12.8106 13.8717L14.2197 15.2807L15.2803 14.22L13.8713 12.811L12.8106 13.8717Z" 
    fill="currentColor"/>
    <path d="M5.05 10H5.2H5.05ZM5.05 14.875H5.2H5.05ZM14.8 5.125H14.95H14.8ZM9.925 5.125H10.075H9.925ZM5.5 10C5.5 10.2071 5.33211 10.375 5.125 10.375C4.91789 10.375 4.75 10.2071 4.75 10C4.75 9.79292 4.91789 9.625 5.125 9.625C5.33211 9.625 5.5 9.79292 5.5 10ZM5.5 14.875C5.5 15.0821 5.33211 15.25 5.125 15.25C4.91789 15.25 4.75 15.0821 4.75 14.875C4.75 14.6679 4.91789 14.5 5.125 14.5C5.33211 14.5 5.5 14.6679 5.5 14.875ZM15.25 5.125C15.25 5.33211 15.0821 5.5 14.875 5.5C14.6679 5.5 14.5 5.33211 14.5 5.125C14.5 4.91789 14.6679 4.75 14.875 4.75C15.0821 4.75 15.25 4.91789 15.25 5.125ZM10.375 5.125C10.375 5.33211 10.2071 5.5 10 5.5C9.79292 5.5 9.625 5.33211 9.625 5.125C9.625 4.91789 9.79292 4.75 10 4.75C10.2071 4.75 10.375 4.91789 10.375 5.125Z" 
    fill="currentColor"/>
    <path d="M5.05 10H5.2M5.05 14.875H5.2M14.8 5.125H14.95M9.925 5.125H10.075M5.5 10C5.5 10.2071 5.33211 10.375 5.125 10.375C4.91789 10.375 4.75 10.2071 4.75 10C4.75 9.79293 4.91789 9.625 5.125 9.625C5.33211 9.625 5.5 9.79293 5.5 10ZM5.5 14.875C5.5 15.0821 5.33211 15.25 5.125 15.25C4.91789 15.25 4.75 15.0821 4.75 14.875C4.75 14.6679 4.91789 14.5 5.125 14.5C5.33211 14.5 5.5 14.6679 5.5 14.875ZM15.25 5.125C15.25 5.33211 15.0821 5.5 14.875 5.5C14.6679 5.5 14.5 5.33211 14.5 5.125C14.5 4.91789 14.6679 4.75 14.875 4.75C15.0821 4.75 15.25 4.91789 15.25 5.125ZM10.375 5.125C10.375 5.33211 10.2071 5.5 10 5.5C9.79293 5.5 9.625 5.33211 9.625 5.125C9.625 4.91789 9.79293 4.75 10 4.75C10.2071 4.75 10.375 4.91789 10.375 5.125Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="square"/>
    <path d="M5.125 5.5C5.33207 5.5 5.5 5.33207 5.5 5.125C5.5 4.91793 5.33207 4.75 5.125 4.75C4.91793 4.75 4.75 4.91793 4.75 5.125C4.75 5.33207 4.91793 5.5 5.125 5.5Z" 
    fill="currentColor"/>
    <path d="M5.05 5.125H5.2M5.5 5.125C5.5 5.33207 5.33207 5.5 5.125 5.5C4.91793 5.5 4.75 5.33207 4.75 5.125C4.75 4.91793 4.91793 4.75 5.125 4.75C5.33207 4.75 5.5 4.91793 5.5 5.125Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="square"/>
  </svg>
</template>

<script>
export default {
  name: 'ProductCategoryIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>