<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M7.75 7H12.25M7.75 10H9.25M15.25 16.75V4.75C15.25 3.92157 14.5784 3.25 13.75 3.25H6.25C5.42157 3.25 4.75 3.92157 4.75 4.75V16.75L6.5 15.25L8.25 16.75L10 15.25L11.75 16.75L13.5 15.25L15.25 16.75Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'BillingSettingsIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>