import AxiosConfig from "./serverAxiosConfig";
import firebase from '../config/FirebaseConfig'

const getUserReferral = async () => {
  const { Aa: token } = firebase.auth().currentUser
  const { data } = await AxiosConfig.post('/campaigns/referral', null, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return data
}

const setUserPaypal = async (email) => {
  const { Aa: token } = firebase.auth().currentUser
  const { data } = await AxiosConfig.post('/campaigns/setPaypal', { paypal_email: email }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return data;
}




export default {
  getUserReferral,
  setUserPaypal
} 