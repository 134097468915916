<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M2.57501 12.475V14.95C2.57501 15.8613 3.31374 16.6 4.22501 16.6H9.17501C10.0863 16.6 10.825 15.8613 10.825 14.95V12.475" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.14388 8.76248L8.54016 11.1402C8.66853 11.9104 9.33497 12.475 10.1158 12.475C11.2458 12.475 12.0186 11.3336 11.5989 10.2844L11.2113 9.31547C10.9781 8.73236 10.4133 8.34998 9.78525 8.34998H3.61499C2.98695 8.34998 2.42218 8.73236 2.18893 9.31547L1.80137 10.2844C1.38167 11.3336 2.15441 12.475 3.28447 12.475C4.06532 12.475 4.73172 11.9104 4.86009 11.1402L5.25638 8.76248" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.1439 8.76245L8.42359 10.4405C8.60104 11.5055 7.77981 12.475 6.70015 12.475C5.62048 12.475 4.79922 11.5055 4.97672 10.4405L5.2564 8.76245" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.0234 5.4906C15.1515 6.25935 14.7593 6.97835 14.125 7.31883" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.95 11.65H15.775C16.6863 11.65 17.425 10.9113 17.425 10V7.52502" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.7439 3.81252L15.1402 6.19027C15.2686 6.9605 15.9349 7.52502 16.7158 7.52502C17.8459 7.52502 18.6186 6.38367 18.1989 5.33443L17.84 4.43723C17.5895 3.81079 16.9827 3.40002 16.308 3.40002H10.2923C9.61758 3.40002 9.0108 3.81079 8.76024 4.43723L8.51514 5.05002M11.6501 5.05002L11.8564 3.81252" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'B2BIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>