<template>
  <div class="flex flex-col gap-5 mt-8">
    <div class="flex items-center gap-2 p-1.5">
      <MetaMetricsIcon class="flex-shrink-0 text-icon-disabled" />
      <BaseText
        class="text-text-subdued flex-shrink-0 uppercase"
        type="heading"
        size="overline"
      >
        All Meta Metrics
      </BaseText>
      <div class="w-full border-t border-border-normal" />
    </div>
    <div
      class="relative metric-container"
    >
      <MetricSectionList
        ref="performance"
        section-id="performance"
        :data="performanceMetrics"
        :preset-list="preset.tableColumns"
        :fuzzy-results="getFuzzyResults"
        @handleHover="addHover"
        @add="handleMetricAdd"
      />
      <MetricSectionList
        ref="engagement"
        class="mt-5"
        section-id="engagement"
        :data="engagementMetrics"
        :preset-list="preset.tableColumns"
        :fuzzy-results="getFuzzyResults"
        @handleHover="addHover"
        @add="handleMetricAdd"
      />
      <MetricSectionList
        ref="conversions"
        class="mt-5"
        section-id="conversions"
        :data="conversionMetrics"
        :preset-list="preset.tableColumns"
        :fuzzy-results="getFuzzyResults"
        @handleHover="addHover"
        @add="handleMetricAdd"
      />
    </div>
    <!-- Tooltip -->
    <transition name="left-fade">
      <div
        v-if="tooltipHovered?.key"
        ref="tooltipElem"
        class="label-tooltip"
      >
        <div class="label-tooltip-content p-4 flex flex-col gap-1.5">
          <BaseText
            type="label"
            size="sm"
            class="text-white"
          >
            {{ tooltipHovered.title }}
          </BaseText>
          <BaseText
            class="text-white opacity-70"
            type="body"
            size="sm"
          >
            {{ tooltipHovered.description }}
          </BaseText>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MetaMetricsIcon from '../../../globals/Icons/LensIcons/MetaMetricsIcon.vue'
import { performanceMetrics, engagementMetrics, conversionMetrics } from '../../metrics/index'
import { animate, easeOut } from 'popmotion'
import _ from 'lodash'

import MetricSectionList from '../MetricSectionList.vue'

export default {
  name: 'MetaMetricsSettings',
  components: {
    MetaMetricsIcon,
    MetricSectionList
  },
  props: {
    preset: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      tooltipHovered: null,
      lastHover: {
        timestamp: null,
        elem: null
      },
      debounceUnHover: null,

      // static metrics
      performanceMetrics,
      engagementMetrics,
      conversionMetrics
    }
  },
  computed: {
    ...mapGetters('LensModule', ['getFuzzyResults', 'getMetricSearch'])
  },
  watch: {
    // Handles tooltip tweening
    tooltipHovered (newElem, oldElem) {
      if (newElem) {
        this.$nextTick(() => {
          const hoveredElement = document.querySelector(`#${newElem.key}`)
          const clientRect = hoveredElement.getBoundingClientRect()
          const tooltip = this.$refs.tooltipElem
          const halfTooltipHeight = clientRect.height / 2
          const newTop = clientRect.top - 80 - halfTooltipHeight

          // Handle init
          if (!oldElem) {
            tooltip.style.left = `${clientRect.right}px`
            tooltip.style.top = `${newTop}px`
            return
          }
          const prevHoveredElement = document.querySelector(`#${oldElem.key}`)
          const oldClientRect = prevHoveredElement.getBoundingClientRect()

          // Account for 80px drawer padding
          const oldTop = oldClientRect.top - 80 - halfTooltipHeight
          animate({
            from: oldTop,
            to: newTop,
            ease: easeOut,
            duration: 100,
            onUpdate: (value) => {
              tooltip.style.top = `${value}px`
            }
          })
          tooltip.style.left = `${clientRect.right}px`
        })
      }
    }
  },
  mounted () {
    this.debounceUnHover = _.debounce(this.resetLasthover, 100)
  },
  methods: {
    ...mapActions('LensModule', ['updateLocalPresetField']),
    resetLasthover () {
      if (!this.lastHover.elem) {
        this.lasthover = null
        this.tooltipHovered = null
      }
    },
    addHover (metric) {
      if (!metric) {
        this.lastHover = {
          timestamp: Date.now(),
          elem: metric
        }
        this.debounceUnHover()
        return
      }
      this.tooltipHovered = metric
      this.lastHover.elem = metric
    },
    manageListOperation (metricKey) {
      let newMetricValue = [...this.preset.tableColumns]
      console.log(newMetricValue, metricKey)
      if (newMetricValue.includes(metricKey)) {
        newMetricValue = newMetricValue.filter(key => key !== metricKey)
      } else {
        newMetricValue.push(metricKey)
      }

      return newMetricValue
    },
    handleMetricAdd (metricKey, sectionKey) {
      // Update later
      if (!this.preset?.tableColumns) {
        this.$showAlert({ type: 'error', message: 'Select a preset before editing' })
        return
      }
      const newKpiOrder = this.manageListOperation(metricKey)
      // this.updateLocalPresetField({
      //   key: sectionKey,
      //   value: newMetricData
      // })
      // Also updated seleckted KPIs
      this.updateLocalPresetField({
        key: 'tableColumns',
        value: newKpiOrder
      })
    }
  }
}
</script>

<style scoped>
.left-fade-enter-active,
.left-fade-leave-active {
  transition: opacity 100ms;
}

.left-fade-enter-from, .left-fade-enter, .left-fade-leave-to {
  opacity: 0;
}
.left-fade-enter-to, .left-fade-leave-from {
  opacity: 1;
}

.label-tooltip {
  position: fixed;
  margin-top: auto;
  margin-bottom: auto;
  width: 290px;
}
.label-tooltip-content{
    background: rgba(6, 7, 15, 0.92);
    border-radius: 12px;
}
</style>
