<template>
  <!-- Manage Board Modal -->
  <BaseModal
    no-toolbar
    no-exit
  >
    <template #default>
      <div class="flex px-4 border-b">
        <img
          class="h-14 mr-4"
          src="../../../assets/images/paymentIcon.png"
        >
        <div>
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted w-full"
          >
            <span>Payment Past Due - Action Required</span>
          </BaseText>
          <BaseText
            v-if="currentStep === STEP_TYPES.TRY_AGAIN"
            size="sm"
            class="text-text-normal"
          >
            Your recent subscription payment failed.
          </BaseText>
          <BaseText
            v-else
            size="sm"
            class="text-text-normal"
          >
            Choose a different payment method.
          </BaseText>
        </div>
      </div>
      <!-- Body -->
      <div class="p-5">
        <div v-if="currentStep === STEP_TYPES.TRY_AGAIN">
          <div class="flex items-center justify-between">
            <div class="flex gap-4 items-center">
              <div
                v-if="loading.card"
                class="w-14 h-14 bg-gray-200 animate-pulse rounded-xl"
              />
              <div
                v-else
                class="border border-neutral-50 rounded-xl h-14 w-14 p-3"
              >
                <img
                  :src="cardBrandAsset[paymentMethod?.cardBrand]?.img"
                  class="w-full h-full"
                >
              </div>
              <div>
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-muted"
                >
                  <div
                    v-if="loading.card"
                    class="skeleton w-24 h-3 rounded-full"
                  />
                  <div v-else>
                    <span>{{ cardBrandAsset[paymentMethod?.cardBrand]?.name }} </span><span>ending in {{ paymentMethod?.last4
                    }}</span>
                  </div>
                </BaseText>
                <BaseText
                  size="sm"
                  class="text-text-normal"
                >
                  <div
                    v-if="loading.card"
                    class="skeleton h-3 rounded-full w-14 mt-1"
                  />
                  <div v-else>
                    Expires at {{ paymentMethod?.expMonth }}/{{ paymentMethod?.expYear }}
                  </div>
                </BaseText>
              </div>
            </div>
            <BaseButton
              outlined
              @click="currentStep = STEP_TYPES.NEW_PAYMENT"
            >
              Update Payment Method
            </BaseButton>
          </div>
        </div>
        <div v-else>
          <div class="border border-neutral-50 rounded-xl p-4">
            <StripeElements
              #default="{ elements }"
              ref="cardElms"
              :stripe-key="stripeKey"
              :instance-options="instanceOptions"
              :elements-options="elementsOptions"
            >
              <StripeElement
                ref="card"
                type="card"
                :elements="elements"
                :options="cardOptions"
              />
            </StripeElements>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-between border-t px-5 pt-5">
        <div class="flex items-center gap-1">
          <BaseText
            class="text-text-normal"
            size="sm"
          >
            Powered by
          </BaseText>
          <img
            class="h-4"
            src="../../../assets/images/stripe-logo-gray.svg"
          >
        </div>

        <div
          class="text-right flex justify-end"
        >
          <BaseButton
            v-if="currentStep === STEP_TYPES.TRY_AGAIN"
            primary
            :loading="loadingPaymentUpdate || loading.retry"
            :disabled="loadingPaymentUpdate || loading.retry"
            @click="retryWithDefault"
          >
            Try Again
          </BaseButton>
          <BaseButton
            v-else
            primary
            :loading="loadingPaymentUpdate"
            :disabled="paymentSucceeded || loadingPaymentUpdate"
            @click="updatePaymentMethod"
          >
            Update Payment Method
          </BaseButton>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import firebase from '@/api/config/FirebaseConfig'
import API from '@/api'

export default {
  name: 'UserPaymentFailModal',
  components: {
    StripeElements,
    StripeElement
  },
  props: {
  },
  data () {
    return {

      STEP_TYPES: {
        TRY_AGAIN: 'try_again',
        NEW_PAYMENT: 'new_payment'
      },
      currentStep: 'try_again',

      stripeKey: null,
      instanceOptions: {
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
      },
      elementsOptions: {
      },
      cardOptions: {
        style: {
          base: {
            fontSize: '1.2rem'
          }
        }
      },
      paymentMethod: null,
      loadingPaymentUpdate: false,
      preventSubmit: false,

      loading: {
        card: false,
        retry: false
      },
      paymentSucceeded: false,

      // Statics
      cardBrandAsset: {
        visa: {
          img: require('../../../assets/images/visa-dark.svg'),
          name: 'Visa'
        },
        mastercard: {
          img: require('../../../assets/images/mastercard.svg'),
          name: 'Mastercard'
        },
        amex: {
          img: require('../../../assets/images/americanexpress.svg'),
          name: 'American Express'
        }
      }
    }
  },
  async created () {
    if (process.env.NODE_ENV === 'development') {
      console.log('=== development ===')
      this.stripeKey = 'pk_test_51JS7DeAQtq0TW7KZz4oPmzFVgxlULjWuUNKBoo0KOJjVi90wLS3VniuKgSLJHNHeFBNuY6f8jP9mD3bLygTj8BRM00aL3PiZeq'
    } else {
      console.log('=== production ===')
      this.stripeKey = 'pk_live_51JS7DeAQtq0TW7KZxoGv8Spu6IJhR335IoLxdfBQMF8VCbWJFDrUl2DfeNJllyC79mZJv8kX0qiaNGFCgbrTznpA00kPK2gMwN'
    }
    const hasRetried = localStorage.getItem('last-retry')
    if (hasRetried) {
      const item = JSON.parse(hasRetried)
      const now = new Date().getTime()
      if (now < item.expiry) {
        this.currentStep = this.STEP_TYPES.NEW_PAYMENT
      }
    }
  },
  mounted () {
    this.getCustomerPaymentMethod()
  },
  computed: {
    ...mapGetters('AuthModule', ['getUserSubscription', 'getStripeCustomer', 'getUser'])
  },
  methods: {
    ...mapActions('AuthModule', ['setUserSubscription', 'checkTeamExpired']),
    ...mapMutations('AuthModule', ['SET_TEAM_SUBSCRIPTION_STATE']),
    async retryWithDefault () {
      this.loading.retry = true
      try {
        // Retry the past due charge
        await API.Stripe.retryPastDuePayment(
          this.getStripeCustomer.stripeId
        )

        const user = firebase.auth().currentUser
        await this.setUserSubscription(user)
        this.SET_TEAM_SUBSCRIPTION_STATE('active')
      } catch (e) {
        this.$showAlert({
          message: 'Payment failed, please try adding a new card.',
          type: 'error'
        })

        const now = new Date().getTime()
        const fiveMinutesLater = now + 5 * 60 * 1000
        const item = {
          expiry: fiveMinutesLater
        }
        localStorage.setItem('last-retry', JSON.stringify(item))
        this.currentStep = this.STEP_TYPES.NEW_PAYMENT
      }
      this.loading.retry = false
    },
    async getCustomerPaymentMethod () {
      this.loading.card = true
      const customerId = this.getStripeCustomer.stripeId

      const { paymentMethod } = await API.Stripe.getCustomerPaymentMethod(customerId)
      this.paymentMethod = paymentMethod
      this.loading.card = false
    },
    async updatePaymentMethod () {
      // Update the card then retry the last failed payment
      this.loadingPaymentUpdate = true
      try {
        // ref in template
        const groupComponent = this.$refs.cardElms
        const cardElement = groupComponent.elements.getElement('card')

        // Make an api call to create a payment intent using the current setup intent
        const stripe = groupComponent.instance

        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement
        })
        if (error) {
          console.log(error)
          this.$showAlert({
            message: error.message,
            type: 'error'
          })
        } else {
          await API.Stripe.updatePaymentMethod(
            paymentMethod.id,
            this.getStripeCustomer.stripeId
          )

          // Retry the past due charge
          await API.Stripe.retryPastDuePayment(
            this.getStripeCustomer.stripeId
          )

          this.$showAlert({
            message: 'Your payment method has been updated.',
            type: 'success'
          })
          this.paymentSucceeded = true
          localStorage.removeItem('last-retry')

          const user = firebase.auth().currentUser
          await this.setUserSubscription(user)
          this.SET_TEAM_SUBSCRIPTION_STATE('active')
          // this.$router.push({
          //   name: 'AccountView'
          // })
        }
      } catch (error) {
        console.log(error)
        this.$showAlert({
          message: 'Error updating payment method.',
          type: 'error'
        })
        this.paymentSucceeded = false
      }
      this.loadingPaymentUpdate = false
    }
  }
}
</script>

  <style scoped>
.test{
    border: 2px solid red;
}</style>
