<template>
  <div class="w-full h-full flex flex-col rounded-lg">

    <!-- COMMENTS -->
    <div v-if="loadingContent" class="relative h-full min-h-0 flex flex-col items-center justify-center">
      <BaseLoadingLogo />
    </div>
    <div v-show="!loadingContent && comments.length" id="comment-container" class="flex-grow min-h-0 overflow-y-scroll scrollbar-hide p-4">
      <!-- COMMENT -->
      <div v-for="(comment, index) in comments" :key="index" class="flex items-start">
        <div class="flex items-center justify-start mt-1.5 mb-1 w-full">
          <!-- <BaseAvatar size="24" class="mr-2" :avatar="getCommenterAvatar(comment.created_by)"/> -->
          <img v-if="getCommenterAvatar(comment.created_by)" :src="getCommenterAvatar(comment.created_by)" class="w-6 h-6 mr-2 rounded-full" />
          <DefaultAvatarIcon v-else :width="24" :height="24" class="mr-2" />

          <!-- Comment text -->
          <div class="bg-neutral-25 rounded-xl break-words max-w-full px-2.5 py-1.5" style="max-width: calc(100% - 60px);">
            <BaseText type="body" size="sm" class="text-text-muted flex items-center mr-1">
              <div class="bg-background-normal rounded break-words max-w-full" v-html="comment.body"/>
            </BaseText>
            <div class="flex items-center justify-start w-full divide-x divide-text-subdued mt-0.5">
              <BaseText type="body" size="xs" class="text-text-subdued flex items-center mr-1">
                {{ comment.created_by === getUser.id ? 'You' : getCommenterName(comment.created_by) }}
              </BaseText>
              <BaseText type="body" size="xs" class="text-text-subdued flex items-center pl-1">
                {{ getCommentTimeAgo(comment.created_at) }}
              </BaseText>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loadingContent && !comments.length" class="relative flex-grow min-h-0 w-full flex flex-col gap-6 items-center justify-center">
      <img src="../../../assets/images/break-the-silence.png" alt="" style="width: 160px; height: 84px" />
      <div class="w-full flex flex-col gap-1 text-center">
        <BaseText type="label" size="sm" class="text-text-muted">
          Break the silence
        </BaseText>
        <BaseText type="body" size="sm" class="text-text-normal">
          Be the first to start the conversation.
        </BaseText>
      </div>
    </div>
    <!-- TEXT EDITOR -->
    <div v-if="editor && canModifyBoard" class="editor border-t border-border-normal relative">
      <editor-content :editor="editor" class="editor__content items-center border-b border-border-normal text-text-loud"/>
      <div v-if="valueEmpty" class="absolute top-2.5 left-5 text-gray-400 pointer-events-none">
        Add your comment...
      </div>
      <MenuBar
        class="editor__header bg-white"
        :editor="editor"
        :saving-comment="savingComment"
        :value-empty="valueEmpty"
        @createComment="createComment"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FirebaseAPI from '@/api/firebase'
import CharacterCount from '@tiptap/extension-character-count'
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import VuePopper from 'vue-popperjs'

import MenuBar from './textEditor/MenuBar'

import 'vue-popperjs/dist/vue-popper.css'

import DefaultAvatarIcon from '../../globals/Icons/DefaultAvatarIcon.vue'

export default {
  name: 'Comments',
  components: {
    EditorContent,
    MenuBar,
    VuePopper,
    DefaultAvatarIcon
  },
  props: {
    advertisement: {
      type: Object,
      default: () => {}
    },
    comments: {
      type: Array,
      default: () => []
    },
    canModifyBoard: {
      type: Boolean,
      default: () => false
    },
    commentsLoading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      editor: null,
      timeAgo: null,
      savingComment: false,
      valueEmpty: true,
      commentUsers: [],
      value: '',
      usersLoading: false
    }
  },
  computed: {
    loadingContent () {
      return this.commentsLoading || this.usersLoading
    }
  },
  watch: {
    comments: {
      async handler (newVal) {
        this.usersLoading = true
        this.commentUsers = await this.getCommentUsers(newVal)
        this.usersLoading = false
      },
      immediate: true
    }
  },
  async mounted () {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        Underline,
        StarterKit,
        Link.configure({
          HTMLAttributes: {
            class: 'text-blue-600 cursor-pointer'
          }
        }),
        CharacterCount.configure({
          limit: 3000
        }),
        Placeholder.configure({
          placeholder: 'Add your comment...'
        })
      ],
      editorProps: {
        attributes: {
          class:
            'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl m-5 focus:outline-none h-10'
        }
      },
      onUpdate: () => {
        const editorValue = this.editor.getHTML()
        this.value = editorValue

        this.valueEmpty = editorValue === '<p></p>'
      }
    })

    // Init time ago
    TimeAgo.addDefaultLocale(en)

    // Create formatter (English).
    this.timeAgo = new TimeAgo('en-US')
  },
  beforeDestroy () {
    this.editor.destroy()
  },
  methods: {
    ...mapGetters('AuthModule', ['getUser']),
    closeDrawer (commentId) {
      const popperRef = this.$refs[`popper${commentId}`][0]

      if (popperRef.showPopper) {
        setTimeout(() => {
          popperRef.doClose()
        }, 70)
      }
    },
    scrollToBottomOfCommentContainer () {
      // Start scroll at the bottom of the container
      const container = this.$el.querySelector('#comment-container')
      container.scrollTop = container.scrollHeight
    },
    getCommenterAvatar (userId) {
      return this.commentUsers.find((user) => user.id === userId).avatar
    },
    getCommenterName (userId) {
      const foundUser = this.commentUsers.find((user) => user.id === userId)
      const name = foundUser?.first_name || '' + ' ' + foundUser?.last_name || ''

      return name && name.length ? name : foundUser.name || 'foreplay User'
    },
    getCommentTimeAgo (createdAt) {
      return this.timeAgo.format(createdAt)
    },
    async getCommentUsers (comments) {
      const userIds = comments.map((comment) => comment.created_by)

      const uniqueIds = new Set(userIds)

      const getUsersPromises = [...uniqueIds].map((uid) => {
        return FirebaseAPI.Users.getById(uid)
      })
      const commentUsers = await Promise.all(getUsersPromises)

      return commentUsers
    },
    async createComment () {
      this.savingComment = true

      const comment = await FirebaseAPI.Comments.create(
        this.value,
        this.advertisement.id
      )
      this.commentUsers = await this.getCommentUsers([
        ...this.comments,
        comment
      ])
      const updatedComments = this.comments.concat(comment)
      this.$emit('update:comments', updatedComments)
      this.editor.commands.clearContent()
      this.valueEmpty = true
      this.savingComment = false

      return comment
    },
    async deleteComment (commentId) {
      const comment = await FirebaseAPI.Comments.remove(commentId)

      // Find comment in state and remove it
      const updatedComments = this.comments.filter(
        (comment) => comment.id !== commentId
      )
      this.$emit('update:comments', updatedComments)

      // Make sure drawer is closed when comment is deleted
      this.closeDrawer(commentId)

      return comment
    }
  }
}
</script>

<style lang="scss">
.editor {
  display: flex;
  flex-direction: column;
  color: #0d0d0d;
  background-color: #fff;

  &__header {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    flex-wrap: wrap;
    padding: 0.25rem;
  }

  &__content {
    background-color: #ffffff;
    height: 60px;
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

div.ProseMirror {
  margin: 0px !important;
  padding-top: 10px !important;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
}
</style>