<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M10.0013 5.5V4M7.11164 6.55278L6.14746 5.40372M12.898 6.55275L13.8622 5.40369M2.5 15.2501H6.51374C7.64847 15.2501 8.6056 14.4051 8.74637 13.2792L8.93387 11.7792C9.10172 10.4363 8.05461 9.25008 6.70124 9.25008H6.625L6.09675 8.89788C5.71668 8.64453 5.24736 8.56323 4.80422 8.674C4.29917 8.8003 3.88547 9.16143 3.69213 9.64473L3.25 10.7501H2.5M17.5004 15.2501H13.4867C12.3519 15.2501 11.3948 14.4051 11.254 13.2792L11.0665 11.7792C10.8986 10.4363 11.9458 9.25008 13.2992 9.25008H13.3754L13.9037 8.89788C14.2837 8.64453 14.753 8.56323 15.1961 8.674C15.7012 8.8003 16.1149 9.16143 16.3082 9.64473L16.7504 10.7501H17.5004" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'AffiliateReferralSettingsIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>