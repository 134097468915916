// Imports
import serverAxiosConfig from '../foreplayServer/serverAxiosConfig'
import AxiosConfig from '../config/AxiosConfig'
import firebase from '../config/FirebaseConfig'

// Pointing these to server rather than cloud functions, since they are part of brief generator
const generateBrief = async (adId, language, brandDetails) => {
  const { Aa: token } = firebase.auth().currentUser
  // console.log('token', token)

  const payload = {
    adId: adId,
    language: language,
    ...brandDetails
  }

  // console.log('payload', payload)
  const { data } = await serverAxiosConfig.post('/briefs/generateUserBrief', payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return data
}

const generateBriefVariations = async (story, index, rewriteText, field) => {

  const { Aa: token } = firebase.auth().currentUser

  const payload = {
    story,
    index,
    rewriteText,
    field
  }

  const { data } = await serverAxiosConfig.post('/briefs/generateBriefVariations', payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return data
}

// This one will still point to cloud functions, not part of brief generator

const generateFrameScreenshots = async (videoUrl, adId, scenes) => {
  const { data } = await AxiosConfig.post('/generateFrameScreenshots', {
    adId,
    videoUrl,
    scenes
  })

  return data
}

export default {
  generateBrief,
  generateBriefVariations,
  generateFrameScreenshots
}
