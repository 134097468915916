<template>
  <button class="w-full flex items-center pl-2 py-2 pr-4 rounded-md transition-colors duration-100 min-w-0"
  :class="isNavActive ? 'bg-neutral-malpha-50' : 'hover:bg-neutral-malpha-50'"
  @click="option.action">
    <div v-if="option.image" class="relative w-7 h-7 rounded-md mr-3 flex-shrink-0 overflow-hidden">
      <img :src="option.image" alt="Brand Image" class="w-full h-full rounded-md mr-3 flex-shrink-0" @load="loadingImage = false">
      <div v-if="loadingImage" class="absolute left-0 right-0 top-0 bottom-0 bg-neutral-800 opacity-60">
        <BaseLoadingSpinner small class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    </div>
    </img>
    <div v-else-if="['productCategory', 'creativeTarget'].includes(option.type)" 
    class="p-1 rounded-md mr-3" :class="`${getOptionIconBackground(option.type)}`">
      <ProductCategoryIcon v-if="option.type === 'productCategory'" style="color: #D198F5" />
      <CreativeTargetIcon v-else class="text-primary-purple-50" />
    </div>
    <BaseText type="body" size="sm" class="text-neutral-alpha-800 flex-shrink truncate mr-3 capitalize">
      {{ option.name }}
    </BaseText>
    <BaseText type="body" size="xs" class="text-neutral-alpha-600 flex-shrink-0 mr-3">
      {{ option.title }}
    </BaseText>
    <BaseText type="body" size="xs" class="text-neutral-alpha-600 flex-shrink-0 ml-auto">
      {{ option.count.toLocaleString() }} Ads
    </BaseText>
  </button>
</template>

<script>
import ProductCategoryIcon from '../../globals/Icons/SearchIcons/ProductCategoryIcon.vue'
import CreativeTargetIcon from '../../globals/Icons/CreativeTargetIcon.vue'

export default {
  name: 'AutocompleteOption',
  components: {
    ProductCategoryIcon,
    CreativeTargetIcon
  },
  props: {
    option: {
      type: Object,
      required: true
    },
    isNavActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loadingImage: true
    }
  },
  methods: {
    getOptionIconBackground (type) {
      switch (type) {
        case 'productCategory':
          return 'bg-product-category'
        case 'creativeTarget':
          return 'bg-creative-target'
        default:
          return 'bg-neutral-malpha-50'
      }
    }
  }
}
</script>

<style scoped>
/* Option type icon backgrounds */
.bg-product-category {
  background: linear-gradient(180deg, transparent 0%, rgba(202, 33, 231, 0.12) 100%), rgba(202, 33, 231, 0.16);
}
.bg-creative-target {
  background: linear-gradient(180deg, transparent 0%, rgba(109, 49, 232, 0.12) 100%), rgba(109, 49, 232, 0.16);
}
</style>