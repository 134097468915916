<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 16" fill="none">
    <path d="M8.00004 7.66665L8.00004 5.42M1.83337 7.99998C1.83337 4.59422 4.59429 1.83331 8.00004 1.83331C11.4058 1.83331 14.1667 4.59422 14.1667 7.99998C14.1667 11.4057 11.4058 14.1666 8.00004 14.1666C4.59428 14.1666 1.83337 11.4057 1.83337 7.99998Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.99996 10.9967C8.32213 10.9967 8.58329 10.7355 8.58329 10.4134C8.58329 10.0912 8.32213 9.83002 7.99996 9.83002C7.67779 9.83002 7.41663 10.0912 7.41663 10.4134C7.41663 10.7355 7.67779 10.9967 7.99996 10.9967Z" 
    fill="currentColor" stroke="currentColor" stroke-width="0.5"/>
  </svg>
</template>

<script>
export default {
  name: 'AltAlertIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 16
    }
  }
}
</script>

<style scoped></style>