<template>
  <div class="sm:rounded-md">
    <div class="bg-white w-full">
      <div v-if="!popup">
        <h2
          id="plan-heading"
          class="text-lg leading-6 font-medium text-gray-900"
        >
          Plan
        </h2>
      </div>
      <!-- Annual Billing Toggle -->
      <div
        v-if="
          (getSubscriptionState === 'expired' ||
            getSubscriptionState === 'free' ||
            getSubscriptionState === 'Inspiration' ||
            getSubscriptionState === 'Full Workflow') &&
            step === 1
        "
        class="flex justify-between items-center mt-3 px-6 pb-3"
      >
        <div class="text-md font-medium text-gray-700 my-2 flex items-center">
          Pay Annual

          <!-- Separator -->
          <div class="mx-2 h-5 rounded-full bg-gray-300 w-px" />

          <div class="text-green-500">
            Save 10%
          </div>
        </div>
        <div class="flex items-center justify-center">
          <button
            type="button"
            class="relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none"
            :class="showAnnual ? 'bg-blue-600' : 'bg-gray-200'"
            role="switch"
            aria-checked="false"
            aria-labelledby="annual-billing-label"
            @click="changeToShowAnnual"
          >
            <span
              aria-hidden="true"
              class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              :class="showAnnual ? 'translate-x-5' : 'translate-x-0'"
            />
          </button>
        </div>
      </div>
      <div class="px-6">
        <div v-if="step === 1">
          <div
            v-if="
              popup ||
                (getUserSubscription.product &&
                  getUserSubscription.product.name !== 'Full Workflow') ||
                !getActiveSubscription
            "
            class="flex justify-between flex-col pr-2 mb-2"
          >
            <SubscriptionCard
              v-for="(subscription, index) in getSortedSubscriptions"
              :key="index"
              :annual="showAnnual"
              :subscription-state="getSubscriptionState"
              :subscription="subscription"
              :selected="subscription.name === selectedSubscription"
              @click.native="selectSubscription(subscription.name)"
            />
          </div>

          <!-- Compare Plans Button -->
          <div class="w-full my-1 mt-5 flex justify-center">
            <a
              href="https://foreplay.co/pricing"
              target="_blank"
            >
              <BaseButton outlined> Compare Plans </BaseButton>
            </a>
          </div>
          <div
            v-if="
              elementsOptions.clientSecret &&
                ['free', 'expired'].includes(getSubscriptionState)
            "
            class="w-full mb-4"
            style="max-height: calc(90vh - 200px)"
          >
            <label
              for="promo-code"
              class="block text-sm font-medium text-gray-700"
            >Promo Code</label>
            <input
              id="promo-code"
              v-model="promoCode"
              type="text"
              name="promo-code"
              class="hover:border mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm transition"
              :class="{
                'border-gray-300 cursor-not-allowed': !selectedSubscription,
                'focus:ring-blue-600 focus:border-blue-600 hover:border-blue-600':
                  selectedSubscription && !invalidPromoCode,
                'border border-red-400 focus:ring-red-600 focus:border-red-600 hover:border-red-600':
                  invalidPromoCode,
              }"
              @input="checkPromoCode"
            >
          </div>

          <!-- Perk Check Marks -->
          <div class="flex justify-between mb-3">
            <div class="flex items-center gap-2">
              <!-- Checkmark SVG -->
              <svg
                width="14"
                height="11"
                viewBox="0 0 14 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.68756 6.37891L4.50006 9.50391L12.3126 1.37891"
                  stroke="#14C78C"
                  stroke-width="1.875"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div>Cancel Anytime</div>
            </div>
            <div class="flex items-center gap-2">
              <!-- Checkmark SVG -->
              <svg
                width="14"
                height="11"
                viewBox="0 0 14 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.68756 6.37891L4.50006 9.50391L12.3126 1.37891"
                  stroke="#14C78C"
                  stroke-width="1.875"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div>Unlimited Usage</div>
            </div>
            <div class="flex items-center gap-2">
              <!-- Checkmark SVG -->
              <svg
                width="14"
                height="11"
                viewBox="0 0 14 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.68756 6.37891L4.50006 9.50391L12.3126 1.37891"
                  stroke="#14C78C"
                  stroke-width="1.875"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div>Expert Support</div>
            </div>
          </div>
        </div>

        <StripeElements
          v-if="
            elementsOptions.clientSecret &&
              ['free', 'expired'].includes(getSubscriptionState) &&
              step === 2
          "
          #default="{ elements }"
          ref="elms"
          class="my-2"
          :stripe-key="stripeKey"
          :instance-options="instanceOptions"
          :elements-options="elementsOptions"
        >
          <StripeElement
            type="address"
            :elements="elements"
            :elements-options="elementsOptions"
            :options="addressOptions"
          />
          <StripeElement
            type="payment"
            :elements="elements"
            :elements-options="elementsOptions"
            :options="paymentOptions"
          />
        </StripeElements>
        <div
          v-if="
            elementsOptions.clientSecret &&
              ['free', 'expired'].includes(getSubscriptionState) &&
              step === 2
          "
          class="w-1/2 mt-2"
        >
          <!-- The Tax ID label -->
          <label
            for="taxId"
            class="block text-sm leading-6 text-gray-900 mt-1"
          >Tax ID</label>
          <!-- The Tax ID input field -->
          <input
            ref="taxId"
            type="text"
            placeholder="VAT: 123456789"
            class="my-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-100 sm:text-sm sm:leading-6a"
          >
        </div>
      </div>

      <!-- Bottom Section -->
      <div class="flex items-center justify-between border-t pt-4 pl-2 pr-4">
        <!-- Stripe Logo -->
        <img
          class="h-8"
          src="../../assets/images/stripe-logo.png"
        >

        <!-- Button Area -->
        <div class="flex gap-2">
          <!-- Back Button -->
          <BaseButton
            v-if="step === 2"
            outlined
            @click="$emit('changeStep', 1)"
          >
            Back
          </BaseButton>
          <!-- Next Button -->
          <BaseButton
            v-if="step === 1 && getSubscriptionState !== 'Inspiration'"
            primary
            @click="handleStepChange"
          >
            Next
          </BaseButton>
          <!-- Purchase Button -->
          <BaseButton
            v-if="
              ((getSubscriptionState === 'expired' ||
                getSubscriptionState === 'free') &&
                step === 2) ||
                getSubscriptionState === 'Inspiration'
            "
            primary
            :loading="loading"
            :disabled="loading || !selectedSubscription"
            @click="pay"
          >
            {{ getSubscriptionState === "Solo" ? "Upgrade" : "Subscribe" }}
            {{ getPriceText }}
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus'
import SubscriptionCard from './subscription/manage/SubscriptionCard'
import firebase from '@/api/config/FirebaseConfig'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import API from '@/api'
import store from '../../store'

export default {
  name: 'Stripe',
  components: {
    SubscriptionCard,
    StripeElements,
    StripeElement
  },
  props: {
    popup: {
      type: Boolean,
      default: () => false
    },
    teamOnly: {
      type: Boolean,
      default: () => false
    },
    step: {
      type: Number,
      default: () => 1
    }
  },
  data () {
    return {
      stripeKey: null,
      instanceOptions: {
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
      },
      paymentOptions: {},
      elementsOptions: {
        clientSecret: null
      },
      addressOptions: {
        mode: 'billing'
      },
      subscriptions: [],
      annualSubscriptions: [],
      selectedSubscription: null,
      loading: false,
      showAnnual: false,
      loadingPaymentUpdate: false,

      // Promo Code
      timeout: null,
      promoCode: '',
      invalidPromoCode: false,
      price: null,
      discountMessage: null,
      discountPrice: null,
      perUnitPrice: null
    }
  },
  computed: {
    ...mapGetters('AuthModule', [
      'getStripeCustomer',
      'getUserSubscription',
      'getUserEmail',
      'isFreeTier',
      'getTeam',
      'getUserFreeTrialDate'
    ]),
    beforeRouteEnter: (to, from, next) => {
      next((vm) => {
        vm.prevRoute = from
      })
    },
    getPriceText () {
      if (!this.selectedSubscription) {
        return ''
      }

      const priceToShow = this.discountPrice || this.price
      if (this.showAnnual) {
        return `$${this.numberWithCommas(priceToShow / 100)}/yr`
      } else {
        return `$${this.numberWithCommas(priceToShow / 100)}/mo`
      }
    },
    getSortedSubscriptions () {
      const subs = [...this.subscriptions]
      let filteredSubs = subs.filter((s) => s.name !== 'Free')

      if (this.teamOnly) {
        filteredSubs = filteredSubs.filter((s) => s.name === 'Full Workflow')
      }

      return filteredSubs.sort((a, b) => (a.order > b.order ? 1 : -1))
    },
    getSubscriptionState () {
      if (this.getUserSubscription.product) {
        return this.getUserSubscription.product.name
      }
      if (this.isFreeTier && Date.now() > this.getUserFreeTrialDate + 6.048e8) {
        return 'expired'
      }
      return 'free'
    },
    getActiveSubscription () {
      return [...this.subscriptions].find(
        (sub) => sub.name === this.getUserSubscription.product?.name
      )
    }
  },
  async mounted () {
    // Set the Stripe Key
    if (process.env.NODE_ENV === 'development') {
      this.stripeKey = 'pk_test_51JS7DeAQtq0TW7KZz4oPmzFVgxlULjWuUNKBoo0KOJjVi90wLS3VniuKgSLJHNHeFBNuY6f8jP9mD3bLygTj8BRM00aL3PiZeq'
    } else {
      this.stripeKey = 'pk_live_51JS7DeAQtq0TW7KZxoGv8Spu6IJhR335IoLxdfBQMF8VCbWJFDrUl2DfeNJllyC79mZJv8kX0qiaNGFCgbrTznpA00kPK2gMwN'
    }

    await this.getPaymentSetupIntent()
    await this.fetchSubscriptions()
    await this.checkPromoCode(true)

    if (['free', 'expired'].includes(this.getSubscriptionState)) {
      this.selectedSubscription = this.getSortedSubscriptions[0].name
    }
  },
  methods: {
    ...mapActions('AuthModule', ['setUserSubscription', 'checkTeamExpired']),
    ...mapMutations('AuthModule', ['SET_TEAM_SUBSCRIPTION_STATE']),
    numberWithCommas (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    async getPaymentSetupIntent () {
      const { clientSecret } = await API.Stripe.createSubscriptionSetup(
        this.getStripeCustomer.stripeId
      )

      this.elementsOptions.clientSecret = clientSecret
    },
    async handleStepChange () {
      const email = store.getters['AuthModule/getUserEmail']
      const planValue = this.selectedSubscription === 'Inspiration' ? 49 : 99
      await API.Analytics.planselected({
        email: email,
        planName: this.selectedSubscription || 'Free',
        planValue: planValue,
        billingPeriod: this.showAnnual ? 'yearly' : 'monthly',
        couponUsed: !!this.promoCode
      })

      // window.analytics.track("Plan Selected", {
      //   planName: this.selectedSubscription || "Free",
      //   planValue,
      //   billingPeriod: this.showAnnual ? "yearly" : "monthly",
      //   couponUsed: !!this.promoCode,
      // });

      // await API.

      if (this.step === 1) {
        this.$emit('changeStep', 2)
      }
    },
    async pay () {
      this.loading = true
      try {
        let priceId
        if (this.showAnnual) {
          priceId = this.subscriptions.find(
            (sub) => sub.name === this.selectedSubscription
          ).annualPrice.id
        } else {
          priceId = this.subscriptions.find(
            (sub) => sub.name === this.selectedSubscription
          ).price.id
        }
        // Check if we should upgrade
        if (
          this.getUserSubscription.product?.name === 'Inspiration' &&
          this.selectedSubscription === 'Full Workflow'
        ) {
          await API.Stripe.upgradeSubscription(
            priceId,
            this.getStripeCustomer.stripeId
          )

          this.$showAlert({
            message: 'Your account has been upgraded. Thank you!',
            type: 'success'
          })

          if (this.showAnnual) {
            this.$router.push({ name: 'AnnualTeamThankYouRedirectView' })
          } else {
            this.$router.push({ name: 'MonthlyTeamThankYouRedirectView' })
          }

          return
        }

        // ref in template
        const groupComponent = this.$refs.elms

        // Make an api call to create a payment intent using the current setup intent
        const result = await groupComponent.instance.confirmSetup({
          elements: groupComponent.elements,
          redirect: 'if_required',
          confirmParams: { return_url: 'https://app.foreplay.co/library' }
        })

        // Send the Tax ID as well
        const taxId = this.$refs.taxId.value

        // Check if there is already team members
        const teamMembers = this.getTeam?.members?.length

        // Make an API endoint call to update the default payment method and create the payment intent
        const { clientSecret } = await API.Stripe.createSubscription(
          priceId,
          this.getStripeCustomer.stripeId,
          this.promoCode,
          result.setupIntent.payment_method,
          taxId,
          teamMembers
        )

        let confirmPaymentResult
        if (clientSecret) {
          confirmPaymentResult = await this.$stripe.confirmCardPayment(
            clientSecret
          )
        }

        if (confirmPaymentResult?.error) {
          // Show Error
          this.$showAlert({
            message: confirmPaymentResult.error.message,
            type: 'error'
          })
        } else {
          this.$showAlert({
            message: 'Charge Succeeded. Thank you!',
            type: 'success'
          })

          this.$emit('changeStep', 3)

          window.history.pushState(null, null, '/thank-you')

          // Send to the correct endpoint for conversion tracking
          // if (this.selectedSubscription === 'Inspiration') {
          //   if (this.showAnnual) {
          //     this.$router.push({ name: 'AnnualSoloThankYouRedirectView' })
          //   } else {
          //     this.$router.push({ name: 'MonthlySoloThankYouRedirectView' })
          //   }
          // }

          // if (this.selectedSubscription === 'Full Workflow') {
          //   if (this.showAnnual) {
          //     this.$router.push({ name: 'AnnualTeamThankYouRedirectView' })
          //   } else {
          //     this.$router.push({ name: 'MonthlyTeamThankYouRedirectView' })
          //   }
          // }
          const user = firebase.auth().currentUser
          await this.setUserSubscription(user)
          this.SET_TEAM_SUBSCRIPTION_STATE('active')
        }
      } catch (error) {
        console.log(error)
        this.$showAlert({
          message: error.response.data.error.message,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async checkPromoCode (skipTimeout = false) {
      if (this.timeout || skipTimeout) {
        clearTimeout(this.timeout)
      }

      const updatePriceAndCoupon = async () => {
        if (!this.selectedSubscription) return

        this.loading = true
        let priceId
        if (this.showAnnual) {
          priceId = this.subscriptions.find(
            (sub) => sub.name === this.selectedSubscription
          ).annualPrice.id
        } else {
          priceId = this.subscriptions.find(
            (sub) => sub.name === this.selectedSubscription
          ).price.id
        }

        try {
          const {
            originalPrice,
            discountPrice,
            discountMessage,
            perUnitPrice
          } = await API.Stripe.checkPromoCode(priceId, this.promoCode)

          this.price = originalPrice
          this.discountMessage = discountMessage
          this.discountPrice = discountPrice
          this.perUnitPrice = perUnitPrice
          this.invalidPromoCode = false
        } catch (error) {
          this.$showAlert({
            message: 'Invalid Promo Code',
            type: 'error'
          })
          this.invalidPromoCode = true
        } finally {
          this.loading = false
        }
      }

      if (this.skipTimeout) {
        updatePriceAndCoupon()
      } else {
        this.timeout = setTimeout(updatePriceAndCoupon, 500)
      }
    },
    async changeToShowAnnual () {
      this.loading = true
      this.showAnnual = !this.showAnnual

      if (
        this.getUserSubscription.product?.name === this.selectedSubscription
      ) {
        this.selectedSubscription = null
      }

      await this.checkPromoCode(true)
      this.loading = false
    },
    async selectSubscription (subscriptionName) {
      if (
        this.getUserSubscription.product?.name !== 'Full Workflow' &&
        subscriptionName === 'Full Workflow'
      ) {
        this.selectedSubscription = subscriptionName
        await this.checkPromoCode(true)
        return
      }

      if (
        this.getUserSubscription.product?.name === 'Inspiration' &&
        (this.getUserSubscription.price.interval === 'year') !== this.showAnnual
      ) {
        this.selectedSubscription = subscriptionName
        await this.checkPromoCode(true)
        return
      }

      if (!this.isFreeTier) return
      this.selectedSubscription = subscriptionName
      await this.checkPromoCode(true)
    },
    // Fetch subscriptions to display on the page
    async fetchSubscriptions () {
      this.loadingSubscriptions = true

      try {
        const db = firebase.firestore()

        const data = await db
          .collection('products')
          .where('active', '==', true)
          .get()

        for (let i = 0; i < data.docs.length; i++) {
          const currentDoc = data.docs[i].data()

          // REMOVE THIS WHEN TEAMS EXISTS
          // if (currentDoc.name === 'Full Workflow') {
          //   continue
          // }

          if (!currentDoc.metadata.perks) {
            continue
          }

          const subscription = {
            ...currentDoc,
            perks: JSON.parse(currentDoc.metadata.perks),
            price: [],
            order: currentDoc.metadata.order,
            description: currentDoc.metadata.description
          }

          const priceSnap = await data.docs[i].ref
            .collection('prices')
            .where('active', '==', true)
            .get()

          priceSnap.docs.forEach((doc, index) => {
            if (doc.data().interval === 'year') {
              subscription.annualPrice = {
                ...doc.data(),
                id: doc.id
              }
            }

            if (!index) {
              subscription.price = {
                ...doc.data(),
                id: doc.id
              }
            }
          })

          this.subscriptions = [...this.subscriptions].concat(subscription)
        }
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e,
          type: 'error'
        })
      } finally {
        this.loadingSubscriptions = false
      }
    }
  }
}
</script>
