<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M6.3334 14.6667L4.13814 12.4714C3.87778 12.2111 3.87778 11.7889 4.13814 11.5286L6.3334 9.33333M4.66673 12H13.3334C14.8062 12 16 10.8061 16 9.33333V8.66667C16 7.19391 14.8062 6 13.3334 6H10" 
    stroke="currentColor" stroke-opacity="0.56" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'ReturnKeyIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>