<template>
  <div class="bg-white px-4 p-6 relative shadow sm:rounded-md sm:overflow-hidden">
    <div class="space-y-6 flex flex-col justify-between max-w-sm">
      <div>
        <h1 class="text-lg leading-6 font-medium text-gray-900">
          White Label Sharing is here!
        </h1>
        <p class="mt-1 text-sm text-gray-500">
          Share boards and Sypder analytics with your brand and customize as much as you want.
        </p>
      </div>
      <BaseButton primary class="w-min" @click="showCustomizeDrawer = true">
        Customize your brand
      </BaseButton>
    </div>
    <img class="absolute -bottom-5 right-0 max-w-xl" src="@/assets/images/white-label-preview.png">
    <CustomizeLabelShareDrawer v-if="showCustomizeDrawer" :current-user="currentUser" :current-team="currentTeam"
      @close="showCustomizeDrawer = false" />
  </div>
</template>

<script>
import CustomizeLabelShareDrawer from './CustomizeLabelShareDrawer.vue'

export default {
  name: 'WhiteLabelShare',
  components: {
    CustomizeLabelShareDrawer
  },
  props: {
    currentUser: {
      type: Object
    },
    currentTeam: {
      type: Object
    },
  },
  data() {
    return {
      showCustomizeDrawer: false
    }
  },
  mounted() {
    this.showCustomizeDrawer = this.$route.query?.openLabelEdit || false
  }
}
</script>

<style scoped></style>
