import AxiosConfig from '../config/AxiosConfig'
import firebase from '../config/FirebaseConfig'

const migrateUserToTeam = async (teamId) => {
  const user = firebase.auth().currentUser

  await AxiosConfig.post('/migrateUserToTeam', {
    teamId,
    uid: user.uid
  })
}

const migrateUserOutOfTeam = async (userId, teamId, keepDataInTeam) => {
  await AxiosConfig.post('/migrateUserOutOfTeam', {
    teamId,
    uid: userId,
    keepDataInTeam
  })
}

const addUserToTeam = async (teamId) => {
  const user = firebase.auth().currentUser
  await AxiosConfig.post('/addUserToTeam', {
    teamId,
    uid: user.uid
  })
}

const adminRemoveTeamMember = async (memberId) => {
  await AxiosConfig.post('/adminRemoveTeamMember', {
    memberId
  })
}

export default {
  migrateUserToTeam,
  migrateUserOutOfTeam,
  addUserToTeam,
  adminRemoveTeamMember
}