<template>
  <div>
    <!-- Render header if we have metric results -->
    <BaseText
      v-if="searchList.metrics.length > 0"
      :id="sectionId"
      :ref="sectionId"
      class="capitalize text-text-muted mb-2.5 metric-section"
      type="heading"
      size="sm"
    >
      {{ sectionId }}
    </BaseText>
    <div
      v-for="metric in searchList.metrics"
      :id="metric.key"
      :key="metric.key"
      class="flex items-center gap-2 relative p-1.5 hover:bg-background-normal cursor-pointer rounded-lg"
      @click="handleMetricAdd(metric.key, sectionId)"
      @mouseenter="addHover(metric)"
      @mouseleave="addHover(null)"
    >
      <input
        type="checkbox"
        :checked="presetList.includes(metric.key)"
        class="h-4 w-4 rounded checkbox-input text-black cursor-pointer focus:ring-transparent transition-colors"
      >
      <BaseText
        type="label"
        size="sm"
      >
        {{ metric.title }}
      </BaseText>
    </div>
    <!-- Sub section list -->
    <div
      v-for="(subSection, index) in searchList.subSections"
      :key="index"
    >
      <div>
        <!-- SubSection Header -->
        <BaseText
          :id="subSection.domId"
          :ref="subSection.domId"
          type="heading"
          size="overline"
          class="text-text-subdued mt-5 mb-2.5 uppercase metric-section"
        >
          {{ subSection.sectionId }}
        </BaseText>
        <div class="flex flex-col gap-1">
          <div
            v-for="metric in subSection?.metrics"
            :id="metric.key"
            :key="metric.key"
            class="flex items-center gap-2 relative p-1.5 hover:bg-background-normal cursor-pointer rounded-lg"
            @click="handleMetricAdd(metric.key, sectionId)"
            @mouseenter="addHover(metric)"
            @mouseleave="addHover(null)"
          >
            <input
              type="checkbox"
              :checked="presetList.includes(metric.key)"
              class="h-4 w-4 rounded checkbox-input text-black cursor-pointer focus:ring-transparent transition-colors"
            >
            <BaseText
              type="label"
              size="sm"
            >
              {{ metric.title }}
            </BaseText>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MetricSectionList',
  props: {
    presetList: {
      type: Array,
      default: () => []
    },
    data: {
      type: Object,
      default: () => {}
    },
    sectionId: {
      type: String,
      default: () => ''
    },
    fuzzyResults: {
      type: Set,
      default: () => new Set()
    }
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters('LensModule', ['getMetricSearch']),
    searchList () {
      const metrics = this.filterSearchResults(this.data.metrics)
      const subSections = this.data.subSections.reduce((acc, section) => {
        const filteredMetrics = this.filterSearchResults(section.metrics)
        if (filteredMetrics.length > 0) {
          acc.push({
            ...section,
            metrics: filteredMetrics
          })
        }
        return acc
      }, [])
      return {
        metrics: this.getMetricSearch?.length > 0 ? metrics : this.data.metrics,
        subSections: this.getMetricSearch?.length > 0 ? subSections : this.data.subSections
      }
    }
  },
  methods: {
    filterSearchResults (arr) {
      return arr.filter((val) => this.fuzzyResults.has(val.key))
    },
    addHover (metricKey) {
      this.$emit('handleHover', metricKey)
    },
    handleMetricAdd (metricKey, parentId) {
      this.$emit('add', metricKey, parentId)
    }
  }
}
</script>

<style scoped>
.checkbox-input {
  border: 1px;
  box-shadow: 0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(9, 25, 72, 0.13);
}
</style>
