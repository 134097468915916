import AxiosConfig from '../serverAxiosConfig'
import firebase from '../../config/FirebaseConfig'

const create = async (title, desc) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const payload = {
    name: title,
    description: desc
  }
  const { data } = await AxiosConfig.post(
    '/lenses',
    { payload },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const getAll = async () => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.get(
    '/lenses',
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

export default {
  create,
  getAll
}
