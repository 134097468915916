<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <g id="Frame">
      <path id="Vector" d="M11.875 6.25C10.8395 6.25 10 7.08947 10 8.125C10 9.16053 10.8395 10 11.875 10C12.9105 10 13.75 9.16053 13.75 8.125C13.75 7.08947 12.9105 6.25 11.875 6.25Z" :fill="fill"/>
      <path id="Vector_2" fill-rule="evenodd" clip-rule="evenodd" d="M3.25 5.5C3.25 4.25736 4.25736 3.25 5.5 3.25H14.5C15.7427 3.25 16.75 4.25736 16.75 5.5V14.5C16.75 15.6438 15.8967 16.5882 14.7919 16.7313L14.8106 16.75H5.5C4.25736 16.75 3.25 15.7427 3.25 14.5V5.5ZM13.3106 15.25L8.59097 10.5303C7.71231 9.65163 6.28769 9.65163 5.40901 10.5303L4.75 11.1894V5.5C4.75 5.08579 5.08579 4.75 5.5 4.75H14.5C14.9142 4.75 15.25 5.08579 15.25 5.5V14.5C15.25 14.9142 14.9142 15.25 14.5 15.25H13.3106Z" :fill="fill"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SolidImageIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    },
    fill: {
      type: String,
      default: '#5E6678'
    }
  }
}
</script>

<style scoped></style>
