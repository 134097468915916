<template>
  <MainLayout bg-color="white" no-content-padding>
    <template #content>
      <div class="bg-white">
        <!-- Dev Notes -->
        <div v-if="!hideTodos" class="bg-white shadow-md fixed bottom-0 right-0 rounded-md p-4" style="z-index:9999">
          <div class="w-full h-full relative">
            <div class="absolute top-0 right-0 hover:opacity-50 cursor-pointer" @click="hideTodos = true">
              Close
            </div>
            <pre>DEV TODOS:
            ✓ Add Header actions (UI) - hover for now
            ✓ Fix header icon spacing (16px atm, need 20)
            ✓ Add cell hover -> row/col highlight
            ✓ Fix group by column Name
            ✓ Add preset updates
            ✓ Fix layout padding and bg colors
            ✓ Add table rounded
            ! Add pin Columns
            Fix pagination of # delay
            ! Add unit specific cells (0.0, 0.0%, $0.0)
            Add horizontal scrollbar hover
            ! Add table average & heatmaps
            Finalize status cell
            Change qmark icon to info w/ tooltip(?)
            Add selected column tooltip (column checkmark)
            Add selected option on results per
            Add selected groups
          </pre>
            <pre class="text-red-500">
            ! Pin disabled, need to think how
            ! need kpi data from zac (title, desc, type)
          </pre>
          </div>
        </div>
        <div class="w-full grid grid-cols-12 min-h-screen">
          <div class="p-2 gap-1 flex flex-col w-full h-full rounded-md border border-border-normal col-span-4">
            <BaseText>Step #1 - Create/Select a Lens</BaseText>
            <BaseInput v-model="lensForm.title" placeholder="Lens Title" />
            <BaseInput v-model="lensForm.desc" placeholder="Lens desc" />

            <button class="px-2 py-1 bg-black text-white rounded-md" @click="createLens">
              Create
            </button>
            <div v-for="lens in lenses" :key="lens.id"
              class="flex flex-col gap-1 border border-border-normal rounded-md cursor-pointer"
              :class="lens.id === lensId ? 'bg-green-300' : ''" @click="updateLensId(lens.id)">
              {{ lens.id }}
            </div>
          </div>
          <div class="p-2 gap-1 flex flex-col w-full h-full rounded-md border border-border-normal col-span-4">
            <BaseText>Step #2 - Create/Select a report</BaseText>
            <BaseInput v-model="reportForm.title" placeholder="Report Title" />
            <BaseInput v-model="reportForm.desc" placeholder="Report desc" />

            <button class="px-2 py-1 bg-black text-white rounded-md" @click="createReport">
              Create
            </button>
            <div v-for="report in reports" :key="report.id"
              class="flex flex-col gap-1 border border-border-normal rounded-md cursor-pointer"
              @click="openReport(report.id)">
              {{ report.id }}
            </div>
          </div>
          <div class="p-4">
            <button class="px-2 py-1 bg-black text-white rounded-md w-max" @click="openReportModal">
              Open create report modal
            </button>
          </div>
        </div>
      </div>
      <NewReportModal v-if="showReportModal" :lens-id="lensId" @close="showReportModal = false" />
    </template>
  </MainLayout>
</template>
<script>
import ForeplayAPI from '@/api/foreplayServer'
// Components
import MainLayout from '../../layouts/MainLayout.vue'
import NewReportModal from '../../components/lens/NewReportModal.vue'

export default {
  name: 'LensTest',
  components: {
    MainLayout,
    NewReportModal
  },
  data() {
    return {
      // dev
      hideTodos: false,
      showReportModal: false,
      lensForm: {
        title: '',
        desc: ''
      },
      reportForm: {
        name: '',
        desc: ''
      },

      lensId: null,
      lenses: [],
      reports: []
    }
  },
  async mounted() {
    this.lenses = await ForeplayAPI.Lens.getAll()
  },
  methods: {
    async updateLensId(lensId) {
      console.log(lensId, 'sam')
      this.lensId = lensId
      this.reports = await ForeplayAPI.LensReport.getAllByLensId(lensId)
    },
    async createLens() {
      this.lensId = await ForeplayAPI.Lens.create(this.lensForm.title, this.lensForm.desc)
      this.lenses = await ForeplayAPI.Lens.getAll()
    },
    openReport(reportId) {
      const url = process.env.VUE_APP_BASE_URL +
        '/lens/' +
        encodeURIComponent(this.lensId) +
        '/report/' +
        encodeURIComponent(reportId)

      window.open(url, '_blank')
    },
    async createReport() {
      await ForeplayAPI.LensReport.create(this.lensId, { ...this.reportForm, filters: [] })
      this.updateLensId(this.lensId)
    },
    openReportModal() {
      if (this.lensId) {
        this.showReportModal = true
      } else {
        this.$showAlert({ type: 'error', message: 'Create/Select a lens first' })
      }
    }
  }
}
</script>

<style scoped>
.dropdown-scrollable {
  transition: colors 250ms ease-in-out;
  top: 100%;
  z-index: 999;
}

.dropdown-scrollable::-webkit-scrollbar {
  width: 3px;
}

.dropdown-scrollable::-webkit-scrollbar-thumb {
  background-color: #DFE1E7;
  border-radius: 18px;
}

.base-btn {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 100ms ease-in-out, background-color 100ms ease-in-out;
  border-radius: 6px;
  padding: 6px 12px 6px 6px;
}

.base-btn:hover {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.17), 0px 0px 0px 1px rgba(0, 56, 108, 0.12);
}
</style>
