<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M12.626 5.875C12.626 7.32475 11.4507 8.5 10.001 8.5C8.55121 8.5 7.37598 7.32475 7.37598 5.875C7.37598 4.42525 8.55121 3.25 10.001 3.25C11.4507 3.25 12.626 4.42525 12.626 5.875Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M10.0008 10.75C7.45747 10.75 5.52783 12.2607 4.7377 14.3972C4.4311 15.2261 5.13958 16 6.02347 16H13.9782C14.8621 16 15.5705 15.2261 15.2639 14.3972C14.4738 12.2607 12.5442 10.75 10.0008 10.75Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'MyAccountSettingsIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>