<template>
  <div class="flex gap-2 py-1">
    <div class="flex items-center flex-wrap">
      <FilterSearch class="mr-0.5"
        :items="filterTypeItems" 
        :isFocused="focusedFilterIndex === -1" 
        :hasNextItem="activeFilterTypes.length > 0"
        @focusNext="focusItem(0)" 
        @focusCurr="focusedFilterIndex = -1"
      />
      <div v-for="(filter, index) in activeFilterTypes" :key="index" class="flex items-center whitespace-nowrap">
        <FilterItem  
          :filterType="filter"
          :icon="{ component: getFilterTypeIcon(filter) }"
          :selections="getFilterTypeSelections(filter)" 
          :options="getFilterItemOptions(filter)"
          :isFocused="index === focusedFilterIndex" 
          :hasNextItem="index < activeFilterTypes.length - 1"
          @remove-filter-type="removeFilterType"
          @focusNext="focusItem(index + 1)" 
          @focusPrev="focusItem(index - 1)" 
          @focusCurr="focusedFilterIndex = index"
        />
        <div v-if="index === activeFilterTypes.length - 1 && activeFilterTypes.length > 1" 
        @click="clearFilters" @mouseenter="clearFiltersHovered" @mouseleave="clearFiltersUnhovered"
        class="relative group p-1 ml-1.5 rounded-lg bg-transparent transition-colors ease-in-out hover:bg-neutral-10 cursor-pointer">
          <ClearFiltersIcon class="text-neutral-300 transition-colors ease-in-out group-hover:text-neutral-700" />
          <transition name="tooltip">
            <div v-if="clearFiltersTooltipVisible" @mouseenter="clearFiltersUnhovered"
            class="clear-filters-tooltip z-50 pb-1">
              <div class="px-2 py-1 rounded-md shadow-lg bg-neutral-700 text-xs text-white whitespace-nowrap">
                Clear Filters
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import FilterItem from './FilterItem'
  import FilterSearch from './FilterSearch'
  import * as Icons from '../../globals/Icons/FilterIcons';
  import DCOIcon from '../../globals/Icons/DCOIcon.vue'
  import TargetMarketIcon from '../../globals/Icons/TargetMarketIcon.vue'
  import B2CIcon from '../../globals/Icons/B2CIcon.vue'
  import B2BIcon from '../../globals/Icons/B2BIcon.vue'

  export default {
    name: 'FilterList',
    components: {
      FilterItem,
      FilterSearch,
      ...Icons,
      DCOIcon,
      TargetMarketIcon,
      B2CIcon,
      B2BIcon
    },
    props: {
      // =========== FILTER SUBITEMS (Fetched in parent) ===========
      boardItems: { type: Array, required: true },
      brandItems: { type: Array, required: true },
      tagItems: { type: Array, required: true },
      languageItems: { type: Array, required: true },
      nicheItems: { type: Array, required: true },

      // =========== FILTER SELECTIONS (Synced with grandparent) ===========
      selectedFormats: { type: Array, default: null },
      selectedBoards: { type: Array, default: null},
      selectedPlatforms: { type: Array, default: null },
      selectedBrands: { type: Array, default: null},
      selectedTags: { type: Array, default: null},
      selectedLanguages: { type: Array },
      selectedStatuses: { type: Array, default: null },
      selectedNiches: { type: Array, required: true },
      selectedContentFilters: { type: Array, default: null },
      selectedMarketTargets: { type: Array, default: null },

      // Other
      excludedFilterTypes: { type: Array, default: () => [] }
    },
    computed: {
      filterTypeItems () {
        // List of filter types to display in the filter type search dropdown
        // Exclude filter types that are already active, not used on the page, or have no options
        return [
          { name: 'Boards', type: 'board', icon: this.getFilterTypeIcon('board') },
          { name: 'Brands', type: 'brand', icon: this.getFilterTypeIcon('brand') },
          { name: 'Tags', type: 'tag', icon: this.getFilterTypeIcon('tag') },
          { name: 'Format', type: 'format', icon: this.getFilterTypeIcon('format') },
          { name: 'Platform', type: 'platform', icon: this.getFilterTypeIcon('platform') },
          { name: 'Language', type: 'language', icon: this.getFilterTypeIcon('language') },
          { name: 'Status', type: 'status', icon: this.getFilterTypeIcon('status') },
          { name: 'Niche', type: 'niche', icon: this.getFilterTypeIcon('niche') },
          { name: 'Content Style', type: 'contentFilter', icon: this.getFilterTypeIcon('contentFilter')},
          { name: 'Target Market', type: 'marketTarget', icon: this.getFilterTypeIcon('marketTarget')}
        ]
        .filter(item => 
          !this.activeFilterTypes.includes(item.type) 
          && this.getFilterTypeSelections(item.type)
          && this.getFilterItemOptions(item.type).length > 0
        )
        .map(item => (
          { name: item.name, action: () => { this.addFilterType(item.type) }, icon: item.icon }
        ))
      }
    },
    data () {
      return {
        activeFilterTypes: [],
        focusedFilterIndex: -1,
        clearFiltersTooltipTimeout: null,
        clearFiltersTooltipVisible: false,
      }
    },
    methods: {
      getFilterTypeSelections (filterType) {
        // Maps filterTypes to the corresponding selected array prop
        switch (filterType) {
          case 'format': return this.selectedFormats
          case 'board': return this.selectedBoards
          case 'platform': return this.selectedPlatforms
          case 'brand': return this.selectedBrands
          case 'tag': return this.selectedTags
          case 'language': return this.selectedLanguages
          case 'status': return this.selectedStatuses
          case 'niche': return this.selectedNiches
          case 'contentFilter': return this.selectedContentFilters
          case 'marketTarget': return this.selectedMarketTargets
          default: return []
        }
      },
      getFilterItemOptions (filterType) {
        // Maps filterTypes to the corresponding options for FilterItem
        switch (filterType) {
          case 'format': 
            if (this.excludedFilterTypes.includes('format')) return []
            return [
              { name: 'Video', icon: Icons.VideoFormatIcon },
              { name: 'Image', icon: Icons.ImageFormatIcon },
              { name: 'Carousel', icon: Icons.CarouselFormatIcon },
              { name: 'DCO', icon: DCOIcon }
            ].map(format => (
              {name: format.name, icon: format.icon, action: () => { this.updateFilterSelection('format', format) }}
            ))
          case 'board': 
            if (this.excludedFilterTypes.includes('board')) return []
            return this.boardItems.map(board => (
              {name: board.name.slice(1), icon: Icons.BoardIcon, action: () => { this.updateFilterSelection('board', board) }}
            ))
          case 'platform': 
            if (this.excludedFilterTypes.includes('platform')) return []
            return [
              { name: 'Facebook', image: 'facebook', icon: Icons.FacebookPlatformIcon },
              { name: 'Instagram', image: 'instagram', icon: Icons.InstagramPlatformIcon },
              { name: 'TikTok Organic', image: 'tiktok', icon: Icons.TikTokPlatformIcon },
              { name: 'TikTok Ads', image: 'tiktok', icon: Icons.TikTokPlatformIcon },
              { name: 'YouTube', image: 'youtube', icon: Icons.YouTubePlatformIcon },
              { name: 'LinkedIn', image: 'linkedin', icon: Icons.LinkedInPlatformIcon },
            ].map(platform => (
              {name: platform.name, icon: platform.icon, action: () => { this.updateFilterSelection('platform', platform)}}
            ))
          case 'brand': 
            if (this.excludedFilterTypes.includes('brand')) return []
            return this.brandItems.map(brand => (
              {name: brand.name, icon: brand.avatar || null, action: () => { this.updateFilterSelection('brand', brand) }}
            ))
          case 'tag': 
            if (this.excludedFilterTypes.includes('tag')) return []
            return this.tagItems.map(tag => (
              {name: tag.name, color: tag.color, action: () => { this.updateFilterSelection('tag', tag) }}
            ))
          case 'language': 
            if (this.excludedFilterTypes.includes('language')) return []
            return this.languageItems.map(language => (
              {name: language.name, action: () => { this.updateFilterSelection('language', language) }}
            ))
          case 'status': 
            if (this.excludedFilterTypes.includes('status')) return []
            return [
              {name: 'Still Running', value: 'live', color: 'green'},
              {name: 'Not Running', value: 'dead', color: 'gray'}
            ].map(status => (
              {name: status.name, value: status.value, action: () => { this.updateFilterSelection('status', status) }}
            ))
          case 'niche': 
            if (this.excludedFilterTypes.includes('niche')) return []
            return this.nicheItems.map(niche => (
              {name: niche.name, action: () => { this.updateFilterSelection('niche', niche) }, icon: getNicheIcon(niche.name)}
            ))
          case 'contentStyle': 
            return []
          case 'contentFilter':
            return [
              { name: 'Before and After' },
              { name: 'Facts and Stats' },
              { name: 'Features and Benefits' },
              { name: 'Holiday - Seasonal' },
              { name: 'Media and Press' },
              { name: 'Promotion and Discount' },
              { name: 'Reasons why' },
              { name: 'Testimonial - Review' },
              // { name: 'UGC' },
              // { name: 'Unboxing' },
              { name: 'Us vs Them' }
            ].map(contentFilter => (
              { name: contentFilter.name, icon: Icons.OtherNicheIcon, action: () => { this.updateFilterSelection('contentFilter', contentFilter)}}
            ))
          case 'marketTarget':
            return [
              { name: 'B2C', value: 'b2c', icon: B2CIcon },
              { name: 'B2B', value: 'b2b', icon: B2BIcon }
            ].map(marketTarget => (
              { name: marketTarget.name, icon: marketTarget.icon, action: () => { this.updateFilterSelection('marketTarget', marketTarget) }}
            ))
          default: return []
        }
      },
      updateFilterSelection (filterType, selection) {
        // Handles update events from <FilterItem/> components
        // If the selection is already in the list, remove it, otherwise add it
        const existingSelections = this.getFilterTypeSelections(filterType)
        const selections = existingSelections.some(item => item.name === selection.name)
          ? existingSelections.filter(item => item.name !== selection.name)
          : (filterType === 'status' ? [selection] : [...existingSelections, selection])
        this.$emit(getEmitUpdateString(filterType), selections)
      },
      addFilterType (filterType) {
        if (!this.activeFilterTypes.includes(filterType)) {
          this.activeFilterTypes = [...this.activeFilterTypes, filterType]
          this.focusedFilterIndex = this.activeFilterTypes.length - 1
        }
      },
      removeFilterType (filterType) {
        if (this.activeFilterTypes.includes(filterType)) {
          this.activeFilterTypes = this.activeFilterTypes.filter(type => type !== filterType)
          if (this.getFilterTypeSelections(filterType).length > 0)
            this.$emit(getEmitUpdateString(filterType), [])
        }
      },
      clearFilters () {
        this.clearFiltersUnhovered()
        this.activeFilterTypes.forEach(filterType => {
          if (this.getFilterTypeSelections(filterType).length > 0)
            this.$emit(getEmitUpdateString(filterType), [])
        })
        this.activeFilterTypes = []
      },
      focusItem (index) {
        if (index >= -1 && index < this.activeFilterTypes.length) {
          this.focusedFilterIndex = index
        }
      },
      clearFiltersHovered () {
        clearTimeout(this.clearFiltersTooltipTimeout)
        this.clearFiltersTooltipTimeout = setTimeout(() => {
          this.clearFiltersTooltipVisible = true
        }, 1000)
      },
      clearFiltersUnhovered () {
        clearTimeout(this.clearFiltersTooltipTimeout)
        this.clearFiltersTooltipVisible = false
      },
      getFilterTypeIcon (filterType) {
        switch (filterType) {
          case 'format': return Icons.FormatIcon
          case 'board': return Icons.BoardIcon
          case 'platform': return Icons.PlatformIcon
          case 'brand': return Icons.BrandIcon
          case 'tag': return Icons.TagIcon
          case 'language': return Icons.LanguageIcon
          case 'status': return Icons.StatusIcon
          case 'niche': return Icons.NicheIcon
          case 'contentStyle': return Icons.ContentStyleIcon
          case 'marketTarget': return TargetMarketIcon
          default: return Icons.OtherNicheIcon
        }
      }
    }
  }

  const getNicheIcon = (niche) => {
    switch (niche) {
      case 'Accessories': return Icons.AccessoriesNicheIcon
      case 'Alcohol': return Icons.AlcoholNicheIcon
      case 'App/Software': return Icons.SoftwareNicheIcon
      case 'Automotive': return Icons.AutomotiveNicheIcon
      case 'Beauty': return Icons.BeautyNicheIcon
      case 'Book/Publishing': return Icons.BookNicheIcon
      case 'Business/Professional': return Icons.BusinessNicheIcon
      case 'Charity/NFP': return Icons.CharityNicheIcon
      case 'Education': return Icons.EducationNicheIcon
      case 'Entertainment': return Icons.EntertainmentNicheIcon
      case 'Fashion': return Icons.FashionNicheIcon
      case 'Finance': return Icons.FinanceNicheIcon
      case 'Food/Drink': return Icons.FoodNicheIcon
      case 'Games': return Icons.GamesNicheIcon
      case 'Government': return Icons.GovernmentNicheIcon
      case 'Health/Wellness': return Icons.HealthNicheIcon
      case 'Home/Garden': return Icons.HomeNicheIcon
      case 'Insurance': return Icons.InsuranceNicheIcon
      case 'Jewelry/Watches': return Icons.JewelryNicheIcon
      case 'Kids/Baby': return Icons.KidsNicheIcon
      case 'Media/News': return Icons.MediaNicheIcon
      case 'Medical': return Icons.MedicalNicheIcon
      case 'Pets': return Icons.PetsNicheIcon
      case 'Real Estate': return Icons.RealEstateNicheIcon
      case 'Service Business': return Icons.ServiceNicheIcon
      case 'Sports/Outdoors': return Icons.SportsNicheIcon
      case 'Tech': return Icons.TechNicheIcon
      case 'Travel': return Icons.TravelNicheIcon
      case 'Other': return Icons.OtherNicheIcon
      default: return Icons.OtherNicheIcon
    }
  }

  const getEmitUpdateString = (filterType) => {
    switch (filterType) {
      case 'contentFilter': return 'update:selected-content-filters'
      case 'status': return 'update:selected-statuses'
      case 'marketTarget': return 'update:selected-market-targets'
      default: return `update:selected-${filterType}s`
    }
  }
</script>

<style scoped>
  .clear-filters-tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  .tooltip-enter-active, .tooltip-leave-active {
    transition: opacity 150ms ease-in-out;
  }
  .tooltip-enter-from, .tooltip-enter, .tooltip-leave-to {
    opacity: 0;
  }
  .tooltip-enter-to, .tooltip-leave-from {
    opacity: 1;
  }

  .v-enter-active, .v-leave-active {
    transition: opacity 150ms ease-in-out;
  }
  .v-enter-from, .v-enter, .v-leave-to {
    opacity: 0;
  }
  .v-enter-to, .v-leave-from {
    opacity: 1;
  }
  
</style>