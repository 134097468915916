<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 12" fill="none">
    <path d="M2.1633 7.64297C2.1633 8.23528 2.29331 8.69003 2.46323 8.96515C2.68602 9.3255 3.01832 9.47816 3.35709 9.47816C3.79404 9.47816 4.19377 9.36973 4.9641 8.30433C5.58122 7.45039 6.30839 6.25175 6.79766 5.5003L7.62625 4.22722C8.20183 3.34307 8.86805 2.3602 9.6319 1.69399C10.2555 1.15023 10.9282 0.848145 11.6052 0.848145C12.7418 0.848145 13.8245 1.50681 14.653 2.74213C15.5598 4.09505 16 5.79916 16 7.55774C16 8.60318 15.794 9.37135 15.4433 9.97822C15.1046 10.5651 14.4443 11.1515 13.3336 11.1515V9.47816C14.2846 9.47816 14.522 8.60426 14.522 7.60413C14.522 6.17892 14.1897 4.59728 13.4576 3.46714C12.9381 2.66553 12.2649 2.17571 11.5243 2.17571C10.7232 2.17571 10.0786 2.77989 9.35409 3.85716C8.96892 4.42951 8.57351 5.12701 8.12955 5.91406L7.64081 6.77986C6.65903 8.52065 6.41034 8.91714 5.91945 9.57148C5.05904 10.7173 4.32431 11.1515 3.35709 11.1515C2.2097 11.1515 1.48415 10.6547 1.03479 9.90594C0.667967 9.29583 0.487793 8.49529 0.487793 7.5831L2.1633 7.64297Z" 
    fill="#0081FB"/>
    <path d="M1.8089 2.86027C2.57707 1.67619 3.68562 0.848145 4.95709 0.848145C5.69343 0.848145 6.42546 1.06608 7.18985 1.69022C8.02598 2.37261 8.91714 3.49627 10.0289 5.34818L10.4276 6.01278C11.39 7.616 11.9375 8.44081 12.2579 8.82975C12.6701 9.32927 12.9587 9.47816 13.3336 9.47816C14.2846 9.47816 14.522 8.60426 14.522 7.60413L16 7.55774C16 8.60318 15.794 9.37135 15.4433 9.97822C15.1046 10.5651 14.4443 11.1515 13.3336 11.1515C12.6431 11.1515 12.0314 11.0015 11.3549 10.3634C10.8349 9.87357 10.2269 9.00345 9.75922 8.22126L8.36799 5.89734C7.66995 4.73106 7.02963 3.86148 6.65903 3.46768C6.26039 3.04422 5.74791 2.53283 4.93012 2.53283C4.26822 2.53283 3.70612 2.99729 3.23573 3.70773L1.8089 2.86027Z" 
    fill="url(#paint0_linear_7820_7252)"/>
    <path d="M4.93011 2.53283C4.26821 2.53283 3.70611 2.99729 3.23572 3.70773C2.57058 4.71164 2.1633 6.20698 2.1633 7.64297C2.1633 8.23528 2.29331 8.69003 2.46323 8.96515L1.03479 9.90594C0.667967 9.29583 0.487793 8.4953 0.487793 7.5831C0.487793 5.92431 0.943083 4.19539 1.80889 2.86027C2.57706 1.67619 3.68561 0.848145 4.95708 0.848145L4.93011 2.53283Z" 
    fill="url(#paint1_linear_7820_7252)"/>
    <defs>
      <linearGradient id="paint0_linear_7820_7252" x1="3.77841" y1="7.15963" x2="14.4594" y2="7.69908" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0064E1"/>
        <stop offset="0.4" stop-color="#0064E1"/>
        <stop offset="0.83" stop-color="#0073EE"/>
        <stop offset="1" stop-color="#0082FB"/>
      </linearGradient>
      <linearGradient id="paint1_linear_7820_7252" x1="2.91529" y1="8.34641" x2="2.91529" y2="4.40847" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0082FB"/>
        <stop offset="1" stop-color="#0064E0"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'MetaPlatformIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 12
    }
  }
}
</script>

<style scoped></style>