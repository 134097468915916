<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M5.05 10H5.2H5.05ZM5.05 14.875H5.2H5.05ZM9.925 14.875H10.075H9.925ZM14.8 5.125H14.95H14.8ZM14.8 10H14.95H14.8ZM9.925 5.125H10.075H9.925ZM5.5 10C5.5 10.2071 5.33211 10.375 5.125 10.375C4.91789 10.375 4.75 10.2071 4.75 10C4.75 9.79292 4.91789 9.625 5.125 9.625C5.33211 9.625 5.5 9.79292 5.5 10ZM5.5 14.875C5.5 15.0821 5.33211 15.25 5.125 15.25C4.91789 15.25 4.75 15.0821 4.75 14.875C4.75 14.6679 4.91789 14.5 5.125 14.5C5.33211 14.5 5.5 14.6679 5.5 14.875ZM10.375 14.875C10.375 15.0821 10.2071 15.25 10 15.25C9.79292 15.25 9.625 15.0821 9.625 14.875C9.625 14.6679 9.79292 14.5 10 14.5C10.2071 14.5 10.375 14.6679 10.375 14.875ZM15.25 5.125C15.25 5.33211 15.0821 5.5 14.875 5.5C14.6679 5.5 14.5 5.33211 14.5 5.125C14.5 4.91789 14.6679 4.75 14.875 4.75C15.0821 4.75 15.25 4.91789 15.25 5.125ZM15.25 10C15.25 10.2071 15.0821 10.375 14.875 10.375C14.6679 10.375 14.5 10.2071 14.5 10C14.5 9.79292 14.6679 9.625 14.875 9.625C15.0821 9.625 15.25 9.79292 15.25 10ZM11.125 10C11.125 10.6213 10.6213 11.125 10 11.125C9.3787 11.125 8.875 10.6213 8.875 10C8.875 9.3787 9.3787 8.875 10 8.875C10.6213 8.875 11.125 9.3787 11.125 10ZM10.375 5.125C10.375 5.33211 10.2071 5.5 10 5.5C9.79292 5.5 9.625 5.33211 9.625 5.125C9.625 4.91789 9.79292 4.75 10 4.75C10.2071 4.75 10.375 4.91789 10.375 5.125Z" 
    fill="currentColor"/>
    <path d="M5.05 10H5.2M5.05 14.875H5.2M9.925 14.875H10.075M14.8 5.125H14.95M14.8 10H14.95M9.925 5.125H10.075M5.5 10C5.5 10.2071 5.33211 10.375 5.125 10.375C4.91789 10.375 4.75 10.2071 4.75 10C4.75 9.79293 4.91789 9.625 5.125 9.625C5.33211 9.625 5.5 9.79293 5.5 10ZM5.5 14.875C5.5 15.0821 5.33211 15.25 5.125 15.25C4.91789 15.25 4.75 15.0821 4.75 14.875C4.75 14.6679 4.91789 14.5 5.125 14.5C5.33211 14.5 5.5 14.6679 5.5 14.875ZM10.375 14.875C10.375 15.0821 10.2071 15.25 10 15.25C9.79293 15.25 9.625 15.0821 9.625 14.875C9.625 14.6679 9.79293 14.5 10 14.5C10.2071 14.5 10.375 14.6679 10.375 14.875ZM15.25 5.125C15.25 5.33211 15.0821 5.5 14.875 5.5C14.6679 5.5 14.5 5.33211 14.5 5.125C14.5 4.91789 14.6679 4.75 14.875 4.75C15.0821 4.75 15.25 4.91789 15.25 5.125ZM15.25 10C15.25 10.2071 15.0821 10.375 14.875 10.375C14.6679 10.375 14.5 10.2071 14.5 10C14.5 9.79293 14.6679 9.625 14.875 9.625C15.0821 9.625 15.25 9.79293 15.25 10ZM11.125 10C11.125 10.6213 10.6213 11.125 10 11.125C9.3787 11.125 8.875 10.6213 8.875 10C8.875 9.3787 9.3787 8.875 10 8.875C10.6213 8.875 11.125 9.3787 11.125 10ZM10.375 5.125C10.375 5.33211 10.2071 5.5 10 5.5C9.79293 5.5 9.625 5.33211 9.625 5.125C9.625 4.91789 9.79293 4.75 10 4.75C10.2071 4.75 10.375 4.91789 10.375 5.125Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="square"/>
  </svg>
</template>

<script>
export default {
  name: 'CreativeTargetIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>