<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M2.75 6V14M6.17424 6V14M9.11364 6L12.75 14M13.5 2.75L16.25 2.75C16.8023 2.75 17.25 3.19772 17.25 3.75L17.25 6.28557M2.75 17.25H15.25C16.3546 17.25 17.25 16.3546 17.25 15.25V10M2.75 2.75H10M16.25 3.75L13.75 6.25" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'AdLibraryIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>