<template>
  <BaseDrawer
    no-padding
    height-auto-only
    no-toolbar
    no-bottom-rounding
    class="scrollbar-hide"
    close-label="settings"
    @close="$emit('close')"
  >
    <template #content>
      <div class="w-full flex flex-col" style="height: calc(100vh - 80px)">
        <!-- Toolbar -->
        <div class="w-full flex items-center h-14 justify-between px-4 py-3 border-b border-border-normal">
          <!-- Label -->
          <div class="flex items-center p-1.5 gap-1">
            <SettingsIcon class="text-icon-muted" />
            <BaseText type="label" size="sm" class="text-text-muted">
              Settings
            </BaseText>
          </div>
          <!-- Exit -->
          <button class="p-1.5" @click="$emit('close')">
            <TagRemoveIcon stroke="#5E6678" />
          </button>
        </div>
        <div class="content-grid flex-grow h-full min-h-0">
          <!-- Sidebar navigation -->
          <div class="h-full flex flex-col justify-between border-r border-border-normal p-4">
            <!-- Navigation Options -->
            <div class="w-full flex flex-col gap-1">
              <button v-for="(option, index) in sidebarNavOptions" :key="`nav-option-${index}`"
               class="group nav-btn flex items-center gap-2 p-1.5 rounded-lg relative"
               :disabled="preventNav(option)"
              :class="{'active': getSettingsDrawerViewMode === option.viewMode}"
              @click="handleViewModeNavigation(option.viewMode)">
                <component :is="option.icon" class="transition-colors duration-100"
                :class="getSettingsDrawerViewMode === option.viewMode ? 'text-icon-muted' : 'text-icon-normal group-hover:text-icon-muted'"/>
                <BaseText type="label" size="sm" class="text-text-muted">
                  {{ option.name }}
                </BaseText>
                <!-- Disable icon -->
                <div v-if="preventNav(option)" class="absolute my-auto top-0 bottom-0 right-1">
                  <div class="flex items-center gap-2 relative w-full h-full">
                    <!-- Admin tooltip -->
                    <BaseText size="xs" class="opacity-0 transition-all left-0 right-0 mx-auto -top-3 w-max tooltip-text">
                      Admin only
                    </BaseText>
                    <LockedIcon />
                  </div>
                </div>
              </button>
            </div>
            <!-- Logout Button -->
            <button @click="handleLogout" class="group logout-btn flex items-center justify-center gap-1.5 px-2 py-1.5 rounded-md">
              <LogoutIcon class="text-icon-normal transition-colors duration-100 group-hover:text-secondary-red-200" />
              <BaseText type="label" size="sm" class="text-text-muted transition-colors duration-100 group-hover:text-secondary-red-200">
                Log Out
              </BaseText>
            </button>
          </div>
          <!-- Content View -->
          <div class="w-full h-full overflow-y-auto">
            <MyAccountSettings v-if="getSettingsDrawerViewMode === 'account'" />
            <TeamSettings v-if="getSettingsDrawerViewMode === 'team'" />
            <WhiteLabelSettings
              v-if="getSettingsDrawerViewMode === 'whitelabel'"
              :current-team="getTeam"
              :current-user="getUser"
            />
            <AffiliateReferralSettings v-if="getSettingsDrawerViewMode === 'referral'" />
            <PlanSettings v-if="getSettingsDrawerViewMode === 'plan'" @close="$emit('close')"/>
            <BillingSettings v-if="getSettingsDrawerViewMode === 'billing'" />
            <IntegrationSettings v-if="getSettingsDrawerViewMode === 'integrations'" />
            <NotificationSettings v-if="getSettingsDrawerViewMode === 'notifications'" />
          </div>
        </div>
      </div>
    </template>
  </BaseDrawer>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

// VIEW COMPONENTS
import MyAccountSettings from './MyAccountSettings.vue'
import TeamSettings from './team/TeamSettings.vue'
import WhiteLabelSettings from './WhiteLabelSettings.vue'
import AffiliateReferralSettings from './AffiliateReferralSettings.vue'
import PlanSettings from './PlanSettings.vue'
import BillingSettings from './BillingSettings.vue'
import IntegrationSettings from './integration/IntegrationSettings.vue'
import NotificationSettings from './NotificationSettings.vue'

// ICONS
import SettingsIcon from '../globals/Icons/SettingsIcons/SettingsIcon.vue'
import TagRemoveIcon from '../globals/Icons/TagRemoveIcon.vue'
import LogoutIcon from '../globals/Icons/LogoutIcon.vue'
import LockedIcon from '../globals/Icons/LockedIcon.vue'
import * as ViewIcons from '../globals/Icons/SettingsIcons'

export default {
  name: 'SettingsDrawer',
  components: {
    MyAccountSettings,
    TeamSettings,
    WhiteLabelSettings,
    AffiliateReferralSettings,
    PlanSettings,
    BillingSettings,
    IntegrationSettings,
    NotificationSettings,
    SettingsIcon,
    TagRemoveIcon,
    LogoutIcon,
    LockedIcon
  },
  data () {
    const sidebarNavOptions = [
      { name: 'My Account', icon: ViewIcons.MyAccountSettingsIcon, viewMode: 'account' },
      { name: 'Team', icon: ViewIcons.TeamSettingsIcon, viewMode: 'team' },
      { name: 'Whitelabel', icon: ViewIcons.WhitelabelSettingsIcon, viewMode: 'whitelabel' },
      { name: 'Referral', icon: ViewIcons.AffiliateReferralSettingsIcon, viewMode: 'referral' },
      { name: 'Integrations', icon: ViewIcons.IntegrationsSettingsIcon, viewMode: 'integrations' },
      { name: 'Plan', icon: ViewIcons.PlanSettingsIcon, viewMode: 'plan' },
      { name: 'Billing', icon: ViewIcons.BillingSettingsIcon, viewMode: 'billing' },
      // TODO: Notification settings will be hidden until we have notifications to send
      // { name: 'Notifications', icon: ViewIcons.NotificationsSettingsIcon, viewMode: 'notifications' }
    ]
    return {
      sidebarNavOptions
    }
  },
  // watch: {
  //   // When navigating view modes, update the URL
  //   getSettingsDrawerViewMode: {
  //     handler (viewMode) {
  //       console.log('View mode:', viewMode)
  //       if (viewMode) {
  //         const url = new URL(window.location.href)
  //         url.searchParams.set('settings', viewMode)
  //         console.log('URL:', url)
  //         window.history.replaceState({}, '', url)
  //       }
  //     },
  //     immediate: true
  //   }
  // },
  computed: {
    ...mapGetters('MiscModule', ['getSettingsDrawerViewMode']),
    ...mapGetters('AuthModule', ['getUser', 'getTeam', 'isTeamOwner']),
  },
  methods: {
    ...mapActions('AuthModule', ['logoutUser']),
    ...mapMutations('MiscModule', ['SET_SETTINGS_DRAWER_VIEW_MODE']),
    handleViewModeNavigation (viewMode) {
      this.SET_SETTINGS_DRAWER_VIEW_MODE(viewMode)
    },
    // Prevent navigation for the below cases
    preventNav (option) {
      switch (option.viewMode) {
        // If user isn't admin
        case 'billing':
          return this.getTeam ? !this.isTeamOwner : false
        default:
          return false
      }
    },
    handleLogout () {
      console.log('Logging out...')
      this.logoutUser()
    },
  }
}
</script>

<style scoped>
.content-grid {
  display: grid;
  grid-template-columns: 280px 1fr;
  width: 100%;
}
.logout-btn {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  background-color: white;
  transition: box-shadow 100ms ease-in-out, background-color 100ms ease-in-out;
}
.logout-btn:hover {
  box-shadow: none;
  background-color: #FFF0F3;
}
.nav-btn {
  box-shadow: none;
  background-color: transparent;
  transition: box-shadow 100ms ease-in-out, background-color 100ms ease-in-out;
}
.nav-btn:hover:not([disabled]) {
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}
.nav-btn.active, .nav-btn.active:hover {
  box-shadow: none;
  background-color: #F6F8FA; /* bg-neutral-25 */
}
.nav-btn:disabled{
  cursor: default !important;
}
.nav-btn:disabled > *{
  color: #a4abb8 !important;
}

.nav-btn:hover >>> .tooltip-text{
  opacity: 1;
}
</style>
