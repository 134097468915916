
<template>
  <!-- Manage Board Modal -->
  <BaseModal
    :no-exit="true"
    no-toolbar
  >
    <template #default>
      <div>
        <div class="flex px-4 border-b">
          <img
            class="h-14 mr-4"
            src="../../../assets/images/paymentIcon.png"
          >
          <div>
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted w-full"
            >
              <span>Payment Past Due - Action Required</span>
            </BaseText>
            <BaseText
              size="sm"
              class="text-text-normal"
            >
              Your recent subscription payment failed.
            </BaseText>
          </div>
        </div>
      </div>
      <div
        class="px-4"
      >
        <!-- Save Board -->
        <div class="flex flex-col justify-between">
          <div class="flex flex-col items-center justify-center py-7 pb-4 gap-1">
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              Contact Account Admin
            </BaseText>
            <BaseText
              size="sm"
              class="text-text-normal text-center"
            >
              You do not have permission to update payment details. Please contact <span class="font-semibold text-gray-600">{{ teamOwnerEmail }}</span> to regain access.
            </BaseText>
          </div>
          <BaseText size="sm">
            <div
              class="text-center underline cursor-pointer text-text-muted"
              @click="handleLogout"
            >
              Logout of Foreplay
            </div>
          </BaseText>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import firebase from '@/api/config/FirebaseConfig'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TrialEndedModal',
  props: {
    // Board
    board: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      teamOwnerEmail: ''
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUserSubscription', 'getTeam', 'getTeamSubscriptionState'])
  },
  async mounted () {
    await this.getTeamOwnerEmail()
  },
  methods: {
    ...mapActions('AuthModule', ['logoutUser']),
    // Handle Logout User
    async getTeamOwnerEmail () {
      const owner = this.getTeam.created_by

      const teamOwner = await firebase.firestore().collection('auth-users').doc(owner).get()
      this.teamOwnerEmail = teamOwner.data().email || teamOwner.data().firstName
    },
    handleLogout () {
      this.logoutUser()
    }
  }
}
</script>

<style scoped></style>
