<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M10 10.75L7.75 8.5M8.5 2.5H11.5M16 10.75C16 14.0637 13.3137 16.75 10 16.75C6.68629 16.75 4 14.0637 4 10.75C4 7.43629 6.68629 4.75 10 4.75C13.3137 4.75 16 7.43629 16 10.75Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'StopwatchIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>