<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.875 6.8125C2.375 8.10585 2.375 9.26913 2.875 10.5625M12.9961 5.53045L9.73188 7.47814L8.2242 4.77934C7.80787 4.03408 6.88578 3.77874 6.16467 4.20901C5.44356 4.63929 5.19649 5.59224 5.61282 6.3375L7.87434 10.3857L6.97888 10.2447C6.15571 10.1152 5.38676 10.6998 5.26138 11.5506L5.125 12.476L9.20965 15.1386C10.9072 16.2452 13.0582 16.2886 14.7963 15.2515C17.3817 13.7088 18.2676 10.2921 16.7749 7.62012L16.0854 6.38594C15.4609 5.26806 14.0778 4.88504 12.9961 5.53045Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'EngagementIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

    <style scoped></style>
