<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M13.25 4.75H4.75C3.64543 4.75 2.75 5.64543 2.75 6.75V17.25C2.75 18.3546 3.64543 19.25 4.75 19.25H13.25M16.2454 4.75H19.2454C20.35 4.75 21.2454 5.64543 21.2454 6.75V17.25C21.2454 18.3546 20.35 19.25 19.2454 19.25H16.2454M16.2454 4.75V2.5M16.2454 4.75V19.25M16.2454 19.25V21.5"
      :stroke="stroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'DynamicFormatIcon',
  props: {
    width: {
      type: Number,
      default: 24
    },
    height: {
      type: Number,
      default: 24
    },
    stroke: {
      type: String,
      default: '#5E6678'
    }
  }
}
</script>

<style scoped></style>
