<template>
  <div class="w-full p-1">
    <BrandsList
      discovery
      :brands="brands"
      :brand-total="brandTotal"
      :loading-brands="loadingBrands"
      :favorite-filter="favoriteFilter"
      :favorited-brands="favoritedBrands"
      :ininite-id="infiniteId"
      @favorite="favoriteBrand($event)"
      @get-more-brands="getMoreBrands($event)"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import firebase from '@/api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'
import ForeplayAPI from '@/api/foreplayServer'

import BrandsList from '../brands/BrandsList.vue'

export default {
  name: 'DiscoveryBrands',
  components: {
    BrandsList,
  },
  props: {
    favoriteFilter: {
      type: Boolean,
      default: () => false
    },
    textSearch: {
      type: String,
      default: () => ''
    },
    sortOrder: {
      type: Array,
      default: () => [
        {
          name: 'Ads Saved',
          value: 'saved'
        }
      ]
    },
    selectedNiches: {
      type: Array,
      default: () => []
    },
    updateQuery: {
      type: String,
      default: () => null
    }
  },
  data () {
    return {
      brands: [],
      favoritedBrands: [],
      brandTotal: '0',
      loadingBrands: false,
      hoveredPlatformTooltip: null,
      infiniteId: +new Date(),
    }
  },
  computed: {
    ...mapGetters('AdvertisementsModule', ['getAdsBeingSaved']),
    ...mapGetters('AuthModule', [
      'getUser',
      'getUserEmail',
      'getUserName',
      'getTeam'
    ]),
    ...mapGetters('BoardsModule', ['getBoards'])
  },
  watch: {
    updateQuery (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchBrands()
      }
    },
    favoriteFilter (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchBrands()
      }
    },
    selectedNiches (newValue, oldValue) {
      if (newValue[0]?.name !== oldValue[0]?.name) {
        this.fetchBrands()
      }
    }
  },
  mounted () {
    this.fetchBrands()
    this.favoritedBrands = this.getUser.favoritedBrands || []
    this.getBrandsCount()
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_USER']),

    // ================================================================================
    // ================================= DATA METHODS =================================
    // ================================================================================

    async getBrandsCount () {
      const db = firebase.firestore()

      // Get the shards subdocuments
      const snapshot = await db
        .collection('counters')
        .doc('brands')
        .collection('shards')
        .get()

      // Sum the total of all the shards (this is to get around the 1 update per second firebase limitation)
      let totalCount = 0
      snapshot.forEach((doc) => {
        totalCount += doc.data().count
      })

      this.brandTotal = totalCount.toLocaleString(undefined, {
        minimumFractionDigits: 0
      })
    },
    async favoriteBrand (brandId) {
      const favoritedBrands = this.favoritedBrands.includes(brandId)
        ? this.favoritedBrands.filter((bId) => bId !== brandId)
        : [...this.favoritedBrands, brandId]

      // Update the firebase user
      const user = firebase.auth().currentUser
      await FirebaseAPI.Users.update(user.uid, {
        favoritedBrands
      })

      // Add to local state
      this.favoritedBrands = favoritedBrands

      // Update global state
      this.SET_USER({
        ...this.getUser,
        favoritedBrands
      })
    },
    async getMoreBrands ($state) {
      if (!this.lastDocId) {
        $state.complete()
        return
      }

      const { results, nextPage } = await ForeplayAPI.Brands.getDiscoveryBrands(
        this.lastDocId,
        {
          textSearch: this.textSearch.trim(),
          // TODO: Removed until we have better brand sorting options
          // sort: this.textSearch.trim() !== '' ? 'saved' : this.sortOrder[0].value,
          sort: 'saved',
          favorites: this.favoriteFilter,
          orFilters: {
            niches: this.selectedNiches
          }
        }
      )

      this.lastDocId = nextPage

      this.brands = this.brands.concat(results.map(brand => ({
        ...brand,
        ...(brand.publisherPlatforms && {
          publisherPlatforms: brand.publisherPlatforms.filter(
            (platform) => platform !== 'audience_network'
          )
        })
      })))
      $state.loaded()
    },
    async fetchBrands (load = true) {
      this.brands = []
      this.loadingBrands = load

      try {
        const { results, nextPage } =
          await ForeplayAPI.Brands.getDiscoveryBrands(null, {
            textSearch: this.textSearch.trim(),
            // TODO: Removed until we have better brand sorting options
            // sort: this.textSearch.trim() !== '' ? 'saved' : this.sortOrder[0].value,
            sort: 'saved',
            favorites: this.favoriteFilter,
            orFilters: {
              niches: this.selectedNiches
            }
          })

        this.lastDocId = nextPage
        this.brands = results.map(brand => ({
          ...brand,
          ...(brand.publisherPlatforms && {
            publisherPlatforms: brand.publisherPlatforms.filter(platform => platform !== 'audience_network')
          })
        }))

      } catch (e) {
        console.log(e)
      } finally {
        this.loadingBrands = false
        this.infiniteId += 1
      }

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 600)
    },
  }
}
</script>