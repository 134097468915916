var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative flex flex-col",class:[
    { 'h-96 overflow-y-scroll': _vm.advertisement.publisher_platform?.includes('landing page') },
    { 'small-width  mx-auto bg-background-hover': _vm.shrinkMedia },
    { 'relative': ['dco', 'dpa'].includes(_vm.advertisement.type) }
  ],staticStyle:{"min-height":"60px"},style:(_vm.advertisement.publisher_platform?.includes('landing page') ? 'height: 550px;' : '')},[_c('transition',[(_vm.loadingMedia && !_vm.showError)?_c('div',{staticClass:"absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center z-10"},[_c('BaseLoadingLogo')],1):_vm._e()]),_c('transition',[((['dco', 'dpa'].includes(_vm.advertisement.type)) && _vm.showDynamicIndicator && !_vm.loadingMedia)?_c('div',{staticClass:"absolute top-3 left-4 flex items-center gap-1 w-max bg-black bg-opacity-30 z-10 py-1 px-1.5 rounded cursor-none pointer-events-none"},[_c('DynamicFormatIcon',{attrs:{"width":15,"height":15,"stroke":"#fff"}}),_c('BaseText',{staticClass:"text-white",attrs:{"type":"label","size":"xs"}},[_vm._v(" Dynamic Ad ("+_vm._s(_vm.carouselIndex + 1)+"/"+_vm._s(_vm.advertisement.cards.length)+") ")])],1):_vm._e()]),(_vm.advertisement.type === 'image' && !_vm.showError)?_c('img',{ref:"media",staticClass:"w-full transition-opacity duration-100",class:[
      _vm.advertisement.publisher_platform?.includes('landing page') ? '' : 'object-contain',
      { 'rounded-lg': _vm.rounded },
      _vm.loadingMedia ? 'opacity-0' : 'opacity-100'
    ],attrs:{"alt":"Image","src":_vm.advertisement.image},on:{"load":function($event){_vm.loadingMedia = false, _vm.$emit('loaded-content')},"error":function($event){_vm.showError = true}}}):(_vm.showError)?_c('div',{staticClass:"flex flex-col items-center justify-center h-full w-full",class:{'rounded-lg': _vm.rounded},staticStyle:{"min-height":"250px"}},[_c('i',{staticClass:"fas fa-unlink text-2xl mb-2"}),_vm._v(" Broken Media ")]):(_vm.advertisement.type === 'video')?_c('BaseAdvertisementVideoPlayer',{ref:"videoPlayer",staticClass:"transition-opacity duration-100",class:_vm.loadingMedia ? 'opacity-0' : 'opacity-100',attrs:{"advertisement":_vm.advertisement,"rounded":""},on:{"loaded-thumbnail":function($event){_vm.loadingMedia = false, _vm.$emit('loaded-content')},"loaded-content":function($event){_vm.loadingMedia && (_vm.loadingMedia = false), _vm.$emit('loaded-content')},"error":function($event){_vm.showError = true}}}):_c('BaseCarousel',{ref:"carousel",staticClass:"transition-opacity duration-100",class:_vm.loadingMedia ? 'opacity-0' : 'opacity-100',attrs:{"identifier":_vm.advertisement.id,"items":_vm.advertisement.cards,"carousel-index":_vm.carouselIndex,"keep-height-constant":!_vm.inAdDetailsDrawer,"rounded":""},on:{"update:carouselIndex":function($event){_vm.carouselIndex=$event},"update:carousel-index":function($event){_vm.carouselIndex=$event},"loaded-content":function($event){_vm.loadingMedia = false, _vm.$emit('loaded-content')},"error":function($event){_vm.showError = true}},nativeOn:{"mouseenter":function($event){_vm.showDynamicIndicator = true},"mouseleave":function($event){_vm.showDynamicIndicator = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }