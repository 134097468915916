<template>
  <BaseText size="sm">
    {{ formattedCurrency }}
  </BaseText>
</template>

<script>
export default {
  name: 'CurrencyCell',
  props: {
    data: {
      type: Object,
      required: true
    },
    currency: {
      type: String,
      default: 'USD'
    }
  },
  computed: {
    formattedCurrency () {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: this.currency
      }).format(this.data.value)
    }
  }
}
</script>

  <style scoped>
  /* Add any styles you need here */
  </style>
