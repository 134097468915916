<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24" fill="none">
    <rect x="8" y="5" width="8" height="14" rx="1.66667" stroke="currentColor" stroke-width="1.5"/>
  </svg>
</template>

<script>
export default {
  name: 'AspectRatioVerticalIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>