<template>
  <div class="rounded-md py-1 px-1.5 flex items-center gap-1 min-w-0 w-min">
    <div class="w-1 h-1 rounded-full" :class="`${status.state}-tag`" />
    <BaseText class="truncate text-text-muted" size="sm">
      {{ status.text }}
    </BaseText>
  </div>
</template>

<script>
export default {
  name: 'StatusCell',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    status() {
      const active = this.data.value.active
      const inactive = this.data.value.inactive
      const paused = this.data.value.paused
      const total = active + inactive + this.data.value.paused

      if (active !== 0) {
        return { state: 'active', text: `${active} / ${total} Active` }
      }
      if (active === 0 && inactive !== 0 && paused !== 0) {
        return { state: 'inactive', text: 'Inactive' }
      }
      return { state: 'paused', text: 'Paused' }
    }
  }
}
</script>

<style scoped>
.active-tag {
  background-color: #00A879;
}

.inactive-tag {
  background-color: #5E6678 !important;
}

.paused-tag {
  background-color: #FFC107;
}
</style>
