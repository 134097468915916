// Advertisements Module
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    adsBeingSaved: [],
    adToOpen: {},
    adPlaying: null,
    expandedDropdownId: null
  },
  getters: {
    getAdsBeingSaved: state => state.adsBeingSaved,
    areAdsBeingSaved: state => state.adsBeingSaved.filter(ad => ad.status === 'saving').length > 0,
    getAdToOpen: state => state.adToOpen,
    getAdPlaying: state => state.adPlaying,
    getExpandedDropdownId: state => state.expandedDropdownId
  },
  mutations: {
    // Set Ad To Open In Modal
    SET_AD_TO_OPEN (state, ad) {
      state.adToOpen = ad
    },
    // Set Ads Being Saved Mutation
    SET_ADS_BEING_SAVED (state, ads) {
      state.adsBeingSaved = ads
    },
    // Set Ad Status
    SET_AD_STATUS (state, payload) {
      const foundAd = state.adsBeingSaved.find(ad => ad.ad_id === payload.ad_id.toString())

      Vue.set(foundAd, 'status', payload.status)
    },
    // Set Ad Playing
    SET_AD_PLAYING (state, adId) {
      state.adPlaying = adId
    },
    // Set Expanded Dropdown Id
    SET_EXPANDED_DROPDOWN_ID (state, adId) {
      state.expandedDropdownId = adId
    }
  }
}
