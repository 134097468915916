<template>
    <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.68">
            <path
                d="M6.1875 13.625H4.25C3.42157 13.625 2.75 12.9534 2.75 12.125V4.25C2.75 3.42157 3.42157 2.75 4.25 2.75H12.125C12.9534 2.75 13.625 3.42157 13.625 4.25V6.1875"
                :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M11.6875 6.25V17.125M11.6875 17.125H15.625C16.4534 17.125 17.125 16.4534 17.125 15.625V7.75C17.125 6.92157 16.4534 6.25 15.625 6.25H7.75C6.92157 6.25 6.25 6.92157 6.25 7.75V15.625C6.25 16.4534 6.92157 17.125 7.75 17.125H11.6875ZM17.125 13H11.6875M11.6875 10.375H6.25"
                :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
    </svg>

</template>

<script>
export default {
    name: 'CopyBoardIcon',
    props: {
        width: {
            type: Number,
            default: 20
        },
        height: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: "#ffffff"
        }
    }
}
</script>

<style scoped></style>