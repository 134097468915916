<template>
  <svg
    :width="width"
    :Height="height"
    viewBox="0 0 60 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_ii_1_13)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.2008 3.78633C28.3262 1.40456 33.4088 1.40456 37.5342 3.78633L53.0684 12.755C57.1937 15.1368 59.735 19.5385 59.735 24.302V42.2393C59.735 47.0029 57.1937 51.4046 53.0684 53.7863L37.5342 62.755C33.4088 65.1368 28.3262 65.1368 24.2008 62.755L8.66667 53.7863C4.54132 51.4046 2 47.0029 2 42.2393V24.302C2 19.5385 4.54132 15.1368 8.66667 12.755L24.2008 3.78633ZM49.9589 17.8879L34.5342 8.98248C34.3148 8.85584 34.0905 8.74144 33.8623 8.63928V20.0607L40.0289 23.621L49.9589 17.8879ZM27.8623 8.64395C27.6377 8.74492 27.4169 8.85776 27.2008 8.98248L11.6667 17.9511C11.4108 18.0989 11.1659 18.2608 10.9329 18.4355L20.5812 24.0059C20.6548 24.0484 20.726 24.0936 20.7946 24.1413L27.8623 20.0607V8.64395ZM8.0259 23.6853C8.00872 23.8893 8 24.095 8 24.302V42.2393C8 42.4448 8.0086 42.649 8.02554 42.8516L17.5732 37.3392C17.668 37.2845 17.7645 37.2356 17.8623 37.1923V29.3455C17.7672 29.3031 17.6734 29.2553 17.5812 29.2021L8.0259 23.6853ZM10.9295 48.1032C11.1635 48.2789 11.4095 48.4417 11.6667 48.5902L27.2008 57.5588C27.4169 57.6836 27.6377 57.7964 27.8623 57.8974V46.6189L20.6789 42.4715C20.6443 42.4934 20.6091 42.5147 20.5732 42.5354L10.9295 48.1032ZM33.8623 57.902C34.0905 57.7999 34.3148 57.6855 34.5342 57.5588L49.9589 48.6534L40.1487 42.9894L33.8623 46.6189V57.902ZM53.5656 43.8075C53.6771 43.2981 53.735 42.7729 53.735 42.2393V24.302C53.735 23.7684 53.6771 23.2431 53.5656 22.7338L43.8198 28.3605C43.8479 28.5868 43.8623 28.8163 43.8623 29.0478V37.6318C43.8623 37.8191 43.8529 38.0051 43.8344 38.1892L53.5656 43.8075ZM23.8623 29.2983L30.8623 25.2569L37.8623 29.2983V37.3812L30.8623 41.4227L23.8623 37.3812V29.2983Z"
        fill="white"
        fill-opacity="0.12"
      />
    </g>
    <path
      d="M8.625 41.7838V24.7529L17.2373 29.7252V36.8115L8.625 41.7838ZM34.4873 19.6999V9.6771L48.7089 17.8879L40.0289 22.8993L34.4873 19.6999ZM12.1303 18.4051L27.2373 9.68312V19.6999L20.8051 23.4135L12.1303 18.4051ZM27.2373 56.8582L12.1264 48.1339L20.6814 43.1946L27.2373 46.9797V56.8582ZM48.7089 48.6534L34.4873 56.8642V46.9797L40.1487 43.7111L48.7089 48.6534ZM53.11 42.2393C53.11 42.4305 53.1019 42.6204 53.0859 42.8088L44.4837 37.8424C44.4861 37.7724 44.4873 37.7022 44.4873 37.6318V29.0478C44.4873 28.9322 44.4841 28.817 44.4777 28.7024L53.0859 23.7324C53.1019 23.9208 53.11 24.1108 53.11 24.302V42.2393ZM37.8467 3.24506C33.528 0.751646 28.2071 0.751646 23.8883 3.24506L8.35417 12.2137C4.03544 14.7071 1.375 19.3152 1.375 24.302V42.2393C1.375 47.2262 4.03544 51.8342 8.35417 54.3276L23.8883 63.2963C28.2071 65.7897 33.528 65.7897 37.8467 63.2963L53.3809 54.3276C57.6996 51.8342 60.36 47.2262 60.36 42.2393V24.302C60.36 19.3152 57.6996 14.7071 53.3809 12.2137L37.8467 3.24506ZM24.4873 37.0204V29.6592L30.8623 25.9786L37.2373 29.6592V37.0204L30.8623 40.701L24.4873 37.0204Z"
      stroke="black"
      stroke-opacity="0.12"
      stroke-width="1.25"
      stroke-linejoin="round"
    />
    <mask
      id="mask0_1_13"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="2"
      y="2"
      width="58"
      height="63"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.2008 3.78633C28.3262 1.40456 33.4088 1.40456 37.5342 3.78633L53.0684 12.755C57.1937 15.1368 59.735 19.5385 59.735 24.302V42.2393C59.735 47.0029 57.1937 51.4046 53.0684 53.7863L37.5342 62.755C33.4088 65.1368 28.3262 65.1368 24.2008 62.755L8.66667 53.7863C4.54132 51.4046 2 47.0029 2 42.2393V24.302C2 19.5385 4.54132 15.1368 8.66667 12.755L24.2008 3.78633ZM49.9589 17.8879L34.5342 8.98248C34.3148 8.85584 34.0905 8.74144 33.8623 8.63928V20.0607L40.0289 23.621L49.9589 17.8879ZM27.8623 8.64395C27.6377 8.74492 27.4169 8.85776 27.2008 8.98248L11.6667 17.9511C11.4108 18.0989 11.1659 18.2608 10.9329 18.4355L20.5812 24.0059C20.6548 24.0484 20.726 24.0936 20.7946 24.1413L27.8623 20.0607V8.64395ZM8.0259 23.6853C8.00872 23.8893 8 24.095 8 24.302V42.2393C8 42.4448 8.0086 42.649 8.02554 42.8516L17.5732 37.3392C17.668 37.2845 17.7645 37.2356 17.8623 37.1923V29.3455C17.7672 29.3031 17.6734 29.2553 17.5812 29.2021L8.0259 23.6853ZM10.9295 48.1032C11.1635 48.2789 11.4095 48.4417 11.6667 48.5902L27.2008 57.5588C27.4169 57.6836 27.6377 57.7964 27.8623 57.8974V46.6189L20.6789 42.4715C20.6443 42.4934 20.6091 42.5147 20.5732 42.5354L10.9295 48.1032ZM33.8623 57.902C34.0905 57.7999 34.3148 57.6855 34.5342 57.5588L49.9589 48.6534L40.1487 42.9894L33.8623 46.6189V57.902ZM53.5656 43.8075C53.6771 43.2981 53.735 42.7729 53.735 42.2393V24.302C53.735 23.7684 53.6771 23.2431 53.5656 22.7338L43.8198 28.3605C43.8479 28.5868 43.8623 28.8163 43.8623 29.0478V37.6318C43.8623 37.8191 43.8529 38.0051 43.8344 38.1892L53.5656 43.8075ZM23.8623 29.2983L30.8623 25.2569L37.8623 29.2983V37.3812L30.8623 41.4227L23.8623 37.3812V29.2983Z"
        fill="white"
        fill-opacity="0.12"
      />
    </mask>
    <g mask="url(#mask0_1_13)">
      <g filter="url(#filter1_f_1_13)">
        <circle
          cx="15.25"
          cy="9.25"
          r="8.25"
          fill="white"
        />
      </g>
      <g filter="url(#filter2_f_1_13)">
        <circle
          cx="15.25"
          cy="9.25"
          r="8.25"
          fill="white"
        />
      </g>
      <g filter="url(#filter3_f_1_13)">
        <circle
          cx="15.25"
          cy="9.25"
          r="8.25"
          fill="white"
        />
      </g>
      <g filter="url(#filter4_f_1_13)">
        <circle
          cx="51.25"
          cy="16.25"
          r="8.25"
          fill="white"
        />
      </g>
      <g filter="url(#filter5_f_1_13)">
        <circle
          cx="20.75"
          cy="35.75"
          r="8.25"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_ii_1_13"
        x="0.75"
        y="0.75"
        width="60.235"
        height="69.0413"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite
          in2="hardAlpha"
          operator="arithmetic"
          k2="-1"
          k3="1"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1_13"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite
          in2="hardAlpha"
          operator="arithmetic"
          k2="-1"
          k3="1"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_1_13"
          result="effect2_innerShadow_1_13"
        />
      </filter>
      <filter
        id="filter1_f_1_13"
        x="1"
        y="-5"
        width="28.5"
        height="28.5"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="3"
          result="effect1_foregroundBlur_1_13"
        />
      </filter>
      <filter
        id="filter2_f_1_13"
        x="-23"
        y="-29"
        width="76.5"
        height="76.5"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="15"
          result="effect1_foregroundBlur_1_13"
        />
      </filter>
      <filter
        id="filter3_f_1_13"
        x="-23"
        y="-29"
        width="76.5"
        height="76.5"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="15"
          result="effect1_foregroundBlur_1_13"
        />
      </filter>
      <filter
        id="filter4_f_1_13"
        x="37"
        y="2"
        width="28.5"
        height="28.5"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="3"
          result="effect1_foregroundBlur_1_13"
        />
      </filter>
      <filter
        id="filter5_f_1_13"
        x="6.5"
        y="21.5"
        width="28.5"
        height="28.5"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="3"
          result="effect1_foregroundBlur_1_13"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'MetaMetricsIcon',
  props: {
    width: {
      type: Number,
      default: 60
    },
    height: {
      type: Number,
      default: 60
    }
  }
}
</script>

<style scoped></style>
