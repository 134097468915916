<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M6.8125 6.8125V5.3125C6.8125 4.48407 7.48407 3.8125 8.3125 3.8125H14.6875C15.5159 3.8125 16.1875 4.48407 16.1875 5.3125V11.695C16.1875 12.5234 15.5159 13.195 14.6875 13.195H13.1875M3.8125 8.3125V14.6875C3.8125 15.5159 4.48407 16.1875 5.3125 16.1875H11.6875C12.5159 16.1875 13.1875 15.5159 13.1875 14.6875V8.3125C13.1875 7.48407 12.5159 6.8125 11.6875 6.8125H5.3125C4.48407 6.8125 3.8125 7.48407 3.8125 8.3125Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'StaticCopyIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>