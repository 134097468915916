<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      id="Vector"
      d="M4.66673 15.3332L7.33339 12.6666M4.97486 10.308L9.69193 15.0251C10.4196 15.7528 11.6623 15.4168 11.9242 14.4216L12.8465 10.9167C12.9441 10.5458 13.1967 10.2349 13.5397 10.0634L15.3455 9.16057C16.1595 8.75357 16.3355 7.66868 15.692 7.02516L12.9748 4.30798C12.3313 3.66446 11.2464 3.84051 10.8394 4.6545L9.93653 6.46029C9.76506 6.8033 9.45413 7.05584 9.08326 7.15344L5.57835 8.0758C4.58317 8.33768 4.24721 9.58037 4.97486 10.308Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

  </svg>
</template>

<script>
export default {
  name: 'PinIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
