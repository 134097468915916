<template>
  <div>
    <BaseText
      type="label"
      size="md"
      class="text-text-loud my-5"
    >
      2. Add-ons
    </BaseText>
    <ProductCard
      main-text="Team Members"
      :sub-text="`$20 each (${freeSeats} included)`"
      :label="teamMembers > freeSeats ? 'Users' : '(Free)'"
      :maximum-selectable-index="getTeam?.members?.length - 1"
      :current-quantity="teamMembers"
      :items="getEveryNumberFromOneToFifty().map((number) => ({ name: number > freeSeats ? `${number} Users` : `${number} (Free)`, id: number, quantity: number, value: 20 }))"
      additional-info
      @update-quantity="(newQuantity) => { $emit('updateTeamMembers', newQuantity[0].id) }"
    >
      <template #icon>
        <div
          class="h-full border-r border-border-normal w-max mr-1"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="mx-1 h-full "
          >
            <g clip-path="url(#clip0_11808_13683)">
              <path
                d="M9.69978 8.48923C11.6587 7.67562 14.0925 8.70754 14.8467 11.585C15.0305 12.2863 14.4429 12.9077 13.718 12.9077H10.8404M7.40288 4.62646C7.40288 5.74829 6.49346 6.65771 5.37163 6.65771C4.2498 6.65771 3.34038 5.74829 3.34038 4.62646C3.34038 3.50464 4.2498 2.59521 5.37163 2.59521C6.49346 2.59521 7.40288 3.50464 7.40288 4.62646ZM13.0279 4.62646C13.0279 5.74829 12.1185 6.65771 10.9966 6.65771C9.8748 6.65771 8.96538 5.74829 8.96538 4.62646C8.96538 3.50464 9.8748 2.59521 10.9966 2.59521C12.1185 2.59521 13.0279 3.50464 13.0279 4.62646ZM8.00363 12.9077H2.65034C1.92538 12.9077 1.33827 12.283 1.52243 11.5818C2.69963 7.09967 7.95434 7.09967 9.13155 11.5818C9.31571 12.283 8.72859 12.9077 8.00363 12.9077Z"
                stroke="#5E6678"
                stroke-width="0.9375"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_11808_13683">
                <rect
                  width="15"
                  height="15"
                  fill="white"
                  transform="translate(0.683594 0.251465)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </template>
      <template #cardIcon>
        <div
          class="h-full w-max ml-3.5 my-auto"
        >
          <img
            src="../../../assets/images/team-product.svg"
            alt=""
          >
        </div>
      </template>
      <template #additionalInfo>
        <div>
          <BaseText
            type="body"
            size="sm"
            class="text-text-normal"
          >
            Users Added:
          </BaseText>
          <!-- Container for team memberEmails -->
          <div class="flex justify-start gap-1 flex-wrap mt-2">
            <BaseBadge
              v-for="(email, index) in teamEmails"
              :key="index"
              big
              gray
            >
              <BaseText
                type="body"
                size="sm"
                class="text-text-neutral-900"
              >
                {{ email }}
              </BaseText>
            </BaseBadge>
            <!-- For additional team members -->
            <BaseBadge
              v-if="teamMembers - teamEmails.length > 0"
              big
              gray
            >
              <BaseText
                type="label"
                size="xs"
                class="text-text-neutral-25 font-medium"
              >
                +{{ `${teamMembers - teamEmails.length}` }} Seat{{ teamMembers - teamEmails.length > 1 ? 's' : '' }}
              </BaseText>
            </BaseBadge>
          </div>
        </div>
      </template>
    </ProductCard>
    <ProductCard
      main-text="Spyder Brands"
      sub-text="Track every ad your competitors launch"
      class="mt-4"
      :label="spyderPackage[0].quantity > 2 ? 'Brands' : '(Free)'"
      :current-quantity="spyderPackage[0].quantity"
      :items="spyderSubscriptions"
      @update-quantity="(newQuantity) => { $emit('updateSpyders', newQuantity) }"
    >
      <template #icon>
        <div
          class="h-full border-r border-border-normal w-max mr-1"
        >
          <img
            src="../../../assets/icons/spyder-icon.svg"
            alt=""
            class="h-full p-1.5 opacity-60"
          >
        </div>
      </template>
      <template #cardIcon>
        <div
          class="h-full w-max ml-3.5 my-auto"
        >
          <img
            src="../../../assets/icons/spyder.png"
            class="h-9"
          >
        </div>
      </template>
    </ProductCard>
  </div>
</template>

<script>
import ProductCard from './ProductCard.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ProductSelection',
  components: {
    ProductCard
  },
  props: {
    selectedSubscription: {
      type: Object,
      default: () => ({})
    },
    subscriptions: {
      type: Array,
      default: () => []
    },
    spyderPackage: {
      type: Array,
      default: () => []
    },
    teamEmails: {
      type: Array,
      default: () => []
    },
    teamMembers: {
      type: Number,
      default: 2
    }
  },
  data () {
    return {
      loadingSubscriptions: false,
      spyderSubscriptions: []
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getTeam']),
    freeSeats () {
      return this.selectedSubscription?.price.tiers[0].up_to || 2
    }
  },
  mounted () {
    // Development data
    this.spyderSubscriptions = [
      {
        name: '2 Brands',
        id: 'no_price',
        formattedPrice: 'Free',
        quantity: 2,
        value: 0
      },
      {
        name: '10 Brands',
        id: 'price_1NpudLAQtq0TW7KZbBqLvDiO',
        // id: 'price_1O2caIAQtq0TW7KZSAeX2df6', // dev
        formattedPrice: '+$99/mo',
        quantity: 10,
        value: 99,

        annualId: 'price_1PMXJqAQtq0TW7KZFrYjI0Dn',
        // annualId: 'price_1OtC1VAQtq0TW7KZi8rIkYGv', // dev
        annualFormattedPrice: '+$1,069/yr',
        annualValue: 1069
      },
      {
        name: '20',
        id: 'price_1Npue6AQtq0TW7KZblNmFnGu',
        // id: 'price_1O2caHAQtq0TW7KZRJhU1vb5', // dev
        formattedPrice: '+$149/mo',
        offerText: 'Save 25%',
        quantity: 20,
        value: 149,

        annualId: 'price_1PMXLBAQtq0TW7KZsLTwDRFn',
        // annualId: 'price_1OtC85AQtq0TW7KZcDfx8zz2', // dev
        annualFormattedPrice: '+$1,609/yr',
        annualValue: 1609
      },
      {
        name: '30',
        id: 'price_1NpufDAQtq0TW7KZ6c4ndclX',
        // id: 'price_1O2caGAQtq0TW7KZgEGPBFqs', // dev
        formattedPrice: '+$199/mo',
        offerText: 'Save 30%',
        quantity: 30,
        value: 199,

        annualId: 'price_1PMXMIAQtq0TW7KZiKxiCJgc',
        // annualId: 'price_1OtC90AQtq0TW7KZkNWouIzg', // dev
        annualFormattedPrice: '+$2149/yr',
        annualValue: 2149
      },
      {
        name: '50',
        id: 'price_1NpuheAQtq0TW7KZpK9e6t06',
        // id: 'price_1O2caFAQtq0TW7KZGM3FKcr1', // dev
        formattedPrice: '+$249/mo',
        offerText: 'Save 50%',
        quantity: 50,
        value: 249,

        annualId: 'price_1PMXj5AQtq0TW7KZj9duxBru',
        // annualId: 'price_1OEzDbAQtq0TW7KZhxd3uyTH', // dev
        annualFormattedPrice: '+$2,689/yr',
        annualValue: 2689
      }
    ]

    // Get the team members and add them into additional info
  },
  methods: {
    getEveryNumberFromOneToFifty () {
      return Array.from({ length: 50 }, (_, i) => i + 1)
    }
  }
}
</script>

<style scoped>
.custom-rounding {
  border-radius: 10px;
}
</style>
