<template>
  <div class="w-full flex flex-col p-6">
    <div class="flex flex-col mx-auto w-full" style="max-width: 900px">
      <!-- Header/Title -->
      <div class="flex flex-col gap-1 mt-6">
        <BaseText type="label" size="md" class="text-text-muted">
          Integration Settings
        </BaseText>
        <BaseText type="body" size="sm" class="text-text-normal">
          Connect your Foreplay account to the tools you love.
        </BaseText>
      </div>
      <!-- divider -->
      <div class="w-full h-px my-5 bg-neutral-50" />
      <!-- FOREPLAY API -->
      <div class="w-full flex items-center gap-3 p-2 rounded-xl border border-border-normal">
        <img src="../../../assets/images/foreplay-api-logo.png" class="w-8 h-8">
        <BaseText type="label" size="sm" class="text-text-muted">
          Foreplay API
        </BaseText>
        <BaseText type="label" size="xs" class="px-2 py-0.5 bg-primary-blue-10 text-primary-blue-300 rounded-2xl">
          BETA
        </BaseText>
        <button class="group ml-auto px-2.5 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-25"
        @click="showIntercom">
          <BaseText type="label" size="sm" class="text-text-muted transition-colors duration-100 group-hover:text-text-loud">
            Request Access
          </BaseText>
        </button>
      </div>
      <!-- INSTAGRAM -->
      <div class="w-full flex flex-col gap-2 p-2 mt-2 rounded-xl border border-border-normal">
        <div class="flex items-center gap-3.5">
          <img src="../../../assets/images/instagram-small.png" class="w-6 h-6 mx-0.5">
          <BaseText type="label" size="sm" class="text-text-muted">
            Instagram
          </BaseText>
          <button class="group ml-auto px-2.5 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-25"
          @click="showIgModal = true">
            <BaseText type="label" size="sm" class="text-text-muted transition-colors duration-100 group-hover:text-text-loud">
              Edit Connection
            </BaseText>
          </button>
        </div>
        <!-- Connections Table -->
        <div class="w-full flex flex-col">
          <!-- Header -->
          <div class="connections-grid w-full px-3 py-1 rounded-md bg-neutral-25 text-text-normal">
            <BaseText type="heading" size="overline">USER</BaseText>
            <BaseText type="heading" size="overline">STATUS</BaseText>
            <BaseText type="heading" size="overline">IG HANDLE</BaseText>
            <BaseText ref="linked-boards-container" type="heading" size="overline">LINKED BOARDS</BaseText>
          </div>
          <!-- User Connections -->
          <div v-if="!igIntegrationUsers.length" class="w-full flex flex-col gap-2 p-2">
            <div v-for="n in getTeam?.members?.length || 1" :key="`skele-${n}`" class="skeleton w-full rounded-md h-7 opacity-70"/>
          </div>
          <IGAccountRow v-for="(user, index) in igIntegrationUsers" :key="`ig-user-${index}`" :user="user" />
        </div>
      </div>
      <!-- NOTION -->
      <!-- TODO: Will implement Notion embedding in the future -->
      <!-- <div class="w-full flex items-center gap-3.5 p-2 mt-2 rounded-xl border border-border-normal">
        <img src="../../../assets/images/notion-logo.png" class="w-6 h-6 mx-0.5">
        <BaseText type="label" size="sm" class="text-text-muted">
          Embed in Notion
        </BaseText>
        <button class="group ml-auto px-2.5 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-25">
          <BaseText type="label" size="sm" class="text-text-muted transition-colors duration-100 group-hover:text-text-loud">
            View Tutorial
          </BaseText>
        </button>
      </div> -->
    </div>
    <!-- Connect Instagram Modal -->
    <ConnectInstagramModal 
      v-if="showIgModal" 
      @close="showIgModal = false"
      @refresh="initializeIgUsers"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import FirebaseAPI from '@/api/firebase'

import IGAccountRow from './IGAccountRow.vue'
import ConnectInstagramModal from './ConnectInstagramModal.vue'

export default {
  name: 'IntegrationSettings',
  components: {
    IGAccountRow,
    ConnectInstagramModal
  },
  data () {
    return {
      igIntegrationUsers: [],
      showIgModal: false
    }
  },
  mounted () {
    this.initializeIgUsers()
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser', 'getTeam']),
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_USER']),
    async initializeIgUsers () {
      this.igIntegrationUsers = []
      // We need to refetch the user object in case the verification status has changed
      const currentUserData = await FirebaseAPI.Users.getById(this.getUser.user_id)
      this.SET_USER({ ...this.getUser, ...currentUserData })
      if (this.getTeam) {
        const teamMembers = await FirebaseAPI.Teams.getTeamMembers()
        // Put the current user first in the list
        this.igIntegrationUsers = [
          this.getUser,
          ...teamMembers.filter(member => member.user_id !== this.getUser.user_id)
        ]
      } else {
        this.igIntegrationUsers = [this.getUser]
      }
    },
    showIntercom () {
      window.Intercom('show')
    }
  }
}
</script>

<style scoped>
.connections-grid {
  display: grid;
  grid-template-columns: 240px 120px 160px 1fr;
  column-gap: 12px;
}
.fade-in {
  animation: fadeIn 150ms ease-in-out;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
</style>