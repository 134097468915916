<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="138" height="138" viewBox="0 0 138 138" fill="none">
    <circle cx="69" cy="69" r="68.5" transform="rotate(-90 69 69)" stroke="#ECEFF3"/>
    <circle cx="69" cy="69.0003" r="48.1286" transform="rotate(-90 69 69.0003)" stroke="#ECEFF3"/>
    <circle cx="68.9995" cy="68.9996" r="27.1" transform="rotate(-90 68.9995 68.9996)" stroke="#ECEFF3"/>
    <line x1="68.5" y1="138" x2="68.5" y2="1.52369e-05" stroke="#ECEFF3""/>
    <line x1="68.8424" y1="67.0293" x2="68.8424" y2="71.6293" stroke="#ECEFF3"/>
    <line x1="128.296" y1="103.915" x2="8.6965" y2="35.5717" stroke="#ECEFF3"/>
    <line x1="66.7649" y1="68.0299" x2="70.7486" y2="70.3299" stroke="#ECEFF3"/>
    <line x1="128.798" y1="34.8119" x2="9.45812" y2="103.713" stroke="#ECEFF3"/>
    <line x1="67.1166" y1="70.0279" x2="70.7967" y2="67.9032" stroke="#ECEFF3"/>
  </svg>
</template>

<script>
export default {
  name: 'RadarChartBackgroundWheel'
}
</script>

<style scoped></style>