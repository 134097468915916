import Ads from './AdsRequests'
import Brands from './BrandsRequests'
import Screenshots from './ScreenshotsRequests'
import Search from './SearchRequests'
import Users from './UsersRequests'
import Campaigns from './CampaignRequests'
// Lens
import Lens from './lens/LensRequests'
import LensReport from './lens/ReportRequests'
import LensPreset from './lens/PresetRequests'

export default {
  Ads,
  Brands,
  Screenshots,
  Search,
  Users,
  Campaigns,
  Lens,
  LensReport,
  LensPreset
}
