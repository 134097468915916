<template>
  <!-- Draggable Card Container -->
  <div
    :class="{
      'fixed top-0 left-0 w-full h-full': fullScreen
    }"
    :style="fullScreen ? 'z-index: 99999;' : ''"
  >
    <div
      :class="{
        'pt-10': fullScreen
      }"
      class="relative p-4 bg-white border-t mb-4"
    >
      <div
        v-if="fullScreen"
        class="absolute top-2 right-4 text-gray-500 cursor-pointer"
        @click="$emit('exitFullscreen')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          class="h-5 w-5"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
      <draggable
        v-model="storyDuplicate"
        v-bind="dragOptions"
        class="grid grid-cols-3 gap-4"
        @change="emitUpdate"
      >
        <!-- Scene Card -->
        <BaseCard
          v-for="(scene, index) in storyDuplicate"
          :key="index"
          class="h-max rounded-lg bg-white"
          border
        >
          <!-- Card Heading -->
          <div
            class="group p-3 flex justify-between items-center border-b border-neutral-50 border-opacity-80"
          >
            <div class="flex items-center gap-x-2.5">
              <BaseText
                tyle="label"
                size="sm"
                class="text-text-muted p-3 rounded-full border w-6 h-6 flex justify-center items-center"
              >
                {{ index + 1 }}
              </BaseText>
              <BaseText
                tyle="heading"
                size="overline"
              >
                <BaseContentEditable
                  custom-text-classes="text-heading-overline font-medium uppercase"
                  :current-value="scene.scene[0].title"
                  @update="(newValue) => updatePanelSection(index, 'title', newValue)"
                />
              </BaseText>
            </div>

            <!-- Buttons -->
            <div class="flex gap-2 opacity-0 group-hover:opacity-100 transition">
              <!-- Delete Scene -->
              <div
                class="cursor-pointer"
                @click="deletePanel(index)"
              >
                <svg
                  class="text-secondary-red-100"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.69114 16.1483L5.43959 16.1L4.69114 16.1483ZM15.309 16.1483L14.5606 16.1L15.309 16.1483ZM2.29175 4.04102C1.87753 4.04102 1.54175 4.3768 1.54175 4.79102C1.54175 5.20523 1.87753 5.54102 2.29175 5.54102V4.04102ZM17.7084 5.54102C18.1226 5.54102 18.4584 5.20523 18.4584 4.79102C18.4584 4.3768 18.1226 4.04102 17.7084 4.04102V5.54102ZM8.87508 8.95768C8.87508 8.54347 8.5393 8.20768 8.12508 8.20768C7.71087 8.20768 7.37508 8.54347 7.37508 8.95768H8.87508ZM7.37508 13.541C7.37508 13.9552 7.71087 14.291 8.12508 14.291C8.5393 14.291 8.87508 13.9552 8.87508 13.541H7.37508ZM12.6251 8.95768C12.6251 8.54347 12.2893 8.20768 11.8751 8.20768C11.4609 8.20768 11.1251 8.54347 11.1251 8.95768H12.6251ZM11.1251 13.541C11.1251 13.9552 11.4609 14.291 11.8751 14.291C12.2893 14.291 12.6251 13.9552 12.6251 13.541H11.1251ZM12.5021 4.97796C12.6053 5.3791 13.0142 5.62059 13.4154 5.51734C13.8165 5.4141 14.058 5.00521 13.9547 4.60407L12.5021 4.97796ZM3.20997 4.8393L3.9427 16.1966L5.43959 16.1L4.70686 4.74273L3.20997 4.8393ZM6.35435 18.4577H13.6458V16.9577H6.35435V18.4577ZM16.0575 16.1966L16.7902 4.8393L15.2933 4.74273L14.5606 16.1L16.0575 16.1966ZM16.0417 4.04102H3.95841V5.54102H16.0417V4.04102ZM2.29175 5.54102H3.95841V4.04102H2.29175V5.54102ZM16.0417 5.54102H17.7084V4.04102H16.0417V5.54102ZM13.6458 18.4577C14.9201 18.4577 15.9754 17.4682 16.0575 16.1966L14.5606 16.1C14.5295 16.5824 14.1292 16.9577 13.6458 16.9577V18.4577ZM3.9427 16.1966C4.02474 17.4682 5.08007 18.4577 6.35435 18.4577V16.9577C5.871 16.9577 5.47071 16.5824 5.43959 16.1L3.9427 16.1966ZM7.37508 8.95768V13.541H8.87508V8.95768H7.37508ZM11.1251 8.95768V13.541H12.6251V8.95768H11.1251ZM10.0001 3.04102C11.2028 3.04102 12.2153 3.86359 12.5021 4.97796L13.9547 4.60407C13.5015 2.84306 11.9038 1.54102 10.0001 1.54102V3.04102ZM7.49811 4.97796C7.78493 3.86359 8.7974 3.04102 10.0001 3.04102V1.54102C8.0964 1.54102 6.4987 2.84306 6.04545 4.60407L7.49811 4.97796Z"
                    fill="currentColor"
                  />
                </svg>
              </div>

              <div class="border-r border-border-normal h-5" />

              <!-- Drag Icon -->
              <div class="cursor-move">
                <img src="../../../../assets/icons/drag.svg">
              </div>
            </div>
          </div>
          <div
            v-if="showReference"
            class="group relative"
          >
            <div
              v-if="loadingReferences && aiBrief"
              class="flex justify-center"
            >
              <div
                class="flex py-18 h-full w-full justify-center items-center transition duration-500 cursor-pointer"
              >
                <div class="flex flex-col items-center justify-center my-16">
                  <BaseLoadingLogo />
                  <div>
                    <div class="text-gray-600 text-lg">
                      Generating Image...
                    </div>
                    <div class="text-gray-100 text-sm" />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else-if="scene.reference?.length"
              class="p-2 h-72 flex justify-center items-center"
            >
              <div
                style="height: 250px; width: fit-content"
              >
                <div
                  v-if="scene.reference[0].format.includes('video')"
                >
                  <video
                    style="height: 250px"
                    class="rounded"
                    preload="metadata"
                    controls
                  >
                    <source
                      :src="`${getImagePath(scene.reference[0].path)}#t=0.3`"
                      type="video/mp4"
                    >
                  </video>
                  <a
                    class="opacity-0 group-hover:opacity-100 transition duration-500 -mt-px"
                    style="position: absolute; top: 20px; right: 70px; cursor: pointer;"
                    :href="getImagePath(scene.reference[0].path)"
                    target="_blank"
                  >
                    <BaseButton
                      outlined
                      icon-only
                    >
                      <div
                        class="flex items-center justify-center p-px"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.2083 11.6667V14.2083C15.2083 15.1418 15.2083 15.6085 15.0267 15.965C14.8669 16.2786 14.6119 16.5336 14.2983 16.6933C13.9418 16.875 13.4751 16.875 12.5417 16.875H5.79167C4.85825 16.875 4.39154 16.875 4.03502 16.6933C3.72141 16.5336 3.46644 16.2786 3.30666 15.965C3.125 15.6085 3.125 15.1418 3.125 14.2083V7.39583C3.125 6.52132 3.125 6.08407 3.28502 5.74574C3.44988 5.39717 3.7305 5.11655 4.07907 4.95168C4.4174 4.79167 4.85466 4.79167 5.72917 4.79167H7.70833M11.4583 3.125H16.875M16.875 3.125V8.54167M16.875 3.125L9.16667 10.8333"
                            stroke="#5E6678"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </BaseButton>
                  </a>
                  <div
                    v-if="!shared"
                    class="opacity-0 group-hover:opacity-100 transition duration-500"
                    style="position: absolute; top: 20px; right: 30px; cursor: pointer;"
                  >
                    <BaseButton
                      destroy
                      icon-only
                      class=""
                      @click="deleteReference(index)"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.69114 16.1483L5.43959 16.1L4.69114 16.1483ZM15.309 16.1483L14.5606 16.1L15.309 16.1483ZM2.29175 4.04102C1.87753 4.04102 1.54175 4.3768 1.54175 4.79102C1.54175 5.20523 1.87753 5.54102 2.29175 5.54102V4.04102ZM17.7084 5.54102C18.1226 5.54102 18.4584 5.20523 18.4584 4.79102C18.4584 4.3768 18.1226 4.04102 17.7084 4.04102V5.54102ZM8.87508 8.95768C8.87508 8.54347 8.5393 8.20768 8.12508 8.20768C7.71087 8.20768 7.37508 8.54347 7.37508 8.95768H8.87508ZM7.37508 13.541C7.37508 13.9552 7.71087 14.291 8.12508 14.291C8.5393 14.291 8.87508 13.9552 8.87508 13.541H7.37508ZM12.6251 8.95768C12.6251 8.54347 12.2893 8.20768 11.8751 8.20768C11.4609 8.20768 11.1251 8.54347 11.1251 8.95768H12.6251ZM11.1251 13.541C11.1251 13.9552 11.4609 14.291 11.8751 14.291C12.2893 14.291 12.6251 13.9552 12.6251 13.541H11.1251ZM12.5021 4.97796C12.6053 5.3791 13.0142 5.62059 13.4154 5.51734C13.8165 5.4141 14.058 5.00521 13.9547 4.60407L12.5021 4.97796ZM3.20997 4.8393L3.9427 16.1966L5.43959 16.1L4.70686 4.74273L3.20997 4.8393ZM6.35435 18.4577H13.6458V16.9577H6.35435V18.4577ZM16.0575 16.1966L16.7902 4.8393L15.2933 4.74273L14.5606 16.1L16.0575 16.1966ZM16.0417 4.04102H3.95841V5.54102H16.0417V4.04102ZM2.29175 5.54102H3.95841V4.04102H2.29175V5.54102ZM16.0417 5.54102H17.7084V4.04102H16.0417V5.54102ZM13.6458 18.4577C14.9201 18.4577 15.9754 17.4682 16.0575 16.1966L14.5606 16.1C14.5295 16.5824 14.1292 16.9577 13.6458 16.9577V18.4577ZM3.9427 16.1966C4.02474 17.4682 5.08007 18.4577 6.35435 18.4577V16.9577C5.871 16.9577 5.47071 16.5824 5.43959 16.1L3.9427 16.1966ZM7.37508 8.95768V13.541H8.87508V8.95768H7.37508ZM11.1251 8.95768V13.541H12.6251V8.95768H11.1251ZM10.0001 3.04102C11.2028 3.04102 12.2153 3.86359 12.5021 4.97796L13.9547 4.60407C13.5015 2.84306 11.9038 1.54102 10.0001 1.54102V3.04102ZM7.49811 4.97796C7.78493 3.86359 8.7974 3.04102 10.0001 3.04102V1.54102C8.0964 1.54102 6.4987 2.84306 6.04545 4.60407L7.49811 4.97796Z"
                          fill="white"
                        />
                      </svg>
                    </BaseButton>
                  </div>
                </div>
                <img
                  v-else
                  style="height: 250px"
                  class="rounded"
                  :src="getImagePath(scene.reference[0].path)"
                >
                <!-- Don't show this overlay on video -->
                <div
                  v-if="!scene.reference[0].format.includes('video')"
                  class="overlay rounded-md opacity-0 hover:opacity-100 transition duration-300 h-full absolute top-0 right-0 left-0 flex items-center justify-center"
                >
                  <div class="text-white flex gap-2">
                    <a
                      :href="getImagePath(scene.reference[0].path)"
                      target="_blank"
                    >
                      <BaseButton
                        outlined
                        icon-only
                      >

                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.2083 11.6667V14.2083C15.2083 15.1418 15.2083 15.6085 15.0267 15.965C14.8669 16.2786 14.6119 16.5336 14.2983 16.6933C13.9418 16.875 13.4751 16.875 12.5417 16.875H5.79167C4.85825 16.875 4.39154 16.875 4.03502 16.6933C3.72141 16.5336 3.46644 16.2786 3.30666 15.965C3.125 15.6085 3.125 15.1418 3.125 14.2083V7.39583C3.125 6.52132 3.125 6.08407 3.28502 5.74574C3.44988 5.39717 3.7305 5.11655 4.07907 4.95168C4.4174 4.79167 4.85466 4.79167 5.72917 4.79167H7.70833M11.4583 3.125H16.875M16.875 3.125V8.54167M16.875 3.125L9.16667 10.8333"
                            stroke="#5E6678"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>

                      </BaseButton>
                    </a>
                    <div
                      v-if="!shared"
                    >
                      <BaseButton
                        destroy
                        icon-only
                        @click="deleteReference(index)"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.69114 16.1483L5.43959 16.1L4.69114 16.1483ZM15.309 16.1483L14.5606 16.1L15.309 16.1483ZM2.29175 4.04102C1.87753 4.04102 1.54175 4.3768 1.54175 4.79102C1.54175 5.20523 1.87753 5.54102 2.29175 5.54102V4.04102ZM17.7084 5.54102C18.1226 5.54102 18.4584 5.20523 18.4584 4.79102C18.4584 4.3768 18.1226 4.04102 17.7084 4.04102V5.54102ZM8.87508 8.95768C8.87508 8.54347 8.5393 8.20768 8.12508 8.20768C7.71087 8.20768 7.37508 8.54347 7.37508 8.95768H8.87508ZM7.37508 13.541C7.37508 13.9552 7.71087 14.291 8.12508 14.291C8.5393 14.291 8.87508 13.9552 8.87508 13.541H7.37508ZM12.6251 8.95768C12.6251 8.54347 12.2893 8.20768 11.8751 8.20768C11.4609 8.20768 11.1251 8.54347 11.1251 8.95768H12.6251ZM11.1251 13.541C11.1251 13.9552 11.4609 14.291 11.8751 14.291C12.2893 14.291 12.6251 13.9552 12.6251 13.541H11.1251ZM12.5021 4.97796C12.6053 5.3791 13.0142 5.62059 13.4154 5.51734C13.8165 5.4141 14.058 5.00521 13.9547 4.60407L12.5021 4.97796ZM3.20997 4.8393L3.9427 16.1966L5.43959 16.1L4.70686 4.74273L3.20997 4.8393ZM6.35435 18.4577H13.6458V16.9577H6.35435V18.4577ZM16.0575 16.1966L16.7902 4.8393L15.2933 4.74273L14.5606 16.1L16.0575 16.1966ZM16.0417 4.04102H3.95841V5.54102H16.0417V4.04102ZM2.29175 5.54102H3.95841V4.04102H2.29175V5.54102ZM16.0417 5.54102H17.7084V4.04102H16.0417V5.54102ZM13.6458 18.4577C14.9201 18.4577 15.9754 17.4682 16.0575 16.1966L14.5606 16.1C14.5295 16.5824 14.1292 16.9577 13.6458 16.9577V18.4577ZM3.9427 16.1966C4.02474 17.4682 5.08007 18.4577 6.35435 18.4577V16.9577C5.871 16.9577 5.47071 16.5824 5.43959 16.1L3.9427 16.1966ZM7.37508 8.95768V13.541H8.87508V8.95768H7.37508ZM11.1251 8.95768V13.541H12.6251V8.95768H11.1251ZM10.0001 3.04102C11.2028 3.04102 12.2153 3.86359 12.5021 4.97796L13.9547 4.60407C13.5015 2.84306 11.9038 1.54102 10.0001 1.54102V3.04102ZM7.49811 4.97796C7.78493 3.86359 8.7974 3.04102 10.0001 3.04102V1.54102C8.0964 1.54102 6.4987 2.84306 6.04545 4.60407L7.49811 4.97796Z"
                            fill="white"
                          />
                        </svg>
                      </BaseButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else-if="!uploadsLoading.includes(index) && !shared"
              class="h-72"
            >
              <label
                :for="`uploadInput${index}`"
                class="relative w-full h-full flex items-center justify-center "
                @drop.prevent="onDrop($event, index)"
              >
                <!-- Container for upload -->
                <div class="w-64 h-64 border border-dashed border-border-hover hover:bg-background-disabled transition rounded-md flex justify-center cursor-pointer">
                  <!-- Text Container -->
                  <div class="text-center flex flex-col items-center">
                    <!-- Icon -->
                    <div class="mt-20">
                      <img src="../../../../assets/icons/cloud upload.svg">
                    </div>
                    <BaseText
                      class="mt-3 text-text-muted"
                      type="label"
                      size="sm"
                    >
                      Upload reference
                    </BaseText>
                    <!-- Text -->
                    <BaseText
                      class="mt-1 text-text-disabled"
                      type="body"
                      size="sm"
                    >
                      Drag and drop your
                      MP4, PNG, orJPEG file
                    </BaseText>
                  </div>
                </div>

              </label>
              <input
                :id="`uploadInput${index}`"
                type="file"
                hidden
                @change="handleUploadFile($event, index)"
              >
            </div>
            <div
              v-else-if="!shared"
              class="flex justify-center"
            >
              <div
                class="flex py-18 h-full w-full justify-center items-center transition duration-500 cursor-pointer"
              >
                <div class="flex flex-col items-center justify-center my-16">
                  <BaseLoadingLogo />
                  <div>
                    <div class="text-gray-600 text-lg">
                      Uploading your file
                    </div>
                    <div class="text-gray-100 text-sm" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="group relative px-4 py-2 flex items-center flex-wrap gap-y-1.5"
          >
            <div class="w-full flex">
              <img
                src="../../../../assets/icons/voice-icon.svg"
                alt=""
              >
              <BaseText
                tyle="label"
                size="xs"
                class="text-text-muted ml-2"
              >
                Script Copy
              </BaseText>
            </div>
            <BaseContentEditable
              :id="`copy-${index}`"
              :current-value="scene.copy"
              no-padding
              custom-text-classes="text-body-xs font-normal text-text-normal w-full"
              @update="(newValue) => updatePanelSection(index, 'copy', newValue)"
              @click="aiButtonToHide = `copy-${index}`"
              @blur="aiButtonToHide = null"
            />

            <!-- Ai Section -->
            <div
              v-if="aiButtonToHide !== `copy-${index}`"
              class="absolute flex items-center justify-end pr-4 bg-gradient-to-l to-transparent from-white via-white opacity-0 group-hover:opacity-100  pointer-events-none top-0 bottom-0 right-0 w-1/2 h-full transition"
            >
              <!-- AI Modal Button -->
              <div
                class="p-2 pointer-events-auto cursor-pointer hover:bg-background-disabled rounded transition"
                @click="openAiModal(index, 'copy')"
              >
                <img src="../../../../assets/icons/ai-green.svg">
              </div>
            </div>
          </div>
          <div
            class="group relative px-4 py-2 flex items-center flex-wrap gap-y-1.5"
          >
            <div class="w-full flex">
              <img
                src="../../../../assets/icons/bolt-icon.svg"
                alt=""
              >
              <BaseText
                tyle="label"
                size="xs"
                class="text-text-muted ml-2"
              >
                Action Description
              </BaseText>
            </div>
            <BaseContentEditable
              :id="`visuals-${index}`"
              :current-value="scene.visuals"
              no-padding
              custom-text-classes="text-body-xs font-normal text-text-normal w-full"
              @update="(newValue) => updatePanelSection(index, 'visuals', newValue)"
              @click="aiButtonToHide = `action-${index}`"
              @blur="aiButtonToHide = null"
            />

            <!-- Ai Section -->
            <div
              v-if="aiButtonToHide !== `action-${index}`"
              class="absolute flex items-center justify-end pr-4 bg-gradient-to-l to-transparent from-white via-white opacity-0 group-hover:opacity-100  pointer-events-none top-0 bottom-0 right-0 w-1/2 h-full transition"
            >
              <!-- AI Modal Button -->
              <div
                class="p-2 pointer-events-auto cursor-pointer hover:bg-background-disabled rounded transition"
                @click="openAiModal(index, 'visuals')"
              >
                <img src="../../../../assets/icons/ai-green.svg">
              </div>
            </div>
          </div>
          <div
            class="px-4 py-2 mb-2 flex items-center flex-wrap gap-y-1.5"
          >
            <div class="w-full flex">
              <img
                src="../../../../assets/icons/typography-icon.svg"
                alt=""
              >
              <BaseText
                tyle="label"
                size="xs"
                class="text-text-muted ml-2"
              >
                Text Overlay
              </BaseText>
            </div>
            <BaseContentEditable
              class="w-full"
              :current-value="scene.overlay"
              no-padding
              custom-text-classes="text-body-xs font-normal text-text-normal w-full"
              @update="(newValue) => updatePanelSection(index, 'overlay', newValue)"
            />
          </div>
        </BaseCard>
      </draggable>

      <div class="grid grid-cols-3 gap-4">
        <div
          class="flex select-none items-center cursor-pointer h-14 pl-5 mt-5 border border-dashed rounded-lg border-border-hover hover:bg-background-disabled transition"
          @click="$emit('addPanel')"
        >
          <!--  -->
          <img
            src="../../../../assets/icons/plus-icon.svg"
            class="mr-2"
          >
          <BaseText
            class="text-black"
            type="label"
            size="sm"
          >
            Add Scene
          </BaseText>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { nanoid } from 'nanoid'
import firebase from '@/api/config/FirebaseConfig'

import Lottie from 'vue-lottie/src/lottie.vue'
import animationData from '../../../../assets/lotties/loadingV2Green.json'
import BaseContentEditable from '../../../globals/BaseContentEditable.vue'

const events = ['dragenter', 'dragover', 'dragleave', 'drop']

export default {
  name: 'StoryDragView',
  components: {
    draggable,
    lottie: Lottie,
    BaseContentEditable
  },
  props: {
    brief: {
      type: Object,
      default: () => ({})
    },
    shared: {
      type: Boolean
    },
    loadingReferences: {
      type: Boolean
    },
    aiBrief: {
      type: Boolean
    },
    fullScreen: {
      type: Boolean
    },
    // View Settings
    showReference: {
      type: Boolean
    },
    showCopy: {
      type: Boolean
    },
    showActions: {
      type: Boolean
    }
  },
  data () {
    return {
      storyDuplicate: [],
      uploadsLoading: [],
      filesUploading: [],
      filesCompleted: [],
      uploading: false,
      defaultOptions: { animationData: animationData, loop: true, autoplay: true },
      aiButtonToHide: null
    }
  },
  computed: {
    dragOptions () {
      return {
        animation: 400,
        group: 'description',
        disabled: this.shared,
        ghostClass: 'ghost'
      }
    }
  },
  watch: {
    'brief.story': function (newVal, oldVal) {
      if (newVal.length !== oldVal.length) {
        this.storyDuplicate = newVal
      }
    },
    loadingReferences: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.storyDuplicate = this.brief.story
      }
    }
  },
  mounted () {
    this.storyDuplicate = this.brief.story

    events.forEach((eventName) => {
      document.body.addEventListener(eventName, this.preventDefaults)
    })
  },
  methods: {
    openAiModal (index, section) {
      this.$emit('openAiModal', index, section)
    },
    updatedBrief () {
      this.$emit('update', this.storyDuplicate)
    },
    handleKeydown (event) {
      if (event.key === 'Enter' || event.keyCode === 13) {
        event.preventDefault()
      }
    },
    // Upload Methods
    onDrop (e, index) {
      if (!e.dataTransfer.files.length) return
      const fakeEvent = { target: { files: [...e.dataTransfer.files] } }
      this.handleUploadFile(fakeEvent, index)
    },
    preventDefaults (e) {
      e.preventDefault()
    },
    async handleUploadFile (event, index) {
      try {
      // Set upload loading
        this.uploadsLoading = this.uploadsLoading.concat(index)

        const fileToUpload = event.target.files[0]
        const extension = fileToUpload.type.split('/')[1]

        const folderName = nanoid()
        const fileName = nanoid()
        const filePath = `${folderName}/${fileName}.${extension}`

        const storageRef = firebase.storage().ref(filePath)

        const firstFile = fileToUpload
        const savingFile = await storageRef.put(firstFile)

        // Upload is complete
        const reference = {
          path: `https://storage.googleapis.com/foreplay-submissions/${filePath}`,
          format: savingFile.metadata.contentType
        }
        this.updatePanelReference(index, reference)

        this.uploadsLoading = this.uploadsLoading.filter(i => i !== index)

        this.$showAlert({
          message: 'Your image has been uploaded.',
          type: 'success'
        })
      } catch (error) {
        console.error(error)
        this.$showAlert({
          message: 'Something went wrong uploading your image',
          type: 'error'
        })
      }
    },
    updatePanelReference (index, value) {
      const copyArr = [...this.storyDuplicate]

      copyArr[index].reference = [value]

      this.panels = copyArr
      this.emitUpdate()
    },
    deletePanel (index) {
      // Delete previous reference
      this.deleteReference(index, true)

      this.storyDuplicate = this.storyDuplicate.filter((field, i) => i !== index)

      this.emitUpdate()
    },
    getImagePath (referenceString) {
      if (referenceString.includes('.appspot.com')) {
        return referenceString.replace('.appspot.com', '')
      }

      return referenceString
    },
    async deleteReference (index, skipAlert) {
      try {
        // Get the panel to update
        const panel = this.storyDuplicate[index]

        if (!panel?.reference || !panel?.reference[0]?.path) return

        // let referenceUrl
        // // If its using the old reference type
        // if (panel.reference[0].path.includes('appspot.com')) {
        //   referenceUrl = panel.reference[0].path.split('foreplay-submissions.appspot.com/')[1]
        // } else {
        //   referenceUrl = panel.reference[0].path.split('foreplay-submissions/')[1]
        // }

        // // Create a reference to the file to delete
        // var storage = firebase.storage()

        // // Create a storage reference from our storage service
        // var storageRef = storage.ref()
        // var desertRef = storageRef.child(referenceUrl)

        // // Delete the file
        // await desertRef.delete()

        // File deleted successfully
        // Update panel and save new reference
        const copyArr = [...this.storyDuplicate]

        copyArr[index].reference = []

        this.storyDuplicate = copyArr
        this.emitUpdate()

        if (!skipAlert) {
          this.$showAlert({
            message: 'Your image has been deleted.',
            type: 'success'
          })
        }
      } catch (error) {
        console.log(error)
        // Uh-oh, an error occurred!
        this.$showAlert({
          message: 'Something went wrong deleting your image',
          type: 'error'
        })
      }
    },
    updatePanelSection (index, section, value) {
      if (section === 'title') {
        this.storyDuplicate[index].scene[0].title = value
      } else {
        this.storyDuplicate[index][section] = value
      }

      this.emitUpdate()
    },
    emitUpdate () {
      // console.log('emitting story update', this.storyDuplicate)
      if (this.shared) return
      this.$emit('update', this.storyDuplicate)
    }
  }
}
</script>
<style>
.ghost {
  opacity: 0;
}

.overlay {
  background: rgba(7, 32, 69, 0.25);
  backdrop-filter: blur(6px);
}
</style>
