<template>
  <!-- Advertisement List -->
  <div
    ref="list"
  >
    <!-- Ads Table -->
    <transition
      name="fade"
      enter-active-class="transition-opacity duration-500"
      leave-active-class="transition-opacity duration-500"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-if="advertisements.length">
        <div>
          <img
            v-if="!advertisements.length"
            src="../../assets/images/empty-with-filters.gif"
            alt=""
            class="mx-auto mt-10 mb-5 shadow"
          >
          <BaseText
            v-if="!advertisements.length"
            type="body"
            size="sm"
            class="text-center mx-auto mb-10 text-gray-700"
          >
            <span class="text-lg font-bold my-5 block text-black">
              Too many filters
            </span>
            No ads found that meet your current filters
          </BaseText>
          <BaseButton
            v-if="!advertisements.length"
            class="mx-auto mt-10 mb-5 shadow"
            cta
            @click="
              () => {
                clearFilters = true;
              }
            "
          >
            Clear filters
          </BaseButton>
          <!-- Briefs Table -->
          <transition
            name="fade"
            enter-active-class="transition-opacity duration-500"
            leave-active-class="transition-opacity duration-500"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <div v-if="advertisements.length">
              <div>
                <!-- HEADERS -->
                <div class="flex bg-neutral-10 rounded-lg py-3 mb-4">
                  <!-- Bulk Checkbox -->
                  <div class="flex items-center justify-end">
                    <!-- Checkbox -->
                    <input
                      type="checkbox"
                      class="h-4 w-4 ml-2 rounded border-neutral-100 text-primary-blue-100 focus:ring-transparent shadow-sm"
                      :checked="selectedAds.length === advertisements.length"
                      @click.stop="selectAllAds($event)"
                    >
                  </div>

                  <div
                    class="ad-table-header w-full pl-5 pr-3"
                  >
                    <!-- Name -->
                    <div>
                      <BaseText
                        type="body"
                        size="sm"
                        class="text-neutral-400"
                      >
                        Ad
                      </BaseText>
                    </div>

                    <!-- Active Time -->
                    <div>
                      <BaseText
                        type="body"
                        size="sm"
                        class="text-neutral-400 whitespace-nowrap"
                      >
                        Active Time
                      </BaseText>
                    </div>

                    <!-- Boards -->
                    <div>
                      <BaseText
                        type="body"
                        size="sm"
                        class="text-neutral-400"
                      >
                        Boards
                      </BaseText>
                    </div>

                    <!-- Rating -->
                    <div>
                      <BaseText
                        type="body"
                        size="sm"
                        class="text-neutral-400"
                      >
                        Rating
                      </BaseText>
                    </div>

                    <!-- Tags -->
                    <div>
                      <BaseText
                        type="body"
                        size="sm"
                        class="text-neutral-400"
                      >
                        Tags
                      </BaseText>
                    </div>
                  </div>
                </div>

                <!-- ROWS -->
                <transition-group
                  v-if="advertisements.length"
                  name="fade"
                  enter-active-class="transition-opacity duration-200"
                  leave-active-class="transition-opacity duration-200"
                  enter-class="opacity-0"
                  enter-to-class="opacity-100"
                  leave-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <div
                    v-for="(advertisement, index ) of advertisements"
                    :key="advertisement.id"
                  >
                    <BaseDynamicLoader :identifier="advertisement.id" :enableInitialMountFadeIn="false" :buffer="400">
                      <AdvertisementTableViewRow
                        class="flex items-center max-w-full py-1 mb-4 cursor-pointer rounded-lg hover:bg-neutral-10 transition-opacity duration-300"
                        :class="{
                          'bg-neutral-10': selectedAds.includes(
                            advertisement.id
                          )
                        }"
                        :index="advertisements.length - index"
                        :checked="selectedAds.includes(advertisement.id)"
                        :advertisement="advertisement"
                        @toggleSelectedAd="toggleSelectedAd(advertisement.id)"
                        @updateAds="$emit('updateAds', $event)"
                        @removeAds="$emit('removeAds', $event)"
                        @adClicked="handleOpenAdvertisementDetailsDrawer(advertisement)"
                      />
                    </BaseDynamicLoader>
                  </div>
                </transition-group>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>

    <!-- Check if briefs has initially loaded -->
    <div
      v-if="!advertisements.length && !getInitialLoad"
      class="w-full h-96 flex items-center justify-center"
    >
      <BaseLoadingLogo />
    </div>

    <!-- Advertisement Details Drawer -->
    <AdvertisementDetailsDrawer
      v-if="showAdvertisementDetailsDrawer && !trialExpired"
      :all-ads.sync="advertisements"
      :advertisement="selectedAdvertisement"
      :loading="loading"
      @updateAds="$emit('updateAds', $event)"
      @removeAds="$emit('removeAds', $event)"
      @getMoreAds="$emit('getMoreAds')"
      @tagDeleted="$emit('tagDeleted', $event)"
      @update:tableViewTags="forwardTagEdits"
      @close="closeModal"
      @save="$emit('reload')"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import AdvertisementDetailsDrawer from './AdvertisementDetailsDrawer'
import AdvertisementTableViewRow from './AdvertisementTableViewRow.vue'
import FirebaseAPI from '@/api/firebase'

export default {
  name: 'AdvertisementTableView',
  components: {
    AdvertisementDetailsDrawer,
    AdvertisementTableViewRow
  },
  props: {
    // Advertisements
    advertisements: {
      type: Array,
      default: () => []
    },
    sharedBoard: {
      type: Boolean,
      default: () => false
    },
    discovery: {
      type: Boolean,
      default: () => false
    },
    noDrawer: {
      type: Boolean,
      default: () => false
    },
    smallAds: {
      type: Boolean,
      default: () => false
    },
    selectedAds: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      selectedAdvertisement: {},
      showAdvertisementDetailsDrawer: false,
      promptSignup: false,
      currentSize: 0,
      loading: false
    }
  },
  computed: {
    ...mapState('AdvertisementsModule', ['adToOpen']),
    ...mapGetters('AuthModule', ['getUserFreeTrialDate', 'isFreeTier', 'getTeam']),
    trialExpired () {
      // check if a week has passed since trial and if they're free tier
      return this.isFreeTier && Date.now() > this.getUserFreeTrialDate + 6.048e8 && !this.getTeam && !this.discovery
    }
  },
  watch: {
    adToOpen (newValue, oldValue) {
      const newAd =
        this.advertisements.find((a) => a.ad_id === Number(newValue.ad_id)) || {}

      if (Object.keys(newAd).length) {
        this.selectedAdvertisement = newAd
        this.showAdvertisementDetailsDrawer = true
      }
    }
  },
  async mounted () {
    const adId = this.$route.query.ad
    if (!adId) return

    let newAd = this.advertisements.find((a) => a.ad_id === Number(adId))
    if (!newAd) {
      newAd = await FirebaseAPI.Advertisements.get(adId)
    }

    if (Object.keys(newAd).length) {
      this.selectedAdvertisement = newAd
      this.showAdvertisementDetailsDrawer = true
    }
  },
  methods: {
    ...mapMutations('AdvertisementsModule', ['SET_AD_TO_OPEN']),
    closeModal () {
      this.showAdvertisementDetailsDrawer = false
      this.selectedAdvertisement = {}
      this.SET_AD_TO_OPEN({})
    },
    handleOpenAdvertisementDetailsDrawer (advertisement) {
      this.selectedAdvertisement = advertisement
      this.showAdvertisementDetailsDrawer = true
    },
    reflowGrid () {
      this.$refs.feed.reflow()
    },
    selectAllAds (e) {
      if (!e.target.checked) {
        this.selectedAds = []
        this.$emit('update:selectedAds', this.selectedAds)
        return
      }

      this.selectedAds = this.advertisements.map((ad) => ad.id)
      this.$emit('update:selectedAds', this.selectedAds)
    },
    toggleSelectedAd (adId) {
      let selectedAdsCopy = []

      if (this.selectedAds.includes(adId)) {
        selectedAdsCopy = this.selectedAds.filter(
          (id) => id !== adId
        )
      } else {
        selectedAdsCopy = this.selectedAds.concat(adId)
      }

      this.$emit('update:selectedAds', selectedAdsCopy)
    },
    forwardTagEdits (tagId, updatedTag) {
      this.$emit('update:tableViewTags', tagId, updatedTag)
    }
  }
}
</script>

<style scoped>
.ad-table-header {
  display: grid;
  grid-template-columns: 213px 118px 332px 132px 1fr;
  gap: 0.5rem;
  align-items: center;
}
</style>
