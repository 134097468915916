<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 21 20" fill="none">
    <path d="M14.9194 11.2367L15.273 10.8831C16.8351 9.32102 16.8351 6.78836 15.273 5.22626C13.7109 3.66416 11.1782 3.66416 9.61611 5.22626L9.26256 5.57981M11.7374 14.4186L11.3839 14.7722C9.82178 16.3343 7.28912 16.3343 5.72702 14.7722C4.16493 13.2101 4.16493 10.6774 5.72702 9.11535L6.08058 8.7618M12.091 8.40824L8.909 11.5902" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'ShareIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>