<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="0.75"
      width="18.5"
      height="18.5"
      rx="5.25"
      stroke="#00A879"
      style="stroke:#00A879;stroke:color(display-p3 0.0000 0.6600 0.4730);stroke-opacity:1;"
      stroke-width="1.5"
    />
    <path
      d="M6.6665 10.6663L8.6665 12.6663L13.3332 7.33301"
      stroke="#00A879"
      style="stroke:#00A879;stroke:color(display-p3 0.0000 0.6600 0.4730);stroke-opacity:1;"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'SuccessIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

  <style scoped></style>
