<template>
  <div
    class="flex items-start text-gray-400 animate-pulse w-full justify-center"
    :class="[`my-${margin}`, noPadding ? 'p-0' : 'p-4']"
  >
    <lottie
      :options="defaultOptions"
      :height="height"
      :width="width"
    />
  </div>
</template>

<script>
import Lottie from 'vue-lottie/src/lottie.vue'
import animationData from '../../assets/lotties/logoAnimation.json'

export default {
  name: 'BaseLoadingLogo',
  components: {
    lottie: Lottie
  },
  props: {
    small: {
      type: Boolean
    },
    margin: {
      type: Number,
      default: 10
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 40
    },
    height: {
      type: Number,
      default: 40
    }
  },
  data () {
    return {
      defaultOptions: { animationData: animationData, loop: true, autoplay: true }
    }
  },
  computed: {
  }
}
</script>
