// Tags Module
import FirebaseAPI from '@/api/firebase'
import ForeplayAPI from '@/api/foreplayServer'

export default {
  namespaced: true,
  state: {
    tags: []
  },
  getters: {
    // Get Tags
    getTags: state => state.tags
  },
  mutations: {
    SET_TAGS (state, tags) {
      state.tags = tags
    }
  },
  actions: {
    // Fetch Tags
    async fetchTags ({ commit, rootState }) {
      const team = rootState.AuthModule.team
      let userTags
      if (team) {
        userTags = await FirebaseAPI.Tags.getAllByTeam(team.id)
      } else {
        userTags = await FirebaseAPI.Tags.getAllByUser()
      }

      userTags = userTags.sort((a, b) => a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1)

      const adCounts = await ForeplayAPI.Ads.getTagAdCounts()

      // Now you can access the current tags with state.tags
      const updatedTags = userTags.map(tag => ({
        ...tag,
        adCount: adCounts[tag.id] || 0
      }))


      commit('SET_TAGS', updatedTags)
    }
  }
}
