<template>
  <svg
    :width="width"
    :Height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9836 12.7757C15.7932 13.3697 15.4667 13.6667 15.0039 13.6667C14.4189 13.6667 13.8438 13.3157 13.2787 12.6137C12.8862 12.1262 12.3357 11.1997 11.6273 9.83433C11.3233 9.2482 11.0414 8.7306 10.7816 8.2815C10.9879 7.95581 11.172 7.69451 11.3339 7.49761C11.972 6.72143 12.6398 6.33333 13.3372 6.33333C13.8938 6.33333 14.424 6.69056 14.9278 7.40502C15.4455 8.13931 15.8083 9.04627 16.0159 10.1259C16.2201 11.188 16.2094 12.0713 15.9836 12.7757ZM10.0039 9.61973C10.1439 9.87673 10.2906 10.1529 10.4438 10.4483C11.1931 11.8927 11.7919 12.8931 12.2401 13.4499C13.0721 14.4833 13.9933 15 15.0039 15C15.5745 15 16.0627 14.8198 16.4686 14.4595C16.8185 14.1489 17.0801 13.7233 17.2533 13.1827C17.5484 12.2621 17.5724 11.1592 17.3252 9.87413C17.0815 8.60652 16.6455 7.52737 16.0175 6.63665C15.2481 5.54555 14.3547 5 13.3372 5C12.2199 5 11.2087 5.55029 10.3039 6.65085C10.2095 6.76575 10.1095 6.89779 10.0039 7.04697C9.89838 6.89779 9.79838 6.76575 9.70385 6.65085C8.79905 5.55029 7.788 5 6.67065 5C5.65318 5 4.75976 5.54555 3.9904 6.63665C3.36233 7.52737 2.92641 8.60651 2.68264 9.87413C2.4355 11.1592 2.45947 12.2621 2.75454 13.1827C2.92781 13.7233 3.18938 14.1489 3.53924 14.4595C3.94513 14.8198 4.43338 15 5.004 15C6.01456 15 6.93582 14.4833 7.76778 13.4499C8.21596 12.8931 8.81472 11.8927 9.56398 10.4484C9.71732 10.1529 9.86392 9.87673 10.0039 9.61973ZM9.22625 8.28151C8.96645 8.7306 8.68452 9.2482 8.38046 9.83433C7.67208 11.1997 7.12165 12.1262 6.72918 12.6137C6.16408 13.3157 5.58902 13.6667 5.004 13.6667C4.54121 13.6667 4.21462 13.3697 4.02424 12.7757C3.79848 12.0713 3.78772 11.188 3.99198 10.1259C4.1996 9.04627 4.5623 8.13931 5.08007 7.40501C5.58386 6.69056 6.11405 6.33333 6.67065 6.33333C7.36806 6.33333 8.03583 6.72143 8.67398 7.49761C8.83585 7.69451 9.01992 7.95581 9.22625 8.28151Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'MetaMetricsIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
