// Boards Module
import FirebaseAPI from '@/api/firebase'

export default {
  namespaced: true,
  state: {
    // Check if briefs has been loaded the first time (Fixes a glitch on reload in BriefsView)
    initialLoad: false,

    // All briefs
    briefs: [],

    currentEditingBrief: null,

    briefSaving: false,
    

    // Brief being edited
    currentBriefId: null,

    // Trigger the Inspo Banner
    isInspoMode: false,
    isAiInspoMode: false,
    inspoBrief: null,
    inspoBriefFolderName: null,

    // Active Inspo Mode
    inspoAds: [],
    aiInspoAds: []
  },
  getters: {
    // Get all briefs by user or team
    getBriefs: state => {
      return state.briefs
    },
    // Check if first load has happened
    getInitialLoad: state => {
      return state.initialLoad
    },
    getEditingBrief: state => {
      return state.currentEditingBrief
    },
    getBriefSaving: state => {
      return state.briefSaving
    },
    // Get the current brief being edited
    getCurrentBriefId: state => {
      return state.currentBriefId
    },
    // See if Inspo Banner Should Show
    getIsInspoMode: state => {
      return state.isInspoMode
    },
    getIsAiInspoMode: state => {
      return state.isAiInspoMode
    },
    getInspoBrief: state => {
      return state.inspoBrief
    },
    getInspoBriefFolderName: state => {
      return state.inspoBriefFolderName
    },
    // Get Ads to Add to Brief
    getInspoAds: state => {
      console.log('getInspoAds')
      return state.inspoAds
    },
    getAiInspoAds: state => {
      console.log('getAiInspoAds')
      return state.aiInspoAds
    }
  },
  mutations: {
    SET_IS_INSPO_MODE (state, inspoBool) {
      console.log('set is inspo mode', inspoBool)
      state.isInspoMode = inspoBool
    },
    SET_IS_AI_INSPO_MODE (state, inspoBool) {
      console.log('set is inspo mode', inspoBool)
      state.isAiInspoMode = inspoBool
    },
    SET_INITIAL_LOAD (state, loaded = true) {
      console.log('set initial load', loaded)
      state.initialLoad = loaded
    },
    // Set the brief thats being edited
    SET_CURRENT_BRIEF_ID (state, newBriefId) {
      console.log('set current brief id', newBriefId)
      state.currentBriefId = newBriefId
    },
    // The Inspiration to Add to the Brief
    SET_INSPO_ADS (state, newInspoAds) {
      console.log('set inspo ads', newInspoAds)
      state.inspoAds = newInspoAds
    },
    SET_AI_INSPO_ADS (state, newInspoAds) {
      state.aiInspoAds = newInspoAds
    },
    SET_INSPO_BRIEF (state, inspoBrief) {
      console.log('set inspo brief', inspoBrief)
      state.inspoBrief = inspoBrief
    },
    SET_INSPO_BRIEF_FOLDER_NAME (state, inspoBriefFolderName) {
      console.log('set inspo brief folder name', inspoBriefFolderName)
      state.inspoBriefFolderName = inspoBriefFolderName
    },
    SET_BRIEFS (state, briefs) {
      console.log('set briefs', briefs)
      state.briefs = briefs
    },
    SET_CURRENT_EDITING_BRIEF (state, brief){
      console.log('FETCHING EDITING BRIEF', brief)
      state.currentEditingBrief = brief;
    },
    SET_BRIEF_SAVING(state, bool) {
      state.briefSaving = bool;
    },
    // Updates a specific field of the current editing brief
    SET_EDITING_BRIEF_FIELD(state, payload) {
      const {field,value, modifiedAt} = payload;
      console.log('set brief field', field)
      if (state.currentEditingBrief) {
        // Update field and last modified
        state.currentEditingBrief[field] = value;
        state.currentEditingBrief.modifiedAt = modifiedAt;
      }
    },
  },
  actions: {
    // Fetch Boards
    async fetchBriefs ({ commit }) {
      console.log('fetch briefs')
      const userTeam = await FirebaseAPI.Teams.getUserTeam()

      if (userTeam) {
        const briefs = await FirebaseAPI.Briefs.getAllByTeam(userTeam.id)

        const filteredSortedBriefs = briefs.filter(b => !b.archived).sort((a, b) => a.name > b.name ? 1 : -1)
        commit('SET_BRIEFS', filteredSortedBriefs)
        commit('SET_INITIAL_LOAD', true)
        return
      }

      // Get a users briefs
      const briefs = await FirebaseAPI.Briefs.getAllByUser()
      const filteredSortedBriefs = briefs.filter(b => !b.archived).sort((a, b) => a.name > b.name ? 1 : -1)
      commit('SET_BRIEFS', filteredSortedBriefs)
      commit('SET_INITIAL_LOAD', true)
    },
    async fetchEditingBrief({commit}, briefId){
      const brief = await FirebaseAPI.Briefs.getByID(briefId);
      commit('SET_CURRENT_EDITING_BRIEF', brief)
    },
    async updateBriefField({commit, state}, savePayload){
      const modifiedAt = +new Date()
      commit('SET_EDITING_BRIEF_FIELD', {...savePayload, modifiedAt}, modifiedAt);
        const {field, value} = savePayload;

        const payload = {
          [field]: value,
          modifiedAt
        }
      await FirebaseAPI.Briefs.update(state.currentEditingBrief.id, payload)

      commit('SET_BRIEF_SAVING', false)

    }
  }
}
