<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 24 24" fill="none">
    <rect x="5.3335" y="5.33398" width="13.3333" height="13.3333" rx="1.66667" stroke="currentColor" stroke-width="1.5"/>
  </svg>
</template>

<script>
export default {
  name: 'AspectRatioIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>