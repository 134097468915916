<template>
  <div class="p-1 flex items-center justify-between w-full bg-background-normal rounded-lg">
    <!-- Preset info -->
    <SelectedPresetDropdown :selected-preset="getLocalPreset" @handleShowModal="showCreateModal = true"
      @handleSelect="updateCurrentPreset" />
    <!-- Preset actions -->
    <div class="flex items-center gap-1">
      <button class="px-2.5 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-50"
        @click="showEditModal = true">
        <BaseText type="label" size="xs" class="text-text-muted">
          Edit
        </BaseText>
      </button>
      <!-- Divider -->
      <div class="border-l h-3 border-border-hover" />
      <button class="px-2.5 py-1.5 rounded-md transition-colors duration-100"
        :class="!canUpdatePreset ? 'cursor-default bg-neutral-10 text-text-disabled opacity-50' : 'hover:bg-neutral-50 text-text-muted'"
        :disabled="!canUpdatePreset" @click="handlePresetSave(getLocalPreset)">
        <BaseText type="label" size="xs" class="text-text-muted">
          Update Preset
        </BaseText>
      </button>
      <!-- Divider -->
      <div class="border-l border-border-hover h-3" />
      <button class="px-2.5 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-50"
        @click="handleNewDerivedPreset">
        <BaseText type="label" size="xs" class="text-text-muted">
          Save new Preset
        </BaseText>
      </button>
    </div>
    <!-- Modals -->
    <EditPresetModal v-if="showEditModal" key="edit-preset" @close="handleModalClose" @save="handlePresetSave"
      @delete="handlePresetDelete" />
    <EditPresetModal v-if="showCreateModal" key="new-preset" is-new @close="handleModalClose" @save="handleNewPreset" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
import { mixin as clickaway } from 'vue-clickaway2'
import ForeplayAPI from '@/api/foreplayServer'
// Components
import SelectedPresetDropdown from '../SelectedPresetDropdown.vue'
import EditPresetModal from './EditPresetModal.vue'

export default {
  name: 'ManagePresetHeader',
  components: {
    EditPresetModal,
    SelectedPresetDropdown
  },
  mixins: [clickaway],
  data() {
    return {
      showEditModal: false,
      showCreateModal: false,
      isExpanded: false,

      shouldInheritColumns: false
    }
  },
  computed: {
    ...mapGetters('LensModule', ['getLocalPreset', 'getSelectedPreset', 'getPresets']),
    canUpdatePreset() {
      const originalPreset = this.getPresets.find((preset) => preset.id === this.getLocalPreset.id)
      return !_.isEqual(originalPreset?.tableColumns, this.getLocalPreset?.tableColumns)
    }
  },
  methods: {
    ...mapActions('LensModule', ['updatePreset', 'setPresetById', 'fetchPresets']),
    handleModalClose() {
      this.showEditModal = false
      this.showCreateModal = false
      // Reset on close, init on open
      this.shouldInheritColumns = false
    },
    handleNewDerivedPreset() {
      this.shouldInheritColumns = true
      this.showEditModal = true
    },
    updateCurrentPreset(presetId) {
      this.setPresetById({ id: presetId })
    },
    async handleNewPreset(toSave) {
      try {
        const newPreset = {
          ...toSave,
          tableColumns: this.shouldInheritColumns ? this.getLocalPreset?.tableColumns || [] : []
        }
        const data = await ForeplayAPI.LensPreset.create(newPreset)
        const newPresetId = data.presetId
        await this.fetchPresets()
        this.setPresetById({ id: newPresetId })

        this.$showAlert({ type: 'success', message: 'Preset created successfully' })
      } catch (e) {
        this.$showAlert({ type: 'error', message: e.message })
      }
    },
    async handlePresetDelete(presetId) {
      try {
        await ForeplayAPI.LensPreset.remove(presetId)
        await this.fetchPresets()
        if (this.getLocalPreset.id === presetId) {
          this.setPresetById({ id: null })
        }
        this.$showAlert({ type: 'success', message: 'Preset deleted successfully' })
      } catch (e) {
        this.$showAlert({ type: 'error', message: e.message })
      }
    },
    handlePresetSave(toUpdate) {
      console.log('handle EDIT', toUpdate)
      this.updatePreset(toUpdate)
    }
  }
}
</script>

<style scoped>
.preset-tag {
  box-shadow:
    0px 0px 0.25px 0.75px rgba(6, 7, 15, 0.02),
    0px 1.5px 1.5px -0.75px rgba(6, 7, 15, 0.08),
    0px 3px 3px -1.5px rgba(6, 7, 15, 0.04);

}
</style>
