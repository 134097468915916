<template>
  <div class="flex flex-col p-1 rounded-lg bg-neutral-800 shadow-lg w-max">
    <router-link :to="{ name: 'DiscoveryBrandView', params: { id: brandId } }"
    class="group flex items-center gap-2 p-1.5 rounded-md transition-colors hover:bg-neutral-700">
      <img src="../../assets/images/discovery-new.png" class="h-6 w-6" style="border-radius: 4.5px;" />
      <BaseText type="label" size="sm" class="transition text-white text-opacity-90 group-hover:text-opacity-100">
        Community Saved Ads
      </BaseText>
      <BaseText v-if="brandDiscoveryAdCount > 0" type="body" size="sm" class="text-neutral-200 -ml-1 transition-colors group-hover:text-neutral-100">
        ({{ brandDiscoveryAdCount.toLocaleString() }})
      </BaseText>
      <div class="transform -rotate-90 ml-auto">
        <ChevronIcon className="text-neutral-300 transition-colors group-hover:text-white group-hover:text-opacity-90" />
      </div>
    </router-link>
    <router-link v-if="canTrackAsSpyder" :to="{ name: 'SpyderBrandsView', query: { id: brandAdLibraryId }}"
    class="group flex items-center gap-2 p-1.5 rounded-md transition-colors hover:bg-neutral-700">
      <img src="../../assets/icons/spyder.png" class="h-6 w-6" style="border-radius: 4.5px;" />
      <BaseText type="label" size="sm" class="transition text-white text-opacity-90 group-hover:text-opacity-100">
        Track brand in Spyder
      </BaseText>
      <div class="ml-auto transform -rotate-90">
        <ChevronIcon className="text-neutral-300 transition-colors group-hover:text-white group-hover:text-opacity-90" />
      </div>
    </router-link>
  </div>
</template>

<script>
import ChevronIcon from '../globals/Icons/ChevronIcon.vue'

export default {
  name: 'BrandActionsTooltip',
  components: {
    ChevronIcon
  },
  props: {
    brandId: {
      type: String,
      required: true
    },
    brandAdLibraryId: {
      type: String,
      required: true
    },
    brandDiscoveryAdCount: {
      type: Number,
      required: true
    },
    canTrackAsSpyder: {
      type: Boolean,
      required: true
    },
  }
}
</script>