<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.29134 5.62484H12.708M7.29134 8.95817H12.708M7.29134 12.2915H9.37467M5.62467 17.7082H14.3747C15.2951 17.7082 16.0413 16.962 16.0413 16.0415V3.95817C16.0413 3.0377 15.2951 2.2915 14.3747 2.2915H5.62467C4.7042 2.2915 3.95801 3.0377 3.95801 3.95817V16.0415C3.95801 16.962 4.7042 17.7082 5.62467 17.7082Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'DocumentIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>