<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M14.5009 15.25H16.0007C16.8706 15.25 17.5822 14.4986 17.316 13.6705C16.5825 11.3893 14.6925 10.4445 13.001 10.836M9.25098 6.25C9.25098 7.49264 8.24365 8.5 7.001 8.5C5.75836 8.5 4.751 7.49264 4.751 6.25C4.751 5.00736 5.75836 4 7.001 4C8.24365 4 9.25098 5.00736 9.25098 6.25ZM15.626 6.625C15.626 7.66053 14.7865 8.5 13.751 8.5C12.7155 8.5 11.876 7.66053 11.876 6.625C11.876 5.58947 12.7155 4.75 13.751 4.75C14.7865 4.75 15.626 5.58947 15.626 6.625ZM9.89155 16H4.11046C3.2405 16 2.5341 15.2496 2.76787 14.4117C4.12993 9.52945 9.87205 9.52945 11.2341 14.4117C11.4679 15.2496 10.7615 16 9.89155 16Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'B2CIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>