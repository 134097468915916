<template>
  <div class="border-border-normal">
    <div :class="{ 'h-5 mb-1': showLabelSpace }">
      <BaseText
        type="label"
        size="sm"
      >
        {{ label }}
      </BaseText>
    </div>
    <div class="relative flex-grow">
      <button
        class="flex items-center gap-1 w-full pl-1.5 pr-3 py-1.5 border border-border-normal flex-nowrap"
        :class="{
          'rounded-t-lg': expanded,
          'rounded-lg': !expanded,
          [triggerClass]: triggerClass
        }"
        :style="minWidth"
        @click="toggleDropdown"
      >
        <slot
          name="icon"
          class="text-icon-normal mr-2 flex-shrink-0"
        />
        <BaseText
          v-if="selectedItem"
          type="body"
          size="sm"
          class="flex-grow text-text-muted truncate text-left mr-2"
        >
          {{ selectedLabel(selectedItem) }}
        </BaseText>
        <BaseText
          v-else
          type="body"
          size="sm"
          :class="expanded ? 'text-neutral-200' : 'text-neutral-500'"
          class="flex-grow placeholder truncate text-left mr-2 transition-colors"
        >
          {{ placeholder }}
        </BaseText>
        <div
          class="transform transition-transform flex-shrink-0"
          :style="{ transform: expanded ? 'scaleY(-1)' : '' }"
        >
          <ChevronIcon class="text-icon-normal" />
        </div>
      </button>
      <div
        v-on-clickaway="() => { expanded = false }"
        class="dropdown-container rounded-b-lg"
      >
        <div
          class="dropdown flex flex-col gap-1 px-1 rounded-b-lg bg-white cursor-pointer overflow-y-scroll scrollbar-hide"
          :class="{ expanded: expanded }"
          :style="{ height: expanded ? `${Math.min(dropdownOptions.length * 36 + 4, 203)}px` : '0px' }"
        >
          <button
            v-for="(option, index) in dropdownOptions"
            :key="`dropdown-option-${index}`"
            class="group flex items-center justify-between rounded-lg px-2 py-1.5 transition duration-100 hover:bg-neutral-10 whitespace-nowrap"
            :class="{
              'mt-1': index === 0,
              'mb-1': index === options.length - 1,
              'opacity-100': expanded,
              'opacity-0': !expanded,
              'bg-neutral-10': selectedItem && selectedItem[optionKey] === option[optionKey]
            }"
            @click="updateSelectedItem(option)"
          >
            <slot
              name="option-icon"
              :option="option"
              class="transition-colors text-neutral-400 group-hover:text-icon-normal mr-2 flex-shrink-0"
            />
            <BaseText
              type="body"
              size="sm"
              class="text-text-muted flex-grow truncate text-left"
            >
              {{ optionLabel(option) }}
            </BaseText>
            <div
              v-if="selectedItem && selectedItem[optionKey] === option[optionKey]"
              class="text-icon-normal"
            >
              <CheckmarkIcon />
            </div>
          </button>
        </div>
        <div
          v-if="options.length > 5"
          class="dropdown-fade-overlay"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'
import ChevronIcon from './Icons/ChevronIcon.vue'
import CheckmarkIcon from './Icons/CheckmarkIcon.vue'

export default {
  name: 'BaseSingleDropdown',
  components: {
    ChevronIcon,
    CheckmarkIcon
  },
  mixins: [clickaway],
  props: {
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      required: true
    },
    selectedObj: {
      type: [Object, String, Number],
      required: false,
      default: null
    },
    optionLabel: {
      type: Function,
      required: false,
      default: (option) => option.name || option.label || option
    },
    selectedLabel: {
      type: Function,
      required: false,
      default: (option) => option.name || option.label || option
    },
    optionKey: {
      type: String,
      required: false,
      default: 'id'
    },
    placeholder: {
      type: String,
      default: 'Select an option'
    },
    showLabelSpace: {
      type: Boolean,
      default: false
    },
    triggerClass: {
      type: String,
      default: ''
    },
    sortOptions: {
      type: Boolean,
      default: false
    },
    minWidth: {
      type: String,
      default: 'auto'
    }
  },
  data () {
    return {
      expanded: false,
      selectedItem: this.selectedObj || null
    }
  },
  computed: {
    dropdownOptions () {
      if (!this.sortOptions) return this.options
      return this.selectedItem ? [...this.options].sort((a, b) => a[this.optionKey] === this.selectedItem[this.optionKey] ? -1 : 1) : this.options
    }
  },
  methods: {
    toggleDropdown () {
      this.expanded = !this.expanded
    },
    updateSelectedItem (option) {
      this.selectedItem = option
      this.expanded = false
      this.$emit('change', option)
    }
  }
}
</script>

<style scoped>
.dropdown-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
}

.dropdown {
  transition: height 100ms ease-in-out;
}

.dropdown.expanded {
  border-left: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-color: #ECEFF3;
}

.dropdown-fade-overlay {
  position: absolute;
  pointer-events: none;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px;
  z-index: 40;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffffb9 100%);
}
</style>
