<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M9.25 3.25V4.375M13.4927 5.00744L12.6971 5.80294M5.80286 12.6971L5.00736 13.4927M4.375 9.25H3.25M5.80286 5.80286L5.00736 5.00736M11.6024 16.6006L8.8006 9.28495C8.68457 8.98195 8.98195 8.68457 9.28495 8.8006L16.6006 11.6024C16.8912 11.7137 16.9276 12.1102 16.662 12.2725L14.0148 13.8903C13.964 13.9213 13.9213 13.964 13.8903 14.0148L12.2725 16.662C12.1102 16.9276 11.7137 16.8912 11.6024 16.6006Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'CtaIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>