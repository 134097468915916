<template>
  <BaseText
    size="sm"
    class="text-text-muted"
  >
    {{ formattedDecimal }}<span v-if="suffix">{{ suffix }}</span>
  </BaseText>
</template>

<script>
export default {
  name: 'DecimalCell',
  props: {
    data: {
      type: Object,
      required: true
    },
    fixedTo: {
      type: Number,
      default: () => -1
    },
    suffix: {
      type: String,
      default: ''
    }
  },
  computed: {
    formattedDecimal () {
      if (!this.data.value) return 0
      return this.fixedTo === -1 ? this.data.value : this.data.value.toFixed(this.fixedTo)
    }
  }
}
</script>

  <style scoped>
  /* Add any styles you need here */
  </style>
