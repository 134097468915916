// Imports
import AxiosConfig from './serverAxiosConfig'
import firebase from '../config/FirebaseConfig'

const getBillingDetails = async () => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.get(
    '/users/billing',
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

// Churn
const getChurnHash = async (cusId) => {
  const { Aa: token } = firebase.auth().currentUser

  const payload = {
    customerId: cusId
  }
  const { data } = await AxiosConfig.post(
    '/users/churn',
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}
export default {
  getBillingDetails,
  getChurnHash
}
