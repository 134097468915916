<template>
  <div class="w-full h-full rounded-t-3xl px-32 py-12">
    <div>
      <div>
        <div class="flex flex-col gap-1">
          <BaseText
            type="label"
            class="text-neutral-600"
          >
            Plan
          </BaseText>
          <BaseText
            size="sm"
            class="max-w-xs text-neutral-500"
          >
            View and edit your active Foreplay subscription.
          </BaseText>
        </div>
      </div>
    </div>
    <!-- Divider -->
    <div class="border-t border-neutral-50 w-full my-7" />
    <!-- Plan Name -->
    <div class="flex justify-between items-center">
      <!-- Left Column -->
      <div class="flex gap-4 items-center">
        <div class="w-14 h-14 p-3.5 border rounded-xl">
          <img
            src="../../assets/icons/foreplay-gray.svg"
            class="w-full h-full"
          >
        </div>
        <div>
          <BaseText
            v-if="hasSubscription"
            class="text-neutral-600"
            type="label"
            size="sm"
          >
            {{ basePlan.name }} {{ `($${basePlan.price}/${basePlan.interval})` }}
          </BaseText>
          <BaseText
            v-else
            class="text-neutral-600"
            type="label"
            size="sm"
          >
            No active subscription
          </BaseText>
          <BaseText
            class="text-neutral-500 mt-1"
            size="sm"
          >
            {{ basePlan.desc }}
          </BaseText>
        </div>
      </div>
      <!-- Right Column -->
      <div class="relative w-min">
        <BaseButton
          outlined
          :disabled="getTeam && getTeam && !isTeamOwner"
          @click="redirectToManage"
          @mouseenter.native="tooltipHovered.changePlan = true"
          @mouseleave.native="tooltipHovered.changePlan = false"
        >
          Update Plan
        </BaseButton>
        <transition>
          <div
            v-if="tooltipHovered.changePlan && getTeam && !isTeamOwner"
            class="label-tooltip"
          >
            <BaseText
              type="body"
              size="sm"
              class="text-white"
            >
              Only account admins can access this: {{ teamOwnerEmail }}
            </BaseText>
          </div>
        </transition>
      </div>
    </div>
    <!-- Add on details -->
    <div class="mt-7">
      <BaseText
        type="label"
        size="sm"
        class="mb-1"
      >
        Add-Ons
      </BaseText>
      <div v-if="basePlan?.addOns?.length > 0">
        <div
          v-for="addOn in basePlan.addOns"
          :key="addOn.name"
        >
          <BaseText
            v-if="addOn.quantity > 0"
            size="sm"
            class="text-neutral-500"
          >
            {{ `${addOn.formatQuantity} ${addOn.name}` }}
          </BaseText>
        </div>
      </div>
      <div v-else>
        <BaseText
          class="text-neutral-300"
          size="sm"
        >
          No additional items
        </BaseText>
      </div>
    </div>

    <!-- Divider -->
    <div class="border-t border-neutral-50 w-full my-7" />
    <!-- Cancel -->
    <div class="relative w-min">
      <BaseButton
        class="cancel-btn"
        :disabled="getTeam && !isTeamOwner"
        @click="cancelSubscription"
        @mouseenter.native="tooltipHovered.cancelPlan = true"
        @mouseleave.native="tooltipHovered.cancelPlan = false"
      >
        <div class="relative">
          <div
            class="absolute bottom-0 btn-underline w-full h-0.5"
          />
          Cancel Plan
        </div>
      </BaseButton>
      <transition>
        <div
          v-if="tooltipHovered.cancelPlan && getTeam && !isTeamOwner"
          class="label-tooltip"
        >
          <BaseText
            type="body"
            size="sm"
            class="text-white"
          >
            Only account admins can access this: {{ teamOwnerEmail }}
          </BaseText>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FirebaseAPI from '@/api/firebase'
import ForeplayAPI from '@/api/foreplayServer'
import _ from 'lodash'

export default {
  name: 'PlanSettings',
  data () {
    return {
      basePlan: {},
      teamOwnerEmail: '',
      fullworkflowId: 'prod_LfIi8NmO2xjOpY',
      inspiratonId: 'prod_K9H5hPoU2WPBuU',

      tooltipHovered: {
        changePlan: false,
        cancelPlan: false
      }
    }
  },
  computed: {
    ...mapGetters('AuthModule', [
      'getUserSubscription',
      'getStripeCustomer',
      'getUser',
      'getTeam',
      'getTeamSubscription',
      'isTeamOwner'
    ]),
    hasSubscription () {
      return !_.isEmpty(this.getUserSubscription) || !_.isEmpty(this.getTeamSubscription)
    }
  },
  async mounted () {
    if (this.hasSubscription) {
      await this.getUserPlan()
    }
    this.teamOwnerEmail = await this.getTeamOwnerEmail()
  },
  methods: {
    async getTeamOwnerEmail () {
      const team = this.getTeam
      const owner = await FirebaseAPI.Users.getById(team.created_by)

      return owner.email
    },
    async getUserPlan () {
      const customer = !_.isEmpty(this.getUserSubscription) ? this.getUserSubscription : this.getTeamSubscription
      // const basePlan = customer.items.find((item) => /inspiration|fullworkflow/i.test(item.plan.nickname))
      const basePlan = customer.items.find((item) => item.plan.product === this.fullworkflowId || item.plan.product === this.inspiratonId)
      const planId = basePlan.plan.product
      const planDesc = planId === this.inspiratonId ? 'Unlimited access to Swipe File & Discovery' : 'Unlimited access to Swipe File, Discovery & Briefs'
      const freeSeats = planId === this.inspiratonId ? 1 : 2
      const spyderQuantity = this.getUser.spyderLimit

      const addOns = customer.items.map((item) => {
        const intervalPrice = item.price.product.metadata.monthlyPrice
        const interval = item.price.recurring.interval === 'month' ? 'mo' : 'yr'
        // Handle plan quantity as additional member count
        if (planId === item.plan.product) {
          const quantity = (item.quantity - freeSeats) > freeSeats ? item.quantity - freeSeats : 0
          return {
            name: 'Additional Team Members ($20ea/mo)',
            formatQuantity: `${quantity}x`,
            quantity: quantity
          }
        } else {
          const productName = item.price?.product?.name?.split(' ')
          let fallbackSpyder = 0
          if (productName.length > 2) {
            fallbackSpyder = productName[2]
          }
          const spyderCount = spyderQuantity ?? fallbackSpyder
          return {
            name: `Tracked Brands ($${intervalPrice}/${interval})`,
            formatQuantity: spyderCount,
            quantity: spyderCount
          }
        }
      })
      this.basePlan = {
        name: `${basePlan.price?.product?.name} Plan`,
        interval: basePlan.price?.recurring.interval === 'month' ? 'mo' : 'yr',
        price: basePlan.price?.product?.metadata?.monthlyPrice,
        desc: planDesc,
        addOns
      }
    },
    redirectToManage () {
      this.$router.push({ name: 'PaymentView' })
      this.$emit('close')
    },
    async cancelSubscription () {
      const cusId = this.getStripeCustomer.stripeId
      const sub = this.getUserSubscription
      const data = await ForeplayAPI.Users.getChurnHash(cusId)
      window.churnkey.init('show', {
        subscriptionId: sub.id,
        customerId: cusId,
        authHash: data.hash,
        appId: 'yk5n1rq9q',
        mode: 'live',
        provider: 'stripe',
        handleSupportRequest: customer => {
          const choiceReason = window.churnkey.getSessionData().surveyChoiceValue?.toLowerCase()
          window.Intercom('showNewMessage', `Hi, I'm considering canceling my subscription because of ${choiceReason}`)
          window.churnkey.hide()
        }
      })
    }
  }
}
</script>

<style scoped>
.cancel-btn {
  color: #A4ACB9
}
.btn-underline{
  opacity: 0;
  background-color: #d0d2d6;
  transition: opacity 150ms ease-in-out;
}
.cancel-btn:not([disabled]):hover .btn-underline{
  opacity: 1;
}

.v-enter-active, .v-leave-active {
  transition: opacity 150ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
.label-tooltip {
  position: absolute;
  left: 50%;
  bottom: calc(100% + 6px);
  transform: translateX(-50%);
  padding: 8px 12px;
  border-radius: 6px;
  color: white;
  background-color: #131520;
  width: 240px;
  text-align: left;
}
</style>
