<template>
  <svg
    :width="width"
    :Height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33398 5H15.0007"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.33398 10H7.50065"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.33398 15H7.50065"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3328 15.2077V14.3743M13.3328 9.79102V10.6243M11.7091 13.4368L10.9873 13.8535M14.9566 11.5618L15.6783 11.1452M11.7091 11.5618L10.9873 11.1452M14.9566 13.4368L15.6783 13.8535M14.9995 12.4993C14.9995 12.1957 14.9183 11.911 14.7765 11.6658C14.4882 11.1678 13.9496 10.8327 13.3328 10.8327C12.716 10.8327 12.1774 11.1678 11.8892 11.6659C11.7474 11.9111 11.6661 12.1958 11.6661 12.4993C11.6661 12.803 11.7474 13.0877 11.8892 13.3328C12.1774 13.8309 12.716 14.166 13.3328 14.166C13.9496 14.166 14.4882 13.8309 14.7765 13.3328C14.9183 13.0877 14.9995 12.803 14.9995 12.4993Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

  </svg>
</template>

<script>
export default {
  name: 'CustomizeIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
