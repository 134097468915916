// Imports
import AdvertisementsModule from './AdvertisementsModule'
import AuthModule from './AuthModule'
import BoardsModule from './BoardsModule'
import BriefsModule from './BriefsModule'
import MiscModule from './MiscModule'
import RouterModule from './RouterModule'
import SearchModule from './SearchModule'
import SpyderModule from './SpyderModule'
import TagsModule from './TagsModule'
import LensModule from './LensModule'

export default {
  AdvertisementsModule,
  AuthModule,
  BoardsModule,
  BriefsModule,
  MiscModule,
  RouterModule,
  SearchModule,
  SpyderModule,
  TagsModule,
  LensModule
}
