<template>
  <div class="flex items-center rounded-md p-0.5 gap-2 color-input-container form-input relative min-w-0">
    <transition name="fade">
      <div 
      v-on-clickaway="() => { isPickerOpen = false }"
      class="absolute bottom-10" v-show="isPickerOpen">
          <!-- <div ref="hexInput" class="mt-4 flex gap-3">
            <div>Span</div>
            <input :value="colorValue" @input="updateColor"/>
          </div> -->
        <sketch-picker ref="colorPicker" disableAlpha disableFields :value="colorValue" @input="updateColor">
        </sketch-picker>
      </div>
    </transition>
    <div class="h-7 w-7 color-square cursor-pointer flex-shrink-0" @click="isPickerOpen = !isPickerOpen" :style="{backgroundColor: colorValue}"></div>
    <input type="text" class="color-input p-0 uppercase min-w-0" placeholder="#000000" v-model="colorValue"
      @input="updateTextColor">
  </div>
</template>

<script>
import { Chrome } from 'vue-color'
import { mixin as clickaway } from 'vue-clickaway2'

export default {
  name: 'BaseColorPicker',
  mixins: [
    clickaway
  ],
  components: {
    'sketch-picker': Chrome
  },
  props: {
    value: {
      type: String,
      default: '#ff0000'
    }
  },
  data() {
    return {
      colorValue: '',

      isPickerOpen: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.colorValue = this.$props.value;

      // const picker = this.$refs.colorPicker;
      // const hexInput = this.$refs.hexInput;
      // const pickerBody = picker.$el.querySelector('.vc-chrome-body')

      // pickerBody.appendChild(hexInput)
    })
  },
  methods: {
    updateTextColor() {
      this.$emit('change', this.colorValue)
    },
    updateColor(event) {
      const val = event.hex
      this.colorValue = val
      this.$emit('change', this.colorValue)
    }
  }
}
</script>

<style scoped>
.form-input {
  color: #06070F;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 150ms ease-in-out;
}
.form-input:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.1), 0px 0px 0px 1px rgba(0, 56, 108, 0.1);
}
.form-input::placeholder {
  color: #5E6678;
  transition: color 150ms ease-in-out;
  opacity: 0.9;
}
.form-input:hover::placeholder {
  color: #303546;
}
.form-input:focus::placeholder {
  color: #C1C7D0;
}

.color-input-container,
.color-input {
  height: 32px;
}

.color-input {
  border: none !important;
  outline: none;
  height: min-content;
}

.color-input[type='text']:focus {
  border: none !important;
  box-shadow: none;
  outline: none !important;
}

.color-square{
  border-radius: 4px;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 100ms !important;
}

.fade-enter,
.fade-leave-to .fade-leave-active {
  opacity: 0;
  transform: translateY(-1rem);
}

/* Override chrome color styles */
.vc-chrome-color-wrap {
  display: none;
}

.vc-chrome {
  padding: 8px !important;
  border-radius: 12px !important;
  background-color: #191a22 !important;
  box-shadow: none !important;
}


.vc-chrome-saturation-wrap {
  border-radius: 4px !important;
}

.vc-chrome >>> .vc-chrome-body{
  padding: 12px 4px 8px !important;
  background: #191a22 !important;
}

.vc-chrome-hue-wrap .vc-hue{
  border-radius: 12px !important;
}

/* Color picker styles */
/* .vc-sketch-container {
  height: 28px !important;
  width: 28px !important;
  border-radius: 4px;
  box-shadow: none !important;
  user-select: none;
}

.vc-sketch {
  background-color: #191a22 !important;
}

.vc-saturation-pointer .vc-saturation-circle {
  width: 1rem;
  height: 1rem;
  border-width: 2px;
}

.vc-sketch-presets,
.vc-sketch-color-wrap {
  display: none !important;
} */
</style>