<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.25 9.75V7.25C16.25 4.90279 14.3472 3 12 3C9.65279 3 7.75 4.90279 7.75 7.25V9.75M12 14V17M6.75 21.25H17.25C18.3546 21.25 19.25 20.3546 19.25 19.25V11.75C19.25 10.6454 18.3546 9.75 17.25 9.75H6.75C5.64543 9.75 4.75 10.6454 4.75 11.75V19.25C4.75 20.3546 5.64543 21.25 6.75 21.25Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'LockedIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>
