<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M10 7.76438V10.0101M10 12.25H10.0075M8.70738 3.99277L3.45611 12.9985C2.87414 13.9966 3.59377 15.25 4.74877 15.25H15.2512C16.4062 15.25 17.1258 13.9966 16.5439 12.9985L11.2926 3.99276C10.7152 3.00241 9.2848 3.00241 8.70738 3.99277ZM10.1875 12.25C10.1875 12.3536 10.1036 12.4375 10 12.4375C9.89643 12.4375 9.8125 12.3536 9.8125 12.25C9.8125 12.1464 9.89643 12.0625 10 12.0625C10.1036 12.0625 10.1875 12.1464 10.1875 12.25Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'WarningIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>