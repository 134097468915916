<template>
  <!-- Account View -->
  <MainLayout>
    <!-- Toolbar -->
    <template #toolbar />
  </MainLayout>
</template>

<script>
import MainLayout from '../../layouts/MainLayout'
import firebase from '@/api/config/FirebaseConfig'
import { mapGetters } from 'vuex'

export default {
  name: 'ChromeExtensionAuthView',
  components: { MainLayout },
  computed: {
    ...mapGetters('AuthModule', ['getUser'])
  },
  async mounted () {
    const createTokenFunction = firebase
      .functions()
      .httpsCallable('createToken')
    const token = await createTokenFunction(this.getUser.uid)

    chrome.runtime.sendMessage(
      'eaancnanphggbfliooildilcnjocggjm',
      {
        event: 'auth',
        data: { token }
      },
      function (response) {
        if (response.status === 'worked') {
          window.Intercom('update', {
            installed_extension: true
          })
          window.analytics.identify({
            extensionInstalled: true
          })
        }
      }
    )

    // So this doesnt break for people witht he old ext
    try {
      // Also send this token to the plugin to authenticate the user
      const root = document.querySelector('#FP-PLUGIN')
      const iframe = root?.shadowRoot?.querySelector('.FP-plugin-iframe')
      if (iframe) {
        console.log('posting message')
        iframe.contentWindow.postMessage({
          source: 'fp:app',
          type: 'auth',
          data: { token }
        }, '*')
      }
    } catch (error) {
      console.log(error)
    }

    this.$router.push({ name: 'LibraryView' })
  }
}
</script>

<style scoped lang="sass"></style>
