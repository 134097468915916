<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M4.79167 4.79102V16.041C4.79167 16.9615 5.53786 17.7077 6.45833 17.7077H13.5417C14.4621 17.7077 15.2083 16.9615 15.2083 16.041V4.79102M4.79167 4.79102H15.2083M4.79167 4.79102H3.125M15.2083 4.79102H16.875M11.6667 8.95768V13.541M8.33333 8.95768V13.541M7.5 4.79102C7.5 3.4103 8.61929 2.29102 10 2.29102C11.3807 2.29102 12.5 3.4103 12.5 4.79102" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'DeleteV2Icon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>