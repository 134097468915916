// Imports
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/functions'

let firebaseConfig
if (process.env.NODE_ENV === 'development') {
  firebaseConfig = {
    apiKey: 'AIzaSyD8SKSozKPmOXGZORdSp9-rZLSmvZ1uMY4',
    authDomain: 'foreplay-dev-67500.firebaseapp.com',
    projectId: 'foreplay-dev-67500',
    storageBucket: 'foreplay-dev-67500.appspot.com',
    messagingSenderId: '244510868000',
    appId: '1:244510868000:web:e6424b7d46bdd65ad3b245'
  }
} else {
  // Firebase Config
  firebaseConfig = {
    apiKey: 'AIzaSyCIn3hB6C5qsx5L_a_V17n08eJ24MeqYDg',
    authDomain: 'adison-foreplay.firebaseapp.com',
    projectId: 'adison-foreplay',
    storageBucket: 'foreplay-submissions',
    messagingSenderId: '886520493671',
    appId: '1:886520493671:web:06331880430218a69946c2',
    measurementId: 'G-ZZT7JBH0L3'
  }
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()
firebase.functions()

export default firebase
