<template>
  <!-- Manage Board Modal -->
  <BaseModal
    no-toolbar
    no-exit
  >
    <template #default>
      <div>
        <div class="flex px-4 border-b">
          <img
            class="h-14 mr-4"
            src="../../../assets/images/paymentIcon.png"
          >
          <div>
            <div class="text-md font-medium text-black w-full">
              <span>Payment Past Due</span>
            </div>
            <div class="text-sm text-red-600">
              Your recent subscription payment failed.
            </div>
          </div>
        </div>
        <!-- Divider -->
        <div class="p-4">
          <div class="border p-4 rounded-md">
            <StripeElements
              #default="{ elements }"
              ref="cardElms"
              :stripe-key="stripeKey"
              :instance-options="instanceOptions"
              :elements-options="elementsOptions"
            >
              <StripeElement
                ref="card"
                type="card"
                :elements="elements"
                :options="cardOptions"
              />
            </StripeElements>
          </div>
        </div>

        <div class="flex items-center justify-between border-t pt-4 pl-2 pr-4">
          <img
            class="h-8"
            src="../../../assets/images/stripe-logo.png"
          >
          <div
            class="pt-3 text-right flex justify-end"
          >
            <BaseButton
              primary
              :loading="loadingPaymentUpdate"
              :disabled="loadingPaymentUpdate"
              @click="updatePaymentMethod"
            >
              Update Address
            </BaseButton>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import firebase from '@/api/config/FirebaseConfig'
import API from '@/api'

export default {
  name: 'UpdatePaymentMethodModal',
  components: {
    StripeElements,
    StripeElement
  },
  props: {
  },
  data () {
    return {
      stripeKey: 'pk_live_51JS7DeAQtq0TW7KZxoGv8Spu6IJhR335IoLxdfBQMF8VCbWJFDrUl2DfeNJllyC79mZJv8kX0qiaNGFCgbrTznpA00kPK2gMwN',
      instanceOptions: {
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
      },
      elementsOptions: {
      },
      cardOptions: {
        style: {
          base: {
            fontSize: '1.2rem'
          }
        }
      },
      loadingPaymentUpdate: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUserSubscription', 'getStripeCustomer', 'getUser'])
  },
  methods: {
    ...mapActions('AuthModule', ['setUserSubscription', 'checkTeamExpired']),
    ...mapMutations('AuthModule', ['SET_TEAM_SUBSCRIPTION_STATE']),
    ...mapMutations('MiscModule', ['SET_SETTINGS_DRAWER_VIEW_MODE']),
    async updatePaymentMethod () {
      // Update the card then retry the last failed payment
      this.loadingPaymentUpdate = true
      try {
        // ref in template
        const groupComponent = this.$refs.cardElms
        const cardElement = groupComponent.elements.getElement('card')

        // Make an api call to create a payment intent using the current setup intent
        const stripe = groupComponent.instance

        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement
        })
        if (error) {
          console.log(error)
          this.$showAlert({
            message: error.message,
            type: 'error'
          })
        } else {
          await API.Stripe.updatePaymentMethod(
            paymentMethod.id,
            this.getStripeCustomer.stripeId
          )

          // Retry the past due charge
          await API.Stripe.retryPastDuePayment(
            this.getStripeCustomer.stripeId
          )

          this.$showAlert({
            message: 'Your payment method has been updated.',
            type: 'success'
          })

          const user = firebase.auth().currentUser
          await this.setUserSubscription(user)
          this.SET_TEAM_SUBSCRIPTION_STATE('active')

          this.$router.push({
            name: 'DashboardView'
          })
        }
      } catch (error) {
        console.log(error)
        this.$showAlert({
          message: error,
          type: 'error'
        })
      }
      this.loadingPaymentUpdate = false
    }
  }
}
</script>

<style scoped></style>
