<template>
  <div class="relative">
    <!-- Button -->
    <button class="opener-button" :class="[primary ? `primary ${getTheme}` : 'secondary', {'active': expanded}]"
    @click="expanded = !expanded">
      <PlusIcon :class="{'text-icon-normal': !primary}" />
      <BaseText type="label" size="sm" :class="{'text-text-muted': !primary}">
        New
      </BaseText>
    </button>
    <!-- Dropdown -->
    <transition>
      <div v-if="expanded" class="new-dropdown" :class="`${position}`" v-on-clickaway="() => { expanded = false }">
        <button v-for="(option, index) in options" :key="`create-option-${index}`"
        @click="handleOptionAction(option)" 
        class="group flex items-center gap-2 w-full px-2 py-1.5 rounded-md transition-colors duration-100 hover:bg-neutral-25">
          <component :is="option.icon" class="text-icon-normal transition-colors duration-100 group-hover:text-icon-muted"/>
          <BaseText type="body" size="sm" class="text-text-normal transition-colors duration-100 group-hover:text-text-muted">
            {{ option.name }}
          </BaseText>
        </button>
      </div>
    </transition>

    <!-- Modals -->
    <CreateAdvertisementModal 
      v-if="showAdModal"
      :starting-tab="manualUpload ? 'manual' : 'extension'"
      @close="showAdModal = false"
      @save="$emit('save')"
      @refresh="$emit('refresh')"
      @updateFileBoards="$emit('updateFileBoards')"
    />
    <ManageBoardModal 
      v-if="showManageBoardModal"
      :board="{}"
      @close="showManageBoardModal = false"
    />
    <FolderModal 
      v-if="showFolderModal"
      @close="showFolderModal = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway2'

// Icons
import PlusIcon from './Icons/PlusIcon.vue'
import NewBoardIcon from './Icons/NewBoardIcon.vue'
import NewFolderIcon from './Icons/NewFolderIcon.vue'
import AnalyzeSpyderIcon from './Icons/AnalyzeSpyderIcon.vue'
import UploadIcon from './Icons/UploadIcon.vue'
import DocumentIcon from './Icons/DocumentIcon.vue'

// Modals
import CreateAdvertisementModal from '../advertisements/AdvertisementCreateModal.vue'
import ManageBoardModal from '../boards/ManageBoardModal.vue'
import FolderModal from '../sidebar/FolderModal.vue'

export default {
  name: 'BaseCreateNewDropdown',
  mixins: [clickaway],
  components: {
    CreateAdvertisementModal,
    ManageBoardModal,
    FolderModal,
    PlusIcon
  },
  props: {
    product: {
      type: String,
      default: 'library'
    },
    primary: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'left'
    }
  },
  data () {
    return {
      expanded: false,
      options: [],

      // Modal States
      manualUpload: false,
      showAdModal: false,
      showManageBoardModal: false,
      showFolderModal: false
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme'])
  },
  created () {
    this.options = [
      { 
        name: 'Save New Ad',
        emitBackup: 'openAdModal',
        icon: PlusIcon, 
        action: this.openAdModal
      },
      { 
        name: 'Manual Upload', 
        emitBackup: 'openManualUpload',
        icon: UploadIcon, 
        action: this.openManualUpload 
      },
      { 
        name: 'Create Board',
        emitBackup: 'showManageBoardModal', 
        icon: NewBoardIcon, 
        action: () => { this.showManageBoardModal = true } },
      { 
        name: 'Create Folder',
        emitBackup: 'showFolderModal', 
        icon: NewFolderIcon, 
        action: () => { this.showFolderModal = true } 
      },
      { 
        name: 'Create Brief', 
        icon: DocumentIcon, 
        emitBackup: 'showCreateBriefModal',
        action: () => { this.$router.push({ name: 'BriefsView', query: { folderId: 'default' } }).catch(() => {}) } 
      },
      { 
        name: 'Add Spyder', 
        icon: AnalyzeSpyderIcon, 
        action: () => { this.$router.push({ name: 'SpyderBrandsView', query: { folderId: 'default' } }).catch(() => {}) } 
      }
    ]
  },
  methods: {
    handleOptionAction (option) {
      if (this.product === 'briefs' && option.emitBackup) {
        // Due to the briefs view's fixed top bar, we cannot load the modals here. Instead we emit a backup event
        this.$emit(option.emitBackup)
      } else {
        option.action()
      }
      this.expanded = false
    },
    openManualUpload () {
      this.manualUpload = true
      this.showAdModal = true
    },
    openAdModal () {
      this.manualUpload = false
      this.showAdModal = true
    }
  }
}
</script>

<style scoped>
.opener-button {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 6px 12px 6px 8px;
  border-radius: 6px;
}

@property --primary-btn-opacity-1 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
@property --primary-btn-opacity-2 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
.opener-button.primary.blue {
  --background-color: #006BFF;
  --shadow-color: #0063F4;
}
.opener-button.primary.purple {
  --background-color: #6534DF;
  --shadow-color: #6336D3;
}
.opener-button.primary.red {
  --background-color: #FF5453;
  --shadow-color: #f45555;
}
.opener-button.primary.green {
  --background-color: #009F6D;
  --shadow-color: #1F8E6B;
}
.opener-button.primary {
  color: white;
  background: linear-gradient(180deg, rgba(255, 255, 255, var(--primary-btn-opacity-1)) 0%, rgba(255, 255, 255, var(--primary-btn-opacity-2)) 100%), var(--background-color);
  box-shadow: 0px -1px 12px 0px rgba(255, 255, 255, 0.12) inset, 0px 0px 0px 1px var(--shadow-color);
  transition: --primary-btn-opacity-1 100ms ease-in-out, --primary-btn-opacity-2 100ms ease-in-out;
  --primary-btn-opacity-1: 0.12;
  --primary-btn-opacity-2: 0.12;
}
.opener-button.primary:hover {
  --primary-btn-opacity-1: 0.24;
  --primary-btn-opacity-2: 0;
}
.opener-button.primary.active {
  --primary-btn-opacity-1: 0;
  --primary-btn-opacity-2: 0.24;
}

@property --secondary-btn-opacity {
  syntax: '<number>';
  initial-value: 0.08;
  inherits: false;
}
.opener-button.secondary {
  background: linear-gradient(180deg, rgba(223, 225, 231, var(--secondary-btn-opacity)) 0%, transparent 100%);
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: background-color 100ms ease-in-out, box-shadow 100ms ease-in-out, --secondary-btn-opacity 100ms ease-in-out;
  --secondary-btn-opacity: 0.08;
}
.opener-button.secondary:hover {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.17), 0px 0px 0px 1px rgba(0, 56, 108, 0.12);
}
.opener-button.secondary.active {
  --secondary-btn-opacity: 0;
  background-color: #F6F8FA; /* bg-neutral-25 */
  box-shadow: none;
}

.new-dropdown {
  width: 165px;
  position: absolute;
  top: calc(100% + 6px);
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 4px;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px #FFF;
  z-index: 50001;
  background-color: white;
}
.new-dropdown.left {
  right: 0px;
}
.new-dropdown.center {
  left: 50%;
  transform: translateX(-50%);
}
.new-dropdown.right {
  left: 0px;
}

.v-enter-active, .v-leave-active {
  transition: opacity 100ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
</style>