<template>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 9.25V13M16.75 10C16.75 13.728 13.728 16.75 10 16.75C6.27208 16.75 3.25 13.728 3.25 10C3.25 6.27208 6.27208 3.25 10 3.25C13.728 3.25 16.75 6.27208 16.75 10Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 6.4375C9.68935 6.4375 9.4375 6.68934 9.4375 7C9.4375 7.31066 9.68935 7.5625 10 7.5625C10.3106 7.5625 10.5625 7.31066 10.5625 7C10.5625 6.68934 10.3106 6.4375 10 6.4375Z" fill="currentColor" stroke="currentColor" stroke-width="0.375"/>
</svg>

</template>
  
  <script>
    export default {
      name: 'InfoBorderIcon',
      props: {
        width: {
          type: Number,
          default: 20
        },
        height: {
          type: Number,
          default: 20
        }
      }
    }
  </script>
  
  <style scoped></style>