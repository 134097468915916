// Misc Module
// For things that don't fit in other modules

export default {
  namespaced: true,
  state: {
    // Display control for the settings drawer (string or null for closed)
    settingsDrawerViewMode: null,
    // App style (this is set in the router)
    theme: 'blue',
    lastTheme: 'blue',
    themeChangedAt: 0,
    discoveryAdCount: 0,
    preventDrawerEscapeKey: false,
  },
  getters: {
    getSettingsDrawerViewMode: state => state.settingsDrawerViewMode,
    // Get Theme color
    getTheme: state => {
      console.log('getting theme')

      return state.theme
    },
    // Get the previous theme to transtion from
    getLastTheme: state => state.lastTheme,
    // Last time the theme was updated
    getThemeChangedAt: state => state.themeChangedAt,
    // Get UTM_source UTM_medium UTM_campaign for tracking purposes
    getUtmSource: state => state.utmSource,
    getUtmMedium: state => state.utmMedium,
    getUtmCampaign: state => state.utmCampaign,
    getDiscoveryAdCount: state => state.discoveryAdCount,
    getPreventDrawerEscapeKey: state => state.preventDrawerEscapeKey
  },
  mutations: {
    SET_SETTINGS_DRAWER_VIEW_MODE (state, mode) {
      state.settingsDrawerViewMode = mode
    },
    SET_THEME (state, theme) {
      console.log('setting theme')
      state.themeChangedAt = +new Date()
      state.lastTheme = state.theme
      state.theme = theme
    },
    SET_UTM_SOURCE (state, utmSource) {
      state.utmSource = utmSource
    },
    SET_UTM_MEDIUM (state, utmMedium) {
      state.utmMedium = utmMedium
    },
    SET_UTM_CAMPAIGN (state, utmCampaign) {
      state.utmCampaign = utmCampaign
    },
    SET_DISCOVERY_AD_COUNT (state, count) {
      state.discoveryAdCount = count
    },
    SET_PREVENT_DRAWER_ESCAPE_KEY (state, doPrevent) {
      state.preventDrawerEscapeKey = doPrevent
    }
  },
  actions: {}
}
