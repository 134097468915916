<template>
  <!-- Main Container -->
  <div>
    <!-- Description Text -->
    <div class="px-8 text-sm text-gray-500 my-4">
      Use this fuction to upload your own content to Foreplay for use in Boards
      and Briefs.
    </div>

    <!-- Upload Area -->
    <div>
      <label
        :for="`uploadInput`"
        class="relative block w-full text-center"
        @drop.prevent="onDrop"
      >
        <div
          class="flex justify-center items-center m-4 h-48 border-2 border-dashed rounded-xl bg-gradient-to-b to-transparent transition duration-500 cursor-pointer"
          :class="{
            'border-gray-400 from-gray-200': uploading,
            'border-blue-400 from-blue-100 hover:opacity-70 ': !uploading
          }"
        >
          <div class="flex flex-col items-center justify-center">
            <lottie
              v-if="uploading"
              :options="defaultOptions"
              :height="40"
              :width="40"
              @animCreated="handleAnimation"
            />
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-10 h-10 text-blue-500"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
              />
            </svg>

            <div v-if="uploading">
              <div class="text-gray-600 text-lg">Uploading your files</div>
              <div class="text-gray-400 text-sm">
                {{ filesCompleted }} of {{ filesUploading }} finished uploading
              </div>
            </div>
            <div v-else>
              <div class="text-gray-600 text-lg">Upload Content</div>
              <div class="text-gray-400 text-sm">
                Drag and drop your .mp4, .png or .jpeg file
              </div>
            </div>
          </div>
        </div>
      </label>
      <input
        :id="'uploadInput'"
        type="file"
        hidden
        :disabled="uploading"
        @drop.prevent="handleUploadFile"
        @change="handleAddFile($event)"
      >
    </div>

    <!-- File Display -->
    <div
      v-if="filesCompleted"
      class="grid grid-cols-2 px-5 pt-4"
    >
      <!-- Headers Section -->
      <div class="text-gray-600 text-sm">
        Asset
      </div>
      <div class="text-gray-600 text-sm mb-2">
        Add to Board
      </div>
    </div>
    <div class="w-full pr-1.5">
      <div v-if="uploadedFiles.length" ref="uploaded-files-list"
      class="uploaded-files-list flex flex-col gap-2 w-full overflow-y-scroll mb-2">
        <div v-for="(file, index) in uploadedFiles" :key="index" :style="{ zIndex: uploadedFiles.length - index}">
          <File
            :file="file"
            :dropdownOpenedId.sync="dropdownOpenedId"
            @updateBoards="$emit('updateFileBoards', file.id, $event)"
          />
        </div>
      </div>
    </div>
    <BaseButton
      primary
      class="ml-auto mt-4 mr-5"
      @click="$emit('close')"
    >
      Done
    </BaseButton>
  </div>
</template>

<script>
import { nanoid } from 'nanoid'
import FirebaseAPI from '@/api/firebase'
import { mapGetters, mapMutations } from 'vuex'
import firebase from '@/api/config/FirebaseConfig'

import File from './File.vue'
import Lottie from 'vue-lottie/src/lottie.vue'
import animationData from '../../../assets/lotties/loadingV2.json'

const events = ['dragenter', 'dragover', 'dragleave', 'drop']

export default {
  name: 'ManualUpload',
  components: {
    lottie: Lottie,
    File
  },
  data () {
    return {
      defaultOptions: { animationData: animationData, loop: true, autoplay: true },
      uploading: false,
      filesUploading: 0,
      filesCompleted: 0,
      uploadedFiles: [],
      dropdownOpenedId: null
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getTeam', 'getUser'])
  },
  watch: {
    dropdownOpenedId (val) {
      // We listen to see if one of the board select dropdowns is opened so we can lock the scroll
      const container = this.$refs['uploaded-files-list']
      if (val && container) {
        this.scrollEventListener = (e) => e.preventDefault() // Prevent scrolling
        container.addEventListener('wheel', this.scrollEventListener)
        container.addEventListener('touchmove', this.scrollEventListener)
      } else if (container) {
        // Remove listeners to re-enable scrolling
        container.removeEventListener('wheel', this.scrollEventListener)
        container.removeEventListener('touchmove', this.scrollEventListener)
      }
    }
  },
  mounted () {
    events.forEach((eventName) => {
      document.body.addEventListener(eventName, this.preventDefaults)
    })
  },
  beforeDestroy () {
    events.forEach((eventName) => {
      document.body.removeEventListener(eventName, this.preventDefaults)
    })

    // Clean up scroll lock event listeners
    const scrollContainer = this.$refs['uploaded-files-list']
    if (scrollContainer && this.scrollEventListener) {
      scrollContainer.removeEventListener('wheel', this.scrollEventListener)
      scrollContainer.removeEventListener('touchmove', this.scrollEventListener)
    }
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_USER']),
    // Lottie Methods
    handleAnimation (anim) {
      this.anim = anim
      this.anim.setSpeed(1)
      this.play()
    },
    play () {
      this.anim.play()
    },
    reset () {
      this.anim.stop()
    },

    // Uploading Methods
    async updateAd (data, index) {
      const adToUpdate = this.uploadedFiles[index]
      const payload = {
        board_ids: data.board_ids
      }

      await FirebaseAPI.Advertisements.update(adToUpdate.id, payload)

      // Emit update for ad refresh
      this.$emit('refresh')
    },
    onDrop (e) {
      const fakeEvent = { target: { files: [...e.dataTransfer.files] } }
      this.handleAddFile(fakeEvent)
    },
    preventDefaults (e) {
      e.preventDefault()
    },
    async handleAddFile (event, index) {
      if (this.uploading) return
      this.filesCompleted = 0

      const filesToUpload = [...event.target.files]

      // Check if the user has enough storage space
      // Round up, saving in bytes will cause huge ints - save in kb
      const kbToSave = Math.ceil(filesToUpload.reduce((total, file) => total + file.size, 0) / 1000)

      // 5MM kb is 1gb - limit at 5gb for now
      if (this.getUser.storageUsed && (this.getUser.storageUsed + kbToSave) > 5000000) {
        // Show error
        this.$showAlert({
          message: 'Storage Limit Reached (5GB)',
          type: 'error'
        })

        return
      }

      // Validate Files
      const invalidFiles = filesToUpload.find(f => !['image/png', 'image/jpeg', 'video/mp4', 'video/quicktime', 'video/x-msvideo'].includes(f.type))

      if (invalidFiles) {
        // Show error
        this.$showAlert({
          message: 'Invalid File Type',
          type: 'error'
        })
        return
      }

      this.uploading = true
      this.filesUploading = filesToUpload.length

      for (const fileToUpload of filesToUpload) {
        // Create File Name (Random Folder Path)
        const folderName = nanoid()
        const fileName = nanoid()
        const filePath = `${folderName}/${fileName}`
        const fileType = ['image/png', 'image/jpeg'].includes(fileToUpload.type) ? 'image' : 'video'

        // Dont use the default brief submissions bucket
        const storageRef = firebase.app().storage('gs://foreplay-manual-upload').ref(filePath)

        const firstFile = fileToUpload
        await storageRef.put(firstFile)

        const fileLocation = `https://storage.googleapis.com/foreplay-manual-upload/${filePath}`

        const user = firebase.auth().currentUser

        // Create an "Ad"
        const payload = {
          board_ids: [],
          name: 'Manual Upload',
          createdAt: new Date().getTime(),
          created_by: user.uid,
          type: fileType,
          fileData: {
            filePath,
            size: fileToUpload.size,
            name: fileToUpload.name,
            fileExtension: fileToUpload.type.split('/')[1]
          }
        }

        if (fileType === 'video') {
          payload.video = fileLocation
        } else {
          payload.image = fileLocation
        }

        // Add a teamId
        if (this.getTeam) {
          payload.teamId = this.getTeam.id
        }

        // Add team Id to the payload
        const { id } = await FirebaseAPI.Advertisements.create(payload)
        payload.id = id

        // Update the UI
        this.filesCompleted = this.filesCompleted + 1
        this.uploadedFiles = [...this.uploadedFiles, payload]
      }

      // Update the users total storage use
      const storageUsed = this.getUser.storageUsed ? this.getUser.storageUsed + kbToSave : kbToSave

      const user = firebase.auth().currentUser
      await FirebaseAPI.Users.update(user.uid, { storageUsed })

      // Set the local user
      this.SET_USER({ ...this.getUser, storageUsed })

      // Show that all files are uploaded
      this.uploading = false
      this.$showAlert({
        message: 'Files Uploaded',
        type: 'success'
      })

      // Emit update for ad refresh
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>
.uploaded-files-list {
  max-height: 178px;
}
.uploaded-files-list::-webkit-scrollbar {
  width: 3px;
}
.uploaded-files-list::-webkit-scrollbar-thumb {
  background-color: #C1C7D0;
  border-radius: 18px;
}
</style>
