<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M4 5.50144V14.5015M7 4.75144V15.2515M10 5.54761V14.4553C10 15.4312 10.9171 16.1472 11.8638 15.9105L14.8638 15.1605C15.5315 14.9936 16 14.3937 16 13.7053V6.29761C16 5.60931 15.5315 5.00933 14.8638 4.8424L11.8638 4.0924C10.9171 3.85571 10 4.57175 10 5.54761Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'ContentStyleIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>