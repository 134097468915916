<template>
  <div :style="{width: `${width}px`, height: `${height}px`}">
    <!-- We have to embed these anims from an external host since they're massive -->
    <iframe 
      v-if="animationUrl"
      :src="animationUrl" 
      frameborder="0" 
      width="100%" 
      height="100%" 
      allow="autoplay" 
      title="Details Loading Animation"
    />
  </div>
</template>

<script>
export default {
  name: 'DetailsLoaderAnimatedIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: 'blue'
    }
  },
  data () {
    return {
      animationUrl: null,
    }
  },
  created () {
    switch (this.color) {
      case 'purple':
        this.animationUrl = 'https://cdn.lottielab.com/l/3pcL9tGr75MDyK.html'
        break
      case 'red':
        this.animationUrl = 'https://cdn.lottielab.com/l/3Zu6NjPDMxviZb.html'
        break
      default:
        this.animationUrl = 'https://cdn.lottielab.com/l/9JydEUj4AjHjJG.html'
    }
  }
}
</script>

<style scoped></style>