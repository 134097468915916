const conversionColumns = []

const standardEventsMetrics = [
  {
    title: 'Purchase',
    key: 'purchase',
    description: 'The number of purchases made through your ads.'
  },
  {
    title: 'Add to Cart',
    key: 'addToCart',
    description: 'The number of times items were added to cart through your ads.'
  },
  {
    title: 'Initiate Checkout',
    key: 'initiateCheckout',
    description: 'The number of times checkout was initiated through your ads.'
  }
]

const customConversionsMetrics = [
  {
    title: 'Custom Conversion 1',
    key: 'customConversion1',
    description: 'Description for custom conversion 1.'
  },
  {
    title: 'Custom Conversion 2',
    key: 'customConversion2',
    description: 'Description for custom conversion 2.'
  }
]

const customEventsMetrics = [
  {
    title: 'Custom Event 1',
    key: 'customEvent1',
    description: 'Description for custom event 1.'
  },
  {
    title: 'Custom Event 2',
    key: 'customEvent2',
    description: 'Description for custom event 2.'
  }
]

const conversionMetrics = {
  sectionId: 'conversions',
  metrics: [],
  subSections: [
    {
      sectionId: 'Standard Events',
      domId: 'standard-events',
      metrics: standardEventsMetrics
    },
    {
      sectionId: 'Custom Conversions',
      domId: 'custom-conversions',
      metrics: customConversionsMetrics
    },
    {
      sectionId: 'Custom Events',
      domId: 'custom-events',
      metrics: customEventsMetrics
    }
  ]
}
Object.freeze(conversionMetrics)

const conversionSearchList = [
  ...conversionColumns,
  ...standardEventsMetrics,
  ...customConversionsMetrics,
  ...customEventsMetrics
]
export { conversionMetrics, conversionSearchList }
