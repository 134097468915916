<template>
  <div class="flex justify-between">
    <div class="py-1 px-1">
      <template v-for="(item, index) in items">
        <div v-if="item.type === 'divider'" :key="`divider${index}`" class="divider"/>
        <menu-item v-else :key="index" v-bind="item"/>
      </template>
    </div>
    <BaseButton :loading="savingComment" :disabled="valueEmpty" primary @click="$emit('createComment')" class="mr-1">
      <div class="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"/>
        </svg>
        <div>
          Send
        </div>
      </div>
    </BaseButton>
  </div>
</template>

<script>
import MenuItem from './MenuItem.vue'

export default {
  components: {
    MenuItem
  },

  props: {
    savingComment: {
      type: Boolean,
      required: true,
      default: () => false
    },
    valueEmpty: {
      type: Boolean,
      required: true,
      default: () => true
    },
    editor: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      items: [
        {
          icon: 'bold',
          title: 'Bold',
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive('bold')
        },
        {
          icon: 'italic',
          title: 'Italic',
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive('italic')
        },
        {
          icon: 'underline',
          title: 'Underline',
          action: () => this.editor.chain().focus().toggleUnderline().run(),
          isActive: () => this.editor.isActive('underline')
        },
        {
          icon: 'link',
          title: 'Link',
          action: () => this.editor.chain().focus().toggleLink().run(),
          isActive: () => this.editor.isActive('link')
        }
      ]
    }
  }
}
</script>

<style lang="scss">
</style>
