<!-- 
  BaseDownloadProgressRing component is a reusable component that can be used to display a progress ring.
  It accepts two props:
  - radius: The radius of the progress ring (radius of 10 renders a 20px by 20px ring).
  - strokeWidth: The string width of the progress ring.
  The component uses SVG to draw the progress ring and updates the progress using the stroke-dashoffset property.

  Usage:

  The updateProgress() method is used to update the progress (0-1) of the ring.
  It must be called directly on the component instance via a ref.

  It is best used within a ReadableStream or other data source that emits progress events.
-->

<template>
  <div class="progress-ring">
    <svg :width="this.radius * 2" :height="this.radius * 2">
      <circle 
        ref="circle"
        stroke="currentColor" 
        :stroke-width="strokeWidth"
        fill="transparent"
        :r="adjustedRadius"
        :cx="radius"
        :cy="radius"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="circumference"
      />
    </svg>
  </div>
</template>

<script>
let isFramePending = false // Prevents render update buffering

export default {
  name: 'BaseProgressIndicatorRing',
  props: {
    radius: {
      type: Number,
      default: 10
    },
    strokeWidth: {
      type: Number,
      default: 1.5
    },
  },
  computed: {
    adjustedRadius () {
      // Adjusted radius to account for the stroke width
      return this.radius - this.strokeWidth * 2
    },
    circumference () {
      return 2 * Math.PI * this.adjustedRadius
    },
  },
  methods: {
    updateProgress (progress) {
      if (isFramePending) return
      isFramePending = true
      requestAnimationFrame(() => {
        const offset = this.circumference - (progress * this.circumference)
        this.$refs.circle.style.strokeDashoffset = Math.max(offset * 0.8, 0)
        isFramePending = false
      })
    }
  }
}
</script>

<style scoped>
.progress-ring {
  position: relative;
  display: inline-block;
}
.progress-ring svg {
  transform: rotate(-90deg);
}
.progress-ring circle {
  transition: stroke-dashoffset 350ms ease;
}
</style>