const svgFiles = [
  "16x9.svg",
  "1x1.svg",
  "2x3.svg",
  "4x5.svg",
  "9x16.svg",
  "active-icon.svg",
  "ad-detail-icon.svg",
  "add-user.svg",
  "adset-icon.svg",
  "ai-book-white.svg",
  "ai-book.svg",
  "ai-gray.svg",
  "ai-green.svg",
  "ai-script.svg",
  "amex-payment-icon.svg",
  "animation.svg",
  "apply-filter-icon.svg",
  "archive-icon.svg",
  "archive.svg",
  "arrow-guide.svg",
  "aspect-ratio-icon.svg",
  "audio-error-image.svg",
  "avatar-star-icon.svg",
  "back-arrow.svg",
  "basic-folder-icon.svg",
  "board-icon.svg",
  "bolt-icon.svg",
  "book.svg",
  "box-click.svg",
  "brand-profile.svg",
  "brush.svg",
  "calendar.svg",
  "cancel-card.svg",
  "carousel.svg",
  "chain.svg",
  "checked-checkbox.svg",
  "chevron-down-small.svg",
  "chevron-top-small.svg",
  "circle-checkmark-confirm.svg",
  "clipboard-icon.svg",
  "clock-icon.svg",
  "clock2.svg",
  "close.svg",
  "cloud upload.svg",
  "cloud-file.svg",
  "collapse.svg",
  "commercial.svg",
  "community-icon.svg",
  "copy.svg",
  "create-board-icon.svg",
  "create-folder-icon.svg",
  "create-spyder-icon.svg",
  "creative-tests.svg",
  "description-icon.svg",
  "document-gray.svg",
  "document.svg",
  "dots.svg",
  "download.svg",
  "drag.svg",
  "drag1.svg",
  "edit-icon.svg",
  "editorial.svg",
  "expand.svg",
  "export-csv.svg",
  "external-tab.svg",
  "exthumbnail.svg",
  "file-chain.svg",
  "file.svg",
  "flag.svg",
  "folder.svg",
  "font.svg",
  "foreplay-gray.svg",
  "gif.svg",
  "gift-icon.svg",
  "globe.svg",
  "green-link-arrow-icon.svg",
  "heart-icon.svg",
  "heart.svg",
  "help-icon.svg",
  "image-icon.svg",
  "image.svg",
  "information-fill.svg",
  "landscape-icon.svg",
  "layers-copy.svg",
  "layout-icon.svg",
  "library-create-icon.svg",
  "line.svg",
  "link-document.svg",
  "link.svg",
  "link2.svg",
  "loader.svg",
  "locked-dark.svg",
  "locked.svg",
  "loom.svg",
  "magic-stick-top-white.svg",
  "magic-stick-top.svg",
  "megaphone.svg",
  "move-board-icon.svg",
  "my-ads-icon.svg",
  "nav-brush-icon.svg",
  "nav-layout-icon.svg",
  "nav-video-script-icon.svg",
  "new-board-icon.svg",
  "new-check.svg",
  "new-folder-icon.svg",
  "new-un-pin.svg",
  "note.svg",
  "notifications-icon.svg",
  "odometer.svg",
  "paypal-icon.svg",
  "pencil.svg",
  "pinned-cancel.svg",
  "pinned.svg",
  "plant-icon.svg",
  "play-btn.svg",
  "play-icon.svg",
  "plus-circle.svg",
  "plus-icon-white.svg",
  "plus-icon.svg",
  "powered-by-stripe.svg",
  "questionmark.svg",
  "scrub.svg",
  "search-page-find.svg",
  "search-white.svg",
  "search.svg",
  "send-message-gray.svg",
  "settings-icon.svg",
  "share.svg",
  "size.svg",
  "smaller-aspect-ratio-icon.svg",
  "smiley.svg",
  "sort.svg",
  "source-script.svg",
  "speaker.svg",
  "spyder-icon.svg",
  "star-wand-icon.svg",
  "stars-icon.svg",
  "studio.svg",
  "support.svg",
  "template.svg",
  "text-size.svg",
  "trash-gray.svg",
  "trash-icon.svg",
  "trash.svg",
  "tutorial.svg",
  "typography-icon.svg",
  "ugc.svg",
  "unarchive.svg",
  "unchecked-checkbox.svg",
  "unpinned.svg",
  "upload-icon.svg",
  "video clapperboard.svg",
  "video-icon.svg",
  "video-iconsvg.svg",
  "video-script-icon.svg",
  "video.svg",
  "voice-icon.svg",
  "wand.svg",
  "warning-icon.svg",
  "warning.svg",
  "winner.svg",
  "x-button.svg",
];

export default svgFiles;
