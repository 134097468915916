<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M12.25 3.25024V4.75024M12.25 4.75024V15.2502M12.25 4.75024H4.75C3.92157 4.75024 3.25 5.42182 3.25 6.25024V13.7502C3.25 14.5787 3.92157 15.2502 4.75 15.2502H12.25M12.25 15.2502V16.7502M15.25 15.2502C16.0785 15.2502 16.75 14.5787 16.75 13.7502V6.25024C16.75 5.42182 16.0785 4.75024 15.25 4.75024" 
    :stroke="stroke.length ? stroke : 'currentColor'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'DCOIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    },
    stroke: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped></style>