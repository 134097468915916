import { render, staticRenderFns } from "./ModularDetails.vue?vue&type=template&id=9b270a3a&scoped=true"
import script from "./ModularDetails.vue?vue&type=script&lang=js"
export * from "./ModularDetails.vue?vue&type=script&lang=js"
import style0 from "./ModularDetails.vue?vue&type=style&index=0&id=9b270a3a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b270a3a",
  null
  
)

export default component.exports