// Imports
import AxiosConfig from './LensAxiosConfig'

// Fetch AD
const getLensGroups = async (query) => {
  const { group, page, limit, sortBy } = query
  let queryString = `?page=${page}&limit=${limit}`
  if (group) queryString += `&by=${group}`
  if (sortBy) queryString += `&sort=${sortBy}`
  const { data } = await AxiosConfig.get(
    `/ads/group${queryString}`
  )

  return data
}

export default {
  getLensGroups
}
