import AxiosConfig from '../serverAxiosConfig'
import firebase from '../../config/FirebaseConfig'

const create = async (presetPayload) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.post(
    '/lens-presets',
    { ...presetPayload },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const remove = async (presetId) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.delete(
      `/lens-presets/${presetId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
  )

  return data
}

const getAll = async () => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser
  const { data } = await AxiosConfig.get(
    '/lens-presets',
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const update = async (presetId, payload) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.patch(
    `/lens-presets/${presetId}`,
    { ...payload },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}
export default {
  create,
  update,
  remove,
  getAll
}
