<template>
  <!-- Base Carousel -->
  <div class="relative flex items-center" 
  :style="{minHeight: assetHeight && (keepHeightConstant || loadingAsset) ? `${assetHeight}px` : 'auto'}">
    <!-- Go Left -->
    <transition>
      <button v-if="selectedCardIndex > 0" class="carousel-nav nav-left"
      @click="() => {setCarouselIndex(selectedCardIndex - 1)}">
        <CarouselChevronIcon class="nav-chevron" />
      </button>
    </transition>
    <div class="relative w-full">
      <!-- Image -->
      <div v-if="getSelectedCard.type !== 'video'" ref="imageMedia" class="w-full">
        <img :src="getSelectedCard.image" alt="Image" 
        class="w-full object-contain"
        :class="{'rounded-lg': rounded}"
        @load="loadingAsset = false, $emit('loaded-content')"
        @error="loadingAsset = false, $emit('error')">
      </div>
      <!-- Video -->
      <BaseAdvertisementVideoPlayer 
        v-else
        ref="videoPlayer"
        :advertisement="getSelectedCard"
        :carousel-loading="loadingAsset"
        rounded
        @loaded-thumbnail="loadingAsset = false, $emit('loaded-content')"
        @loaded-content="loadingAsset && (loadingAsset = false), $emit('loaded-content')"
        @error="loadingAsset = false, $emit('error')"
      />
      <!-- Loading Spinner -->
      <transition name="spinner">
        <div v-if="loadingAsset" class="absolute top-0 left-0 right-0 bottom-0 z-10 flex items-center justify-center">
          <div class="p-2 rounded-xl bg-black bg-opacity-20">
            <BaseLoadingSpinner />
          </div>
        </div>
      </transition>
    </div>
    <!-- Go Right -->
    <transition>
      <button v-if="selectedCardIndex < items.length - 1" class="carousel-nav nav-right"
      @click="() => {setCarouselIndex(selectedCardIndex + 1)}">
        <CarouselChevronIcon class="nav-chevron" />
      </button>
    </transition>
  </div>
</template>

<script>
import CarouselChevronIcon from './Icons/CarouselChevronIcon.vue'

export default {
  name: 'BaseCarousel',
  components: {
    CarouselChevronIcon
  },
  props: {
    identifier: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    rounded: {
      type: Boolean,
      default: false
    },
    keepHeightConstant: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedCardIndex: 0,
      assetHeight: null,
      loadingAsset: false,
    }
  },
  computed: {
    getSelectedCard () {
      // We extend the card with an id so vuex can have a unique key for tracking the playing video
      const cardData = this.items[this.selectedCardIndex]
      return {
        id: `${this.identifier}-${this.selectedCardIndex}`,
        ...cardData,
      }
    }
  },
  methods: {
    setCarouselIndex (index) {
      // This func is called localy and directly on the component instance from a parent
      const mediaRef = this.getSelectedCard.type === 'video' ? this.$refs.videoPlayer.$el : this.$refs.imageMedia
      const mediaHeight = mediaRef ? mediaRef.getBoundingClientRect().height : null
      if (mediaHeight && (!this.keepHeightConstant || !this.assetHeight || mediaHeight > this.assetHeight)) {
        this.assetHeight = mediaHeight
      }

      const changeIndex = () => {
        this.selectedCardIndex = index
        this.$emit('update:carousel-index', this.selectedCardIndex)
      }

      const newItem = this.items[index]
      if (
        index !== this.selectedCardIndex && 
          (this.getSelectedCard.image || this.getSelectedCard.thumbnail) 
          !== (newItem.image || newItem.thumbnail)
      ) {
        // We want to make sure that the image media is loaded before we switch to the next card
        this.loadingAsset = true
        const img = new Image()
        img.src = newItem.image || newItem.thumbnail
        img.onload = () => {
          this.loadingAsset = false
          changeIndex()
        }
      } else {
        changeIndex() // In other cases, we have no loading protocol so we change the index immediately
      }
    },
    playVideoAtTimestamp (time) {
      // This func is called directly on the component instance from a parent
      const videoPlayer = this.$refs.videoPlayer
      if (videoPlayer) videoPlayer.playVideoAtTimestamp(time)
    }
  }
}
</script>

<style scoped lang="css">
.carousel-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  width: 26px;
  padding: 4px;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(18px);
  box-shadow: 0px 3px 3px -1.5px rgba(0, 0, 0, 0.12);
  transition: 
    width 150ms ease-in-out,
    background-color 150ms ease-in-out,
    backdrop-filter 150ms ease-in-out;
}
.carousel-nav:hover {
  width: 30px;
  background-color: rgba(0, 0, 0, 0.68);
  backdrop-filter: blur(26px);
}
.carousel-nav.nav-right {
  right: -4px;
  justify-content: flex-start;
  border-radius: 6px 0px 0px 6px;
}
.carousel-nav.nav-left {
  left: -4px;
  justify-content: flex-end;
  border-radius: 0px 6px 6px 0px;
}
.carousel-nav .nav-chevron {
  color: rgba(255, 255, 255, 0.85);
  flex-shrink: 0;
  transition: color 150ms ease-in-out;
}
.carousel-nav:hover .nav-chevron {
  color: rgba(255, 255, 255, 1);
}
.carousel-nav.nav-right .nav-chevron {
  transform: rotate(180deg);
}

.v-leave-active {
  cursor: default;
  transition: opacity 150ms ease-in-out;
  width: 30px;
}
.v-leave-from {
  opacity: 1;
}
.v-leave-to {
  opacity: 0;
}

.spinner-enter-active {
  transition: opacity 75ms ease-in-out;
  transition-delay: 150ms;
}
.spinner-leave-active {
  transition: opacity 75ms ease-in-out;
}
.spinner-enter-from, .spinner-enter, .spinner-leave-to {
  opacity: 0;
}
.spinner-enter-to, .spinner-leave-from {
  opacity: 1;
}
</style>