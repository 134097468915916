<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 13.6V6.4C16 5.55992 16 5.13988 15.8365 4.81902C15.6927 4.53677 15.4632 4.3073 15.181 4.16349C14.8602 4 14.4401 4 13.6 4H6.4C5.55992 4 5.13988 4 4.81902 4.16349C4.53677 4.3073 4.3073 4.53677 4.16349 4.81902C4 5.13988 4 5.55992 4 6.4V13.6C4 14.4401 4 14.8602 4.16349 15.181C4.3073 15.4632 4.53677 15.6927 4.81902 15.8365C5.13988 16 5.55992 16 6.4 16H13.6C14.4401 16 14.8602 16 15.181 15.8365C15.4632 15.6927 15.6927 15.4632 15.8365 15.181C16 14.8602 16 14.4401 16 13.6Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 13V7V13Z"
      fill="currentColor"
    />
    <path
      d="M10 13V7"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ABIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

    <style scoped></style>
