<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5 13.5V6.5C16.5 5.67157 15.8284 5 15 5H5C4.17157 5 3.5 5.67157 3.5 6.5V13.5C3.5 14.3284 4.17157 15 5 15H15C15.8284 15 16.5 14.3284 16.5 13.5Z" 
    stroke="currentColor" stroke-width="1.5"/>
    <path d="M10 5V15" 
    stroke="currentColor" stroke-width="1.5"/>
  </svg>
</template>

<script>
export default {
  name: 'PlanSettingsIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>