// Engagement Metrics
const engagementColumns = [
  {
    title: 'Test',
    key: 'test',
    description: 'The number of times your ads achieved an outcome, based on the objective you selected.'
  },
  {
    title: 'Who the what',
    key: 'whoTheWhat',
    description: 'Test desc'
  }
]

const pagePostMetrics = [
  {
    title: 'Page Likes',
    key: 'pageLikes'
  },
  {
    title: 'Page Engagement',
    key: 'pageEngagement'
  }
]

const callingMetrics = [
  {
    title: 'Calls Initiated',
    key: 'callsInitiated'
  },
  {
    title: 'Calls Connected',
    key: 'callsConnected'
  }
]

const messagingMetrics = [
  {
    title: 'Messages Sent',
    key: 'messagesSent'
  },
  {
    title: 'Messages Received',
    key: 'messagesReceived'
  }
]

const mediaMetrics = [
  {
    title: 'Media Views',
    key: 'mediaViews'
  },
  {
    title: 'Media Engagement',
    key: 'mediaEngagement'
  }
]

const clicksMetrics = [
  {
    title: 'Link Clicks',
    key: 'linkClicks'
  },
  {
    title: 'Button Clicks',
    key: 'buttonClicks'
  }
]

const awarenessMetrics = [
  {
    title: 'Reach',
    key: 'reach'
  },
  {
    title: 'Impressions',
    key: 'impressions'
  }
]

const engagementMetrics = {
  sectionId: 'engagement',
  metrics: engagementColumns,
  subSections: [
    {
      sectionId: 'Page Post',
      domId: 'page-post',
      metrics: pagePostMetrics
    },
    {
      sectionId: 'Calling',
      domId: 'calling',
      metrics: callingMetrics
    },
    {
      sectionId: 'Messaging',
      domId: 'messaging',
      metrics: messagingMetrics
    },
    {
      sectionId: 'Media',
      domId: 'media',
      metrics: mediaMetrics
    },
    {
      sectionId: 'Clicks',
      domId: 'clicks',
      metrics: clicksMetrics
    },
    {
      sectionId: 'Awareness',
      domId: 'awareness',
      metrics: awarenessMetrics
    }
  ]
}

Object.freeze(engagementMetrics)

const engagementSearchList = [
  ...engagementColumns,
  ...pagePostMetrics,
  ...callingMetrics,
  ...messagingMetrics,
  ...mediaMetrics,
  ...clicksMetrics,
  ...awarenessMetrics
]

export { engagementMetrics, engagementSearchList }
