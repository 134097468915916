<template>
  <div class="date-range-dropdown rounded-2xl bg-white overflow-hidden">
    <div class="flex flex-col gap-1 p-4 border-r border-border-normal">
      <div v-for="preset in Object.keys(presetDateRanges)" :key="`range-preset-${preset}`"
      class="preset-range no-select" :class="{'selected': localSelectedPresetRange === preset}"
      @click="presetRangeClicked(preset)">
        <div>{{ formatPresetString(preset) }}</div>
        <transition>
          <NewCheckmarkIcon v-if="localSelectedPresetRange === preset" class="ml-auto text-icon-normal" />
        </transition>
      </div>
    </div>
    <div class="top-bar-calendar">
      <v-date-picker 
        :value="localSelectedDateRange"
        @input="customRangeSelected"
        :max-date="new Date()"
        is-range
      />
    </div>
    <div class="col-span-2 w-full px-4 pt-3 mb-3 border-t border-border-normal">
      <div class="flex items-center gap-2 w-full p-1.5 rounded-md bg-neutral-25">
        <div style="padding: 5px">
          <InfoIcon class="text-text-subdued" />
        </div>
        <BaseText type="body" size="sm" class="relative top-px text-text-muted">
          {{ infoLabelText }}
        </BaseText>
      </div>
    </div>
    <div class="col-span-2 w-full flex px-4 mb-4">
      <button class="px-2 py-1.5 rounded-md font-medium text-text-muted text-sm transition hover:bg-neutral-25"
      @click="$emit('close')">
        Cancel
      </button>
      <BaseButton class="ml-auto" primary animate :disabled="!changesMade"
      @click="applyChanges">
        Apply
      </BaseButton>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import NewCheckmarkIcon from '../../globals/Icons/NewCheckmarkIcon.vue'
  import InfoIcon from '../../globals/Icons/InfoIcon.vue'

  export default {
    name: 'DateRangeSelectorDropdown',
    components: {
      NewCheckmarkIcon,
      InfoIcon
    },
    props: {
      selectedDateRange: {
        type: Object,
        default: () => {}
      },
      dateFilterLabel: {
        type: String,
        default: "All Time"
      }
    },
    data () {
      return {
        localSelectedDateRange: this.selectedDateRange,
        localSelectedPresetRange: null,
        changesMade: false,
        presetDateRanges: {
          today: {start: floorDate(new Date(new Date().setDate(new Date().getDate() - 1))), end: new Date()},
          yesterday: {start: floorDate(new Date(new Date().setDate(new Date().getDate() - 2))), end: floorDate(new Date())},
          last7Days: {start: floorDate(new Date(new Date().setDate(new Date().getDate() - 7))), end: new Date()},
          last30Days: {start: floorDate(new Date(new Date().setDate(new Date().getDate() - 30))), end: new Date()},
          last90Days: {start: floorDate(new Date(new Date().setDate(new Date().getDate() - 90))), end: new Date()},
          last365Days: {start: floorDate(new Date(new Date().setDate(new Date().getDate() - 365))), end: new Date()},
          allTime: {}
        }
      }
    },
    mounted () {
      // Determines if the selected date range is one of the preset date ranges and sets state accordingly
      if (!this.selectedDateRange || !this.selectedDateRange?.start || !this.selectedDateRange?.end) {
        this.localSelectedPresetRange = 'allTime'
        this.localSelectedDateRange = null
      } else {
        for (const [preset, range] of Object.entries(this.presetDateRanges)) {
          if (preset === 'allTime') continue;
          if (areDateRangesEqual(range, this.selectedDateRange)) {
            this.localSelectedPresetRange = preset
            this.localSelectedDateRange = null
            break;
          }
        }
      }

      // Set the calendar color variable overrides based on theme
      const root = document.documentElement;
      console.log('theme', this.getTheme)
      switch (this.getTheme) {
        case 'blue':
          root.style.setProperty('--calendar-selected-bg', '#1F69FF')
          root.style.setProperty('--calendar-selecting-border', '#83BCF5')
          root.style.setProperty('--calendar-highlight-bg', '#EFF7FF')
          root.style.setProperty('--calendar-highlight-text', '#06247D')
          break;
        case 'green':
          root.style.setProperty('--calendar-selected-bg', '#00A878')
          root.style.setProperty('--calendar-selecting-border', '#64D2A6')
          root.style.setProperty('--calendar-highlight-bg', '#E2F8EF')
          root.style.setProperty('--calendar-highlight-text', '#003D34')
          break;
        case 'purple':
          root.style.setProperty('--calendar-selected-bg', '#6534DF')
          root.style.setProperty('--calendar-selecting-border', '#A398F5')
          root.style.setProperty('--calendar-highlight-bg', '#F2F0FE')
          root.style.setProperty('--calendar-highlight-text', '#2D1272')
          break;
        case 'red':
          root.style.setProperty('--calendar-selected-bg', '#ED615A')
          root.style.setProperty('--calendar-selecting-border', '#EA948F')
          root.style.setProperty('--calendar-highlight-bg', '#FDF2F1')
          root.style.setProperty('--calendar-highlight-text', '#762622')
      }
    },
    computed: {
      ...mapGetters('MiscModule', ['getTheme']),
      infoLabelText () {
        const type = ['SpyderView', 'DiscoveryBrandView'].includes(this.$route.name) 
          ? 'that were live' 
          : 'saved'
        return `Only show ads ${type} between these dates`
      }
    },
    methods: {
      presetRangeClicked (range) {
        this.localSelectedPresetRange = range
        this.localSelectedDateRange = null
        this.changesMade = true
      },
      customRangeSelected (range) {
        if (!range) return;
        this.localSelectedPresetRange = null
        this.localSelectedDateRange = range
        this.changesMade = true
      },
      applyChanges () {
        if (this.localSelectedDateRange) {
          this.$emit('update:selectedDateRange', this.localSelectedDateRange)
          this.$emit('update:dateFilterLabel', 'Custom Range')
        } else {
          this.$emit('update:selectedDateRange', this.presetDateRanges[this.localSelectedPresetRange])
          this.$emit('update:dateFilterLabel', this.formatPresetString(this.localSelectedPresetRange, true))
        }
        this.changesMade = false
      },
      formatPresetString (preset, isTitleCase = false) { // Converts camelCase to Sentence case (or Title Case)
        let sentenceCaseStr = preset.replace(/([A-Z])|(\d+)(?=[A-Z])/g, ' $1$2').replace(/(\d+)/, ' $1').trim();
        if (!isTitleCase) sentenceCaseStr = sentenceCaseStr.toLowerCase();
        return sentenceCaseStr.charAt(0).toUpperCase() + sentenceCaseStr.slice(1);
      }
    }
  }

  const areDateRangesEqual = (range1, range2) => {
    console.log('comparing ranges', range1, range2)
    return (
      range1.start.getFullYear() === range2.start.getFullYear() &&
      range1.start.getMonth() === range2.start.getMonth() &&
      range1.start.getDate() === range2.start.getDate() &&
      range1.end.getFullYear() === range2.end.getFullYear() &&
      range1.end.getMonth() === range2.end.getMonth() &&
      range1.end.getDate() === range2.end.getDate()
    )
  }

  const floorDate = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate())
  }
</script>

<style scoped>
  .date-range-dropdown {
    display: grid;
    grid-template-columns: 184px 1fr;
    width: 465px;
    box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  }
  .preset-range {
    display: flex;
    width: 100%;
    padding: 0.375rem 0.5rem;
    border-radius: 0.375rem;
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.25rem;
    background-color: transparent;
    color: #5E6678;
    transition: background-color 100ms ease-in-out, color 100ms ease-in-out;
  }
  .preset-range:hover, .preset-range.selected {
    background-color: #F6F8FA;
    color: black;
  }

  .v-enter-active, .v-leave-active {
    transition: opacity 100ms ease-in-out;
  }
  .v-enter-from, .v-enter, .v-leave-to {
    opacity: 0;
  }
  .v-enter-to, .v-leave-from {
    opacity: 1;
  }

  /* class is applied to elements we don't want users to be able to select text on */
  .no-select {
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
</style>

<style>
  /* Overriding styles for the calendar component */
  /* Incredibly fucked, but it works */
  .top-bar-calendar .vc-container {
    width: 100%;
    padding-left: 4px;
    padding-right: 4px;
    margin-top: 16px;
    margin-bottom: 8px;
    border: none;

    /* Overriding v-calendar's color variables (since blue is default, we override blue) */
    --blue-100: white;
    --blue-200: var(--calendar-highlight-bg, #EFF7FF);
    --blue-600: var(--calendar-selected-bg, #1F69FF);
    --blue-700: var(--calendar-selecting-border, #83BCF5);
    --blue-900: var(--calendar-highlight-text, #06247D);
  }
  .top-bar-calendar .vc-container .vc-nav-popover-container {
    background-color: #06070F !important;
  }
  .top-bar-calendar .vc-container .vc-nav-item.is-active {
    color: black !important;
  }
  .top-bar-calendar .vc-container .vc-nav-item {
    border-color: transparent;
  }
  .top-bar-calendar .vc-container .vc-arrows-container {
    background-color: rgba(73, 114, 146, 0.04);
    border-radius: 8px;
    padding: 0.25rem;
    width: calc(100% - 1rem);
    margin-left: 0.5rem;
  }
  .top-bar-calendar .vc-container .vc-arrow {
    background-color: white;
    border-radius: 4px;
  }
  .top-bar-calendar .vc-container .vc-arrow svg {
    transform: scale(0.75);
  }
  .top-bar-calendar .vc-container .vc-title {
    position: relative;
    bottom: 2px;
    z-index: 5000;
    margin-bottom: 18px;
    color: #2F3547;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  .top-bar-calendar .vc-container .vc-weekday {
    font-weight: 500;
    color: #5E6678;
    margin-bottom: 4px;
  }
  .top-bar-calendar .vc-container .vc-day {
    font-weight: 500;
    color: #5E6678;
  }
  .top-bar-calendar .vc-container .vc-day .vc-day-content {
    border-radius: 5px !important;
    transition: background-color 150ms ease-in-out, color 100ms ease-in-out;
    padding-bottom: 1px;
  }
  .top-bar-calendar .vc-container .vc-day .vc-day-content:focus {
    outline: none;
  }
  .top-bar-calendar .vc-container .vc-day .vc-day-content:hover {
    background-color: #F8FAFB;
  }
  .top-bar-calendar .vc-container .vc-day .vc-day-content.is-disabled {
    background-color: transparent !important;
    font-weight: 500;
    color: #DFE1E6;
    cursor: default;
  }
  .top-bar-calendar .vc-highlights + .vc-day-content {
    /* Disable hover/focus color states when the day is in the highlighted range */
    background-color: transparent !important;
    font-weight: 600 !important;
  }
  .top-bar-calendar .vc-container .vc-day.is-not-in-month .vc-day-content {
    opacity: 0.6 !important;
    color: #DFE1E6 !important;
    font-weight: 400 !important;
  }
  .top-bar-calendar .vc-container .vc-day .vc-highlights .vc-day-layer .vc-highlight {
    border-radius: 5px !important;
    transition: background-color 100ms ease-in-out;
  }

  /* Styling for the 'in-between' highlights */
  .top-bar-calendar .vc-container .vc-day .vc-highlights .vc-day-layer .vc-highlight.vc-highlight-base-start {
    border-radius: 0 !important;
  }
  .top-bar-calendar .vc-container .vc-day.vc-day.weekday-position-7 .vc-highlights .vc-day-layer .vc-highlight.vc-highlight-base-start {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
  .top-bar-calendar .vc-container .vc-day .vc-highlights .vc-day-layer .vc-highlight.vc-highlight-base-end {
    border-radius: 0 !important;
  }
  .top-bar-calendar .vc-container .vc-day.vc-day.weekday-position-1 .vc-highlights .vc-day-layer .vc-highlight.vc-highlight-base-end {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }
  .top-bar-calendar .vc-container .vc-day .vc-highlights .vc-day-layer .vc-highlight.vc-highlight-base-middle {
    border-radius: 0 !important;
  }
  .top-bar-calendar .vc-container .vc-day.weekday-position-1 .vc-highlights .vc-day-layer .vc-highlight.vc-highlight-base-middle {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }
  .top-bar-calendar .vc-container .vc-day.weekday-position-7 .vc-highlights .vc-day-layer .vc-highlight.vc-highlight-base-middle {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
</style>