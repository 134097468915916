<template>
  <div class="h-10 flex flex-col justify-center ml-1.5">
    <div class="filter-item relative flex items-center pl-2 gap-1 rounded-full border border-border-normal cursor-pointer bg-white"
    @click="isExpanded = true" :class="{'expanded': isExpanded}">
      <div class="flex items-center gap-1.5 my-1 mr-0.5">
        <div class="relative" @mouseenter="iconHovered = true" @mouseleave="iconHovered = false">
          <component :is="icon.component" class="text-icon-normal" />
          <transition>
            <div v-if="iconHovered" class="filter-type-tooltip z-50 pb-2" @mouseenter="iconHovered = false">
              <div class="px-2 py-1 rounded-md shadow-lg bg-neutral-700 text-xs text-white whitespace-nowrap">
                {{ typeLabel }}
              </div>
            </div>
          </transition>
        </div>
        <div v-for="(selection, index) in selections.slice(0,3)" :key="index" 
        class="flex text-sm font-medium text-text-muted">
          <div class="truncate" :style="{maxWidth: '150px'}">
            {{ selection.name }}
          </div>
          <div v-if="index !== selections.length - 1">,</div>
        </div>
        <div v-if="selections.length > 3" class="text-sm text-text-muted">
          +{{ selections.length - 3 }}
        </div>
      </div>
      <div v-if="isExpanded" v-on-clickaway="() => { isExpanded = false }">
        <FilterSearch  
          :items="options" 
          :selections="selections" 
          :filterType="filterType" 
          :placeholder="searchPlaceholder" 
          :isFocused="isFocused" :hasNextItem="hasNextItem"
          isSubType 
          @focusNext="focusNext" 
          @focusPrev="focusPrev" 
          @focusCurr="$emit('focusCurr')"
          @collapseSearch="isExpanded = false"
          @removeFilterType="() => {removeFilterType(); focusPrev()}"
        />
      </div>
      <div v-else-if="selections.length === 0" class="w-10 h-5 rounded-full bg-neutral-25 cursor-pointer" />
      <div v-if="!isExpanded" @click.stop="removeFilterType"
      class="mr-1 rounded-full transition-colors ease-in-out hover:bg-neutral-25" >
        <TagRemoveIcon stroke="#808899" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway2'
import FilterSearch from './FilterSearch.vue'
import TagRemoveIcon from '../../globals/Icons/TagRemoveIcon.vue'

export default {
  name: 'FilterItem',
  components: {
    FilterSearch,
    TagRemoveIcon
  },
  mixins: [clickaway],
  props: {
    filterType: {
      type: String,
      required: true
    },
    icon: {
      type: Object,
      required: true
    },
    selections: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    isFocused: {
      type: Boolean,
      default: false
    },
    hasNextItem: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('BoardsModule', ['getBoards']),
    ...mapGetters('TagsModule', ['getTags']),
    typeLabel () {
      switch (this.filterType) {
        case 'format': return 'Formats'
        case 'board': return 'Boards'
        case 'platform': return 'Platforms'
        case 'brand': return 'Brands'
        case 'tag': return 'Tags'
        case 'language': return 'Languages'
        case 'status': return 'Statuses'
        case 'niche': return 'Niches'
        case 'contentFilter': return 'Content Styles'
        case 'marketTarget': return 'Targets'
      }
    },
    searchPlaceholder () {
      return `Search ${this.typeLabel}`
    }
  },
  watch: {
    isFocused: {
      immediate: true,
      handler (newVal) {
        this.isExpanded = newVal
      }
    }
  },
  data () {
    return {
      isExpanded: true,
      iconHovered: false
    }
  },
  methods: {
    removeFilterType () {
      this.$emit('remove-filter-type', this.filterType)
    },
    focusNext () {
      this.$emit('focusNext')
      this.isExpanded = false
    },
    focusPrev () {
      this.$emit('focusPrev')
      this.isExpanded = false
    }
  }
}
</script>

<style scoped>
  .filter-item {
    transition: margin-top 100ms ease-in-out, margin-bottom 100ms ease-in-out;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .filter-item.expanded {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .filter-type-tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  .v-enter-active, .v-leave-active {
    transition: opacity 150ms ease-in-out;
  }
  .v-enter-from, .v-enter, .v-leave-to {
    opacity: 0;
  }
  .v-enter-to, .v-leave-from {
    opacity: 1;
  }
</style>
