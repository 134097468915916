<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M5.5 4.75H4.75C3.92157 4.75 3.25 5.42157 3.25 6.25V7M14.5 4.75H15.25C16.0784 4.75 16.75 5.42157 16.75 6.25V7M16.75 13V13.75C16.75 14.5784 16.0784 15.25 15.25 15.25H14.5M5.5 15.25H4.75C3.92157 15.25 3.25 14.5784 3.25 13.75V13" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7 8.5H13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7 11.5H11.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'KeywordSearchIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>