// Boards Module
import firebase from '@/api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'
import ForeplayAPI from '@/api/foreplayServer'

// Start Database
const db = firebase.firestore()

export default {
  namespaced: true,
  state: {
    boards: [],
    folders: [],
    teamBoards: []
  },
  getters: {
    // Get Boards
    getBoards: state => state.boards,
    // Get Folders
    getFolders: state => state.folders
  },
  mutations: {
    SET_BOARDS (state, boards) {
      state.boards = boards
    },
    SET_FOLDERS (state, folders) {
      console.log('setting folders')
      state.folders = folders
    }
  },
  actions: {
    async fetchFolders ({ commit }) {
      const userTeam = await FirebaseAPI.Teams.getUserTeam()

      // If the user is on a team get the team folders
      if (userTeam) {
        const folders = await FirebaseAPI.Folders.getAllByTeam(userTeam.id)

        commit('SET_FOLDERS', folders.sort((a, b) => a.name > b.name ? 1 : -1))
        return
      }

      // Get a users folders
      const folders = await FirebaseAPI.Folders.getAllByUser()
      commit('SET_FOLDERS', folders.sort((a, b) => a.name > b.name ? 1 : -1))
    },
    // Fetch Boards
    async fetchBoards ({ commit }) {
      const userTeam = await FirebaseAPI.Teams.getUserTeam()

      const boards = userTeam
        ? await FirebaseAPI.Boards.getAllByTeam(userTeam.id)
        : await FirebaseAPI.Boards.getAllByUser()


      if (!firebase.auth().currentUser) {
        commit('SET_BOARDS', boards.sort((a, b) => a.name > b.name ? 1 : -1))
        return
      }

      // Get the ad count for each board
      async function getAdCount () {
        const adCounts = await ForeplayAPI.Ads.getBoardsAdCount(userTeam ? userTeam.id : undefined)
        const boardsWithAdCount = boards.map(board => ({ ...board, adCount: adCounts.find(adCount => adCount.key === board.id)?.doc_count || 0 }))

        commit('SET_BOARDS', boardsWithAdCount.sort((a, b) => a.name > b.name ? 1 : -1))
      }

      getAdCount()
      commit('SET_BOARDS', boards.sort((a, b) => a.name > b.name ? 1 : -1))
    }
  }
}
