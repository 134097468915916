<template>
  <div class="w-full flex flex-col p-1 gap-5">
    <div class="flex items-center gap-2 py-1">
      <InfoResponseIcon class="text-icon-normal" />
      <BaseText type="body" size="sm" class="text-text-muted">
        Only brands whose ads you have saved will be displayed here. Explore all brands in Discovery.
      </BaseText>
    </div>
    <BrandsList 
      :brands="brands"
      :loading-brands="loadingBrands"
      :favorite-filter="favoriteFilter"
      :favorited-brands="favoritedBrands"
      :infinite-id="infiniteId"
      @favorite="favoriteBrand($event)"
      @get-more-brands="getMoreBrands($event)"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import firebase from '@/api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'
import BrandsList from '../brands/BrandsList.vue'

import FavoriteIcon from '../globals/Icons/FavoriteIcon.vue'
import InfoResponseIcon from '../globals/Icons/ResponseAlertIcons/InfoResponseIcon.vue'

export default {
  name: 'LibraryBrandView',
  components: {
    BrandsList,
    FavoriteIcon,
    InfoResponseIcon,
  },
  props: {
    // Advertisements
    favoriteFilter: {
      type: Boolean,
      default: () => false
    },
    selectedNiches: {
      type: Array,
      default: () => []
    },
    sortOrder: {
      type: Array,
      default: () => [{ name: 'A-Z', value: 'asc' }]
    },
    updateQuery: {
      type: Number,
      default: () => null
    }
  },
  data () {
    return {
      brands: [],
      favoritedBrands: [],
      brandTotal: '0',
      loadingBrands: false,
      adLibraryId: null,
      infiniteId: +new Date(),
      favoritedBrands: [],
    }
  },
  computed: {
    ...mapGetters('AdvertisementsModule', ['getAdsBeingSaved']),
    ...mapGetters('AuthModule', ['getUser', 'getUserEmail', 'getUserName', 'getTeam']),
    ...mapGetters('BoardsModule', ['getBoards'])
  },
  watch: {
    updateQuery (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchBrands()
      }
    },
    favoriteFilter (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchBrands()
      }
    },
    selectedNiches (newValue, oldValue) {
      if (newValue[0]?.name !== oldValue[0]?.name) {
        this.fetchBrands()
      }
    }
  },
  mounted () {
    this.favoritedBrands = this.getUser.favoritedBrands || []
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_USER']),
    async favoriteBrand (brandId) {
      const favoritedBrands = this.favoritedBrands.includes(brandId)
        ? this.favoritedBrands.filter(bId => bId !== brandId)
        : [...this.favoritedBrands, brandId]

      // Update the firebase user
      const user = firebase.auth().currentUser
      await FirebaseAPI.Users.update(user.uid, {
        favoritedBrands
      })

      // Add to local state
      this.favoritedBrands = favoritedBrands

      // Update global state
      this.SET_USER({
        ...this.getUser,
        favoritedBrands
      })
    },
    async fetchFavoritedBrands () {
      const db = firebase.firestore()
      this.lastDocId = null
      this.loadingBrands = true

      // Loop through all the favorited brands and query them
      const nichesArray = this.selectedNiches.map(n => n.name)

      try {
        let allBrands = await Promise.all(
          this.favoritedBrands.map(async brandId => {
            let query = db.collection('user-brands').where('brandId', '==', brandId)

            if (nichesArray.length) {
              query = query.where('niches', 'array-contains-any', nichesArray)
            }

            const snapshot = await query.get()

            // If someone has favourited a discovery brand and havent saved it
            if (snapshot.empty) return

            const docInstance = snapshot.docs[0]

            return {
              ...docInstance.data(),
              id: docInstance.id
            }
          })
        )
        allBrands = allBrands.filter(brand => brand) // Remove any undefined values

        // Extend the brands with the unique brand data
        const extendedBrands = await Promise.all(
          allBrands.map(async brand => {
            const uniqueBrand = await FirebaseAPI.UniqueBrands.get(brand.brandId)

            return {
              ...uniqueBrand,
              ...(uniqueBrand.publisherPlatforms && {
                publisherPlatforms: uniqueBrand.publisherPlatforms.filter(platform => platform !== 'audience_network')
              }),
              ...brand
            }
          })
        )

        // Sort all the values Based on the other filters
        const sortedBrands = extendedBrands.filter(brand => brand).sort((brandA, brandB) => brandA.name < brandB.name ? -1 : 1)
        // Set the filtered values into local state
        this.brands = sortedBrands
      } catch (error) {
        console.error(error)
        this.$showAlert({
          type: 'error',
          message: 'Error fetching favorited brands. See console for details'
        })
      } finally {
        this.loadingBrands = false
        this.infiniteId += 1
      }
    },
    async fetchBrands (load = true) {
      this.brands = []
      this.loadingBrands = load

      if (this.favoriteFilter) {
        await this.fetchFavoritedBrands()
        return
      }

      try {
        // Setup
        const query = await this.firebaseQuery()
        const snapshot = await query.limit(20).get()

        const { size } = snapshot
        this.lastDocId = size ? snapshot.docs[size - 1].id : null

        const newDocs = await Promise.all(snapshot.docs?.map(async (doc) => {
          // Get the unique brand here so we can find the brandId
          const uniqueBrand = await FirebaseAPI.UniqueBrands.get(doc.data().brandId)

          return {
            ...uniqueBrand,
            ...(uniqueBrand.publisherPlatforms && {
              publisherPlatforms: uniqueBrand.publisherPlatforms.filter(platform => platform !== 'audience_network')
            }),
            id: doc.id,
            ...(doc.data() || [])
          }
        }))

        this.brands = newDocs
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingBrands = false
        this.infiniteId += 1
      }

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 600)
    },
    async getMoreBrands ($state) {
      if (!this.lastDocId) {
        $state.complete()
        return
      }

      const query = await this.firebaseQuery()

      const db = firebase.firestore()
      const doc = await db.collection('user-brands').doc(this.lastDocId).get()

      // Start after requires a snapshot not a doc id
      const offsetQuery = query.startAfter(doc)
      const snapshot = await offsetQuery.limit(20).get()

      const size = snapshot.size

      if (!size) {
        $state.complete()
        return
      }

      this.lastDocId = snapshot.size ? snapshot.docs[size - 1].id : null

      const newDocs = await Promise.all(snapshot.docs?.map(async (doc) => {
        // Get the unique brand here so we can find the brandId
        const uniqueBrand = await FirebaseAPI.UniqueBrands.get(doc.data().brandId)

        return {
          ...uniqueBrand,
          ...(uniqueBrand.publisherPlatforms && {
            publisherPlatforms: uniqueBrand.publisherPlatforms.filter(platform => platform !== 'audience_network')
          }),
          id: doc.id,
          ...(doc.data() || [])
        }
      }))
      this.brands = this.brands.concat(newDocs)
      $state.loaded()
    },
    async firebaseQuery () {
      // Setup
      const db = firebase.firestore()

      let query
      const createdCondition = this.getTeam ? ['teamId', '==', this.getTeam.id] : ['createdBy', '==', this.getUser.user_id]

      if (this.selectedNiches.length) {
        // Check for selected niches
        const nichesArray = this.selectedNiches.map(n => n.name)
        query = await db
          .collection('user-brands')
          .where('niches', 'array-contains-any', nichesArray)
          .where(...createdCondition)
          .orderBy('name', this.sortOrder[0].value)
      } else {
        query = await db
          .collection('user-brands')
          .where(...createdCondition)
          .orderBy('name', this.sortOrder[0].value)
      }

      return query
    },
  }
}
</script>

<style scoped lang="sass">
</style>
