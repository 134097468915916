<template>
  <BaseCard
    class="h-max mt-5 w-4/5 py-5 flex justify-between items-center"
    :no-padding="true"
  >
    <div
      class="flex justify-between items-center gap-5 w-full px-5"
    >
      <img
        v-if="getUser?.avatar"
        :src="getUser?.avatar"
        class="w-10 h-10 rounded-full"
      >
      <img
        v-else
        src="../../assets/icons/default-avatar.png"
        class="flex items-center justify-center w-11 h-11 rounded-full border border-border-normal shadow-sm"
      >
      <div
        class="flex flex-col justify-between"
      >
        <BaseText
          type="label"
          size="md"
          class="text-text-muted "
        >
          {{ getUser?.first_name ? displayGreeting(getUser?.first_name.split(' ')[0]) : displayGreeting(getUser?.name.split(' ')[0]) }}
        </BaseText>
        <BaseText
          type="body"
          size="sm"
          class="text-text-normal"
        >
          {{ getUser?.email }}
        </BaseText>
      </div>
      <button
        class="w-max flex justify-between items-center gap-2 flex-nowrap ml-auto"
        @click="accountSettingsClicked"
      >
        <BaseButton
          type="primary"
          size="sm"
          outlined
          class="w-max flex justify-between items-center gap-2 flex-nowrap ml-auto"
        >
          <div
            class="flex gap-2 items-center"
          >
            <img
              src="../../assets/icons/settings-icon.svg"
              class="w-5 h-5 -ml-1"
            >
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              Account Settings
            </BaseText>
          </div>
        </BaseButton>
      </button>
    </div>
  </BaseCard>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'AccountCard',
  computed: {
    ...mapGetters('AuthModule', ['getUser']),
  },
  methods: {
    ...mapMutations('MiscModule', ['SET_SETTINGS_DRAWER_VIEW_MODE']),
    displayGreeting (name) {
      const currentTime = new Date()
      const currentHour = currentTime.getHours()
      let greeting = ''
      if (!name) {
        greeting = 'Welcome!'
      } else if (currentHour < 12) {
        greeting = 'Good Morning ' + name + '!'
      } else if (currentHour < 18) {
        greeting = 'Good Afternoon ' + name + '!'
      } else {
        greeting = 'Good Evening ' + name + '!'
      }
      return greeting
    },
    accountSettingsClicked () {
      this.SET_SETTINGS_DRAWER_VIEW_MODE('account')
    }
  }
}
</script>
<style scoped></style>
