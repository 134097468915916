<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M12.25 3.25V4.75M12.25 4.75V15.25M12.25 4.75H4.75C3.92157 4.75 3.25 5.42157 3.25 6.25V13.75C3.25 14.5784 3.92157 15.25 4.75 15.25H12.25M12.25 15.25V16.75M15.25 15.25C16.0784 15.25 16.75 14.5784 16.75 13.75V6.25C16.75 5.42157 16.0784 4.75 15.25 4.75M7 8.5V11.5L8.875 10L7 8.5Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'ProductIntroIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>