<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10 11.875V2.5M10 11.875L7.375 9.25M10 11.875L12.625 9.25"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.75 4.75H14.5C15.3284 4.75 16 5.42157 16 6.25V14.5C16 15.3284 15.3284 16 14.5 16H5.5C4.67157 16 4 15.3284 4 14.5V6.25C4 5.42157 4.67157 4.75 5.5 4.75H6.25"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

  </svg>
</template>

<script>
export default {
  name: 'SaveIcon',
  props: {
    width: {
      type: Number,
      default: 14
    },
    height: {
      type: Number,
      default: 14
    }
  }
}
</script>

<style scoped></style>
