<template>
  <BaseModal @close="$emit('close')" cardWidth="490px" noPadding noToolbarPadding noExit>
    <template #toolbar>
      <div class="flex w-full gap-3 pb-5 pt-1">
        <div id="tags-brand-container" class="relative">
          <div id="tags-brand-shadow" /> 
          <img src="../../../assets/images/tags-branded-icon.png" class="relative z-50" />
        </div>
        <div class="flex-grow flex flex-col">
          <div class="font-medium text-text-loud">
            Edit Tags
          </div>
          <div class="text-sm text-text-normal">
            Rename, change color, add or delete tags
          </div>
        </div>
        <div class="ml-auto">
          <div id="close-wrapper" @click="$emit('close')">
            <TagRemoveIcon stroke="#5E6678" class="cursor-pointer"/>
          </div>
        </div>
      </div>
    </template>
    <template #default>
      <div class="relative w-full border-t border-neutral-50 pr-1 rounded-b-xl overflow-hidden">
        <div ref="tagsList" class="tags-list w-full flex flex-col pl-1.5 pr-0.5 pb-1.5">
          <!-- Add new tag input row -->
          <div class="relative w-full flex items-center gap-2 py-1 mt-3 mb-1 rounded-lg bg-transparent hover:bg-background-normal 
          transition-colors ease-in-out" :class="{'bg-background-normal': inputFocused}"
          v-on-clickaway="() => {inputFocused = false; showNewTagColorPicker = false}">
            <div class="ml-3">
              <div v-if="newTagName !== ''"
              class="hover-shadow p-1 rounded-md bg-transparent hover:bg-white transition ease-in-out cursor-pointer"
              @click="showNewTagColorPicker = !showNewTagColorPicker">
                <TagIcon :stroke="colors[newTagColor].stroke" :fill="colors[newTagColor].fill"/>
              </div>
              <div v-else class="p-1">
                <PlusIcon stroke="#808899" />
              </div>
            </div>
            <input id="add-tag-input" ref="addTagInput" class="flex-grow py-1 px-2 rounded-md text-sm outline-none bg-transparent" 
            v-model="newTagName" placeholder="Add new tag" @keyup.enter.prevent="createTag" autocomplete="off" spellcheck="false"
            @focus="inputFocused = true" @blur="() => {if (newTagName === '') {inputFocused = false; newTagColor = 'gray'}}"/>
            <div v-if="isCreatingTag" class="p-1 ml-auto" :style="{marginRight: '13px'}">
              <BaseLoadingSpinnerCircle class="text-icon-normal" />
            </div>
            <div v-else-if="newTagName !== ''" @click="createTag"
            class="hover-shadow group p-1 ml-auto mr-3 rounded-md bg-transparent hover:bg-white transition ease-in-out cursor-pointer">
              <CheckmarkIcon className="text-icon-normal group-hover:text-black transition ease-in-out" />
            </div>
            <TagColorPicker v-if="showNewTagColorPicker" @colorChange="newTagColorChange" location="bottom"
            v-on-clickaway="() => {showNewTagColorPicker = false}"/>
          </div>
          <!-- Render the user's tags -->
          <TagRow v-for="tag in getTags" :key="tag.id" :tag="tag" @edit="editTag" @delete="deleteTag" />
        </div>
        <!-- Fade overlay -->
        <div id="list-fade-overlay-bot" />
      </div>
    </template>
  </BaseModal>
</template>

<script>
import FirebaseAPI from '@/api/firebase'
import { mapActions, mapGetters } from 'vuex'
import TagRow from './TagRow.vue'
import TagColorPicker from './TagColorPicker.vue'
import PlusIcon from '../../globals/Icons/PlusIcon.vue'
import TagIcon from '../../globals/Icons/TagIcon.vue'
import CheckmarkIcon from '../../globals/Icons/CheckmarkIcon.vue'
import TagRemoveIcon from '../../globals/Icons/TagRemoveIcon.vue'
import TAG_COLORS from '../../../utils/TagColors'
import { mixin as clickaway } from 'vue-clickaway2'

export default {
  name: 'EditTagsModal',
  mixins: [clickaway],
  components: {
    TagRow,
    TagColorPicker,
    PlusIcon,
    TagIcon,
    CheckmarkIcon,
    TagRemoveIcon
  },
  props: {
    advertisement: { // This optional prop is only used for analytics purposes
      type: Object,
      default: null
    }
  },
  data () {
    return {
      newTagName: '',
      newTagColor: 'gray',
      isCreatingTag: false,
      inputFocused: false,
      showNewTagColorPicker: false,
      colors: TAG_COLORS
    }
  },
  async mounted () {
    // Attach the scroll event listener
    this.scrollTimeout = null
    this.$refs.tagsList.addEventListener('scroll', this.handleScroll)
    await this.fetchTags()
    await this.getTagAdCounts()
  },
  beforeDestroy () {
    // Remove the scroll event listener
    this.$refs.tagsList.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    ...mapGetters('TagsModule', ['getTags']),
    ...mapGetters('AuthModule', ['getTeam']),
  },
  methods: {
    ...mapActions('TagsModule', ['fetchTags', 'getTagAdCounts']),
    async deleteTag (tag) {
      try {
        await new Promise(resolve => setTimeout(resolve, 250)) // We wait 250ms before deleting to let the animation play
        await FirebaseAPI.Tags.deleteTag(tag.id); // Delete the tag and its references from firestore
        await this.fetchTags(); // Fetch the tags again to update the global state
        this.$emit('remove', tag); // Remove the tag from the parent list (if it exists)
        this.$showAlert({
          message: 'Tag deleted successfully',
          type: 'success'
        })
      } catch (error) {
        console.error('Error deleting tag:', error);
        this.$showAlert({
          message: error,
          type: 'error'
        })
      }
    },
    async editTag (tag) {
      const tagEdits = { name: tag.name, color: tag.color };
      try {
        await FirebaseAPI.Tags.update(tag.id, tagEdits);
        await this.fetchTags();
        this.$emit('edit', tag.id, tagEdits);
      } catch (error) {
        console.error('Error editing tag:', error);
        // We don't want to spam users with edit alerts, so only show alert on error
        this.$showAlert({
          message: error,
          type: 'error'
        })
      }
    },
    async createTag () {
      if (this.isCreatingTag || this.newTagName === '') return;
      this.isCreatingTag = true;
      const teamId = this.getTeam ? this.getTeam.id : null;

      try {
        if (this.getTags.some(tag => tag.name === this.newTagName)) {
          this.$showAlert({
            message: `Tag '${this.newTagName}' already exists`,
            type: 'error'
          })
          return;
        }
        await FirebaseAPI.Tags.create(this.newTagName, teamId, this.newTagColor);
        await this.fetchTags();
        this.$showAlert({
          message: 'Tag created successfully',
          type: 'success'
        })
        if (this.advertisement) { // track event Tag Added
          window.analytics.track('Tag Added', {
            tagAdded: true,
            tag: this.newTagName,
            advertisement: this.advertisement.name,
            platform: this.advertisement.publisher_platform,
            adNiche: this.advertisement.niches,
            adFormat: this.advertisement.type
          })
        }
      } catch (error) {
        console.error('Error creating tag:', error);
        this.$showAlert({
          message: error,
          type: 'error'
        })
      } finally {
        this.isCreatingTag = false;
        this.$refs.addTagInput.blur();
        this.inputFocused = false;
        this.newTagName = '';
        this.newTagColor = 'gray';
      }
    },
    newTagColorChange (color) {
      this.newTagColor = color;
    },
    handleScroll () {
      const scrollableElement = this.$refs.tagsList;
      scrollableElement.style.setProperty('--scrollbar-thumb-bg', '#DFE1E7');
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(() => {
        scrollableElement.style.setProperty('--scrollbar-thumb-bg', 'white');
      }, 500);
    }
  },
}
</script>

<style scoped lang="css">
  @property --scrollbar-thumb-bg {
    syntax: '<color>';
    inherits: true;
    initial-value: white;
  }

  #tags-brand-container {
    width: 44px;
    height: 44px;
  }
  #tags-brand-shadow {
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    border-radius: 8px;
    box-shadow: 0px 3px 20px 2px rgba(0,0,0,0.25);
  }
  .tags-list {
    max-height: 394px;
    overflow-y: scroll;
    transition: --scrollbar-thumb-bg 250ms ease-in-out;
  }
  .tags-list::-webkit-scrollbar {
    width: 3px;
  }
  .tags-list::-webkit-scrollbar-thumb {
    /* We use an animated CSS variable to apply the scrollbar fading transitions */
    /* This is because scrollbar pseudo-elements do not support the 'transition' property */
    background-color: var(--scrollbar-thumb-bg);
    border-radius: 18px;
  }
  #add-tag-input {
    transition: box-shadow 150ms ease-in-out, background-color 150ms ease-in-out;
    background-color: transparent;
    max-width: 325px;
  }
  #list-fade-overlay-bot {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 18px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    z-index: 20;
    pointer-events: none;
  }
  #add-tag-input:focus {
    box-shadow: 0px 1px 2px 0px rgba(162, 172, 186, 0.08), 0px 1px 2px 0px rgba(162, 172, 186, 0.16);
    background-color: white !important;
  }
  #add-tag-input::placeholder {
    transition: color 150ms ease-in-out;
    color: #303546;
    font-weight: 500;
  }
  #add-tag-input:focus::placeholder {
    color: #808899;
    font-weight: 400;
  }
  #close-wrapper {
    transition: transform 150ms ease-in-out;
    transform: scale(1);
  }
  #close-wrapper:hover {
    transform: scale(1.10);
  }
  .hover-shadow {
    transition: box-shadow 150ms ease-in-out;
  }
  .hover-shadow:hover {
    box-shadow: 0px 24px 32px -12px rgba(53, 57, 75, 0.12), 0px 1px 2px 0px rgba(162, 172, 186, 0.08), 0px 1px 2px 0px rgba(162, 172, 186, 0.16);
  }
</style>