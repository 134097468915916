// Imports
import firebase from '../config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'

// Setup
const db = firebase.firestore()

// Get All
const getAll = async () => {
  const boards = await db.collection('boards').get()

  return boards.docs?.map(doc => {
    return {
      id: doc.id,
      ...doc.data() || []
    }
  })
}

// Get All By User
const getAllByUser = async () => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const snapshot = await db.collection('boards').where('created_by', '==', user.uid).get()

  return snapshot.docs?.map(doc => {
    return {
      id: doc.id,
      ...doc.data() || []
    }
  })
}

// Get All By User
const getAllByTeam = async (teamId) => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const snapshot = await db.collection('boards').where('teamId', '==', teamId).get()

  return snapshot.docs?.map(doc => {
    return {
      id: doc.id,
      ...doc.data() || []
    }
  })
}

// Get By ID
const getByID = async (boardID) => {
  const boards = await db.collection('boards').doc(boardID).get()

  return {
    id: boards.id,
    ...boards.data()
  }
}

const create = async (payload, folder = null) => {
  const user = firebase.auth().currentUser

  payload.created_by = user.uid // Legacy snake casing
  payload.createdAt = +new Date()

  const { id } = await db.collection('boards').add(payload)

  window.Intercom('trackEvent', 'Created Board', {
    description: payload.description,
    name: payload.name
  })

  // Add board to folder, if applicable
  if (folder) {
    const toAddPayload = { boardIds: folder.boardIds ? [...folder.boardIds, id] : [id] }
    await FirebaseAPI.Folders.update(folder.id, toAddPayload)
  }

  return id
}

// Update Board
const update = async (boardId, payload) => {
  return await db
    .collection('boards')
    .doc(boardId)
    .update(payload)
}

// Remove Board
const remove = async (boardId) => {
  return db.collection('boards').doc(boardId).delete()
}

// ----- Shared Boards -----
const getSharedByID = async (boardId) => {
  return await db.collection('shared_boards').doc(boardId).get()
}

const existingShareLink = async (boardId) => {
  const user = firebase.auth().currentUser;
  return (await db.collection('shared_boards').where('board_id', '==', boardId).where('created_by', '==', user.uid).get())
}

const createShared  = async (boardId) => {
  const user = firebase.auth().currentUser;
  const prevCreatedShare = (await existingShareLink(boardId, user.uid))
  
  if (!prevCreatedShare.empty){
    return prevCreatedShare.docs[0].id
  }

  let payload = {
    created_by: user.uid,
    createdAt : +new Date(),
    board_id: boardId,
    access_level: "all"
  }
  const {id} = await db.collection('shared_boards').add(payload)

  return id;
}


export default {
  getAll,
  getAllByUser,
  getAllByTeam,
  getByID,
  create,
  remove,
  update,
  // Shared boards
  createShared,
  getSharedByID
}
