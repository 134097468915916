<template>
  <div :style="{width: `${width}px`, height: `${height}px`}">
    <iframe 
      src="https://cdn.lottielab.com/l/7CGMPM6d2jr7oE.html"
      frameborder="0"
      width="100%"
      height="100%"
      allow="autoplay"
      title="No Brand Favorites Animation"
    />
  </div>
</template>

<script>
export default {
  name: 'NoBrandFavoritesAnimatedIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>