<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 80 80" fill="none">
    <path d="M40 0C17.9086 0 0 17.9086 0 40C0 48.7352 2.8 56.8164 7.55145 63.395C10.6527 67.6889 16.9329 66.1192 20.7073 62.403C25.6713 57.5156 32.4835 54.5 40 54.5C47.5165 54.5 54.3287 57.5156 59.2927 62.403C63.0671 66.1192 69.3473 67.6889 72.4485 63.395C77.2 56.8164 80 48.7352 80 40C80 17.9086 62.0914 0 40 0Z" 
    fill="url(#paint0_linear_7568_1165)"/>
    <path d="M49.2388 26.1732C49.7413 27.3864 50 28.6868 50 30C50 31.3132 49.7413 32.6136 49.2388 33.8268C48.7363 35.0401 47.9997 36.1425 47.0711 37.0711C46.1425 37.9997 45.0401 38.7362 43.8268 39.2388C42.6136 39.7413 41.3132 40 40 40C38.6868 40 37.3864 39.7413 36.1732 39.2388C34.9599 38.7362 33.8575 37.9997 32.9289 37.0711C32.0003 36.1425 31.2638 35.0401 30.7612 33.8268C30.2587 32.6136 30 31.3132 30 30C30 28.6868 30.2587 27.3864 30.7612 26.1732C31.2638 24.9599 32.0003 23.8575 32.9289 22.9289C33.8575 22.0003 34.9599 21.2638 36.1732 20.7612C37.3864 20.2587 38.6868 20 40 20C41.3132 20 42.6136 20.2587 43.8268 20.7612C45.0401 21.2638 46.1425 22.0003 47.0711 22.9289C47.9997 23.8575 48.7362 24.9599 49.2388 26.1732Z" 
    fill="white" stroke="white" stroke-width="8"/>
    <defs>
      <linearGradient id="paint0_linear_7568_1165" x1="40" y1="0" x2="40" y2="71.0413" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DFE1E7"/>
        <stop offset="1" stop-color="#C1C7D0"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'DefaultAvatarIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>