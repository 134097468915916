var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseModal',{on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('div',{staticClass:"bg-white flex w-full rounded-md items-center gap-x-3 -mb-3"},[_c('img',{staticClass:"w-11 h-11",attrs:{"src":require("../../../assets/images/brief-icon-black-white.png"),"alt":""}}),_c('div',{staticClass:"transform -translate-y-0.5"},[_c('BaseText',{attrs:{"type":"label","size":"md"}},[_vm._v(" Submit your content ")]),_c('div',{staticClass:"text-sm text-gray-500"},[_vm._v(" Upload your completed assets for "+_vm._s(_vm.uploadModalInformation?.briefName && _vm.uploadModalInformation?.briefName)+" ")])],1)])]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"border-t border-border-normal p-5 overflow-scroll scrollbar-hide h-max max-h-128"},[(_vm.uploadModalInformation?.aspectRatios)?_c('div',{staticClass:"border border-border-normal rounded-md p-4 flex flex-col gap-y-4 overflow-y-scroll"},[_c('div',{staticClass:"overflow-y-scroll"},[_c('BaseText',{staticClass:"text-text-muted",attrs:{"type":"body","size":"sm"}},[_vm._v(" Deliverables ")]),_c('div',{staticClass:"flex flex-wrap mt-2.5 gap-3"},[(
                _vm.uploadModalInformation?.aspectRatios &&
                _vm.arrayContainsValue(
                  _vm.uploadModalInformation?.aspectRatios,
                  '16:9'
                )
              )?_c('div',{staticClass:"flex flex-row px-3 rounded-full justify-center items-center border border-border-normal w-max h-9 gap-x-2"},[_c('svg',{attrs:{"slot":"icon","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"},slot:"icon"},[_c('rect',{attrs:{"x":"3","y":"6","width":"18","height":"12","rx":"2","fill":"url(#paint0_linear_5369_22401)","fill-opacity":"0.2","stroke":"currentColor","stroke-width":"1.5"}}),_c('defs',[_c('linearGradient',{attrs:{"id":"paint0_linear_5369_22401","x1":"12","y1":"6","x2":"12","y2":"18","gradientUnits":"userSpaceOnUse"}},[_c('stop',{attrs:{"stop-color":"currentColor"}}),_c('stop',{attrs:{"offset":"1","stop-color":"currentColor","stop-opacity":"0"}})],1)],1)]),_c('div',{staticClass:"flex gap-1 items-center"},[_c('BaseText',{staticClass:"text-text-muted",attrs:{"type":"label","size":"sm"}},[_vm._v(" 16:9 ")]),_c('BaseText',{staticClass:"text-neutral-500",attrs:{"type":"body","size":"sm"}},[_vm._v(" (1920 x 1080) ")])],1)]):_vm._e(),(
                _vm.uploadModalInformation?.aspectRatios &&
                _vm.arrayContainsValue(
                  _vm.uploadModalInformation?.aspectRatios,
                  '9:16'
                )
              )?_c('div',{staticClass:"flex flex-row px-3 rounded-full justify-center items-center border border-border-normal w-max h-9 gap-x-2"},[_c('svg',{attrs:{"slot":"icon","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"},slot:"icon"},[_c('path',{attrs:{"d":"M16 3C17.1046 3 18 3.89543 18 5L18 19C18 20.1046 17.1046 21 16 21H8C6.89543 21 6 20.1046 6 19L6 5C6 3.89543 6.89543 3 8 3L16 3Z","fill":"url(#paint0_linear_5369_22408)","fill-opacity":"0.2","stroke":"currentColor","stroke-width":"1.5"}}),_c('defs',[_c('linearGradient',{attrs:{"id":"paint0_linear_5369_22408","x1":"12","y1":"3","x2":"12","y2":"21","gradientUnits":"userSpaceOnUse"}},[_c('stop',{attrs:{"stop-color":"currentColor"}}),_c('stop',{attrs:{"offset":"1","stop-color":"currentColor","stop-opacity":"0"}})],1)],1)]),_c('div',{staticClass:"flex gap-1 items-center"},[_c('BaseText',{staticClass:"text-text-muted",attrs:{"type":"label","size":"sm"}},[_vm._v(" 9:16 ")]),_c('BaseText',{staticClass:"text-neutral-500",attrs:{"type":"body","size":"sm"}},[_vm._v(" (1080 x 1920) ")])],1)]):_vm._e(),(
                _vm.uploadModalInformation?.aspectRatios &&
                _vm.arrayContainsValue(
                  _vm.uploadModalInformation?.aspectRatios,
                  '1:1'
                )
              )?_c('div',{staticClass:"flex flex-row px-3 rounded-full justify-center items-center border border-border-normal w-max h-9 gap-x-2"},[_c('svg',{attrs:{"slot":"icon","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"},slot:"icon"},[_c('rect',{attrs:{"x":"4","y":"4","width":"16","height":"16","rx":"2","fill":"url(#paint0_linear_5369_22415)","fill-opacity":"0.2","stroke":"currentColor","stroke-width":"1.5"}}),_c('defs',[_c('linearGradient',{attrs:{"id":"paint0_linear_5369_22415","x1":"12","y1":"4","x2":"12","y2":"20","gradientUnits":"userSpaceOnUse"}},[_c('stop',{attrs:{"stop-color":"currentColor"}}),_c('stop',{attrs:{"offset":"1","stop-color":"currentColor","stop-opacity":"0"}})],1)],1)]),_c('div',{staticClass:"flex gap-x-1"},[_c('BaseText',{staticClass:"text-text-muted",attrs:{"type":"label","size":"sm"}},[_vm._v(" 1:1 ")]),_c('BaseText',{staticClass:"text-neutral-500",attrs:{"type":"body","size":"sm"}},[_vm._v(" (1080 x 1080) ")])],1)]):_vm._e(),(
                _vm.uploadModalInformation?.aspectRatios &&
                _vm.arrayContainsValue(
                  _vm.uploadModalInformation?.aspectRatios,
                  '4:5'
                )
              )?_c('div',{staticClass:"flex flex-row px-3 rounded-full justify-center items-center border border-border-normal w-max h-9 gap-x-2"},[_c('svg',{attrs:{"slot":"icon","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"},slot:"icon"},[_c('path',{attrs:{"d":"M19 5C20.1046 5 21 5.89543 21 7V17C21 18.1046 20.1046 19 19 19L5 19C3.89543 19 3 18.1046 3 17L3 7C3 5.89543 3.89543 5 5 5L19 5Z","fill":"url(#paint0_linear_5369_22430)","fill-opacity":"0.2","stroke":"currentColor","stroke-width":"1.5"}}),_c('defs',[_c('linearGradient',{attrs:{"id":"paint0_linear_5369_22430","x1":"12","y1":"5","x2":"12","y2":"19","gradientUnits":"userSpaceOnUse"}},[_c('stop',{attrs:{"stop-color":"currentColor"}}),_c('stop',{attrs:{"offset":"1","stop-color":"currentColor","stop-opacity":"0"}})],1)],1)]),_c('div',{staticClass:"flex gap-1 items-center"},[_c('BaseText',{staticClass:"text-text-muted",attrs:{"type":"label","size":"sm"}},[_vm._v(" 4:5 ")]),_c('BaseText',{staticClass:"text-neutral-500",attrs:{"type":"body","size":"sm"}},[_vm._v(" (1080 x 1350) ")])],1)]):_vm._e(),(
                _vm.uploadModalInformation?.aspectRatios &&
                _vm.arrayContainsValue(
                  _vm.uploadModalInformation?.aspectRatios,
                  '2:3'
                )
              )?_c('div',{staticClass:"flex flex-row px-3 rounded-full justify-center items-center border border-border-normal w-max h-9 gap-x-2"},[_c('svg',{attrs:{"slot":"icon","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"},slot:"icon"},[_c('rect',{attrs:{"x":"6","y":"4","width":"12","height":"16","rx":"2","fill":"url(#paint0_linear_5369_22437)","fill-opacity":"0.2","stroke":"currentColor","stroke-width":"1.5"}}),_c('defs',[_c('linearGradient',{attrs:{"id":"paint0_linear_5369_22437","x1":"12","y1":"4","x2":"12","y2":"20","gradientUnits":"userSpaceOnUse"}},[_c('stop',{attrs:{"stop-color":"currentColor"}}),_c('stop',{attrs:{"offset":"1","stop-color":"currentColor","stop-opacity":"0"}})],1)],1)]),_c('div',{staticClass:"flex gap-1 items-center"},[_c('BaseText',{staticClass:"text-text-muted",attrs:{"type":"label","size":"sm"}},[_vm._v(" 2:3 ")]),_c('BaseText',{staticClass:"text-neutral-500",attrs:{"type":"body","size":"sm"}},[_vm._v(" (1080 x 1620) ")])],1)]):_vm._e()])],1)]):_vm._e(),_c('div',{staticClass:"flex gap-x-4"},[_c('BaseInputV2',{staticClass:"w-1/2 mt-5",attrs:{"value":_vm.firstName,"label":"First Name","placeholder":"John"},on:{"input":(value) => (_vm.firstName = value)}}),_c('BaseInputV2',{staticClass:"w-1/2 mt-5",attrs:{"value":_vm.lastName,"label":"Last Name","optional":"","placeholder":"Doe","base-input-v2":""},on:{"input":(value) => (_vm.lastName = value)}})],1),_c('BaseInputV2',{staticClass:"w-full mt-5",attrs:{"value":_vm.email,"label":"Email","placeholder":"example@email.com","errored":_vm.validEmail},on:{"input":(value) => {
            (_vm.email = value), (_vm.validEmail = false);
          }},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('div',{staticClass:"flex pb-1 pt-5"},[_c('BaseText',{staticClass:"text-text-muted mr-auto",attrs:{"type":"label","size":"sm"}},[_vm._v(" Upload Content ")]),_c('label',{staticClass:"h-5 rounded-full",attrs:{"for":`uploadInput`},on:{"click":_vm.shouldGetUserData,"drop":function($event){$event.preventDefault();return _vm.onDrop.apply(null, arguments)}}},[_c('BaseBadge',{staticClass:"bg-background-normal cursor-pointer hover:bg-background-hover",attrs:{"grey":""}},[_c('BaseText',{staticClass:"text-text-muted",attrs:{"type":"label","size":"xs"}},[_vm._v(" Add file + ")])],1)],1)],1),_c('div',[(!_vm.filePreviews[0])?_c('label',{staticClass:"block w-full text-center mb-16",attrs:{"for":`uploadInput`},on:{"click":_vm.shouldGetUserData,"drop":function($event){$event.preventDefault();return _vm.onDrop.apply(null, arguments)}}},[_c('div',{staticClass:"flex justify-center items-center h-36 w-full border-2 border-border-hover border-dashed rounded-xl transition duration-500 cursor-pointer",class:{
              'bg-white': !_vm.uploading,
            }},[_c('div',{staticClass:"flex flex-col items-center justify-center"},[_c('BaseText',{staticClass:"text-text-muted",attrs:{"type":"body","size":"sm"}},[_vm._v(" Drag & Drop or "),_c('span',{staticClass:"text-primary-green-100"},[_vm._v("Choose File")]),_vm._v(" to upload ")]),_c('BaseText',{staticClass:"text-text-subdued",attrs:{"type":"body","size":"sm"}},[_vm._v(" MP4, MOV, JPG or PNG ")])],1)])]):_c('div',{staticClass:"flex-col items-center h-max gap-x-5 w-full border-2 border-border-hover border-dashed rounded-xl transition duration-500 cursor-pointer overflow-y-auto mb-20 scrollbar-hide",class:{
            'border-gray-400 bg-gradient-to-b from-gray-600': _vm.uploading,
            'bg-white': !_vm.uploading,
          },staticStyle:{"max-height":"230px"},on:{"drop":function($event){$event.preventDefault();return _vm.onDrop.apply(null, arguments)}}},_vm._l((_vm.filePreviews),function(preview,index){return _c('div',{key:index + 293920,staticClass:"flex items-center justify-center w-full h-full gap-x-6 pr-6 my-3"},[_c('label',{staticClass:"flex items-center justify-center w-full h-full gap-x-6",attrs:{"for":`uploadInput`},on:{"click":_vm.shouldGetUserData,"drop":function($event){$event.preventDefault();return _vm.onDrop.apply(null, arguments)}}},[_c('div',{staticClass:"w-24 h-24 border border-border-normal rounded-md flex items-center justify-center"},[_c('div',{staticClass:"w-24 h-24 border border-border-normal rounded-md flex items-center justify-center contain overflow-auto p-2"},[(preview.file.type.includes('image/'))?_c('img',{staticClass:"contain",attrs:{"src":preview.preview,"alt":"File Preview"}}):_c('video',{staticClass:"contain max-h-full max-w-full",attrs:{"src":preview.preview}})])]),_c('div',{staticClass:"flex flex-col whitespace-nowrap truncate items-start justify-center w-1/2"},[_c('BaseText',{staticClass:"text-text-muted truncate",attrs:{"type":"body","size":"sm"}},[_vm._v(" "+_vm._s(preview.file.name.substring( 0, preview.file.name.length - 4 ))+" "),_c('span',{staticClass:"text-text-subdued -ml-1"},[_vm._v(_vm._s(_vm.getFileExtension(preview.file.type)))])]),_c('BaseText',{staticClass:"text-text-subdued",attrs:{"type":"body","size":"sm"}},[_vm._v(" "+_vm._s(_vm.convertSize(preview.file.size))+" ")])],1)]),_c('BaseButton',{attrs:{"type":"button","size":"sm","outlined":"","danger":""},on:{"click":() => {
                  _vm.removeFile(preview.file);
                }}},[_vm._v(" Delete ")])],1)}),0),_c('input',{attrs:{"id":'uploadInput',"type":"file","hidden":""},on:{"change":_vm.filePreviewing}})]),_c('div',{staticClass:"flex justify-end items-center gap-4 border-t border-border-normal mt-5 -mx-5 px-5 pt-5 absolute bottom-0 left-5 w-full self-center bg-white h-max py-5 rounded-b-xl"},[(!_vm.filePreviews.length || !_vm.firstName || !_vm.email)?_c('BaseButton',{staticClass:"text-text-disabled bg-neutral-50",attrs:{"type":"button","size":"sm"}},[_vm._v(" Submit Content ")]):_c('BaseButton',{attrs:{"type":"button","size":"sm","cta":""},on:{"click":() => _vm.onSubmit()}},[_vm._v(" Submit Content ")])],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }