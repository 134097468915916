<template>
  <!-- Boards Details View -->
  <!-- Main Library Section -->
  <MainLayout
    v-scroll="onScroll"
    scroll-toolbar
    :aditional-classes="shared ? 'ml-4' : ''"
    :style="shared ? 'height: calc(100vh - 90px); width: calc(100vw - 30px)' : 'height: calc(100vh - 30px)'"
  >
    <!-- Toolbar -->
    <template #toolbar>
      <!-- Filters -->
      <div class="flex px-2 justify-between items-center">
        <!-- Profile Section -->
        <div class="flex flex-col">
          <div class="flex items-start py-2">
            <img
              class="rounded-lg h-16 w-16 mr-2"
              :src="expert.avatar"
            >
            <div>
              <div
                v-if="!shared"
                class="flex items-center text-gray-600 text-xs cursor-pointer hover:text-gray-400 transition"
                @click="$router.push({ name: 'DiscoveryExpertsView' })"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-3 h-3 mr-1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                  />
                </svg>

                Back to experts
              </div>
              <div class="text-lg font-medium">
                {{ expert.fullName }}
              </div>
              <!-- Socials -->
              <div class="flex items-center">
                <!-- Twitter -->
                <a
                  v-if="expert.twitter"
                  :href="expert.twitter"
                  target="_blank"
                  class="mr-2"
                >
                  <TwitterIcon
                    :width="22"
                  />
                </a>

                <!-- Facebook -->
                <a
                  v-if="expert.facebook"
                  :href="expert.facebook"
                  target="_blank"
                  class="mr-2"
                >
                  <FacebookIcon
                    :width="22"
                  />
                </a>

                <!-- Instagram -->
                <a
                  v-if="expert.instagram"
                  :href="expert.instagram"
                  target="_blank"
                  class="mr-2"
                >
                  <InstagramIcon
                    :width="22"
                  />
                </a>

                <!-- linkedin -->
                <a
                  v-if="expert.linkedin"
                  :href="expert.linkedin"
                  target="_blank"
                  class="mr-2"
                >
                  <LinkedInIcon
                    :width="22"
                  />
                </a>

                <!-- YouTube -->
                <a
                  v-if="expert.youtube"
                  :href="expert.youtube"
                  target="_blank"
                  class="mr-2"
                >
                  <YouTubeIcon
                    :width="22"
                  />
                </a>

                <!-- Website -->
                <a
                  v-if="expert.website"
                  :href="expert.website"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>

        <!-- Share Link -->
        <BaseButton
          v-if="!shared"
          v-clipboard:copy="getShareLink"
          v-clipboard:success="handleClipboardCopy"
          primary
          class="ml-3"
        >
          <i class="fas fa-link mr-1 text-xs" />
          Share
        </BaseButton>
      </div>
    </template>

    <template #filter>
      <div ref="filterBar" class="border border-border-normal bg-white">
        <FilterTopBar
          :selected-formats.sync="selectedFormats"
          :selected-platforms.sync="selectedPlatforms"
          :selected-niches.sync="selectedNiches"
          :selected-languages.sync="selectedLanguages"
          :sort-order.sync="sortOrder"
          :text-search.sync="textSearch"
          :live-status.sync="liveStatus"
          @change="fetchAdvertisements"
        />
      </div>
    </template>

    <template #content>
      <!-- Side Scrollable Boards -->
      <div class="w-full overflow-x-scroll scrollbar-hide h-10 flex justify-start items-center px-4 -mt-2 mb-2">
        <!-- Boards Title -->
        <div class="select-none text-gray-600 text-md font-medium mr-2">
          Boards
        </div>

        <!-- Ad Saved Count -->
        <div
          :class="!selectedBoard.id ? 'bg-purple-200 text-purple-600' : 'bg-gray-200 text-gray-600 hover:bg-purple-100 hover:text-purple-600'"
          class="bg-gray-200 rounded px-2 py-1 select-none flex items-center cursor-pointer whitespace-nowrap"
          @click="setSelectedBoard(null)"
        >
          <svg
            height="14"
            viewBox="0 0 10 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.052002 1.68575C0.052002 1.25228 0.4034 0.900879 0.836872 0.900879H2.86369V4.37384H0.052002V1.68575Z"
              fill="currentColor"
            />
            <rect
              x="0.052002"
              y="4.68982"
              width="2.81169"
              height="3.47296"
              fill="currentColor"
            />
            <path
              d="M0.052002 8.47852H2.86369V11.9515H0.444437C0.227701 11.9515 0.052002 11.7758 0.052002 11.559V8.47852Z"
              fill="currentColor"
            />
            <rect
              x="3.22803"
              y="0.900879"
              width="2.81169"
              height="3.47296"
              fill="currentColor"
            />
            <rect
              x="3.22803"
              y="4.68982"
              width="2.81169"
              height="3.47296"
              fill="currentColor"
            />
            <path
              d="M6.40381 0.900879H9.2155V4.37384H6.40381V0.900879Z"
              fill="currentColor"
            />
          </svg>

          <div
            class="ml-2"
          >
            All Ads
          </div>
        </div>

        <!-- Boards -->
        <div
          v-for="(board, index) in boards"
          :key="index"
          class="rounded px-2 py-1 select-none flex items-center cursor-pointer transition duration-200 ml-2 whitespace-nowrap"
          :class="selectedBoard.id === board.id ? 'bg-purple-200 text-purple-600' : 'bg-gray-200 hover:bg-purple-100 hover:text-purple-600'"
          @click="setSelectedBoard(board.id)"
        >
          <!--  -->
          {{ board.name }}
        </div>
      </div>
      <!-- Loading State -->
      <BaseLoadingSpinner
        v-if="loadingAdvertisements && !loadingAdvertisements"
        class="mt-24"
      />

      <!-- Ads List -->
      <AdvertisementList
        v-else
        :advertisements="advertisements"
        :shared-board="shared"
        discovery
        @getMoreAds="() => getMoreAds({})"
        @reload="fetchAdvertisements(false, true)"
      />

      <infinite-loading
        v-if="advertisements.length"
        :identifier="infiniteId"
        @infinite="getMoreAds"
      >
        <div slot="spinner">
          <BaseLoadingLogo :margin="2" />
        </div>
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading>
    </template>
  </MainLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import firebase from '@/api/config/FirebaseConfig'
import ForeplayAPI from '@/api/foreplayServer'
import FirebaseAPI from '@/api/firebase'
import AdvertisementList from '../../components/advertisements/AdvertisementList.vue'
import MainLayout from '../../layouts/MainLayout.vue'
import InfiniteLoading from 'vue-infinite-loading'

import FilterTopBar from '../../components/search/FilterTopBar.vue'

import FacebookIcon from '../../components/globals/Icons/FilterIcons/FacebookIcon.vue'
import InstagramIcon from '../../components/globals/Icons/FilterIcons/InstagramIcon.vue'
import LinkedInIcon from '../../components/globals/Icons/FilterIcons/LinkedInIcon.vue'
import YouTubeIcon from '../../components/globals/Icons/FilterIcons/YouTubeIcon.vue'
import TwitterIcon from '../../components/globals/Icons/FilterIcons/TwitterIcon.vue'

export default {
  name: 'DiscoveryExpertView',
  components: {
    AdvertisementList,
    MainLayout,
    InfiniteLoading,
    FilterTopBar,
    InstagramIcon,
    FacebookIcon,
    LinkedInIcon,
    TwitterIcon,
    YouTubeIcon
  },
  data () {
    return {
      shared: ['DiscoveryExpertPublicView', 'DiscoveryExpertBoardPublicView'].includes(this.$route.name),
      expert: {},
      folder: {},
      selectedBoard: {},
      selectedFormats: [],
      selectedPlatforms: [],
      selectedNiches: [],
      selectedBrands: [],
      selectedTags: [],
      selectedLanguages: [],
      liveStatus: [],
      sortOrder: [{ name: 'Newest', value: 'desc' }],
      docOrder: 'desc',
      textSearch: '',
      advertisements: [],
      boards: [],
      loadingAdvertisements: false,
      infiniteId: +new Date(),
      lastDocId: null
    }
  },
  computed: {
    ...mapGetters('AdvertisementsModule', ['getAdsBeingSaved']),
    ...mapGetters('AuthModule', ['getUserEmail', 'getUserName', 'getTeam']),
    ...mapGetters('BriefsModule', ['getIsInspoMode']),
    ...mapGetters('BoardsModule', ['getBoards']),
    getShareLink () {
      if (this.selectedBoard.id) {
        return process.env.VUE_APP_BASE_URL + '/share/discovery-experts/' + encodeURIComponent(this.$route.params.id) + '/board/' + this.selectedBoard.id + '?user=' + encodeURIComponent(this.getUserName)
      }

      return process.env.VUE_APP_BASE_URL + '/share/discovery-experts/' + encodeURIComponent(this.$route.params.id) + '?user=' + encodeURIComponent(this.getUserName)
    },
    // Get Advertisements
    getAdvertisements () {
      return this.advertisements.filter(ad => {
        const isSelectedFormat = !this.selectedFormats.length || this.selectedFormats.map(e => e.name.toLowerCase()).includes(ad.type)
        const isSelectedNiche = !this.selectedNiches.length || ad.categories?.some(e => this.selectedNiches.map(e => e.name).includes(e))

        setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
        }, 100)

        return isSelectedFormat && isSelectedNiche
      })
    }
  },
  async mounted () {
    // Set initial top bar styles
    this.$refs.filterBar.style.position = 'sticky'
    this.$refs.filterBar.style.top = '16px'
    this.$refs.filterBar.style.zIndex = '50000'

    this.fetchNiches()
    await this.fetchExpert()
    await this.fetchAdvertisements()

  },
  methods: {
    onScroll (e, position) {
      const marginValue =
        position.scrollTop / 6 < 16 ? position.scrollTop / 6 : 16
      const rounding =
        position.scrollTop / 10 < 5 ? position.scrollTop / 10 : 5

      this.$refs.filterBar.style.marginLeft = `${marginValue}px`
      this.$refs.filterBar.style.marginRight = `${marginValue}px`
      this.$refs.filterBar.style.marginTop = `${marginValue}px`
      this.$refs.filterBar.style.borderRadius = `${rounding}px`

      const shadowSection = (maxValue) => {
        return position.scrollTop / 15 < maxValue
          ? position.scrollTop / 15
          : maxValue
      }

      if (position.scrollTop) {
        this.$refs.filterBar.style.boxShadow = `rgba(0, 0, 0, 0.08) 0px ${shadowSection(
        15
      )}px ${shadowSection(55)}px, rgba(0, 0, 0, 0.08) 0px -${shadowSection(
        7
      )}px ${shadowSection(
        10
      )}px, rgba(0, 0, 0, 0.08) 0px 4px 6px, rgba(0, 0, 0, 0.08) 0px ${shadowSection(
        7
      )}px ${shadowSection(13)}px, rgba(0, 0, 0, 0.08) 0px -${shadowSection(
        3
      )}px ${shadowSection(5)}px`
      // this.$refs.filterBar.style.boxShadow = `rgba(58, 111, 251, 0.4) -${shadowSection(5)}px ${shadowSection(5)}px, rgba(58, 111, 251, 0.3) -${shadowSection(10)}px ${shadowSection(10)}px, rgba(58, 111, 251, 0.2) -${shadowSection(15)}px ${shadowSection(15)}px, rgba(58, 111, 251, 0.1) -${shadowSection(20)}px ${shadowSection(20)}px, rgba(58, 111, 251, 0.05) -${shadowSection(25)}px ${shadowSection(25)}px`
      } else {
        this.$refs.filterBar.style.boxShadow = null
      }
    },
    async fetchNiches () {
      // Setup
      const db = firebase.firestore()

      const snapshot = await db.collection('niches').get()

      const newDocs = snapshot.docs?.map(doc => {
        const docData = {
          ...(doc.data() || [])
        }

        return { name: docData.niche, icon: docData.emoji }
      })

      const sortedDocs = newDocs.sort((a, b) => (a.name > b.name ? 1 : -1))
      const otherIndex = sortedDocs.findIndex(d => d.name === 'Other')
      const otherNiche = sortedDocs.splice(otherIndex, 1)

      sortedDocs.push(otherNiche[0])

      this.niches = sortedDocs
    },
    handleClipboardCopy () {
      this.$showAlert({
        message: 'Copied share link to clipboard!',
        type: 'success'
      })
    },
    setSelectedBoard (boardId, skipPathUpdate) {
      if (this.selectedBoard.id === boardId) return

      let pathToPush
      if (!boardId) {
        if (!this.selectedBoard.id) return
        this.selectedBoard = {}

        pathToPush = this.$route.path
      } else {
        this.selectedBoard = this.boards.find(b => b.id === boardId)

        pathToPush = this.$route.path + '/' + 'board/' + encodeURIComponent(boardId)
      }

      // Update history
      if (!skipPathUpdate) {
        history.pushState(
          {},
          null,
          pathToPush
        )
      }

      // Fetch Ads now from the selected boards
      this.fetchAdvertisements()
    },
    async fetchExpert () {
      const db = firebase.firestore()

      const docInstance = await db
        .collection('auth-users')
        .doc(this.$route.params.id)
        .get()

      const { avatar, expertProfile } = docInstance.data()

      const fullName = `${expertProfile.firstName} ${expertProfile.lastName}`

      this.expert = {
        ...expertProfile,
        fullName,
        avatar,
        id: docInstance.id
      }

      // Get the experts folder
      this.folder = await FirebaseAPI.Folders.getByID(expertProfile.selectedFolder)

      this.boards = await Promise.all(
        this.folder.boardIds.map(async boardId => FirebaseAPI.Boards.getByID(boardId))
      )

      // Check if there is a boardId in the path then update the selected board
      if (this.$route.params.boardId) {
        this.setSelectedBoard(this.$route.params.boardId, true)
      }

      // Trigger the rewardful affiliate
      if (this.expert.utm) {
        // eslint-disable-next-line no-undef
        rewardful('source', this.expert.utm.replace('?via=', ''))
      }
    },
    // Fetch Advertisements
    async getMoreAds ($state) {
      if (!this.lastDocId) {
        if (Object.keys($state).length) {
          $state.complete()
        }
        return
      }

      const orFilters = {
        formats: this.selectedFormats,
        niches: this.selectedNiches,
        publisher_platform: this.selectedPlatforms,
        tags: this.selectedTags,
        liveStatus: this.liveStatus,
        languages: this.selectedLanguages,
        brands: this.selectedBrands
      }

      if (this.selectedBoard.id) {
        orFilters.board_ids = [{ id: this.selectedBoard.id }]
      }

      const { results, nextPage } = await ForeplayAPI.Ads.getExpertAds(
        this.$route.params.id,
        this.lastDocId,
        {
          textSearch: this.textSearch,
          sort: this.sortOrder[0].value,
          orFilters
        }
      )

      this.lastDocId = nextPage

      if (!this.lastDocId) {
        if (Object.keys($state).length) {
          $state.complete()
        }
        return
      }

      this.advertisements = this.advertisements.concat(results)
      if (Object.keys($state).length) {
        $state.loaded()
      }
    },
    // Fetch Advertisements
    async fetchAdvertisements (load = true, refresh = false) {
      this.loadingAdvertisements = load
      this.advertisements = []

      if (refresh) {
        this.lastDocId = null
      }

      try {
        const orFilters = {
          formats: this.selectedFormats,
          niches: this.selectedNiches,
          publisher_platform: this.selectedPlatforms,
          tags: this.selectedTags,
          liveStatus: this.liveStatus,
          languages: this.selectedLanguages,
          brands: this.selectedBrands
        }

        if (this.selectedBoard.id) {
          orFilters.board_ids = [{ id: this.selectedBoard.id }]
        }

        const { results, nextPage } = await ForeplayAPI.Ads.getExpertAds(
          this.$route.params.id,
          null,
          {
            textSearch: this.textSearch,
            sort: this.sortOrder[0].value,
            orFilters
          }
        )

        this.lastDocId = nextPage

        this.advertisements = results
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e,
          type: 'error'
        })
      } finally {
        this.loadingAdvertisements = false
        this.infiniteId += 1

        setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
        }, 100)
      }
    }
  }
}
</script>

<style scoped lang="sass">
</style>
