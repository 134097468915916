<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M6.99206 5.04816L6.47886 4.92974C6.04489 4.82959 5.58993 4.96007 5.275 5.275C4.96007 5.58993 4.82959 6.04488 4.92974 6.47886L5.04816 6.99205C5.18467 7.58357 4.94076 8.19783 4.43565 8.53457L3.81164 8.9506C3.46076 9.18452 3.25 9.57827 3.25 10C3.25 10.4217 3.46076 10.8155 3.81164 11.0494L4.43565 11.4654C4.94076 11.8022 5.18467 12.4164 5.04817 13.0079L4.92974 13.5212C4.82959 13.9551 4.96007 14.4101 5.275 14.725C5.58993 15.0399 6.04488 15.1704 6.47886 15.0703L6.99205 14.9518C7.58357 14.8153 8.19783 15.0593 8.53457 15.5643L8.9506 16.1884C9.18452 16.5392 9.57827 16.75 10 16.75C10.4217 16.75 10.8155 16.5392 11.0494 16.1884L11.4654 15.5643C11.8022 15.0593 12.4164 14.8153 13.0079 14.9518L13.5212 15.0703C13.9551 15.1704 14.4101 15.0399 14.725 14.725C15.0399 14.4101 15.1704 13.9551 15.0703 13.5212L14.9518 13.0079C14.8153 12.4164 15.0593 11.8022 15.5643 11.4654L16.1884 11.0494C16.5392 10.8155 16.75 10.4217 16.75 10C16.75 9.57827 16.5392 9.18452 16.1884 8.9506L15.5643 8.53457C15.0593 8.19783 14.8153 7.58357 14.9518 6.99206L15.0703 6.47886C15.1704 6.04489 15.0399 5.58993 14.725 5.275C14.4101 4.96007 13.9551 4.82959 13.5212 4.92974L13.0079 5.04816C12.4164 5.18467 11.8022 4.94076 11.4654 4.43565L11.0494 3.81163C10.8155 3.46076 10.4217 3.25 10 3.25C9.57827 3.25 9.18452 3.46076 8.9506 3.81164L8.53457 4.43565C8.19783 4.94076 7.58357 5.18467 6.99206 5.04816Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M12.25 10C12.25 11.2427 11.2427 12.25 10 12.25C8.75732 12.25 7.75 11.2427 7.75 10C7.75 8.75732 8.75732 7.75 10 7.75C11.2427 7.75 12.25 8.75732 12.25 10Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'SettingsIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>