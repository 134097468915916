<template>
  <div class="overflow-x-hidden">
    <div class="w-full overflow-x-auto scroll-container">
      <table>
        <thead>
          <tr class="uppercase">
            <!-- Grouped by column -->
            <th class="sticky-column">
              <div class="group-cell flex items-center px-2 py-3 h-10 gap-3 ">
                <input type="checkbox" class="checkbox-input" :checked="areAllRowsSelected" @change="toggleAllRows">
                <BaseText type="heading" size="overline" class="text-text-subdued">
                  {{ groupedBy }}
                </BaseText>
              </div>
            </th>
            <!-- Other selected kpis -->
            <th v-for="header in tableKpis" :key="header" :class="{ 'sticky-column': pinnedColumns.includes(header) }">
              <div class="p-3 pr-1.5 h-10 flex items-center gap-x-1 justify-between group cell-header">
                <!-- TODO @Sam: update header color when selected -->
                <BaseText type="heading" size="overline" class="text-text-subdued text-left truncate flex-shrink">
                  {{ header }}
                </BaseText>
                <div class="flex items-center column-actions">
                  <!-- <div
                    class="hover:bg-background-normal text-icon-disabled rounded-md w-7 h-7 p-1 cursor-pointer opacity-0 group-hover:opacity-100"
                    :class="{'text-icon-muted opacity-100' : pinnedColumns.includes(header)}"
                    @click="pinColumn(header)"
                  >
                    <PinIcon
                      :class-name="pinnedColumns.includes(header) ? 'text-icon-muted' : 'text-icon-disabled'"
                    />
                  </div> -->
                  <div
                    class="hover:bg-background-normal text-icon-disabled rounded-md w-7 h-7 p-1 cursor-pointer opacity-0 group-hover:opacity-100"
                    :class="{ 'text-icon-muted opacity-100': currentSort.key === header }"
                    :style="currentSort.value === 'asc' ? 'transform: scale(1, -1)' : ''" @click="sortColumn(header)">
                    <SortColumnIcon :class="currentSort.key === header ? 'text-icon-muted' : 'text-icon-disabled'" />
                  </div>
                  <div
                    class="hover:bg-background-normal text-icon-disabled  rounded-md w-7 h-7 p-1 cursor-pointer opacity-0 group-hover:opacity-100"
                    :class="{ 'text-icon-muted opacity-100': selectedColumns.includes(header) }"
                    @click="addColumn(header)">
                    <CheckmarkIcon
                      :class="selectedColumns.includes(header) ? 'text-icon-muted' : 'text-icon-disabled'" />
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in rows" :key="`${row}-${index}`">
            <td class="sticky-column table-cell" :class="{ 'table-highlight': hoveredCell.row === index }">
              <div class="group-cell grid grid-cols-12 py-1 px-2 gap-x-3 items-center">
                <!-- Left checkbox -->
                <div class="col-span-1">
                  <input :checked="isRowSelected(row.group_by)" class="checkbox-input" type="checkbox"
                    @change="setRowSelected(row.group_by)">
                </div>
                <!-- Middle info -->
                <div class="col-span-8 ml-1">
                  <div class="flex items-center flex-nowrap gap-2 overflow-x-hidden min-w-0">
                    <!-- Group image -->
                    <img :src="row.ads?.[0].ad_image_url" class="rounded-md w-7 h-7 flex-shrink-0 bg-cover">
                    <!-- Group title & ad count -->
                    <div class="flex-grow min-w-0 whitespace-nowrap">
                      <BaseText class="text-text-muted truncate" size="sm">
                        {{ row.group_by }}
                      </BaseText>
                      <BaseText class="text-text-normal" size="xs">
                        {{ row.ads.length }} Ads
                      </BaseText>
                    </div>
                  </div>
                </div>
                <!-- CTA actions -->
                <div class="col-span-2">
                  <div class="flex items-center min-w-0">
                    <!-- Add hover effects -->
                    <div class="p-1 cursor-pointer">
                      <AdDetailsAnimatedIcon class="cursor-pointer text-icon-normal" />
                    </div>
                    <div class="p-1 cursor-pointer">
                      <CreativeInsightIcon class="cursor-pointer text-icon-normal" />
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <!-- Selected KPI Columns -->
            <td v-for="(kpi, colIndex) in tableKpis" :key="`${kpi}-${colIndex}`" class="table-cell"
              :class="{ 'table-highlight': hoveredCell.col === colIndex || hoveredCell.row === index }"
              @mouseover="hoveredCell = { col: colIndex, row: index }" @mouseleave="hoveredCell = {}">
              <div v-if="kpi === 'status' && getTableConfig.showStatus" class="cell p-3">
                <StatusCell
                  :data="{ value: { active: row.active_ad_count, inactive: row.inactive_ad_count, paused: row.paused_ad_count } }" />
              </div>
              <!-- TODO @Sam Update once kpi db ready -->
              <div v-else class="cell p-3">
                <DecimalCell :data="{ value: row?.[kpi] }" :fixed-to="2" />
              </div>
            </td>
          </tr>
        </tbody>
        <!-- Table footer (Net results across all groupings) -->
        <tfoot>
          <tr>
            <td class="sticky-column">
              <div class="group-cell p-3 bg-background-disabled h-full flex gap-3 items-center">
                <QuestionIcon class="text-icon-disabled" />
                <BaseText size="sm" type="label" class="text-text-muted col-span-10">
                  Net Result
                </BaseText>
              </div>
            </td>
            <td v-for="kpi in tableKpis" :key="kpi">
              <div class="cell p-3 flex items-center gap-3 bg-background-disabled">
                <DecimalCell :data="{ value: netResults?.[kpi] }" :fixed-to="2" />
                <!-- Average -->
                <div class="min-w-0 ">
                  <BaseText class="truncate text-text-muted " size="sm">
                    <div class="flex-nowrap flex items-center">
                      Avg.&nbsp;
                      <DecimalCell :data="{ value: netResults.weighted_averages?.[kpi] }" :fixed-to="2" />
                    </div>
                  </BaseText>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <TablePagination v-if="totalPages !== 0" :showing="rows.length" :total-items="totalItems"
      :total-pages="totalPages" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// Table cell components
import CurrencyCell from './cells/CurrencyCell.vue'
import StatusCell from './cells/StatusCell.vue'
import DecimalCell from './cells/DecimalCell.vue'
import TablePagination from './TablePagination.vue'
// Icons
import AdDetailsAnimatedIcon from '../../globals/Icons/AdDetailsAnimatedIcon.vue'
import CreativeInsightIcon from '../../globals/Icons/CreativeInsightIcon.vue'
import QuestionIcon from '../../globals/Icons/QuestionIcon.vue'
import SortColumnIcon from '../../globals/Icons/SortColumnIcon.vue'
import CheckmarkIcon from '../../globals/Icons/CheckmarkIcon.vue'
import PinIcon from '../../globals/Icons/SpyderV2Icons/PinIcon.vue'
export default {
  name: 'LensMetricTable',
  components: {
    StatusCell,
    CurrencyCell,
    DecimalCell,
    TablePagination,
    // Icons
    AdDetailsAnimatedIcon,
    CreativeInsightIcon,
    QuestionIcon,
    SortColumnIcon,
    CheckmarkIcon,
    PinIcon
  },
  props: {
    includedKpis: {
      type: Array,
      default: () => []
    },
    groupedBy: {
      type: String,
      default: () => 'Ad Name'
    },
    totalPages: {
      type: Number,
      default: () => 0
    },
    totalItems: {
      type: Number,
      default: () => 0
    }
    // tableData: {
    //   type: Array,
    //   default: () => []
    // }
  },
  data() {
    return {
      pinnedColumns: [],
      selectedColumns: [],
      currentSort: {
        key: 'spend',
        value: 'desc'
      },

      hoveredCell: {},

      selectedRows: []
    }
  },
  computed: {
    ...mapGetters('LensModule', ['getTableConfig', 'getAppliedPreset', 'getAdGroups']),
    areAllRowsSelected() {
      return this.rows.length && this.selectedRows.length === this.rows.length
    },
    rows() {
      if (this.getAdGroups.length > 0) {
        return this.getAdGroups[0].ad_groups
      }
      return []
    },
    netResults() {
      if (this.getAdGroups.length > 0) {
        return { ...this.getAdGroups[0] }
      }
      return {}
    },
    tableKpis() {
      const showStatus = this.getTableConfig.showStatus

      return [
        ...(showStatus ? ['status'] : []),
        'spend',
        ...this.includedKpis
      ]
    }
  },
  methods: {
    ...mapActions('LensModule', ['updateSelectedColumns', 'updateSortFilter']),
    manageArrayAdd(arr, toAdd) {
      const exists = arr.includes(toAdd)
      return exists ? arr.filter((val) => val !== toAdd) : [...arr, toAdd]
    },
    // Column actions
    pinColumn(header) {
      this.pinnedColumns = this.manageArrayAdd(this.pinnedColumns, header)
    },
    sortColumn(column) {
      const sameKey = this.currentSort.key === column
      const isAsc = this.currentSort.value === 'asc'

      const newSort = {
        key: column,
        value: sameKey ? isAsc ? 'desc' : 'asc' : 'desc'
      }
      this.currentSort = newSort
      this.updateSortFilter(newSort)
    },
    addColumn(column) {
      this.selectedColumns = this.manageArrayAdd(this.selectedColumns, column)
      this.updateSelectedColumns(this.selectedColumns)
    },
    updatePagination(event) {
      console.log(event, 'samee')
    },
    setRowSelected(id) {
      if (this.selectedRows.includes(id)) {
        this.selectedRows = this.selectedRows.filter(row => row !== id)
        // this.selectedRows.filter((row) => row.group_by !== id)
      } else {
        this.selectedRows.push(id)
      }
    },
    isRowSelected(id) {
      return this.selectedRows.includes(id)
    },
    toggleAllRows() {
      if (this.areAllRowsSelected) {
        console.log('%c CLEARING', 'font-size: 16px; color: green;')
        this.selectedRows = []
      } else {
        console.log(`%c SETTING ALL ${this.rows.length}`, 'font-size: 16px; color: blue;')
        this.selectedRows = this.rows.map(row => row.group_by)
      }
    }
  }
}
</script>

<style scoped>
/* TODO @Sam add hover */
.scroll-container::-webkit-scrollbar {
  height: 4px;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  margin-top: 4px;
  margin-bottom: 4px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #DFE1E7;
  border-radius: 18px;
  height: 6px;
  cursor: pointer;
}

.scroll-container {
  transition: all 150ms;
  scroll-behavior: smooth;
}

/*
  Table Styles
*/
table {
  border-collapse: collapse;
}

th,
td {
  background-color: white;
  border: none;
  padding: 0;
  margin: 0;
}

/* Border Styles (rounded corners & bordered cells) */
table tr th:first-child .group-cell {
  border-top-left-radius: 6px;
  border: 1px solid #ECEFF3;
}

table tr th:last-child .cell-header:last-child {
  border-right: 1px solid #ECEFF3;
  border-top: 1px solid #ECEFF3;
  border-bottom: 1px solid #ECEFF3;
  border-top-right-radius: 6px;
}

table th:not(:first-child):not(:last-child) .cell-header {
  border-right: 1px solid #ECEFF3;
  border-top: 1px solid #ECEFF3;
  border-bottom: 1px solid #ECEFF3;
}

table tr td .group-cell,
table tr td .cell {
  border-right: 1px solid #ECEFF3;
  border-bottom: 1px solid #ECEFF3;
}

table tr td:not(first-child) .group-cell {
  border-left: 1px solid #eceff3;
}

table tfoot tr td:last-child .cell {
  border-bottom-right-radius: 6px;
}

table tfoot tr td .group-cell {
  border-bottom-left-radius: 6px;
}

.table-highlight {
  background-color: #fafafa;
}

.table-cell {
  transition: colors 50ms ease-in-out;
}

.cell {
  /* Who knows why i added this, commented out for now */
  /* max-height: 40px; */
  /* height: 100%; */
  min-width: 132px;
  max-width: 240px;
}

.group-cell {
  width: 320px;
}

.sticky-column {
  position: sticky;
  left: 0;
  z-index: 2;
}

.column-actions>div {
  transition: opacity 150ms ease-in-out;
}

/*
  Checkbox Styles
*/
.checkbox-input {
  box-shadow: 0px 0px 0px 1px #09194821, 0px 1px 2px 0px #09194821;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.checkbox-input:checked {
  background-color: black;
}
</style>
