<!-- Animates a number dial whenever the 'value' prop is changed -->

<template>
  <div ref="number" class="animated-counter no-select" :style="{ lineHeight: `${lineHeight}px`, height: `${lineHeight}px` }">
    <span v-for="(num, index) in numberArray" :key="`outer-${index}`" :ref="`num-${index}`"
    class="flex flex-col items-center w-min">
      <span v-if="num === ','">{{ num }}</span>
      <span v-else v-for="n in 10" :key="`inner-${index}-${n}`">{{ (num + (n - 1)) % 10 }}</span>
    </span>
  </div>
</template>

<script>
import { animate } from 'popmotion'

export default {
  name: 'BaseAnimatedCounter',
  props: {
    value: {
      type: Number,
      required: true
    },
    duration: {
      type: Number,
      default: 1000
    },
    lineHeight: {
      type: Number,
      default: 20
    },
    animateOnMount: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      numberArray: [],
      isAnimating: false
    }
  },
  mounted () {
    if (this.animateOnMount) {
      if (this.value > 9) {
        this.adjustDigits(this.value, 1)
        this.numberArray = ['0', ...this.numberArray.slice(1)]
      } else {
        this.numberArray = ['0']
      }
      this.$nextTick(() => {
        this.animateValueChange(this.value)
      })
    } else {
      this.constructNumberDisplay()
    }
  },
  watch: {
    value (newValue, oldValue) {
      if (this.isAnimating) return
      const newNumArr = newValue.toLocaleString().split('')
      if (newNumArr.length !== this.numberArray.length) {
        this.adjustDigits(newValue, oldValue)
      }
      this.$nextTick(() => {
        this.animateValueChange(newValue)
      })
    }
  },
  methods: {
    constructNumberDisplay () {
      this.numberArray = computeNumberArray(this.value)
    },
    adjustDigits (newValue, oldValue) {
      let oldNumStr = oldValue.toString()
      const newNumStr = newValue.toString()
      if (newNumStr.length < oldNumStr.length) {
        this.numberArray = computeNumberArray(parseInt(oldNumStr.slice(0, newNumStr.length)))
        return
      }
      const digitDiff = newNumStr.length - oldNumStr.length
      for (let i = 0; i < digitDiff; i++) {
        oldNumStr = oldNumStr + '0'
      }
      this.numberArray = computeNumberArray(parseInt(oldNumStr))
    },
    async animateValueChange (targetValue) {
      this.isAnimating = true
      const newNumArr = targetValue.toLocaleString().split('')
      const animationPromises = newNumArr.map((num, index) => {
        return new Promise((resolve) => {
          if (num === ',') {
            resolve()
            return
          }
          const targetNum = parseInt(num)
          const numSpan = this.$refs[`num-${index}`][0]
          const currentNum = parseInt(numSpan.children[0].innerHTML)
          const diff = targetNum >= currentNum ? targetNum - currentNum : 10 + targetNum - currentNum
          const translateOffset = diff * this.lineHeight
          animate({
            from: "translateY(0)",
            to: `translateY(-${translateOffset}px)`,
            duration: this.duration,
            onUpdate: (value) => {
              numSpan.style.transform = value
            },
            onComplete: () => {
              resolve()
            }
          })
        })
      })

      await Promise.all(animationPromises)

      this.$nextTick(() => {
        this.constructNumberDisplay()
        for (let i = 0; i < this.numberArray.length; i++) {
          const numSpan = this.$refs[`num-${i}`][0]
          numSpan.style.transform = "translateY(0)"
        }
        this.isAnimating = false
      })
    }
  }
}
const computeNumberArray = (value) => {
  return value.toLocaleString().split('').map(num => parseInt(num) || num)
}
</script>

<style scoped>
.animated-counter {
  display: flex;
  overflow: hidden;
  font-feature-settings: 'ss01' on, 'cv10' on, 'liga' off, 'calt' off;
  font-family: Inter;
}
.no-select {
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* Internet Explorer/Edge */
}
</style>