<template>
  <BaseModal @close="$emit('close')" cardWidth="448px" noPadding noToolbar noExit>
    <template #default>
      <div class="relative w-full flex flex-col p-5 border-b border-border-normal">
        <div class="flex items-center gap-2">
          <FigmaIcon :width="24" :height="24" />
          <BaseText type="label" size="md" class="text-text-muted">
            Copy Board to Figma
          </BaseText>
        </div>
        <BaseText type="body" size="sm" class="text-text-normal mt-4">
          Quickly paste all images from this board into a Figma file.
        </BaseText>
        <!-- Board Images Preview -->
        <div class="w-full flex items-center gap-3 mt-4">
          <img v-for="(image, index) in adImages" :src="image" :key="`preview-image-${index}`"
          class="border border-border-normal object-cover" style="width: 72px; height: 72px; border-radius: 8px" />
          <div v-if="adCount > 4" class="flex items-center justify-center bg-neutral-25"
          style="width: 72px; height: 72px; border-radius: 8px">
            <BaseText type="label" size="sm" class="text-text-muted">
              +{{ adCount - 4 }}
            </BaseText>
          </div>
        </div>
        <div class="flex items-center gap-2 mt-4">
          <InfoResponseIcon class="text-icon-normal" />
          <BaseText type="body" size="sm" class="text-text-muted">
            Videos will only paste thumbnail images
          </BaseText>
        </div>
      </div>
      <div class="w-full flex flex-col gap-4 p-5">
        <button class="copy-btn relative flex justify-center w-full px-2 py-1.5 rounded-md"
        @click="copyExportToClipboard" :disabled="copySuccess">
          <div class="flex items-center gap-1.5 transition-opacity" :class="copySuccess ? 'opacity-0' : 'opacity-100'">
            <FigmaIcon :width="17" :height="17" />
            <BaseText type="label" size="sm" class="text-text-muted">
              Copy All to Clipboard
            </BaseText>
          </div>
          <transition>
            <div v-if="copySuccess" class="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center gap-1.5">
              <CheckmarkIcon class="text-icon-normal" />
              <BaseText type="label" size="sm" class="text-text-muted">
                Figma Export Copied!
              </BaseText>
            </div>
          </transition>
        </button>
        <div class="w-full flex items-center justify-center gap-2">
          <InfoResponseIcon class="text-text-subdued" />
          <BaseText type="body" size="sm" class="text-text-normal">
            Figma Plugin Required
          </BaseText>
          <a class="text-text-muted transition-colors duration-100 hover:text-text-loud cursor-pointer"
          href="https://www.figma.com/community/plugin/1436108864337297933" target="_blank">
            <BaseText type="label" size="sm">
              Download
            </BaseText>
          </a>
        </div>
        <button class="absolute top-2 right-2 p-1.5" @click="$emit('close')">
          <TagRemoveIcon stroke="#5E6678" />
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import FigmaIcon from '../globals/Icons/FigmaIcon.vue'
import InfoResponseIcon from '../globals/Icons/ResponseAlertIcons/InfoResponseIcon.vue'
import TagRemoveIcon from '../globals/Icons/TagRemoveIcon.vue'
import CheckmarkIcon from '../globals/Icons/NewCheckmarkIcon.vue'

export default {
  name: 'BoardToFigmaModal',
  components: {
    FigmaIcon,
    InfoResponseIcon,
    TagRemoveIcon,
    CheckmarkIcon
  },
  props: {
    board: {
      type: Object,
      default: () => {}
    },
    adImages: {
      type: Array,
      default: () => []
    },
    adCount: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      copySuccess: false
    }
  },
  methods: {
    copyExportToClipboard() {
      if (!this.board.id) return
      const exportCode = `board:${this.board.id}`
      navigator.clipboard.writeText(exportCode)
      this.copySuccess = true
      setTimeout(() => {
        this.copySuccess = false
      }, 3500)
    }
  }
}
</script>

<style scoped>
.copy-btn {
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: background-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
}
.copy-btn:hover, .copy-btn:disabled {
  background-color: #F6F8FA; /* bg-neutral-25 */
  box-shadow: none;
}
.v-enter-active, .v-leave-active {
  transition: opacity 150ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
</style>