<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M10 17.0587C10.6125 17.4686 11.3489 17.7077 12.1412 17.7077C13.9016 17.7077 15.3863 16.5275 15.8473 14.9152C16.9393 14.4675 17.7084 13.3938 17.7084 12.1406C17.7084 11.3018 17.3638 10.5434 16.8086 9.99935C17.3638 9.45526 17.7084 8.69694 17.7084 7.85815C17.7084 6.31229 16.5382 5.03973 15.0354 4.87785C14.6612 3.39141 13.3156 2.29102 11.713 2.29102C11.089 2.29102 10.5039 2.45785 10 2.74934M10 17.0587C9.3876 17.4686 8.65113 17.7077 7.85882 17.7077C6.09847 17.7077 4.61377 16.5275 4.15274 14.9152C3.06078 14.4675 2.29169 13.3938 2.29169 12.1406C2.29169 11.3018 2.63619 10.5434 3.19143 9.99935C2.63619 9.45526 2.29169 8.69694 2.29169 7.85815C2.29169 6.31229 3.4618 5.03973 4.96464 4.87785C5.33887 3.39141 6.68446 2.29102 8.28706 2.29102C8.91107 2.29102 9.49611 2.45785 10 2.74934M10 17.0587L10 2.74934M7.29169 11.4577C8.78746 11.4577 10 12.6702 10 14.166M12.7084 8.54102C11.2126 8.54102 10 7.32845 10 5.83268" 
    stroke="#5E6678" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'BrainIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>