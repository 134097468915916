export default {
  namespaced: true,
  state: {
    lastRoute: null,
    currentRoute: null,
    currentParams: null
  },
  getters: {
    getLastRoute: (state) => state.lastRoute,
    getCurrentRoute: (state) => state.currentRoute,
    getCurrentParams: (state) => state.currentParams
  },
  mutations: {
    SET_LAST_ROUTE (state, routeName) {
      state.lastRoute = routeName
    },
    // This is set in beforeEach router guard
    SET_CURRENT_ROUTE (state, routeName) {
      state.currentRoute = routeName
    },
    SET_CURRENT_PARAMS (state, params) {
      state.currentParams = params
    }
  },
  actions: {}
}
