<template>
  <div class="w-full h-full flex p-6 overflow-y-auto">
    <div class="flex flex-col mx-auto">
      <!-- View Title -->
      <div class="flex flex-col gap-1 mt-6">
        <BaseText type="label" size="md" class="text-text-muted whitespace-nowrap">
          Notification Settings
        </BaseText>
        <BaseText type="body" size="sm" class="text-text-normal whitespace-nowrap">
          Customize how you receive notifications from Foreplay.
        </BaseText>
      </div>
      <!-- divider -->
      <div class="w-full h-px my-7 bg-neutral-50" />
      <div class="flex flex-col xl:flex-row" style="column-gap: 92px; row-gap: 28px">
        <!-- Settings toggles -->
        <div class="flex flex-col gap-7">
          <div v-for="(option, index) in notificationOptions" :key="`notification-option-${index}`"
          class="flex items-center gap-11">
            <div class="flex flex-col gap-1" style="max-width: 400px">
              <BaseText type="label" size="md" class="text-text-muted">
                {{ option.title }}
              </BaseText>
              <BaseText type="body" size="sm" class="text-text-normal">
                {{ option.description }}
              </BaseText>
            </div>
            <BaseToggle @click.native="toggleNotificationEnabled(option)" :value="option.enabled" class="ml-auto" />
          </div>
        </div>
        <!-- Notifications Preview -->
        <div class="relative mx-auto" style="width: 292px; height: 292px;">
          <img src="../../assets/images/notification-preview-bg.png" >
          <transition>
            <div v-if="noOptionsEnabled" class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 
            flex flex-col items-center gap-1.5">
              <InfoResponseIcon :width="24" :height="24" class="text-icon-normal" />
              <BaseText type="label" size="xs" class="text-text-muted text-center" style="width: 132px">
                You are missing important updates!
              </BaseText>
            </div>
          </transition>
          <!-- Preview notifications -->
          <div class="preview-container flex flex-col">
            <div v-for="(preview, index) in notificationOptions" :key="`notification-preview-${index}`" style=" height: 58px"
            class="flex items-center w-full" 
            :class="{'notification-enter': preview.changed && preview.enabled, 'notification-exit': preview.changed && !preview.enabled}">
              <div class="flex flex-grow items-center gap-2 px-2.5 py-2 rounded-2xl" style="background-color: #F2F2F2;">
                <img src="../../assets/images/email-notification-icon.png" class="w-7 h-7 rounded-lg">
                <div class="flex-grow flex flex-col gap-px">
                  <div class="w-full flex items-center">
                    <BaseText type="label" size="xs" class="text-text-loud flex-grow">
                      {{ preview.previewSubject }}
                    </BaseText>
                    <BaseText type="body" size="xs" class="ml-auto" style="color: #7F7F7F80; font-size: 10px">
                      {{ getPreviewTimestamp(index) }}
                    </BaseText>
                  </div>
                  <BaseText type="body" size="xs" class="text-text-muted">
                    {{ preview.previewBody }}
                  </BaseText>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoResponseIcon from '../globals/Icons/ResponseAlertIcons/InfoResponseIcon.vue'

export default {
  name: 'NotificationSettings',
  components: {
    InfoResponseIcon
  },
  data () {
    return {
      notificationOptions: [
        {
          type: 'inspiration',
          title: 'Weekly Inspiration',
          description: 'Enjoy a personally curated email with new inspiration based on what you have saved in the past.',
          previewSubject: 'Here is your weekly update!',
          previewBody: 'Check out what happened this week.',
          enabled: true,
          changed: false
        },
        {
          type: 'spyder',
          title: 'Spyder Updates & Tracking',
          description: 'Get a weekly summary email with updates about your tracked Spyder brands.',
          previewSubject: 'Spyder updates are here.',
          previewBody: 'Track new changes in Spyder.',
          enabled: true,
          changed: false
        },
        {
          type: 'user',
          title: 'User Notifications',
          description: 'Receive emails when you are tagged in comments or added to shared accounts.',
          previewSubject: 'Tim tagged you in an ad.',
          previewBody: 'Tim made a new comment at 3:47pm',
          enabled: true,
          changed: false
        }
      ]
    }
  },
  computed: {
    noOptionsEnabled () {
      return this.notificationOptions.every(option => !option.enabled)
    }
  },
  methods: {
    toggleNotificationEnabled (option) {
      option.changed = true
      option.enabled = !option.enabled

      // TODO: SEND REQUEST TO UPDATE NOTIFICATION SETTINGS
    },
    getPreviewTimestamp (index) {
      const size = this.notificationOptions.length
      if (index === size - 1) return 'Now'
      return `${size - index - 1} min ago`
    }
  }
}
</script>

<style scoped>
.preview-container {
  position: absolute;
  width: 100%;
  left: 0;
  top: calc(50%);
  transform: translateY(-50%);
  transition: height 300ms ease-in-out;
}
.notification-enter {
  animation: notificationEnter 400ms ease-in-out 0s 1 normal forwards;
}
.notification-exit {
  animation: notificationExit 400ms ease-in-out 0s 1 normal forwards;
}
@keyframes notificationEnter {
  0% {
    opacity: 0;
    height: 0px;
  }
  50% {
    opacity: 0;
    height: 58px;
  }
  100% {
    opacity: 1;
    height: 58px;
  }
}
@keyframes notificationExit {
  0% {
    opacity: 1;
    height: 58px;
  }
  50% {
    opacity: 0;
    height: 58px;
  }
  100% {
    opacity: 0;
    height: 0px;
  }
}


.v-enter-active, .v-leave-active {
  transition: opacity 200ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
</style>