<template>
  <div class="connections-grid w-full px-3 py-2 fade-in">
    <BaseText type="body" size="sm" class="w-full text-text-muted truncate">
      {{ user.email }}
    </BaseText>
    <!-- connection status -->
    <div class="flex items-center gap-1 px-1.5 py-1 rounded-md w-min"
    :class="isConnectionPending ? 'bg-secondary-yellow-10 text-secondary-yellow-300' : 'bg-secondary-green-10 text-primary-green-300'">
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16" fill="none">
        <circle cx="4" cy="8" r="2" :fill="isConnectionPending ? '#FFBE4C' : '#00A879'" />
      </svg>
      <BaseText type="label" size="xs">
        {{ isConnectionPending ? 'Pending' : 'Connected' }}
      </BaseText>
    </div>
    <!-- IG username -->
    <BaseText type="body" size="sm" :class="user.igUsername ? 'text-text-muted' : 'text-text-normal'">
      {{ user.igUsername ? `@${user.igUsername}` : 'Not connected' }}
    </BaseText>
    <!-- linked boards -->
    <div ref="linked-boards" class="w-full flex items-center gap-1.5">
      <div v-for="(board, index) in visibleLinkedBoards" :key="`linked-board-${user.user_id}-${index}`"
      class="px-1.5 py-0.5 bg-neutral-25" style="border-radius: 4px">
        <BaseText type="label" size="xs" class="text-text-muted truncate" style="max-width: 120px">
          {{ board.name.slice(1) }}
        </BaseText>
      </div>
      <!-- '+x' hidden boards indicator -->
      <div v-if="hiddenLinkedBoards.length" class="relative px-1.5 py-1 transition-colors cursor-default" 
      style="border-radius: 4px" :class="hiddenIndicatorHovered ? 'bg-neutral-50' : 'bg-neutral-25'"
      @mouseenter="hiddenIndicatorHovered = true" @mouseleave="hiddenIndicatorHovered = false">
        <BaseText type="label" size="xs" class="text-text-muted">
          +{{ hiddenLinkedBoards.length }}
        </BaseText>
        <!-- hover tooltip -->
        <transition>
          <div v-if="hiddenIndicatorHovered" class="hidden-indicator-tooltip pb-1.5">
            <div class="flex flex-col gap-1.5 px-2.5 py-1.5 rounded-md bg-neutral-malpha-25 shadow-sm">
              <div v-for="(board, index) in hiddenLinkedBoards" :key="`hidden-board-${user.user_id}-${index}`">
                <BaseText type="label" size="xs" class="text-white truncate" style="max-width: 120px">
                  {{ board.name.slice(1) }}
                </BaseText>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TagRemoveIcon from '../../globals/Icons/TagRemoveIcon.vue'

export default {
  name: 'IGAccountRow',
  components: {
    TagRemoveIcon
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      visibleLinkedBoards: [],
      hiddenLinkedBoards: [],
      hiddenIndicatorHovered: false
    }
  },
  watch: {
    linkedBoards () {
      this.computeVisibleLinkedBoards()
    }
  },
  computed: {
    ...mapGetters('BoardsModule', ['getBoards']),
    linkedBoards () {
      return this.user?.igBoardIds?.map(boardId => ({
        id: boardId,
        name: this.getBoardName(boardId)
      })) || []
    },
    isConnectionPending () {
      return !this.user.igUsername || this.user.igWaitingVerification
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.computeVisibleLinkedBoards()
      window.addEventListener('resize', this.computeVisibleLinkedBoards)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.computeVisibleLinkedBoards)
  },
  methods: {
    getBoardName (boardId) {
      const board = this.getBoards.find(b => b.id === boardId)
      return board?.name
    },
    computeVisibleLinkedBoards () {
      // Compute how many linked boards can fit in the grid cell
      const containerWidth = this.$refs['linked-boards']?.getBoundingClientRect()?.width - 32 // -32px for '+x' indicator
      if (!containerWidth) return
      let totalWidth = 0
      let visibleBoards = []
      for (const board of this.linkedBoards) {
        const compNode = document.createElement('span')
        compNode.classList.add('px-1.5', 'truncate', 'text-label-xs', 'opacity-0', 'pointer-events-none')
        compNode.style.maxWidth = '120px'
        compNode.textContent = board.name
        document.body.appendChild(compNode)
        const boardWidth = compNode.getBoundingClientRect().width
        document.body.removeChild(compNode)
        if (totalWidth + boardWidth + 6 < containerWidth) {
          totalWidth += boardWidth + 6 // +6px for gap
          visibleBoards.push(board)
        } else {
          break
        }
      }
      this.visibleLinkedBoards = visibleBoards
      this.hiddenLinkedBoards = this.linkedBoards.filter(board => !visibleBoards.includes(board))
    }
  }
}
</script>

<style scoped>
.hidden-indicator-tooltip {
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
  max-width: 250px;
}
.fade-in {
  animation: fadeIn 150ms ease-in-out;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
.v-enter-active, .v-leave-active {
  transition: opacity 150ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
</style>