<template>
  <!-- Library Card -->
  <div class="inline-block h-full w-full rounded-lg card-shadow"
  @mouseenter="cardHovered = true" @mouseleave="cardHovered = false">
    <!-- Title -->
    <div class="flex items-center justify-between bg-white py-2.5 pl-2.5 pr-3 rounded-t-lg max-w-full">
      <div v-if="showBrandDetails" class="relative flex items-center flex-grow min-w-0">
        <!-- Brand Name/Info -->
        <button class="group flex items-center gap-2 pl-0.5 py-0.5 pr-2 mr-0.5 rounded-md transition-colors min-w-0"
        :class="brandOptionsOpen ? 'bg-neutral-50' : !isManualUpload ? 'hover:bg-neutral-25' : 'cursor-default'"
        @click="() => { if (!isManualUpload) brandOptionsOpen = !brandOptionsOpen }">
          <div v-if="isManualUpload"
          class="flex items-center justify-center border border-border-normal bg-gray-200 text-gray-600 rounded-md h-7 w-7 flex-shrink-0">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
              <path d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
              stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <img v-else :src="advertisement?.avatar" class="w-7 h-7 rounded-md flex-shrink-0" >
          <div class="flex-shrink min-w-0">
            <BaseText type="label" size="sm" class="text-text-muted truncate transition-colors"
            :class="brandOptionsOpen ? 'text-text-loud' : 'group-hover:text-text-loud'">
              {{ advertisement?.name }}
            </BaseText>
          </div>
        </button>
        <!-- Brand Actions Popup -->
        <transition>
          <div v-if="brandOptionsOpen && !isManualUpload" class="absolute" 
          v-on-clickaway="() => { brandOptionsOpen = false }" style="top: calc(100% + 4px); left: 0px; z-index: 100;">
            <BrandActionsTooltip 
              :brandId="advertisement?.brandId || advertisement?.brand_Id"
              :brandAdLibraryId="brandAdLibraryId"
              :brandDiscoveryAdCount="brandDiscoveryAdCount"
              :canTrackAsSpyder="canTrackAsSpyder"
            />
          </div>
        </transition>
      </div>

      <!-- Live time -->
      <div v-if="showLiveStatus" class="relative flex items-center mr-2.5"
      :class="{'ml-auto': showBrandDetails}">
        <!-- Live time label -->
        <div class="flex items-center gap-1 px-1 py-1.5" :class="brandOptionsOpen ? 'cursor-default' : 'cursor-pointer'"
        @mouseenter="() => { if (!brandOptionsOpen) showLiveTimeTooltip = true }" @mouseleave="showLiveTimeTooltip = false">
          <div class="h-1.5 w-1.5 rounded-full" :style="{backgroundColor: advertisement?.live ? '#00C7A9' : '#DFE1E7'}" />
          <BaseText type="label" size="xs" class="text-text-muted">
            {{ `${formatRunningDuration(advertisement?.startedRunning, advertisement?.lastChecked || advertisement?.last_checked)}D` }}
          </BaseText>
        </div>
        <!-- Live time tooltip -->
        <transition>
          <div v-if="showLiveTimeTooltip" class="absolute text-left flex flex-col p-1 rounded-md bg-neutral-700 shadow-lg"
          style="top: calc(100% + 6px); right: -2px; z-index: 100">
            <div class="arrow absolute top-1 rotate-45 right-3 transform -mt-2 rounded-sm w-4 h-4 bg-neutral-700" style="z-index: -10"/>
            <!-- Started Time -->
            <div class="flex gap-2 items-center py-1.5 px-2">
              <div class="w-1.5 h-1.5 rounded-full" :style="{backgroundColor: '#00A879'}" />
              <div class="text-xs text-white whitespace-nowrap">
                {{ advertisement?.startedRunning ? formatLiveDate(advertisement?.startedRunning) : "--" }}
              </div>
            </div>
            <!-- Ended Time -->
            <div class="flex gap-2 items-center py-1.5 px-2">
              <div class="w-1.5 h-1.5 rounded-full bg-text-subdued" />
              <div class="text-xs text-white whitespace-nowrap">
                {{ advertisement?.live ? "Still Running..." : formatLiveDate(advertisement?.lastChecked || advertisement?.last_checked) }}
              </div>
            </div>
            <!-- Days Between -->
            <div class="flex gap-2 items-center py-1.5 px-2 mt-1 rounded bg-icon-muted">
              <ClockIcon />
              <div class="text-xs text-white whitespace-nowrap">
                {{ 
                  `${formatRunningDuration(advertisement?.startedRunning, advertisement?.lastChecked || advertisement?.last_checked)} 
                   ${formatRunningDuration(advertisement?.startedRunning, advertisement?.lastChecked || advertisement?.last_checked) > 1
                    ? "Days"
                    : "Day"}`
                }}
              </div>
            </div>
          </div>
        </transition>
      </div>

      <div class="text-gray-500 text-md flex items-center gap-2" :class="{'ml-auto': !showBrandDetails && !showLiveStatus}">
        <BaseButton v-if="noDrawer && !hideCopyButton"
        v-clipboard:copy="getShareLink"
        v-clipboard:success="handleClipboardCopy"
        primary>
          <i class="fas fa-link mr-3 cursor-pointer" />
          Copy
        </BaseButton>
        <!-- Copy Shareable Link button (SwipeFile) -->
        <div v-if="canModifyAdBoard && !discovery && !adInInspo" class="relative">
          <button class="group p-1 rounded-md bg-white button-shadow"
          @mouseenter="copyLinkHovered = true" @mouseleave="copyLinkHovered = false"
          v-clipboard:copy="getLibraryShareLink" v-clipboard:success="handleClipboardCopy">
            <LinkWideRoundedIcon class="text-icon-normal transition-colors duration-300 group-hover:text-icon-loud" />
          </button>
          <!-- Info tooltip -->
          <transition>
            <div v-if="showCopyTooltip" class="absolute -right-3 px-2.5 py-1 rounded-md bg-neutral-700 w-max shadow-md"
            style="top: calc(100% + 8px); z-index: 1000">
              <BaseText type="body" size="sm" class="text-white">
                Copy Share Link
              </BaseText>
              <div class="arrow-top absolute" style="bottom: calc(100% - 1px); right: 20px" />
            </div>
          </transition>
        </div>
        <!-- Ad Details Drawer button -->
        <div v-if="!noDrawer && !adInInspo" class="relative">
          <button class="group relative p-1 rounded-md bg-white button-shadow"
          @mouseenter="adDetailsHovered = true" @mouseleave="adDetailsHovered = false"
          @click="adDetailsHovered = false, openDetailsDrawer()">
            <!-- Ad has comments indicator -->
            <div v-if="hasComments" class="absolute h-2 w-2 bg-primary-red-100 rounded-full -top-0.5 -left-0.5 z-50"/>
            <AdDetailsAnimatedIcon :isHovered="adDetailsHovered" />
          </button>
          <!-- Info tooltip -->
          <transition>
            <div v-if="showDetailsTooltip" class="absolute -right-3 px-2.5 py-1 rounded-md bg-neutral-700 w-max shadow-md"
            style="top: calc(100% + 8px); z-index: 1000">
              <BaseText type="body" size="sm" class="text-white">
                See Ad Details
              </BaseText>
              <div class="arrow-top absolute" style="bottom: calc(100% - 1px); right: 20px" />
            </div>
          </transition>
        </div>
      </div>
    </div>

    <!-- Description -->
    <div v-if="showAdCopy" class="bg-white pb-3">
      <AdvertisementInformation 
        :all-ad-settings="allAdSettings"
        :advertisement="advertisement"
        :open-details-drawer="openDetailsDrawer"
        :start-expanded="initAdCopyExpanded"
        @expandCopy="$emit('expandCopy')"
      />
    </div>
    

    <!-- Content -->
    <AdvertisementMediaContent :advertisement="advertisement" class="px-1 bg-white"
      ref="mediaContent"
      :shrink-media="shrinkMedia"
      rounded
      :class="relativeMedia ? 'relative' : ''"
      :carousel-index.sync="carouselIndex"
      :in-ad-details-drawer="inAdDetailsDrawer"
      @loaded-content="$emit('loaded-content')"
      @assetResized="$emit('assetResized')"
    />

    <!-- Headline & CTA -->
    <div v-if="showHeadlineCTA" class="bg-white px-1 pt-1.5">
      <div :class="headlineAlignment" class="w-full flex gap-2 py-2.5 px-3.5 rounded-lg bg-neutral-25">
        <div v-if="headlineUrl || headline" class="flex-grow flex flex-col min-w-0">
          <BaseText v-if="headlineUrl && headlineUrl.trim() !== ''" 
          type="heading" size="overline" class="text-text-normal truncate">
            {{ headlineUrl }}
          </BaseText>
          <BaseText ref="ctaHeadline"  v-if="headline && headline.trim() !== ''" 
          type="label" size="sm" class="text-text-muted break-words">
            {{  headline }}
          </BaseText>
          <BaseText v-if="(!headline || headline.trim() === '') && (!headlineUrl || headlineUrl.trim() === '')" 
          type="body" size="sm" class="text-text-normal truncate opacity-70">
            No Headline
          </BaseText>
        </div>
        <a v-if="ctaText" :href="advertisement?.link_url || (advertisement?.cards && advertisement.cards[carouselIndex]?.link_url) || ''" download target="_blank"
        class="group w-max max-w-1/3 px-2 py-1.5 rounded-md bg-neutral-50 truncate flex-shrink-0 transition-colors hover:bg-neutral-100">
          <BaseText type="label" size="sm" class="text-text-muted transition-colors group-hover:text-text-loud">
            {{ ctaText }}
          </BaseText>
        </a>
      </div>
    </div>

    <!-- Filters -->
    <div class="bg-white p-1 rounded-b-lg">
      <div v-if="adInInspo && !sharedBrief">
        <BaseButton class="w-full" danger 
        @click="toggleAddToInspo">
          Remove
        </BaseButton>
      </div>
      <div v-else-if="getIsInspoMode && !getIsAiInspoMode && !sharedBrief">
        <BaseButton class="w-full" primary placeholder-overlay="Select Boards..."
        @click="toggleAddToInspo">
          Add to Brief
        </BaseButton>
      </div>
      <div v-else-if="getIsInspoMode && getIsAiInspoMode && !sharedBrief">
        <BaseButton class="w-full" outlined placeholder-overlay="Select Boards..."
        @click="toggleAiAddToInspo">
          Choose Inspiration
        </BaseButton>
      </div>
      <!-- v-if conditional here is cursed bc the conditions are ported over from old versions (should be cleaned up) -->
      <SaveAdDropdown v-else-if="!inAdDetailsDrawer && ((canModifyAdBoard && !discovery && !sharedBrief && !smallAds) || (discovery || (getUser.user_id && sharedBoard)))"
        :advertisement="advertisement"
        :isSavedAd="isSavedAd"
        :isEditMode="isSavedAd"
        :adCardHovered="cardHovered"
        :externalAdSavedId="adDetailsSavedId"
        @adSaved="$emit('adSaved', $event)"
        @updateBoards="handleUpdateAdBoardsLocal($event)"
      />
      <!-- <DiscoverySave
        v-if="discovery || (getUser.user_id && sharedBoard)"
        :list-ad="true"
        :advertisement="advertisement"
        @promptSignup="$emit('promptSignup')"
      /> -->
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import FirebaseAPI from '@/api/firebase'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway2'
import TimeAgo from 'javascript-time-ago'
import moment from 'moment'

import DiscoverySave from '../advertisements/DetailsDrawerComponents/DiscoverySave'
import AdvertisementMediaContent from '../advertisements/AdvertisementMediaContent'
import AdvertisementInformation from '../advertisements/DetailsDrawerComponents/AdvertisementInformation.vue'
import SaveAdDropdown from '../advertisements/SaveAdDropdown/SaveAdDropdown.vue'
import BrandActionsTooltip from '../advertisements/BrandActionsTooltip.vue'

// Icons
import LinkWideRoundedIcon from '../globals/Icons/LinkWideRoundedIcon.vue'
import AdDetailsAnimatedIcon from '../globals/Icons/AdDetailsAnimatedIcon.vue'
import ChevronIcon from '../globals/Icons/ChevronIcon.vue'
import ClockIcon from '../globals/Icons/ClockIcon.vue'

export default {
  name: 'LibraryCard',
  mixins: [clickaway],
  components: {
    AdvertisementMediaContent,
    DiscoverySave,
    AdvertisementInformation,
    SaveAdDropdown,
    BrandActionsTooltip,
    LinkWideRoundedIcon,
    AdDetailsAnimatedIcon,  
    ChevronIcon,
    ClockIcon
  },
  props: {
    advertisement: {
      type: Object,
      default: () => {}
    },
    editBriefView: {
      type: Boolean,
      default: () => false
    },
    sharedBrief: {
      type: Boolean,
      default: () => false
    },
    // Hide boards
    hideBoards: {
      type: Boolean,
      default: false
    },
    sharedBoard: {
      type: Boolean,
      default: () => false
    },
    discovery: {
      type: Boolean,
      default: () => false
    },
    noDrawer: {
      type: Boolean,
      default: () => false
    },
    searchId: {
      type: String,
      default: () => null
    },
    smallAds: {
      type: Boolean,
      default: () => false
    },
    disableAdSettings: {
      type: Boolean,
      default: false
    },
    shrinkMedia: {
      type: Boolean,
      default: false
    },
    hideCopyButton: {
      type: Boolean,
      default: false
    },
    allAdSettings: {
      type: Boolean,
      default: false
    },
    relativeMedia: {
      type: Boolean,
      default: false
    },
    adDetailsSavedId: {
      type: String,
      default: ''
    },
    inAdDetailsDrawer: {
      type: Boolean,
      default: false
    },
    initAdCopyExpanded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showControls: false,
      selectedBoards: [],
      timeAgo: null,
      hasComments: false,
      showError: false,
      carouselIndex: 0,
      copyLinkHovered: false,
      adDetailsHovered: false,
      brandOptionsOpen: false,
      brandDiscoveryAdCount: 0,
      brandAdLibraryId: null,
      cardHovered: false,

      // tooltip states
      showLiveTimeTooltip: false,
      showCopyTooltip: false,
      showDetailsTooltip: false,
      copyTooltipTimeout: null,
      detailsTooltipTimeout: null,

      // Component mount check
      isMounted: false,
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser', 'getUserName']),
    ...mapGetters('MiscModule', ['getTheme']),
    ...mapGetters('BriefsModule', [
      'getInspoAds',
      'getIsInspoMode',
      'getIsAiInspoMode',
      'getEditingBrief'
    ]),
    ...mapGetters('BoardsModule', ['getBoards']),
    adInInspo () {
      return (
        this.getInspoAds.includes(this.advertisement.id) || this.editBriefView
      )
    },
    getShareLink () {
      return (
        process.env.VUE_APP_BASE_URL +
        '/shared-discover/' +
        encodeURIComponent(this.advertisement.id) +
        '?user=' +
        encodeURIComponent('a foreplay user')
      )
    },
    getLibraryShareLink () {
      return (
        process.env.VUE_APP_BASE_URL +
        '/share/ads/' +
        encodeURIComponent(this.advertisement.id) +
        '?user=' +
        encodeURIComponent(this.getUser.user_id)
      )
    },
    canModifyAdBoard () {
      return !this.sharedBoard && !this.sharedBrief && !this.noDrawer
    },
    canTrackAsSpyder () {
      return this.$route.name !== 'SpyderView' 
        && this.brandAdLibraryId
        && !this.advertisement?.organic
        && (
          this.advertisement?.publisher_platform?.includes('facebook') 
          || this.advertisement?.publisher_platform?.includes('instagram')
          || this.advertisement?.publisher_platform?.includes('messenger')
          || this.advertisement?.publisher_platform?.includes('audience_network')
        )
    },
    showBrandDetails () {
      return (
        this.getUser?.adSettings?.some((setting) => setting.name === 'Brand Details' && setting.value === 'Brand Details') 
        || !this.getUser?.adSettings 
      ) || this.allAdSettings
    },
    showLiveStatus () {
      const lastChecked = this.advertisement?.lastChecked || this.advertisement?.last_checked
      return lastChecked 
        && this.advertisement?.startedRunning
        && (lastChecked._seconds * 1000 > this.advertisement?.startedRunning)
        && !this.advertisement?.publisher_platform?.includes('tiktok') 
        && !this.advertisement?.publisher_platform?.includes('linkedin') 
        && !this.advertisement.organic
        && this.advertisement?.name !== 'Manual Upload'
    },
    showAdCopy () {
      return (
        (this.advertisement?.description || (
          ['dpa', 'dco'].includes(this.advertisement?.type)
          && this.advertisement?.cards?.some((card) => card?.description)
          )
        ) && this.getUser?.adSettings?.some((setting) => setting.name === 'Ad Copy' && setting.value === 'Ad Copy') 
        && !this.disableAdSettings
      ) || (this.allAdSettings && this.advertisement?.description)
    },
    showHeadlineCTA () {
      return (
        (this.ctaText || this.headlineUrl) 
        && this.getUser?.adSettings?.some((setting) => setting.name === 'Headline & CTA' && setting.value === 'Headline & CTA') 
        && !this.disableAdSettings
      ) || (this.allAdSettings && (this.ctaText || this.headlineUrl)) 
    },
    ctaText () {
      if (['dpa', 'carousel', 'dco'].includes(this.advertisement?.type)) {
        return this.advertisement?.cards[this.carouselIndex]?.cta_text || ''
      }
      return this.advertisement?.cta_text || ''
    },
    headlineUrl () {
      if (this.advertisement?.type === 'carousel') {
        const splitUrl = this.advertisement?.cards[this.carouselIndex]?.link_url?.split('/') || ''
        return splitUrl[2]?.toUpperCase() || ''
      }
      const splitUrl = this.advertisement?.link_url?.split('/') || ''
      return splitUrl[2]?.toUpperCase() || ''
    },
    headlineAlignment(){
      if (!this.isMounted) return 'items-center';
      const ctaHeadline = this.$refs.ctaHeadline?.$el;
      return ctaHeadline && ctaHeadline.clientHeight > 20 ? 'items-start' : 'items-center';
    },
    headline () {
      if (['dpa', 'carousel', 'dco'].includes(this.advertisement?.type)) {
        return this.advertisement?.cards[this.carouselIndex]?.headline
      }
      return this.advertisement?.headline
    },
    isManualUpload () {
      return this.advertisement?.name === 'Manual Upload'
    },
    isSavedAd () {
      return this.canModifyAdBoard && !this.discovery && !this.sharedBrief && !this.smallAds && !(this.getUser.user_id && this.sharedBoard)
    }
  },
  watch: {
    advertisement: {
      async handler () {
        try {
          const brand = await FirebaseAPI.Brands.get(this.advertisement.brandId || this.advertisement.brand_Id)
          if (brand) {
            this.brandDiscoveryAdCount = brand.adsSaved || 0
            this.brandAdLibraryId = brand?.adLibraryId
          }
        } catch (error) {
          console.error('Error fetching brand', error)
        }
      },
      immediate: true
    },
    getBoards (newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        this.refreshBoards()
      }
    },
    'advertisement.board_ids': function () {
      this.refreshBoards()
    },
    carouselIndex (val) {
      this.$emit('carouselIndex', val)
    },

    // Handle tooltip timing
    copyLinkHovered (val) {
      if (this.brandOptionsOpen) return
      if (this.copyTooltipTimeout) clearTimeout(this.copyTooltipTimeout)
      if (val) {
        this.copyTooltipTimeout = setTimeout(() => {
          this.showCopyTooltip = true
        }, 1500)
      } else {
        this.showCopyTooltip = false
      }
    },
    adDetailsHovered (val) {
      if (this.brandOptionsOpen) return
      if (this.detailsTooltipTimeout) clearTimeout(this.detailsTooltipTimeout)
      if (val) {
        this.detailsTooltipTimeout = setTimeout(() => {
          this.showDetailsTooltip = true
        }, 1500)
      } else {
        this.showDetailsTooltip = false
      }
    }
  },
  async mounted () {
    // Create formatter (English).
    this.timeAgo = new TimeAgo('en-US')

    if (!this.discovery) {
      this.checkForComment()
    }

    setTimeout(() => {
      this.refreshBoards()
    }, 100)

    this.isMounted = true;
  },
  methods: {
    ...mapMutations('BriefsModule', [
      'SET_INSPO_ADS',
      'SET_AI_INSPO_ADS',
      'SET_IS_INSPO_MODE',
      'SET_IS_AI_INSPO_MODE'
    ]),
    ...mapActions('BriefsModule', ['updateBriefField']),
    openDetailsDrawer (withCopyExpanded = false) {
      this.$emit('openDetails', withCopyExpanded)
    },
    formatLiveDate (date) {
      if (date.seconds) {
        const dateMS = date.seconds ? date.seconds * 1000 : date
        return moment(dateMS).format('MMM DD, YYYY')
      }

      const dateMS = date._seconds ? date._seconds * 1000 : date
      return moment(dateMS).format('MMM DD, YYYY')
    },
    formatRunningDuration (start, end) {
      let endTime = end._seconds * 1000

      if (isNaN(endTime)) {
        endTime = new Date().getTime()
      }
      // eslint-disable-next-line new-cap
      const startDate = new moment(start)
      // eslint-disable-next-line new-cap
      const endDate = new moment(endTime)

      return Math.ceil(moment.duration(endDate.diff(startDate)).as('days'))
    },
    async checkForComment () {
      const hasComments = await FirebaseAPI.Comments.hasComments(
        this.advertisement.id
      )
      this.hasComments = hasComments
    },
    // Refresh Boards
    refreshBoards () {
      this.selectedBoards = this.getBoards.filter((board) =>
        this.advertisement.board_ids?.includes(board.id)
      )
    },
    handleClipboardCopy () {
      this.$showAlert({
        message: 'Copied share link to clipboard!',
        type: 'success'
      })
    },
    handleUpdateAdBoardsLocal (boardIds) {
      const copyAd = { ...this.advertisement, board_ids: boardIds }
      this.$emit('updateAds', [copyAd])
    },
    async toggleAddToInspo () {
      // If already in inspo mode
      if (this.getIsInspoMode) {
        // If it's already included in the inspo, remove it
        if (this.getInspoAds.includes(this.advertisement.id)) {
          this.SET_INSPO_ADS(
            this.getInspoAds.filter((id) => id !== this.advertisement.id)
          )
        } else {
          // Add the ad to the brief inspo
          this.SET_INSPO_ADS([...this.getInspoAds, this.advertisement.id])
        }
      } else {
        // In the EditBriefView
        // Remove the ad from the brief and save
        const savePayload = {
          field: 'adIds',
          value: this.getEditingBrief.adIds.filter((adId) => adId !== this.advertisement.id)
        }

        this.updateBriefField(savePayload);
      }
    },
    playVideoAtTimestamp (time) {
      // This func is called directly on the component instance from a parent
      const mediaContent = this.$refs.mediaContent
      if (mediaContent) {
        mediaContent.playVideoAtTimestamp(time)
      }
    },
    setCarouselIndex (index) {
      // This func is called directly on the component instance from a parent
      const mediaContent = this.$refs.mediaContent
      if (mediaContent) {
        mediaContent.setCarouselIndex(index)
      }
    }
  }
}
</script>

<style scoped>
.card-shadow {
  box-shadow: 0px 0px 0px 1px rgba(24, 48, 123, 0.04), 0px 1px 1px -0.5px rgba(26, 48, 84, 0.04), 0px 2px 2px -1px rgba(26, 48, 84, 0.03), 0px 3px 3px -1.5px rgba(26, 48, 84, 0.03), 0px 5px 5px -2.5px rgba(26, 48, 84, 0.02), 0px 8px 8px -4px rgba(26, 48, 84, 0.02);
}

.button-shadow {
  transition: box-shadow 300ms ease-in-out;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}
.button-shadow:hover {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.16);
}
.arrow-top {
  width: 0; 
  height: 0; 
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 7px solid #212431;
}

.v-enter-active, .v-leave-active {
  transition: opacity 150ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
</style>
