<template>
  <BaseModal @close="$emit('close')" cardWidth="540px" noPadding noToolbarPadding noExit>
    <template #toolbar>
      <div class="flex items-center gap-2 pt-1 pr-5 pb-5">
        <BaseText type="label" size="sm" class="text-text-muted">
          Report
        </BaseText>
        <div v-if="advertisement.ad_id" class="flex items-center gap-1 py-0.5 pr-1.5 rounded-md bg-neutral-25"
        :class="adAsset ? 'pl-0.5' : 'pl-1.5'">
          <img v-if="adAsset" :src="adAsset" alt="" class="w-5 h-5" style="border-radius: 4px" />
          <BaseText type="label" size="sm" class="text-text-muted truncate" style="max-width: 150px">
            {{ advertisement.ad_id }}
          </BaseText>
        </div>
        <BaseText v-else type="label" size="sm" class="text-text-muted">
          advertisement
        </BaseText>
        <BaseText type="label" size="sm" class="text-text-muted">
          by
        </BaseText>
        <div class="flex items-center gap-1 py-0.5 pr-1.5 rounded-md bg-neutral-25"
        :class="advertisement.avatar ? 'pl-0.5' : 'pl-1.5'">
          <img v-if="advertisement.avatar" :src="advertisement.avatar" alt="" class="w-5 h-5" style="border-radius: 4px" />
          <BaseText type="label" size="sm" class="text-text-muted truncate" style="max-width: 170px">
            {{ advertisement.name }}
          </BaseText>
        </div>
      </div>
      <button class="absolute top-3 right-3 p-1.5" @click="$emit('close')">
        <TagRemoveIcon stroke="#5E6678" />
      </button>
    </template>
    <template #default>
      <div class="w-full flex flex-col">
        <!-- Loading state -->
        <div v-if="loading" class="relative w-full border-t border-b border-border-normal" style="height: 166px">
          <div class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <BaseLoadingLogo />
          </div>
        </div>
        <!-- User already reported ad state -->
        <div v-else-if="userHasReportedAd" class="relative w-full border-t border-b border-border-normal" style="height: 166px">
          <div class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center">
            <WarningIcon class="text-icon-disabled" :width="48" :height="48" />
            <BaseText type="label" size="md" class="text-text-muted mt-1">
              Advertisement has been reported
            </BaseText>
            <BaseText type="body" size="sm" class="text-text-normal text-center mt-1">
              Our team will review this ad shortly
            </BaseText>
          </div>
        </div>
        <!-- Main state -->
        <div v-else class="w-full flex flex-col gap-2 p-5 border-t border-b border-border-normal">
          <BaseText type="label" size="sm" class="text-text-muted">
            Message
          </BaseText>
          <textarea class="report-text w-full rounded-md py-2.5 px-3 overflow-y-scroll scrollbar-hide"
          v-model="reportMessage" placeholder="What is the reason for this report?" @keydown.enter="handleFormKeyDown" />
        </div>
        <div class="w-full flex items-center justify-between p-5" style="height: 72px">
          <button class="px-2 py-1.5 rounded-md transition-colors hover:bg-neutral-25"
          @click="$emit('close')">
            <BaseText type="label" size="sm" class="text-text-muted">
              Cancel
            </BaseText>
          </button>
          <BaseButton destroy :loading="reportLoading" :disabled="reportDisabled"
          @click="handleReportAdvertisement" paddingX="4px">
            <div class="flex items-center gap-1.5">
              <WarningIcon class="transition-colors" :class="reportDisabled ? 'text-icon-disabled' : 'text-white'" />
              <BaseText type="label" size="sm">
                Submit
              </BaseText>
            </div>
          </BaseButton>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import FirebaseAPI from '@/api/firebase'

// Icons
import TagRemoveIcon from '../../globals/Icons/TagRemoveIcon.vue'
import WarningIcon from '../../globals/Icons/WarningIcon.vue'

export default {
  name: 'ReportAdModal',
  components: {
    TagRemoveIcon,
    WarningIcon
  },
  props: {
    advertisement: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      loading: true,
      userHasReportedAd: false,
      reportMessage: '',
      reportLoading: false,
    }
  },
  async mounted () {
    // Check if the user has already reported this ad
    try {
      const hasReported = await FirebaseAPI.Reports.checkUserHasReportedAd(this.advertisement.id)
      console.log('User has reported ad:', hasReported)
      this.userHasReportedAd = hasReported
    } catch (error) {
      console.error('Failed to check if user has reported ad:', error)
    } finally {
      this.loading = false
    }
  },
  computed: {
    adAsset () {
      if (['carousel', 'dco'].includes(this.advertisement?.type) && this.advertisement?.cards?.length) {
        return this.advertisement.cards[0].image || this.advertisement.cards[0].thumbnail || null
      }
      return this.advertisement.image || this.advertisement.thumbnail || null
    },
    reportDisabled () {
      return !this.reportMessage.length || this.reportLoading
    }
  },
  methods: {
    handleFormKeyDown (event) {
      if (event.shiftKey && event.key === 'Enter') return // Allow shift+enter for new lines
      if (event.key === 'Enter') {
        event.preventDefault()
        this.handleReportAdvertisement()
      }
    },
    async handleReportAdvertisement () {
      if (this.reportDisabled) return
      this.reportLoading = true
      try {
        const payload = {
          reportedAd: this.advertisement.id,
          message: this.reportMessage,
        }
        await FirebaseAPI.Reports.create(payload)
        this.$showAlert({
          type: 'info',
          message: 'Report submitted',
        })
      } catch (error) {
        console.error('Report failed:', error)
        this.$showAlert({
          type: 'error',
          message: 'Failed to report advertisement',
        })
      } finally {
        this.reportLoading = false
        this.$emit('close')
      }
    }
  }
}
</script>

<style scoped>
.report-text {
  height: 96px;

  border: none;
  outline: none;

  box-shadow: 0px 1px 2px 0px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);

  resize: none; /*remove the resize handle on the bottom right*/

  font-feature-settings: 'ss01' on, 'cv10' on, 'liga' off, 'calt' off;
  /* Body/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.report-text::placeholder {
  color: #808899;
  opacity: 1;
  transition: opacity 150ms ease-in-out;

  font-feature-settings: 'ss01' on, 'cv10' on, 'liga' off, 'calt' off;
  /* Body/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.report-text:focus::placeholder {
  opacity: 0.5;
}
</style>