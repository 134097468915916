import store from '../../../store'

// We need misc state module here
function updateDiscoverAdTotal (count) {
  store.commit('MiscModule/SET_DISCOVERY_AD_COUNT', count)
}

async function statsSetup (socket) {
  socket.on('totalAdsCount', updateDiscoverAdTotal)
}

export default statsSetup
