<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M16 8.5V6.625C16 5.17525 14.8247 4 13.375 4H6.625C5.17525 4 4 5.17525 4 6.625V8.5M16 8.5V13.375C16 14.8247 14.8247 16 13.375 16H6.625C5.17525 16 4 14.8247 4 13.375V8.5M16 8.5H4" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
    <path d="M9.34375 11.6875C9.34375 12.0499 9.6376 12.3438 10 12.3438C10.3624 12.3438 10.6562 12.0499 10.6562 11.6875C10.6562 11.3251 10.3624 11.0312 10 11.0312C9.6376 11.0312 9.34375 11.3251 9.34375 11.6875Z" 
    :fill="stroke" :stroke="stroke" stroke-width="0.5625" stroke-linecap="square"/>
  </svg>
</template>

<script>
  export default {
    name: 'AccessoriesNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>