<template>
  <svg
    :width="width"
    :Height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.67925 7.31604C9.87773 7.20145 10.1223 7.20145 10.3208 7.31604L12.1792 8.38905C12.3777 8.50364 12.5 8.71542 12.5 8.94461V11.0906C12.5 11.3198 12.3777 11.5316 12.1792 11.6462L10.3208 12.7192C10.1223 12.8338 9.87773 12.8338 9.67925 12.7192L7.82075 11.6462C7.62227 11.5316 7.5 11.3198 7.5 11.0906V8.94461C7.5 8.71542 7.62227 8.50364 7.82075 8.38905L9.67925 7.31604Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M10 7.08301V3.33301"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 16.6663V13.333"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5 8.33366L15.3867 6.66699"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.3868 13.3337L12.5 11.667"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.05472 8.33366L4.16797 6.66699"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.16598 13.3337L7.05273 11.667"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.70963 3.27876C9.50892 2.81729 10.4937 2.81729 11.293 3.27876L15.1765 5.52093C15.9758 5.9824 16.4682 6.83522 16.4682 7.75816V12.2425C16.4682 13.1654 15.9758 14.0183 15.1765 14.4797L11.293 16.7219C10.4937 17.1834 9.50892 17.1834 8.70963 16.7219L4.82609 14.4797C4.0268 14.0183 3.53442 13.1654 3.53442 12.2425V7.75816C3.53442 6.83522 4.0268 5.9824 4.82609 5.52093L8.70963 3.27876Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linejoin="round"
    />

  </svg>
</template>

<script>
export default {
  name: 'MetricsIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
