<template>
  <svg
    :width="width"
    :height="width"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 13V13.0075M10 10.75C10 9.5206 11.5 9.94893 11.5 8.5C11.5 7.67157 10.8284 7 10 7C9.44478 7 8.96005 7.30165 8.7007 7.75M16.75 10C16.75 13.728 13.728 16.75 10 16.75C6.27208 16.75 3.25 13.728 3.25 10C3.25 6.27208 6.27208 3.25 10 3.25C13.728 3.25 16.75 6.27208 16.75 10Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  name: 'QuestionIcon',
  props: {
    width: {
      type: Number,
      default: () => 20
    }
  }
}
</script>
<style scoped lang="css"></style>
