import Fuse from 'fuse.js'
// Import each metric section
import { performanceMetrics, performanceSearchList } from './performanceMetrics'
import { engagementMetrics, engagementSearchList } from './engagementMetrics'
import { conversionMetrics, conversionSearchList } from './conversionMetrics'
import _ from 'lodash'

const allMetrics = _.flatten([
  performanceSearchList,
  engagementSearchList,
  conversionSearchList
])

const kpiLookup = allMetrics.reduce((acc, { key, title }) => {
  acc[key] = title
  return acc
}, {})

Object.freeze(allMetrics)

const options = {
  includeScore: true,
  keys: ['title'],
  ignoreLocation: true,
  threshold: 0.3
}

const fuse = new Fuse(allMetrics, options)

export {
  performanceMetrics,
  engagementMetrics,
  conversionMetrics,
  kpiLookup,
  fuse
}
